import {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {getAuthCookie} from '../cookies/cookies';
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import HelpPanel from "@cloudscape-design/components/help-panel";
import {Select, TextContent} from "@cloudscape-design/components";
import Modal from "@cloudscape-design/components/modal";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import Toggle from "@cloudscape-design/components/toggle";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import Spinner from "@cloudscape-design/components/spinner";
import RadioGroup from "@cloudscape-design/components/radio-group";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Wizard from "@cloudscape-design/components/wizard";
import Alert from "@cloudscape-design/components/alert";

export function PuzzleWizardV2(props) {

  const inputReference = useRef(null);
  const navigate = useNavigate();

  const rows = 16;
  const cols = 20;
  const cellWidth = 40;
  const cellHeight = 25;
  const languageCharsMap = {
    'russian': "йцукенгшщзхъёфывапролджэячсмитьбюў",
    'italian': "aàábcdeèéfghiìíjklmnoòópqrstuùúvwxyz",
    'german': "aäbcdeëfghijklmnoöpqrsßtuüvwxyz",
    'american': "abcdefghijklmnopqrstuvwxyz"
  }

  // header instructions
  const [editType, setEditType] = useState(undefined);
  // initial empty puzzle matrix rendered in the canvas with size = rows x cols
  const [wordPuzzle, setWordPuzzle] = useState(
    Array.from(Array(rows)).map((_, k) => k + 1).map(k =>
       Array.from(Array(cols)).map((_, i) => i + 1).map(i => ({ value: undefined }))
    )
  );
  // the collected horizontal or vertical words
  const [allWords, setAllWords] = useState({ horizontal: [], vertical: []});
  // the cell where the input is selected for edition
  const [startingCell, setStartingCell] = useState(false);
  // the direction arrow of the input to be created
  const [cellDirection, setCellDirection] = useState(undefined);
  // the maximum amount of cells to display when editing depending on the starting cell and the direction up to the matrix borders
  const [newMaxWordLength, setNewMaxWordLength] = useState(0);
  // whether the edition modal can be displayed
  const [visiblePuzzleModal, setVisiblePuzzleModal] = useState(false);
  // an ephemeral word puzzle (copy) required for updating, meaning pre removes words that are being updated
  const [baseLinePuzzle, setBaseLinePuzzle] = useState([]);
  // the word puzzle copy where the temporary changes are made while in the editing modal
  const [copyWordPuzzle, setCopyWordPuzzle] = useState([])
  // an indicator when the new word is being created/edited on the edit modal
  const [loadingWord, setLoadingWord] = useState(false);
  // the new word being created on the edit modal
  const [newWord, setNewWord] = useState("");
  // [DEPRECATED ???] whether to display a reason for not allowing word creation
  const [warningMessage, setWarningMessage] = useState(false);
  // the description or hint for the word being created
  const [description, setDescription] = useState("");
  // whether to show i,j indexes on the empty matrix puzzle for position reference
  const [showIndexes, setShowIndexes] = useState(false);
  // whether to shon only the created answers on the matrix puzzle and ignore the rest
  const [answersOnly, setAnswersOnly] = useState(false);
  // a mapping of instructions based out of the starting cell and direction arrow to be used on the edition modal
  const directionMapping = {
    "ArrowDown": {
                   name: "Vertical->Down",
                   type: "vertical",
                   i: (k) => k, j: (k) => 0,
                   maxWords: (cell) => rows - cell.row,
                   maxWidth: (maxLen) => cellWidth * 3,
                   hasRow: (i, cell) => i >= cell.row,
                   hasCol: (j, cell) => j == cell.col,
                   color: "tomato"
               },
    "ArrowUp": {
                 name: "Vertical->Up",
                 type: "vertical",
                 i: (k) => -k, j: (k) => 0,
                 maxWords: (cell) => cell.row + 1,
                 maxWidth: (maxLen) => cellWidth * 3,
                 hasRow: (i, cell) => i <= cell.row,
                 hasCol: (j, cell) => j == cell.col,
                 color: "tomato"
               },
    "ArrowRight": {
                    name: "Horizontal->Right",
                    type: "horizontal",
                    i: (k) => 0, j: (k) => k,
                    maxWords: (cell) => cols - cell.col,
                    maxWidth: (maxLen) => cellWidth * (maxLen+2),
                    hasRow: (i, cell) => i == cell.row,
                    hasCol: (j, cell) => j >= cell.col,
                    color: "lightgreen"
                  },
    "ArrowLeft": {
                   name: "Horizontal->Left",
                   type: "horizontal",
                   i: (k) => 0,
                   j: (k) => -k,
                   maxWords: (cell) => cell.col + 1,
                   maxWidth: (maxLen) => cellWidth * (maxLen+2),
                   hasRow: (i, cell) => i == cell.row,
                   hasCol: (j, cell) => j <= cell.col,
                   color: "lightgreen"
                 },
  }

  const [isLoading, setIsLoading] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);
  const [launchingPreview, setLaunchingPreview] = useState(false);
  const [showLaunchPreviewModal, setShowLaunchPreviewModal] = useState(false);
  const [selectedValidChars, setSelectedValidChars] = useState(languageCharsMap['russian']);
  const [selectedMaxHintsOption, setSelectedMaxHintsOption] = useState(undefined);
  const [settings, setSettings] = useState({});
  const [questionTitle, setQuestionTitle] = useState(props.defaultTopicTitle);

  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [alertInfo, setAlertInfo] = useState(false);

  function loadState() {
    const i = props.topicIndex - 1;
    // step 1: title
    setQuestionTitle(props.topics[i].option.md.titleOverride);

    // step 2: media
    setSelectedValidChars(props.topics[i].selectedValidChars);
    setAllWords(props.topics[i].option.qs);
    setWordPuzzle(props.topics[i].wordPuzzle)
  }

  function saveState() {
    const newTopic = {
      title: questionTitle,
      selectedValidChars: selectedValidChars,
      selectedMaxHintsOption: selectedMaxHintsOption,
      wordPuzzle: wordPuzzle,
      option: composeTopicOption()
    };
    let tmpTopics = [...props.topics]
    if (props.topicIndex) {
      const i = props.topicIndex - 1;
      newTopic.index = i;
      tmpTopics[i] = newTopic;
    } else {
      newTopic.index = tmpTopics.length;
      tmpTopics.push(newTopic);
    }
    props.setTopics(tmpTopics);
  }

  /*
    Topic option ready to preview and submit
  */
  function composeTopicOption() {
    return ({
        md: {
          titleOverride: questionTitle
        },
        size: {
          rows: rows, cols: cols
        },
        validChars: selectedValidChars ? languageCharsMap[selectedValidChars] : undefined,
        qs: allWords
      });
  }

  // always reset panel on every step switch
  useEffect(() => {
    props.routerProps.resetSplitPanel();
    setAlertInfo(false);
  }, [activeStepIndex])

  useEffect(() => {
    if (props.topicIndex) {
      loadState();
    }
  }, []);

  useEffect(() => {
    if (visiblePuzzleModal && inputReference && inputReference.current) inputReference.current.focus()
  }, [visiblePuzzleModal]);

  useEffect(() => {
    setIsLoading(true);
    props.routerProps.resetSplitPanel();
    // in order to force sign in after user log in is expired
    if (!getAuthCookie()) { props.routerProps.setAuthUser(undefined); navigate(`/`); }
    else {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, []);

  /*
    Remove Word from Puzzle
  */
  function voidWordInWordPuzzle(wordToVoid, tmpWordPuzzle) {
    for (let k=0;k<wordToVoid.word.length;k++) {
      const i = wordToVoid.startingCell.row + directionMapping[wordToVoid.cellDirection].i(k);
      const j = wordToVoid.startingCell.col + directionMapping[wordToVoid.cellDirection].j(k);
      const oldColor = tmpWordPuzzle[i][j].color;
      const oldValue = tmpWordPuzzle[i][j].value;
      const oldHorizontal = tmpWordPuzzle[i][j].horizontal;
      const oldVertical = tmpWordPuzzle[i][j].vertical;
      switch (directionMapping[wordToVoid.cellDirection].type) {
        case "horizontal":
             tmpWordPuzzle[i][j] = {
               value: oldColor === "gray" ? oldValue : undefined,
               color: oldColor === "gray" ? "tomato" : undefined,
               vertical: oldVertical
             };
        break;
        case "vertical":
             tmpWordPuzzle[i][j] = {
               value: oldColor === "gray" ? oldValue : undefined,
               color: oldColor === "gray" ? "lightgreen" : undefined,
               horizontal: oldHorizontal
             };
        break;
      }
    }
    return tmpWordPuzzle;
  }

  function exitCreate(editType) {
    setNewWord("");
    setDescription("");
    setStartingCell(undefined);
    setCellDirection(undefined);
    setWarningMessage(undefined);
    setLoadingWord(false);
    setVisiblePuzzleModal(false);
  }

  function doUpdateWordPuzzle() {
    setLoadingWord(true);
    let newWordPuzzle = baseLinePuzzle.map(wpRow => wpRow.map(wpCell => wpCell));
    let newVertical = allWords.vertical.map(item => item)
    let newHorizontal = allWords.horizontal.map(item => item)
    const name = directionMapping[cellDirection].name;

    let indexValue = undefined;
    switch (directionMapping[cellDirection].type) {
      case "vertical": indexValue = newVertical.length;
      break;
      case "horizontal": indexValue = newHorizontal.length;
      break;
    }

    for (let k=0;k<newWord.length;k++) {
      let tmpColor = directionMapping[cellDirection].color;
      const i = startingCell.row + directionMapping[cellDirection].i(k);
      const j = startingCell.col + directionMapping[cellDirection].j(k);
      if (newWordPuzzle[i][j].value) {
        if (newWordPuzzle[i][j].value !== `${newWord.charAt(k)}`) {
          setLoadingWord(false);
          setWarningMessage(true);
          return;
        } else if (newWordPuzzle[i][j].color !== tmpColor) {
          tmpColor = 'gray';
        }
      }
      newWordPuzzle[i][j] = {
        value: `${newWord.charAt(k)}`,
        color: tmpColor,
        horizontal: newWordPuzzle[i][j].horizontal,
        vertical:  newWordPuzzle[i][j].vertical
      };
      newWordPuzzle[i][j][directionMapping[cellDirection].type] = indexValue+1;
      console.log(JSON.stringify(newWordPuzzle[i][j]))
    }
    switch (directionMapping[cellDirection].type) {
      case "vertical":
        newVertical.push({
          startingCell: startingCell, word: newWord, description: description, cellDirection: cellDirection
        });
      break;
      case "horizontal":
        newHorizontal.push({
          startingCell: startingCell, word: newWord, description: description, cellDirection: cellDirection
        });
      break;
    }
    setAllWords({ vertical: newVertical, horizontal: newHorizontal });
    setWordPuzzle(newWordPuzzle);
    setTimeout(() => {
      exitCreate();
    }, 750);
  }

    return (
      <Wizard
        i18nStrings={{
          stepNumberLabel: stepNumber => `Step ${stepNumber}`,
          collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
          skipToButtonLabel: (step, stepNumber) => `Skip to ${step.title}`,
          navigationAriaLabel: "Steps",
          cancelButton: "Cancel",
          previousButton: "Previous",
          nextButton: "Next",
          submitButton: "Submit",
          optional: "optional"
        }}
        onNavigate={({ detail }) =>
          setActiveStepIndex(detail.requestedStepIndex)
        }
        onCancel={() => {
          setAlertInfo(false);
          props.setAddTopicQuestion(false);
        }}
        onSubmit={() => {
          if (allWords
              && (allWords.vertical && allWords.vertical.length) && (allWords.horizontal && allWords.horizontal.length)
              && selectedValidChars) {
            setAlertInfo(false);
            saveState();
            props.setAddTopicQuestion(false);
          } else {
            setAlertInfo(true);
            setTimeout(() => setAlertInfo(false), 2000)

          }
        }}
        activeStepIndex={activeStepIndex}
        allowSkipTo
        steps={[
          {
            title: "Title",
            isOptional: false,
            description: "Topic instructions",
            content: (
              <Container>
                <Input
                  invalid={!questionTitle || questionTitle.length == 0}
                  value={questionTitle}
                  onChange={({detail}) => setQuestionTitle(detail.value)}
                />
              </Container>
            ),
          },
          {
            title: "Alphabet",
            isOptional: false,
            description: "Input supported alphabet characters",
            content: (
              <Container>
                <RadioGroup
                  onChange={({ detail }) => setSelectedValidChars(detail.value)}
                  value={selectedValidChars}
                  items={[
                    { value: 'russian', label: "Russian [no accents]", validChars: "йцукенгшщзхъёфывапролджэячсмитьбюЙЦУКЕНГШЩЗХЪЁФЫВАПРОЛДЖЭЯЧСМИТЬБЮ" },
                    { value: 'italian', label: "Italian", validChars: "aAàÀáÁbBcCdDeEèÈéÉfFgGhHiIìÌíÍjJkKlLmMnNoOòÒóÓpPqQrRsStTuUùÙúÚvVwWxXyYzZ" },
                    { value: 'german', label: "German", validChars: "aAäÄbBcCdDeEëËfFgGhHiIjJkKlLmMnNoOöÖpPqQrRsSßtTuUüÜvVwWxXyYzZ" },
                    { value: 'american', label: "American", validChars: "aAbBcCdDeEfFgGhHiIjJkKlLmMnNoOpPqQrRsStTuUvVwWxXyYzZ" },
                  ]}
                />
              </Container>
            ),
          },
          {
            title: "Puzzle Editor",
            isOptional: false,
            description: (
              <div>
                <TextContent>To enter Create Wizard: <em>click on any cell then press an Arrow key to create a new word in that direction</em></TextContent>
                <TextContent>To enter Update Wizard: <em>click on any </em><em style={{color:'green'}}>green</em> | <em style={{color:'red'}}>red</em><em> color cell then press Enter or an Arrow to edit that word.</em></TextContent>
              </div>
            ),
            content: (
    <Container fitHeight={true}>
      <SpaceBetween direction="vertical" size="s">
      <div>
    {alertInfo && <Alert
      onDismiss={() => {
        setAlertInfo(false);
      }}
      dismissible
      type={"error"}
      statusIconAriaLabel={"Error"}
      header="Exception"
    >
      <TextContent>At least 1 vertical and 1 horizontal word(s) must be created, and an alphabet option must be selected!</TextContent>
    </Alert>}
        <Modal
              onDismiss={() => {
                exitCreate();
              }}
              visible={visiblePuzzleModal}
              closeAriaLabel="Close"
              footer={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      disabled={newWord.length > newMaxWordLength || description.length == 0}
                      variant="primary"
                      loading={loadingWord}
                      onClick={() => {
                        doUpdateWordPuzzle();
                      }}
                      >{editType}
                    </Button>
                    <Button variant="normal" onClick={() => {
                        exitCreate();
                      }}
                      >Cancel
                    </Button>
                  </SpaceBetween>
                </Box>
              }
              header={
                <SpaceBetween size="l" direction="horizontal">
                  {`${editType} Word @Cell(${startingCell ? (startingCell.row + 1) : ""},${startingCell ? (startingCell.col + 1) : ""})`}
                </SpaceBetween>}
          >
          <SpaceBetween direction="vertical" size="s">
            {warningMessage && <TextContent>{"Can't set word "+`${newWord}`}</TextContent>}
            <ColumnLayout columns={2}>
              <div>{`Word [max length: ${newMaxWordLength}]`}</div>
              <div>
                <Input disabled={loadingWord}
                  ref={inputReference}
                  onChange={({ detail }) => {
                    let tmpCopyWP = baseLinePuzzle.map(rowCopy => rowCopy.map(cellCopy =>
                      ({ value: cellCopy.value, color: cellCopy.color, horizontal: cellCopy.horizontal, vertical: cellCopy.vertical })));
                    const tmpNewWord = detail.value;
                    let truncatedWord = "";
                    setWarningMessage(undefined);
                    // TODO: it should check first if any word in the same direction either +1/-1 are not neighbours every step of the way
                    for (let k=0;k<newMaxWordLength;k++) {
                      const i = startingCell.row + directionMapping[cellDirection].i(k);
                      const j = startingCell.col + directionMapping[cellDirection].j(k);
                      if (k < tmpNewWord.length) {
                        let tmpColor = directionMapping[cellDirection].color;
                        if (baseLinePuzzle[i][j].value) {
                          // if cell direction already exist from another word with the same direction
                          // or cell doesn't match the value from another direction then discard
                          if (baseLinePuzzle[i][j][directionMapping[cellDirection].type]
                              || baseLinePuzzle[i][j].value !== `${tmpNewWord.charAt(k)}`) {
                            setNewWord(truncatedWord);
                            setCopyWordPuzzle(tmpCopyWP);
                            return;
                          }
                          tmpColor = "gray";
                        }
                        tmpCopyWP[i][j].value = `${tmpNewWord.charAt(k)}`;
                        truncatedWord += `${tmpNewWord.charAt(k)}`;
                        tmpCopyWP[i][j].color = tmpColor;
                      } else {
                        tmpCopyWP[i][j] = baseLinePuzzle[i][j];
                      }
                    }
                    setNewWord(tmpNewWord);
                    setCopyWordPuzzle(tmpCopyWP);
                  }}
                  value={newWord}
                  invalid={newWord.length > newMaxWordLength || newWord.length == 1}
                />
              </div>
              <div>{`Puzzle Hint`}</div>
              <div>
                <Input disabled={loadingWord}
                  onChange={({ detail }) => {
                    setDescription(detail.value);
                  }}
                  value={description}
                  invalid={description == 0}
                />
              </div>
            </ColumnLayout>
            <Container fitHeight={true}>
            <div>
            {(startingCell && cellDirection) && copyWordPuzzle
              .filter((row, iRow) => directionMapping[cellDirection].hasRow(iRow, startingCell))
              .map((row, iRow) => (
                <div key={`puzzle-row-${iRow}`} style={{width: `${directionMapping[cellDirection].maxWidth(newMaxWordLength)}px`, display: 'flex',
                  flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}
                >
                {row
                  .filter((cell, jCell) => directionMapping[cellDirection].hasCol(jCell, startingCell))
                  .map((cell, jCell) =>
                    (<div key={`puzzle-cell-${iRow}-${jCell}`} style={{
                       borderStyle: 'solid', borderColor: 'gray', borderWidth: '1px',
                       height:`${cellHeight}px`, width:`${cellWidth}px`, color: (cell.value ? 'black' : 'gray'),
                       backgroundColor: cell.color || 'white', textAlign:'center', overflow: 'hidden',
                       whiteSpace: 'nowrap', display: 'block'}}
                     >
                     {cell.value}
                     </div>)
                )}
                </div>
              ))}
            </div>
            </Container>
          </SpaceBetween>
        </Modal>
        <Modal
              onDismiss={() => setShowLaunchPreviewModal(false)}
              visible={showLaunchPreviewModal}
              closeAriaLabel="Close"
              footer={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="normal" onClick={() => setShowLaunchPreviewModal(false)}
                      >Cancel
                    </Button>
                    <Button
                      disabled={!selectedValidChars || !selectedMaxHintsOption ||
                        selectedMaxHintsOption.value >
                        ((allWords.vertical ? allWords.vertical.length : 0) + (allWords.horizontal ? allWords.horizontal.length : 0))
                      }
                      variant="primary"
                      loading={launchingPreview}
                      onClick={() => {
                        setLaunchingPreview(true);
                        setTimeout(() => {
                          setLaunchingPreview(false);
                          setPreviewMode(!previewMode);
                          setShowLaunchPreviewModal(false);
                        }, 1000)
                      }}
                      >Launch
                    </Button>
                  </SpaceBetween>
                </Box>
              }
              header={
                <SpaceBetween size="l" direction="horizontal">
                  Launch Preview Settings
                </SpaceBetween>}
          >
          <SpaceBetween direction="vertical" size="s">
            <RadioGroup
              onChange={({ detail }) => setSelectedValidChars(detail.value)}
              value={selectedValidChars}
              items={[
                { value: 'russian', label: "Russian [no accents]" },
                { value: 'italian', label: "Italian" },
                { value: 'german', label: "German" },
                { value: 'american', label: "American" },
              ]}
            />
            <Select
                filteringType={"auto"}
                selectedOption={selectedMaxHintsOption}
                onChange={({ detail }) => {
                  setSelectedMaxHintsOption(detail.selectedOption);
                  let newSettings = settings;
                  newSettings["maxHintedAnswers"] = detail.selectedOption.value;
                  setSettings(newSettings);
                }}
                options={Array.from(Array(
                  (allWords.vertical ? allWords.vertical.length : 0) + (allWords.horizontal ? allWords.horizontal.length : 0) + 1))
                          .map((_, i) => i).map(v => ({ label: `${v}`, value: v }))}
                placeholder="Choose Max Hints"
                selectedAriaLabel="Selected"
            />
          </SpaceBetween>
        </Modal>
      </div>
      <div style={{
        borderRadius: '5px', backgroundColor: 'white', borderStyle: 'solid', borderWidth: '1px',
        width: `${cellWidth * (cols+2)}px`, display:'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}
      >
      {wordPuzzle
      .map((row, iRow) => (
        <div key={`puzzle-row-${iRow}`} style={{width: `${cellWidth * (cols+2)}px`, display: 'flex', flexDirection: 'row', flexWrap: 'nowrap',
          justifyContent: 'center'}}
        >
        {row.map((cell, jCell) =>
          (<div key={`puzzle-cell-${iRow}-${jCell}`} style={{borderRadius: (startingCell && startingCell.row == iRow && startingCell == jCell ? '0px' : '5px'), borderStyle: 'solid',
             borderColor: ((answersOnly && !cell.value) ? 'white' : ((startingCell && iRow == startingCell.row && jCell == startingCell.col) ? 'black' : 'lightgray')),
             borderWidth: '1px', height:`${cellHeight}px`, width:`${cellWidth}px`, color: (cell.value !== undefined ? 'black' : 'lightgray'),
             backgroundColor: cell.color || 'white', textAlign:'center', overflow: 'hidden', whiteSpace: 'nowrap', display: 'block'}}
              onClick={() => {
                const currentCell = wordPuzzle[iRow][jCell];
                setStartingCell({ row: iRow, col: jCell });
              }}
              onKeyDown={(e) => {
                // edge case if it got here without any cell value
                if (!startingCell) return;
                const currentCell = wordPuzzle[startingCell.row][startingCell.col];
                let newValue = `${e.key}`;
                // if current cell is empty and key pressed any of the 4 directional arrow then create
                const notExistAndGoesAnywhere = (!currentCell.value &&
                  (newValue === "ArrowDown" || newValue == "ArrowUp" || newValue == "ArrowRight" || newValue == "ArrowLeft"));
                // if current cell not empty and its part of a word in one direction and the new direction is perpendicular
                const existButEditsIntersection = (currentCell.value &&
                  ((currentCell.horizontal && !currentCell.vertical && (newValue === "ArrowDown" || newValue == "ArrowUp"))
                    || (currentCell.vertical && !currentCell.horizontal && (newValue === "ArrowRight" || newValue == "ArrowLeft"))));
                if (notExistAndGoesAnywhere || existButEditsIntersection) {
                    setCellDirection(newValue);
                    setNewMaxWordLength(directionMapping[newValue].maxWords(startingCell))
                    setTimeout(() => {
                      setBaseLinePuzzle(wordPuzzle.map(rowCopy => rowCopy.map(cellCopy =>
                        ({ value: cellCopy.value, color: cellCopy.color, horizontal: cellCopy.horizontal, vertical: cellCopy.vertical }))));
                      setCopyWordPuzzle(wordPuzzle.map(rowCopy => rowCopy.map(cellCopy =>
                        ({ value: cellCopy.value, color: cellCopy.color }))));
                      setEditType("Create");
                      setVisiblePuzzleModal(true);
                    }, 0);
                    return;
                }

                // at this point is attempting an updating over an existing cell
                if (e.key /*!russianString.includes(`${e.key}`)*/) {
                  // Enter Escape ArrowDown ArrowUp ArrowRight ArrowLeft
                  if (!(e.key === "Backspace" || e.key === "Enter" ||
                  newValue === "ArrowDown" || newValue === "ArrowUp" || newValue === "ArrowRight" || newValue === "ArrowLeft")) return;
                  newValue = undefined;
                  // if both horizontal and vertical indexes are present then it can not update cause of the ambiguity
                  if (currentCell.horizontal && currentCell.vertical) return;
                  // edge case if it got here without any cell direction
                  if (!(currentCell.horizontal || currentCell.vertical)) return;
                  // now it will identify the word to remove
                  let newHorizontal = [];
                  let newVertical = [];
                  let wordToVoid = {};
                  if (currentCell.horizontal) {
                    wordToVoid = allWords.horizontal[currentCell.horizontal - 1];
                    newHorizontal = allWords.horizontal.filter((val, h) => h != currentCell.horizontal-1).map(val => val);
                    newVertical = allWords.vertical;
                  }
                  if (currentCell.vertical) {
                    newHorizontal = allWords.horizontal;
                    wordToVoid = allWords.vertical[currentCell.vertical - 1];
                    newVertical = allWords.vertical.filter((val, v) => v != currentCell.vertical-1).map(val => val);
                  }
                  setAllWords({ horizontal: newHorizontal, vertical: newVertical });
                  let tmpBaselineWordPuzzle = wordPuzzle.map(rowCopy => rowCopy.map(cellCopy =>
                    ({ value: cellCopy.value, color: cellCopy.color, horizontal: cellCopy.horizontal, vertical: cellCopy.vertical })));
                  setStartingCell(wordToVoid.startingCell);
                  setCellDirection(wordToVoid.cellDirection);
                  setNewMaxWordLength(directionMapping[wordToVoid.cellDirection].maxWords(wordToVoid.startingCell));
                  setNewWord(wordToVoid.word);
                  setDescription(wordToVoid.description);
                  setCopyWordPuzzle(wordPuzzle.map(rowCopy => rowCopy.map(cellCopy =>
                    ({ value: cellCopy.value, color: cellCopy.color, horizontal: cellCopy.horizontal, vertical: cellCopy.vertical }))));
                  const cleanedPuzzle = voidWordInWordPuzzle(wordToVoid, tmpBaselineWordPuzzle);
                  setBaseLinePuzzle(cleanedPuzzle);
                  setWordPuzzle(cleanedPuzzle);
                  setEditType("Update");
                  setVisiblePuzzleModal(true);
                  return;
                }
                setWordPuzzle(  wordPuzzle.map((wpRow, k) =>
                                  wpRow.map((wpCell, i) => {
                                    return k==iRow && i==jCell ? ({ value: newValue }) : ({ value: wpCell.value, color: wpCell.color });
                                  })
                                )
                             )
              }}
              tabIndex="0"
           >
            {cell.value ? cell.value : ((answersOnly || !showIndexes) ? cell.value : `${iRow+1},${jCell+1}`)}
          </div>))}
        </div>
      ))}
      </div>
      <SpaceBetween direction="horizontal" size="s">
            <Toggle
              disabled={answersOnly}
              onChange={({ detail }) =>
                setShowIndexes(detail.checked)
              }
              checked={showIndexes}
            >
              Show Indexes
            </Toggle>
            <Toggle
              disabled={allWords.vertical.length == 0 && allWords.horizontal.length == 0}
              onChange={({ detail }) =>
                setAnswersOnly(detail.checked)
              }
              checked={answersOnly}
            >
              Answers Only
            </Toggle>
      </SpaceBetween>
      {/*<Textarea
        disabled={true}
        value={JSON.stringify(JSON.parse(JSON.stringify(allWords)),null,2)}
      />*/}
      <SpaceBetween direction="horizontal" size="s">
        <Button variant="secondary"
          onClick={() => {
            setAllWords({ horizontal: [], vertical: []});
            setWordPuzzle(
              Array.from(Array(rows)).map((_, k) => k + 1).map(k =>
                Array.from(Array(cols)).map((_, i) => i + 1).map(i => ({ value: undefined }))
              )
            );
            setAnswersOnly(false);
          }}
        >
          Clear Answers
        </Button>
      </SpaceBetween>
    </SpaceBetween>
    </Container>
            )
          }
        ]}
      />
    );
}