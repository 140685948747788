import {useEffect, useState} from 'react';
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import Toggle from "@cloudscape-design/components/toggle";
import FormField from "@cloudscape-design/components/form-field";
import RadioGroup from "@cloudscape-design/components/radio-group";
import Textarea from "@cloudscape-design/components/textarea";
import Wizard from "@cloudscape-design/components/wizard";
import { TextContent, Link } from "@cloudscape-design/components";
import Alert from "@cloudscape-design/components/alert";

import { StaticTableV2, gpTopicMdControlV2 } from '../common/common';
import { gpGetNextTestInputStateV2, MediaTypeHeader } from './question-common';
import { MultiQuestionMultiOptionsComponentV2 } from './multi-question-multi-options-component'
import { AnswerEditorV2 } from './answer-editor';
import { PreviewTopicV2 } from './preview-topic';

export function QuestionWizardComponentV2(props) {
    const u2bWidth = 560; const u2bHeight = 315;
    const audioWidth = 315; const audioHeight = 160;
    const imgHeight = 315;
    const qsImgHeight = 60;

    /*
    GOOGLE DRIVE
    ============
    * https://support.google.com/drive/thread/34363118/embed-public-google-drive-image-into-a-html-so-it-is-viewable-to-the-public-static-image-url?hl=en
      from: https://drive.google.com/file/d/<ID_OF_PICTURE>
         => i.e.: https://drive.google.com/file/d/1w0RsepxGCf_Ivlman2rLAHSeMKAbutV6/preview
      to:   https://drive.google.com/uc?export=view&id=<ID_OF_PICTURE>
         => i.e.: https://drive.google.com/uc?export=view&id=1w0RsepxGCf_Ivlman2rLAHSeMKAbutV6

    DROP BOX
    ========
    https://support.geekseller.com/knowledgebase/hosting-images-with-dropbox/#:~:text=Dropbox%20is%20a%20great%20way,and%20import%20them%20to%20GeekSeller.
       www.dropbox.com/s/HOST_ID/filename.extension
       from: https://www.dropbox.com/s/<HOST_ID>/<filename.extension>?dl=0
             https://www.dropbox.com/scl/fi/<HOST_ID>/<filename.extension>&dl=0
               i.e.: https://www.dropbox.com/scl/fi/jwvoyi4iupmwzsn0rmict/.m4a?rlkey=l12alj82vr9nybvoxd4juns6j&dl=0
       to:   https://dl.dropboxusercontent.com/s/<HOST_ID>/<filename.extension>?dl=0
             https://dl.dropboxusercontent.com/s/<HOST_ID>/<filename.extension>&dl=0
               i.e.: https://dl.dropboxusercontent.com/s/jwvoyi4iupmwzsn0rmict/.m4a?rlkey=l12alj82vr9nybvoxd4juns6j&dl=0
    */

    const googleDriveRegex = "https://drive.google.com/file/d/([^/]*)/";
    const dropBoxRegex = "https://www.dropbox.com/s/(([^/]*)/([^&]*)&dl.*)";
    const dropBoxRegexNewer = "https://www.dropbox.com/scl/fi/(([^/]*)/([^&]*)&dl.*)";
    const iFrameRegex = "<iframe .* src=\"([^\"]*)\" .*</iframe>";

    function isCloudUrl(link, regex) {
      const value = link.match(regex)
      return value && value.length >= 2;
    }

    function getAnyGoogleDrive(link, regex) {
      const fileId = link.match(regex)[1]
      return `https://drive.google.com/uc?export=view&id=${fileId}`
    }

    function getAnyDropBox(link, regex) {
      const fileId = link.match(regex)[1]
      return `https://dl.dropboxusercontent.com/s/${fileId}`
    }

    function getIframeSrc(embedLink) {
      const fileSrc = embedLink.match(iFrameRegex)[1]
      return fileSrc;
    }

    function getRightUrl(rawLink) {
      let tmpUrl = rawLink;
      if      (isCloudUrl(rawLink, googleDriveRegex))  tmpUrl = getAnyGoogleDrive(rawLink, googleDriveRegex);
      else if (isCloudUrl(rawLink, dropBoxRegex))      tmpUrl = getAnyDropBox(rawLink, dropBoxRegex);
      else if (isCloudUrl(rawLink, dropBoxRegexNewer)) tmpUrl = getAnyDropBox(rawLink, dropBoxRegexNewer);
      else if (isCloudUrl(rawLink, iFrameRegex))       tmpUrl = getIframeSrc(rawLink);

      return tmpUrl;
    }

    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [alertInfo, setAlertInfo] = useState(false);

    // step 1: title override
    const [questionTitle, setQuestionTitle] =  useState(props.defaultTopicTitle);

    // step 2: media
    const [mdType, setMdType] =  useState('none');
    const [uploadValue, setUploadValue] = useState([]);
    const [accountUrl, setAccountUrl] =  useState(undefined);
    const [youtubeUrl, setYoutubeUrl] =  useState(undefined);
    const [embedImage, setEmbedImage] = useState(undefined);
    const [embedImageAltText, setEmbedImageAltText] = useState(undefined);
    const [embedAudioUrl, setEmbedAudioUrl] =  useState(undefined);
    const [embedVideoUrl, setEmbedVideoUrl] =  useState(undefined);
    // not really used, could be deprecated
    const [selectedQuestion, setSelectedQuestion] = useState(undefined);

    // step 3: topic question
    const [rawQuestion, setRawQuestion] = useState(undefined);
    const [questionList, setQuestionList] = useState([]);
    const [rightAnswerList, setRightAnswerList] = useState([]);
    const [editableQuestionList, setEditableQuestionList] = useState([]);
    const [isUpdatingQuestions, setIsUpdatingQuestions] = useState(false);

    // step 4: drag and drop
    const [answersPoolType, setAnswersPoolType] = useState('none');
    const [rawAdditionalAnswers, setRawAdditionalAnswers] = useState(undefined);
    const [answersPool, setAnswersPool] = useState([]);

    // step 5: which config to show
    const [displayModeOverride, setDisplayModeOverride] = useState('text');

    const [topicMd, setTopicMd] = useState(undefined);
    const [roundState, setRoundState] = useState([]);
    const [testOption, setTestOption] = useState(undefined);
    const [settings, setSettings] = useState({});

    // always reset panel on every step switch
    useEffect(() => {
      props.routerProps.resetSplitPanel();
      if (activeStepIndex == 4) {
        const newSettings = {
          height: qsImgHeight,
          dropDown: displayModeOverride === "dropDown",
          dragAndDrop: answersPoolType !== "none" && displayModeOverride === "dragAndDrop",
          radioGroup: answersPoolType !== "none" && displayModeOverride === "radioGroup"
        }
        setSettings(newSettings);
        const newTestOption = composeTopicOption();
        setTestOption(newTestOption);
        const newTestInputState = gpGetNextTestInputStateV2(newTestOption, props.imgMd, newSettings);
        setRoundState([newTestInputState]);
      }
    }, [activeStepIndex, displayModeOverride])

    useEffect(() => {
          switch (mdType) {
              case "picture":
                  setEmbedImage(accountUrl);
                  setEmbedImageAltText(accountUrl);
              break;
              case "audio":
                  setEmbedAudioUrl(accountUrl);
              break;
              case "video":
                  setEmbedVideoUrl(accountUrl);
              break;
        }
    }, [accountUrl])

    useEffect(() => {
      if (props.topicIndex) {
        loadState();
      }
    }, []);

    function loadState() {
      const i = props.topicIndex - 1;
      // step 1: title
      setQuestionTitle(props.topics[i].title);

      // step 2: media
      setMdType(props.topics[i].mdType);
      setAccountUrl(props.topics[i].accountUrl);
      setYoutubeUrl(props.topics[i].youtubeUrl);
      setEmbedImage(props.topics[i].embedImage);
      setEmbedImageAltText(props.topics[i].embedImageAltText);
      setEmbedAudioUrl(props.topics[i].embedAudioUrl);
      setEmbedVideoUrl(props.topics[i].embedVideoUrl);

      // step 3: topic question
      setRawQuestion(props.topics[i].rawQuestion);
      setQuestionList(props.topics[i].questionList);
      setRightAnswerList(props.topics[i].rightAnswerList);
      setEditableQuestionList(props.topics[i].editableQuestionList);

      // step 4: answers pool
      setAnswersPoolType(props.topics[i].answersPoolType || props.topics[i].dragAndDropType);   // backwards compat
      setRawAdditionalAnswers(props.topics[i].rawAdditionalAnswers);
      setAnswersPool(props.topics[i].answersPool || props.topics[i].dragAndDropPool);           // backwards compat

      // step 5: display mode & review
      setDisplayModeOverride(props.topics[i].displayModeOverride);
    }

    function saveState() {
      const newTopic = {
        title: questionTitle,                           // option.md.titleOverride
        mdType: mdType,                                 // switch on md element non undefined (option.md.[embed/audio/img])
        accountUrl: accountUrl,                         // option.md.account.src
        youtubeUrl: youtubeUrl,                         // option.md.embed.src
        embedImage: embedImage,                         // option.md.img.src
        embedImageAltText: embedImageAltText,           // option.md.img.alt
        embedAudioUrl: embedAudioUrl,                   // option.md.audio.src
        embedVideoUrl: embedVideoUrl,                   // option.md.video.src
        rawQuestion: rawQuestion,                       // COMPOSE **
        questionList: questionList,                     // option.qs
        rightAnswerList: rightAnswerList,               // COMPOSE **
        editableQuestionList: editableQuestionList,     // COMPOSE
        answersPoolType: answersPoolType,               // switch from option.pool
        displayModeOverride: displayModeOverride,       // COMPOSE from from option.md
        rawAdditionalAnswers: rawAdditionalAnswers,     // COMPOSE from from option.pool and rightAnswers
        answersPool: answersPool,                       // option.pool
        option: composeTopicOption()
      };
      let tmpTopics = [...props.topics]
      if (props.topicIndex) {
        const i = props.topicIndex - 1;
        newTopic.index = i;
        tmpTopics[i] = newTopic;
      } else {
        newTopic.index = tmpTopics.length;
        tmpTopics.push(newTopic);
      }
      props.setTopics(tmpTopics);
    }

    /*
      Topic option ready to preview and submit
    */
    function composeTopicOption() {
      return ({
          md: {
            type: "MultiQuestionMultiOptionsComponent",
            titleOverride: questionTitle,
            displayModeOverride: displayModeOverride,
            embed: {
              src: mdType === "youtube" && youtubeUrl ? youtubeUrl : undefined
            },
            audio: {
              src: mdType === "audio" && embedAudioUrl ? embedAudioUrl : undefined
            },
            video: {
              src: mdType === "video" && embedVideoUrl ? embedVideoUrl : undefined
            },
            img: {
                src: mdType === "picture" && embedImage ? embedImage : undefined,
                alt: mdType === "picture" && embedImageAltText ? embedImageAltText : undefined
            }
          },
          pool: answersPoolType !== 'none' && answersPool.length > 1 ? answersPool : undefined,
          qs: questionList
        });
    }

    /*
      Parses the raw text dump into editable questions data and collects all component based data, structured data and raw text data,
      ... along with drag and drop right answers
      ** empty lines or starts with questions needs at least an empty space
      **   i.e.: pre-text =>preOrPostQuestion && preOrPostQuestion.length ? preOrPostQuestion : " "
      **
    */
    function processRawQuestion(text) {
      let collectedAnswer = "";
      let collectedRightAnswers = []
      let parsedQuestion = "";
      let preOrPostQuestion = "";
      let collectedByComponents = []
      let startOfAnswer = undefined;
      let endOfAnswer = undefined;
      for (let i=0; i<text.length ;i++) {
        const charI = text.charAt(i);
        if (!startOfAnswer && !endOfAnswer && charI !== "_") { preOrPostQuestion += charI; continue; }
        if (!startOfAnswer && !endOfAnswer && charI === "_") { startOfAnswer = (i+1); continue; }
        if (startOfAnswer && !endOfAnswer && charI !== "_") { collectedAnswer += charI; continue; }
        if (startOfAnswer && !endOfAnswer && charI === "_" && i != startOfAnswer) { endOfAnswer = (i+1); }
        if (endOfAnswer) {
          const answerOptions = collectedAnswer.split("/");
          const dropDownOptions = answerOptions.length > 1 ? answerOptions : [];
          const rawQuestion = `{ "answer": "${answerOptions[0]}", "isImage": false, "dropDownOptions": ${JSON.stringify(dropDownOptions)} }`;
          // TODO: remove " " when fixed interpretation without pre
          parsedQuestion += (preOrPostQuestion.length > 0 ? preOrPostQuestion : " ") + rawQuestion
          collectedByComponents.push({
            k: collectedByComponents.length,
            raw: rawQuestion,
            pre: preOrPostQuestion,
            qs: { "answer": answerOptions[0], "isImage": false, "dropDownOptions": dropDownOptions },
          })
          collectedRightAnswers.push(answerOptions[0]);
          startOfAnswer = undefined;
          endOfAnswer = undefined;
          collectedAnswer = "";
          preOrPostQuestion = "";
        }
      }
      // add the last post question non empty text if there was any question in the line item otherwise its only a pre text
      if (preOrPostQuestion && preOrPostQuestion.length && collectedByComponents.length) {
        collectedByComponents[collectedByComponents.length-1].post = preOrPostQuestion;
        parsedQuestion += preOrPostQuestion;
      }
      // if its only pre text, meaning no collected answers regardless of the size (empty or not) appends the patched text
      if (!collectedByComponents.length) {
        parsedQuestion += (preOrPostQuestion.length > 0 ? preOrPostQuestion : " ")
        collectedByComponents.push({
                   k: 0,
                   pre: preOrPostQuestion
                 });
      }
      // return all metadata
      return ({
        question: parsedQuestion,
        rightAnswers: collectedRightAnswers,
        editableQuestion: collectedByComponents
      });
    }

    return (
      <Wizard
        i18nStrings={{
          stepNumberLabel: stepNumber => `Step ${stepNumber}`,
          collapsedStepsLabel: (stepNumber, stepsCount) => `Step ${stepNumber} of ${stepsCount}`,
          skipToButtonLabel: (step, stepNumber) => `Skip to ${step.title}`,
          navigationAriaLabel: "Steps",
          cancelButton: "Cancel",
          previousButton: "Previous",
          nextButton: "Next",
          submitButton: "Submit",
          optional: "optional"
        }}
        onNavigate={({ detail }) =>
          setActiveStepIndex(detail.requestedStepIndex)
        }
        onCancel={() => {
          props.setAddTopicQuestion(false);
        }}
        onSubmit={() => {
          if (questionTitle && questionTitle.length > 0) {
            setAlertInfo(false);
            saveState();
            props.setAddTopicQuestion(false);
          } else {
            setAlertInfo(true);
            setTimeout(() => setAlertInfo(false), 5000)
          }
        }}
        activeStepIndex={activeStepIndex}
        allowSkipTo
        steps={[
          {
            title: "Title",
            isOptional: false,
            description: "Topic instructions",
            content: (
              <Container
                header={
                  <Header variant="h2">Title Editor</Header>
                }
              >
                <Input
                  invalid={!questionTitle || questionTitle.length == 0}
                  value={questionTitle}
                  onChange={({detail}) => setQuestionTitle(detail.value)}
                />
              </Container>
            ),
          },
          {
            title: "Media",
            isOptional: true,
            description: "For instance, specify an embedded youtube video in order to test for comprehension questions, or to test for listening on the lyrics of a song; Else, upload an audio or a picture as the subject for a set of follow up questions, etc.",
            content: (
              <Container
                header={<MediaTypeHeader
                    user={props.routerProps.authUser.user}
                    uploadValue={uploadValue}
                    setUploadValue={setUploadValue}
                    currentUrl={accountUrl}
                    setCurrentUrl={setAccountUrl}/>}
              >
                <SpaceBetween direction="vertical" size="l">
                  <RadioGroup
                    onChange={({ detail }) => {
                      if (detail.value !== 'none') {
                        if (accountUrl) {
                          switch (detail.value) {
                              case "picture":
                                  setEmbedImage(accountUrl);
                                  setEmbedImageAltText(`${questionTitle}_IMG`);
                              break;
                              case "audio":
                                  setEmbedAudioUrl(accountUrl);
                              break;
                              case "video":
                                  setEmbedVideoUrl(accountUrl);
                              break;
                          }
                        }
                      }
                      setMdType(detail.value);
                    }}
                    value={mdType}
                    items={[
                      { value: "none", label: "N/A" },
                      { value: "youtube", label: "Youtube" },
                      { value: "picture", label: "Picture" },
                      { value: "audio", label: "Audio"},
                      { value: "video", label: "Video"}
                    ]}
                  />
                  {mdType === 'youtube' &&
                  <FormField label="Youtube embed Url">
                    <SpaceBetween size="l">
                      <Input
                        invalid={!youtubeUrl || !youtubeUrl.length}
                        value={youtubeUrl}
                        onChange={({detail}) => {
                          const tmpU2Url = getRightUrl(detail.value);
                          setYoutubeUrl(tmpU2Url)
                        }}
                      />
                      {youtubeUrl && gpTopicMdControlV2({ embed: { src: youtubeUrl, width: u2bWidth, height: u2bHeight } })}
                    </SpaceBetween>
                  </FormField>}
                  {mdType === 'picture' &&
                  <FormField label="Image embed Url">
                    <SpaceBetween size="l">
                      <Input
                        invalid={!embedImage || !embedImage.length}
                        value={embedImage}
                        onChange={({detail}) => {
                          setEmbedImage(getRightUrl(detail.value));
                          setEmbedImageAltText(`${questionTitle}_IMG`);
                        }}
                      />
                      {embedImage && <img height={imgHeight} src={`${embedImage}`} alt={`${embedImageAltText}`}/>}
                    </SpaceBetween>
                  </FormField>}
                  {mdType === 'audio' && <FormField label="Audio embed Url">
                    <SpaceBetween size="l">
                      <Input
                        invalid={!embedAudioUrl || !embedAudioUrl.length}
                        value={embedAudioUrl}
                        onChange={({detail}) => {
                          setEmbedAudioUrl(getRightUrl(detail.value));
                        }}
                      />
                      {embedAudioUrl && gpTopicMdControlV2({ audio: { src: embedAudioUrl, width: audioWidth, height: audioHeight } })}
                    </SpaceBetween>
                  </FormField>}
                  {mdType === 'video' && <FormField label="Video embed Url">
                    <SpaceBetween size="l">
                      <Input
                        invalid={!embedVideoUrl || !embedVideoUrl.length}
                        value={embedVideoUrl}
                        onChange={({detail}) => {
                          setEmbedVideoUrl(getRightUrl(detail.value));
                        }}
                      />
                      {embedVideoUrl && gpTopicMdControlV2({ video: { src: embedVideoUrl, width: u2bWidth, height: u2bHeight } })}
                    </SpaceBetween>
                  </FormField>}
                </SpaceBetween>
              </Container>
            ),
          },
          {
            title: "Question",
            isOptional: false,
            description:
              `Topics can be a text with dialogs, a set of questions or lyrics etc. that requires to fill in the blanks. Type in questions as usual but only answers within underscores.`,
            content: (
              <Container
                header={
                  <Header variant="h2">Questions Editor</Header>
                }
                fitHeight={true}
              >
                <SpaceBetween direction="vertical" size="l">
                <Textarea
                  disabled={isUpdatingQuestions}
                  placeholder={`A question? An _answer_.\nAnother question? Another _answer_.`}
                  value={rawQuestion}
                  onChange={({detail}) => setRawQuestion(detail.value)}
                />
                <SpaceBetween direction="horizontal" size="l">
                  <Button
                    disabled={isUpdatingQuestions}
                    variant="primary"
                    onClick={() => {
                      let tmpQuestionList = [];
                      let tmpRightAnswerList = [];
                      let tmpEditableQuestionList = []
                      if (rawQuestion && rawQuestion.length) {
                        const questionItems = rawQuestion.split("\n");
                        for (let i=0; i<questionItems.length; i++) {
                          const processedText = processRawQuestion(questionItems[i])
                          tmpQuestionList.push(processedText.question);
                          tmpRightAnswerList = [...tmpRightAnswerList, ...processedText.rightAnswers]
                          tmpEditableQuestionList.push(processedText.editableQuestion)
                        }
                      }
                      setQuestionList(tmpQuestionList);
                      setRightAnswerList(tmpRightAnswerList);
                      setAnswersPoolType('none');
                      setAnswersPool([]);
                      setRawAdditionalAnswers(undefined);
                      setEditableQuestionList(tmpEditableQuestionList);
                      props.routerProps.resetSplitPanel();
                    }}
                  >Update</Button>
                  <Button
                    disabled={isUpdatingQuestions}
                    onClick={() => {
                      setQuestionList([]);
                      setEditableQuestionList([]);
                      props.routerProps.resetSplitPanel();
                      setRawQuestion(undefined);
                      setAnswersPoolType('none');
                      setRightAnswerList([]);
                      setAnswersPool([]);
                      setRawAdditionalAnswers(undefined);
                    }}
                  >Clear
                  </Button>
                </SpaceBetween>
                <StaticTableV2
                  headerVariant={"h4"}
                  columnDefinitions={["question"]
                      .map(item => ({
                            id: item,
                            header: item,
                            cell: e => (
                            <div style={{ display: 'flex', "flexDirection": 'row' }}
                              key={`ae-row-${e["row"]}`}
                            >
                            {e[item].map((qsDef, k) => (<div style={{overflow: 'hidden', gap: '5px', "whiteSpace": 'nowrap', display: 'flex', "flexDirection": 'row', 'alignItems': 'center' }}
                              key={`ae-space-${e["row"]}-${k}`}
                            >
                              <div key={`ae-pre-${e["row"]}-${k}`}>{qsDef.pre}</div>
                              {qsDef.qs &&
                              <Button
                                key={`ae-btn-${e["row"]}-${k}`}
                                wrapText={false}
                                iconName="edit"
                                variant="secondary"
                                onClick={() => {
                                  props.routerProps.resetSplitPanel();
                                  props.routerProps.setSplitPanelItems(
                                    <AnswerEditorV2
                                      key={`ae-${e["row"]}-${k}`}
                                      routerProps={props.routerProps}
                                      id={`${e["row"]}-${k}`}
                                      row={e["row"]}
                                      k={k}
                                      item={qsDef}
                                      questionList={questionList}
                                      setQuestionList={setQuestionList}
                                      editableQuestionList={editableQuestionList}
                                      setEditableQuestionList={setEditableQuestionList}
                                      rightAnswerList={rightAnswerList}
                                      setRightAnswerList={setRightAnswerList}
                                      doCleanUp={() => {
                                        // clean up all following up steps after any edit
                                        setAnswersPoolType('none');
                                        setAnswersPool([]);
                                        setRawAdditionalAnswers(undefined);
                                      }}
                                      setRawQuestion={setRawQuestion}
                                      isUpdatingQuestions={isUpdatingQuestions}
                                      setIsUpdatingQuestions={setIsUpdatingQuestions}
                                      getRightUrl={getRightUrl}
                                    />
                                  );
                                  props.routerProps.setSplitPanelToggle(true);
                                }}
                              >{qsDef.qs.answer}
                              </Button>}
                              {qsDef.post && <div key={`ae-post-${e["row"]}-${k}`}>{qsDef.post}</div>}
                            </div>))}
                            </div>)
                          })
                      )}
                  loading={isUpdatingQuestions}
                  selectionType={undefined}
                  selectedItems={selectedQuestion}
                  setSelectedItems={setSelectedQuestion}
                  trackBy="id"
                  items={editableQuestionList.map((question, row) => ({ row: row, question: question }))}
                />
                </SpaceBetween>
              </Container>
            ),
          },
          {
            title: "Answers Pool",
            isOptional: true,
            description:
              (<div>In order to configure <b style={{color:'orange'}}>Drag & Drop</b> or <b style={{color:'orange'}}>Radio Group</b>, a pool of answers with all expected correct answers and, perhaps some additional deceiving answers are required. Default option i.e. collects only correct answers across the topic but it can be loaded up with as necessary. For clarity, set a valid option and navigate to the Review step and toggle the different view options for reference.</div>),
            content: (
              <Container
                header={
                  <Header variant="h2">
                    Answers pool type
                  </Header>
                }
              >
                <SpaceBetween direction="vertical" size="l">
                  <RadioGroup
                    onChange={({ detail }) => {
                      setAnswersPoolType(detail.value);
                      setAnswersPool(rightAnswerList);
                    }}
                    value={answersPoolType}
                    items={[
                      { value: "none", label: "n/a" },
                      { value: "default", label: "Default options" },
                      { value: "loaded", label: "Overloaded options" },
                    ]}
                  />
                  {answersPoolType === "loaded" &&
                  <SpaceBetween direction="vertical" size="l">
                    <Textarea value={rawAdditionalAnswers} onChange={({detail}) => setRawAdditionalAnswers(detail.value)}/>
                    <SpaceBetween direction="horizontal" size="l">
                      <Button
                        variant="primary"
                        onClick={() => {
                          const newAdditionalAnswerItems = rawAdditionalAnswers ? rawAdditionalAnswers.split("\n") : [];
                          const tmpPoolAnswerList = [...rightAnswerList, ...newAdditionalAnswerItems]
                          setAnswersPool(tmpPoolAnswerList);
                        }}
                      >Update</Button>
                      <Button
                        onClick={() => {
                          setAnswersPool(rightAnswerList);
                          setRawAdditionalAnswers(undefined);
                        }}
                      >Clear
                      </Button>
                    </SpaceBetween>
                  </SpaceBetween>}
                  {answersPoolType !== "none" && <StaticTableV2
                      headerVariant={"h4"}
                      columnDefinitions={["id", "answer"]
                          .map(item => ({
                                id: item,
                                header: item,
                                sortingField: item,
                                cell: e => e[item]
                              })
                          )}
                      loading={false}
                      selectionType={undefined}
                      items={answersPool.map((answer, id) => ({ id: id, answer: answer }))}
                  />}
                </SpaceBetween>
              </Container>
            ),
            isOptional: true
          },
          {
            title: "Review",
            description: `Question Preview`,
            isOptional: false,
            content: (
              <SpaceBetween size="xs">
                <Header
                  variant="h3"
                  actions={
                    <SpaceBetween direction="horizontal" size="l" alignItems="center">
                    <Toggle
                      onChange={({ detail }) => {
                        if (detail.checked) setDisplayModeOverride('dropDown')
                        else setDisplayModeOverride('text');
                      }}
                      checked={displayModeOverride === 'dropDown'}
                    >Drop Down</Toggle>
                    <Toggle
                      disabled={answersPoolType === 'none'}
                      onChange={({ detail }) => {
                        if (detail.checked) setDisplayModeOverride('dragAndDrop')
                        else setDisplayModeOverride('text');
                      }}
                      checked={displayModeOverride === 'dragAndDrop' && answersPoolType !== 'none'}
                    >Drag & Drop</Toggle>
                    <Toggle
                      disabled={answersPoolType === 'none'}
                      onChange={({ detail }) => {
                        if (detail.checked) setDisplayModeOverride('radioGroup')
                        else setDisplayModeOverride('text');
                      }}
                      checked={displayModeOverride === 'radioGroup' && answersPoolType !== 'none'}
                    >Radio Group</Toggle>
                    <Button
                      iconName="redo"
                      onClick={() => setActiveStepIndex(0)}
                    >
                      Redo
                    </Button>
                    </SpaceBetween>
                  }
                >
                  Display Mode
                </Header>
                {alertInfo && <Alert
                  onDismiss={() => {
                    setAlertInfo(false);
                  }}
                  dismissible
                  type={"error"}
                  statusIconAriaLabel={"Error"}
                  header="Exception"
                >
                  <div>
                    {!(questionList && questionList.length > 0)
                      && <TextContent>At least 1 question must be created.</TextContent>}
                    {!(questionTitle && questionTitle.length > 0) && <TextContent>A valid title must be defined!</TextContent>}
                  </div>
                </Alert>}
                {questionList.length > 0 &&
                <SpaceBetween direction="vertical" size="l">
                  {gpTopicMdControlV2(topicMd)}
                  <MultiQuestionMultiOptionsComponentV2
                    topicMdControl={gpTopicMdControlV2}
                    topicMd={topicMd}
                    setTopicMd={setTopicMd}
                    roundState={roundState}
                    setRoundState={setRoundState}
                    testIndex={0}
                    setTestIndex={(value) => { }}
                    isLoadingTest={false}
                    setIsLoadingTest={(value) => { }}
                    commitTry={false}
                    setCommitTry={(value) => { }}
                    commitClear={false}
                    setCommitClear={(value) => { }}
                    commitShow={false}
                    setCommitShow={(value) => { }}
                    commitPrevious={false}
                    setCommitPrevious={(value) => { }}
                    commitNext={false}
                    setCommitNext={(value) => { }}
                    settings={settings}
                    isReplay={false}
                    testOption={composeTopicOption()}
                    imgMd={(imgName, imgSrc, configs) => (
                      { img: { src: imgSrc, height: configs.height, class: "question-img", alt: "logo" } }
                    )}
                    routerProps={props.routerProps}
                    totalHintedAnswers={0}
                    setTotalHintedAnswers={(value) => { }}
                />
                </SpaceBetween>}
              </SpaceBetween>
            )
          }
        ]}
      />
    );
}