import {useEffect, useRef, useState} from 'react';
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import HelpPanel from "@cloudscape-design/components/help-panel";
import {Select, StatusIndicator, TextContent} from "@cloudscape-design/components";
import Toggle from "@cloudscape-design/components/toggle";
import ProgressBar from "@cloudscape-design/components/progress-bar";
import Modal from "@cloudscape-design/components/modal";
import Box from "@cloudscape-design/components/box";

import { useContainerQuery } from '@cloudscape-design/component-toolkit';
import { cloneOptionsV2, gpTopicMdControlV2Keyed } from '../common/common'
import { RoundSettingsV2 } from './round-settings'
import { DisplaySummaryV2 } from './display-summary'
import { dropDivV2, dragStartDivV2, dragEnterDivV2, gpSetNextTestInputStateV2, calculateTotalAttemptsV2,
  gpGetNextTestInputStateV2,
  canDisableWhenInteractiveMode, showStatusIndicator, typeOfStatusIndicator, answerOfStatusIndicator
} from './question-common';


export function MultiQuestionMultiOptionsComponentV2(props) {
  const inputReference = useRef(null);
  const dragItemDiv = useRef();
  const dragOverItemDiv = useRef();

  // displayable state attributes
  const [nonBlanksPre, setNonBlanksPre] = useState([]);
  const [nonBlanksPost, setNonBlanksPost] = useState([]);
  const [actualAnswers, setActualAnswers] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [imagedAnswers, setImagedAnswers] = useState([]);
  const [dropDownAnswers, setDropDownAnswers] = useState([]);
  const [expectedAnswers, setExpectedAnswers] = useState([]);
  const [answersIsMatch, setAnswersIsMatch] = useState([]);
  const [listAnswerDiv, setListAnswerDiv] = useState([]);
  const [listDiv, setListDiv] = useState([]);
  const [topicMd, setTopicMd] = useState({});

  const [showAnswers, setShowAnswers] = useState(false);

  function loadTestInputFromRoundState(index, currentRoundState) {
    const oldTopicState = currentRoundState[index];
    const oldRoundState = currentRoundState[index];
    setExpectedAnswers(oldRoundState.expectedAnswers);
    setImagedAnswers(oldRoundState.imagedAnswers);
    setDropDownAnswers(oldRoundState.dropDownAnswers);
    setActualAnswers(oldRoundState.actualAnswers);
    setAnswersIsMatch(oldRoundState.answersIsMatch);
    setNonBlanksPre(oldRoundState.nonBlanksPre);
    setNonBlanksPost(oldRoundState.nonBlanksPost);
    setListDiv(oldRoundState.listDiv);
    setListAnswerDiv(oldRoundState.listAnswerDiv);
    setTopicMd(oldRoundState.topicMd);
    props.setTopicMd(oldRoundState.topicMd);
  }

  function saveTestInputIntoState(index, roundState, newActualAnswers) {
    let newRoundState = [...roundState];
    let oldInputState = newRoundState[index]
    const newAnswersIsMatch = actualAnswers.map((item, k) =>
      item.map((value, i) => actualAnswers[k][i] ? (actualAnswers[k][i] === toLowerCase(expectedAnswers[k][i]) ? "success" : "error") : undefined));
    newRoundState[index] = {
      type: oldInputState.type,
      expectedAnswers: expectedAnswers,
      imagedAnswers: imagedAnswers,
      dropDownAnswers: dropDownAnswers,
      actualAnswers: [...newActualAnswers],
      answersIsMatch: newAnswersIsMatch,
      nonBlanksPre: nonBlanksPre,
      nonBlanksPost: nonBlanksPost,
      listDiv: listDiv,
      listAnswerDiv: listAnswerDiv,
      topicMd: topicMd,
      // attempts: newAnswersIsMatch.every(item => item.every(isMatch => isMatch === "success")) ? props.settings.attempts : oldInputState.attempts + 1

      attempts: oldInputState.attempts + 1
    };
    return newRoundState;
  }

  function toLowerCase(answer) {
    return props.settings.lowerCaseEnabled ? answer.toLowerCase() : answer;
  }

  function getIsDropDown(settings, stateTopicMd) {
    if (settings.dropDown) return settings.dropDown;
    return (stateTopicMd.displayModeOverride && stateTopicMd.displayModeOverride === "dropDown");
  }

  function getIsDragAndDrop(settings, stateTopicMd) {
    if (settings.dragAndDrop) return settings.dragAndDrop;
    return (stateTopicMd.displayModeOverride && stateTopicMd.displayModeOverride === "dragAndDrop");
  }

  function getIsRadioGroup(settings, stateTopicMd) {
    if (settings.radioGroup) return settings.radioGroup;
    return (stateTopicMd.displayModeOverride && stateTopicMd.displayModeOverride === "radioGroup");
  }

  useEffect(() => {
    if (props.roundState && props.roundState.length > 0 && props.testIndex < props.roundState.length) {
      loadTestInputFromRoundState(props.testIndex, props.roundState);
    }
  }, [props.roundState, props.testIndex]);

  useEffect(() => {
    if (props.commitTry) {
      setAnswersIsMatch(expectedAnswers.map(item => item.map(answer => undefined)));
      const newAnswerIsMatch = actualAnswers.map((item, k) => item
          .map((value, i) => actualAnswers[k][i] ? (actualAnswers[k][i] === toLowerCase(expectedAnswers[k][i]) ? "success" : "error") : undefined));
      setAnswersIsMatch(newAnswerIsMatch);
      new Promise(resolve =>
          setTimeout(() => resolve(), 250)).then(() => console.log("await 1/2 sec(s)"))
          .then(() => {
            props.setIsLoadingTest(false);
            if (props.roundState[props.testIndex].attempts + 1 <= props.settings.attempts) {
              props.setRoundState(saveTestInputIntoState(props.testIndex, props.roundState, actualAnswers));
              if (inputReference.current) inputReference.current.focus();
            }
          });
      props.setCommitTry(false);
    }
  }, [props.commitTry]);

  useEffect(() => {
    if (props.commitClear) {
      props.setCommitClear(false);
    }
  }, [props.commitClear]);

  useEffect(() => {
    if (props.commitShow) {
      setShowAnswers(true);
      props.setTotalHintedAnswers(props.totalHintedAnswers + 1);
      setTimeout(() => setShowAnswers(false), 2000);
      props.setCommitShow(false);
    }
  }, [props.commitShow]);

  useEffect(() => {
    if (props.commitPrevious) {
      if (inputReference.current) inputReference.current.focus();
      props.setCommitPrevious(false);
    }
  }, [props.commitPrevious]);

  useEffect(() => {
    if (props.commitNext) {
      if (inputReference.current) inputReference.current.focus();
      props.setCommitNext(false);
    }
  }, [props.commitNext]);

  return (
  <div>
    <SpaceBetween size="s" direction="vertical">
      {nonBlanksPre.map((nonBlanksPreK, k) => (
        <SpaceBetween key={`ans-sb-${k}`} size="s" direction="horizontal" alignItems="center">
          {nonBlanksPreK
              .map((nonBlanksPreKI, i) => (
                  <SpaceBetween key={`sb-${k}-${i}`} direction={"horizontal"} size={"xxs"} alignItems="center">
                    <TextContent key={`text-pre-${k}-${i}`}>{nonBlanksPreKI}</TextContent>
                    {(getIsDropDown(props.settings, topicMd) && dropDownAnswers && dropDownAnswers.length > 0 && dropDownAnswers[k].length > 0 && dropDownAnswers[k][i].length > 0)
                      && <Select
                      key={`select-${k}-${i}`}
                      ref={(k == 0 && i == 0) ? inputReference : undefined}
                      selectedOption={ actualAnswers[k][i] ? { label: actualAnswers[k][i], value: actualAnswers[k][i] } : undefined }
                      disabled={
                        calculateTotalAttemptsV2(props.roundState, props.testIndex) >= props.settings.attempts
                        //|| (props.roundState[props.testIndex] && props.roundState[props.testIndex].actualAnswers && props.roundState[props.testIndex].actualAnswers[k][i] === toLowerCase(expectedAnswers[k][i]))
                        || canDisableWhenInteractiveMode(props.settings, props.roundState, props.testIndex, k, i, expectedAnswers)
                        || props.isLoadingTest
                        || (getIsDragAndDrop(props.settings, topicMd) && actualAnswers[k].length > 1)
                        || (getIsRadioGroup(props.settings, topicMd) && actualAnswers[k].length > 1)
                        || props.isReplay
                      }
                      onChange={({ detail }) => {
                          let newValueArray = cloneOptionsV2(actualAnswers[k]);
                          newValueArray[i] = detail.selectedOption.value
                          let newItemsValueArray = cloneOptionsV2(actualAnswers);
                          newItemsValueArray[k] = newValueArray;
                          setActualAnswers(newItemsValueArray);

                          let newIsMatchArray = cloneOptionsV2(answersIsMatch[k]);
                          newIsMatchArray[i] = undefined;
                          let newItemsIsMatchArray = cloneOptionsV2(answersIsMatch);
                          newItemsIsMatchArray[k] = newIsMatchArray;
                          setAnswersIsMatch(newItemsIsMatchArray);
                      }}
                      options={dropDownAnswers[k][i].map(answer => ({ label: answer, value: answer }))}
                    />}
                    {(!(getIsDropDown(props.settings, topicMd) && dropDownAnswers && dropDownAnswers.length > 0 && dropDownAnswers[k].length > 0 && dropDownAnswers[k][i].length > 0)
                       && !(getIsRadioGroup(props.settings, topicMd) && listAnswerDiv.length)
                       && actualAnswers[k].length > 0) &&
                    <div
                       key={`dragOver-div-${k}-${i}`}
                       onDragStart={(e) => dragStartDivV2(e, actualAnswers[k][i], i+(k+1)*1000, dragItemDiv)}
                       onDragEnter={(e) => dragEnterDivV2(e, actualAnswers[k][i], i+(k+1)*1000, dragOverItemDiv)}
                       onDragEnd={(e) => dropDivV2(e, dragItemDiv, dragOverItemDiv, listDiv, setListDiv, actualAnswers, setActualAnswers, answersIsMatch, setAnswersIsMatch)}
                       draggable={
                         (getIsDragAndDrop(props.settings, topicMd) && listAnswerDiv.length > 1) &&
                         !(calculateTotalAttemptsV2(props.roundState, props.testIndex) >= props.settings.attempts) &&
                         !props.isLoadingTest && !props.isReplay
                    }>
                    <Input
                        key={`input-${k}-${i}`}
                        ref={(k == 0 && i == 0) ? inputReference : undefined}
                        value={actualAnswers[k][i]}
                        disabled={
                          calculateTotalAttemptsV2(props.roundState, props.testIndex) >= props.settings.attempts
                          //|| props.roundState[props.testIndex] && props.roundState[props.testIndex].actualAnswers && props.roundState[props.testIndex].actualAnswers[k][i] === toLowerCase(expectedAnswers[k][i])
                          || canDisableWhenInteractiveMode(props.settings, props.roundState, props.testIndex, k, i, expectedAnswers)
                          || props.isLoadingTest
                          || (getIsDragAndDrop(props.settings, topicMd) && listAnswerDiv.length > 1)
                          || (getIsRadioGroup(props.settings, topicMd) && listAnswerDiv.length > 1)
                          || props.isReplay
                        }
                        onChange={(event) => {
                          let newValueArray = cloneOptionsV2(actualAnswers[k]);
                          newValueArray[i] = event.detail.value
                          let newItemsValueArray = cloneOptionsV2(actualAnswers);
                          newItemsValueArray[k] = newValueArray;
                          setActualAnswers(newItemsValueArray);

                          let newIsMatchArray = cloneOptionsV2(answersIsMatch[k]);
                          newIsMatchArray[i] = undefined;
                          let newItemsIsMatchArray = cloneOptionsV2(answersIsMatch);
                          newItemsIsMatchArray[k] = newIsMatchArray;
                          setAnswersIsMatch(newItemsIsMatchArray);
                        }}
                    />
                    </div>}
                    {(!getIsDropDown(props.settings, topicMd) &&
                      !getIsDragAndDrop(props.settings, topicMd) &&
                      getIsRadioGroup(props.settings, topicMd) && listAnswerDiv.length &&
                      actualAnswers[k].length > 0) &&
                    <form key={`form-${k}-${i}`}>
                      <SpaceBetween key={`form-sb-${k}-${i}`} direction="horizontal" size="xs" alignItems="start">
                      {listDiv.map((item, index) => (
                      <SpaceBetween key={`form-sb-group-${k}-${i}-${index}`} direction="horizontal" size="xxs" alignItems="start">
                        <input
                          disabled={
                            calculateTotalAttemptsV2(props.roundState, props.testIndex) >= props.settings.attempts
                            //|| props.roundState[props.testIndex] && props.roundState[props.testIndex].actualAnswers && props.roundState[props.testIndex].actualAnswers[k][i] === toLowerCase(expectedAnswers[k][i])
                            || canDisableWhenInteractiveMode(props.settings, props.roundState, props.testIndex, k, i, expectedAnswers)
                            || props.isLoadingTest
                            || props.isReplay
                          }
                          key={`form-sb-group-input-${k}-${i}-${index}`}
                          type="radio" id={index} name={`(${k},${i})`} value={item}
                          checked={actualAnswers[k][i] === item}
                          onChange={() => {
                            let newValueArray = cloneOptionsV2(actualAnswers[k]);
                            newValueArray[i] = item
                            let newItemsValueArray = cloneOptionsV2(actualAnswers);
                            newItemsValueArray[k] = newValueArray;
                            setActualAnswers(newItemsValueArray);

                            let newIsMatchArray = cloneOptionsV2(answersIsMatch[k]);
                            newIsMatchArray[i] = undefined;
                            let newItemsIsMatchArray = cloneOptionsV2(answersIsMatch);
                            newItemsIsMatchArray[k] = newIsMatchArray;
                            setAnswersIsMatch(newItemsIsMatchArray);
                          }}
                        />
                        <label key={`form-sb-group-label-${k}-${i}-${index}`} for={index}>
                          {item}
                        </label>
                      </SpaceBetween>
                      ))}
                      </SpaceBetween>
                    </form>}
                    {showStatusIndicator(props.settings, props.isReplay, showAnswers, props.roundState, props.testIndex, k, i, answersIsMatch[k][i], expectedAnswers) &&
                    <StatusIndicator key={`status-${k}-${i}`} type={typeOfStatusIndicator(showAnswers, answersIsMatch[k][i])}>
                      {answerOfStatusIndicator(props.settings, props.roundState, props.testIndex, k, i, answersIsMatch[k][i], expectedAnswers)}
                    </StatusIndicator>}
                    {(actualAnswers[k].length > 0 && imagedAnswers[k][i]) && gpTopicMdControlV2Keyed({ md: imagedAnswers[k][i], key: `image-${k}-${i}` })}
                    {(actualAnswers[k].length > 0 && nonBlanksPost[k][i]) && <TextContent key={`text-post-${k}-${i}`}>{nonBlanksPost[k][i]}</TextContent>}
                  </SpaceBetween>
              ))}
        </SpaceBetween>))
      }
      {!(getIsRadioGroup(props.settings, topicMd) && listAnswerDiv.length > 1) &&
        (getIsDragAndDrop(props.settings, topicMd) && listAnswerDiv.length > 1) &&
        <div style={{"borderRadius": '25px', "borderStyle":'dotted', margin:'5px 10%'}}
          onDragEnter={(e) => dragEnterDivV2(e, "Answers", 0, dragOverItemDiv)}
          onDragEnd={(e) => dropDivV2(e, dragItemDiv, dragOverItemDiv, listDiv, setListDiv, actualAnswers, setActualAnswers, answersIsMatch, setAnswersIsMatch)}
          key={`baseDiv`}>
              <div style={{textAlign:'center'}}><h4>{`Answers (${listDiv.length})`}</h4></div>
              <div style={{width: '100%', display: 'flex', "flexDirection": 'row', "flexWrap": 'wrap', "justifyContent": 'center'}}>
              {listDiv.map((item, index) => (
                  <div style={{margin:'10px 1%', textAlign:'center', overflow: 'hidden', "whiteSpace": 'nowrap', "display": 'inlineBlock'}}
                       onDragStart={(e) => dragStartDivV2(e, item, index, dragItemDiv)}
                       onDragEnter={(e) => dragEnterDivV2(e, item, index, dragOverItemDiv)}
                       onDragEnd={(e) => dropDivV2(e, dragItemDiv, dragOverItemDiv, listDiv, setListDiv, actualAnswers, setActualAnswers, answersIsMatch, setAnswersIsMatch)}
                       id={index}
                       key={`pool-${index}`}
                       draggable={
                         (getIsDragAndDrop(props.settings, topicMd) && listAnswerDiv.length > 1) &&
                         !(calculateTotalAttemptsV2(props.roundState, props.testIndex) >= props.settings.attempts) &&
                         !props.isLoadingTest && !props.isReplay
                       }>
                       <Button wrapText={false}>{item}</Button>
                  </div>
              ))}
              </div>
        </div>}
    </SpaceBetween>
  </div>
  );
}