import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import logo from './logo.svg';
import learningLogo from './IMG_5022.jpg'
import halfMoonLogo from './half-moon-cs.svg'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TopNavigation from "@cloudscape-design/components/top-navigation";
import Input from "@cloudscape-design/components/input";
import Toggle from "@cloudscape-design/components/toggle";
import SpaceBetween from "@cloudscape-design/components/space-between";
import { applyMode, applyDensity, Density, Mode } from '@cloudscape-design/global-styles';
import { setAuthCookie, setPrefCookie, getMode } from './cookies/cookies';

/*
  Main Top Navigation
  references:
    * css style sticky mode set up => https://cloudscape.design/components/top-navigation/?tabId=api#slots
    * css style border formatting => https://stackoverflow.com/questions/3126995/combining-border-top-border-right-border-left-border-bottom-in-css
    * dark/light mode => https://cloudscape.design/get-started/integration/global-styles/
    * density mode => https://github.com/cloudscape-design/demos/blob/main/src/common/apply-mode.ts
    * i18 locales => https://cloudscape.design/get-started/dev-guides/internationalization/
                  => https://github.com/cloudscape-design/components/blob/main/src/i18n/messages/all.en.json
*/
function CustomTopNavigation(props) {
  const [searchValue, setSearchValue] = useState(undefined);

  useEffect(() => {
    if (props.darkMode) applyMode(Mode.Dark);
    else applyMode(Mode.Light);
  },  [props.darkMode]);


  return (
  <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002, borderWidth: '0px 0px 2px 0px', borderColor: 'gray', borderStyle: 'solid' }}>
    <TopNavigation
      i18nStrings={{
        searchIconAriaLabel: "Search",
        searchDismissIconAriaLabel: "Close search",
        overflowMenuTriggerText: "More",
        overflowMenuTitleText: "All",
        overflowMenuBackIconAriaLabel: "Back",
        overflowMenuDismissIconAriaLabel: "Close menu"
      }}
      identity={{
        href: '#',
        title: 'Strana',
        logo: { src: learningLogo, alt: 'logo' },
      }}
      search={
        <SpaceBetween direction="horizontal" size="l" alignItems="center">
            <Input
              ariaLabel="Input field"
              value={searchValue}
              type="search"
              placeholder="Search"
              onChange={({ detail }) => setSearchValue(detail.value)}
            />
        </SpaceBetween>
      }
      utilities={[
        {
          type: 'button',
          iconUrl: halfMoonLogo,
          ariaLabel: props.darkMode ? 'Light' : 'Dark',
          disableUtilityCollapse: true,
          text: props.darkMode ? 'Light' : 'Dark',
          onClick: (() => {
            const newMode = !props.darkMode;
            setPrefCookie({ mode: newMode ? 'dark' : 'light' }, 60);
            props.setDarkMode(newMode);
          })
        },
        (props.authUser ?
          {
            type: "menu-dropdown",
            text: `${props.authUser.name}`,
            description: `${props.authUser.email}`,
            iconName: "user-profile",
            disableUtilityCollapse: true,
            items: [
              {
                id: "settings",
                text: "Settings",
                items: [
                  { id: "profile", text: "Profile" },
                  { id: "preferences", text: "Preferences" },
                ]
              },
              { id: "signout", text: "Sign out" }
            ],
            onItemClick: (({ detail }) => {
              switch (detail.id) {
                case "signout":
                  // revoke cookie and reload page to force sign in
                  setAuthCookie(props.authUser, 0);
                  window.location.reload(false);
                break;
              }
            })
          }
          :
          {
            type: 'button',
            iconName: 'user-profile',
            ariaLabel: 'Sign in',
            badge: true,
            disableUtilityCollapse: true,
            href: `/`
          }
        ),
        { type: 'button', iconName: 'settings', title: 'Settings', ariaLabel: 'Settings' },
      ]}
    />
  </div>);
}

function provideMode() {
  const mode = getMode();
  if (mode) return mode === 'dark';
  return true;
}

function CustomApp() {
  const [authUser, setAuthUser] = useState(undefined);
  const [darkMode, setDarkMode] = useState(provideMode());

  return (<div>
    <CustomTopNavigation authUser={authUser} setAuthUser={setAuthUser} darkMode={darkMode} setDarkMode={setDarkMode}/>
    <App authUser={authUser} setAuthUser={setAuthUser} darkMode={darkMode} setDarkMode={setDarkMode}/>
  </div>);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CustomApp />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
