import {useEffect, useRef, useState} from 'react';
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import HelpPanel from "@cloudscape-design/components/help-panel";
import {TextContent} from "@cloudscape-design/components";
import Modal from "@cloudscape-design/components/modal";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import Toggle from "@cloudscape-design/components/toggle";
import ProgressBar from "@cloudscape-design/components/progress-bar";
import Alert from "@cloudscape-design/components/alert";

import { uploadConfig } from '../question/question-common';

/*
  General purpose remove Word from Puzzle
*/
function voidWordInWordPuzzle(wordToVoid, tmpWordPuzzle, directionMapping) {
  for (let k=0;k<wordToVoid.word.length;k++) {
    const i = wordToVoid.startingCell.row + directionMapping[wordToVoid.cellDirection].i(k);
    const j = wordToVoid.startingCell.col + directionMapping[wordToVoid.cellDirection].j(k);
    const oldColor = tmpWordPuzzle[i][j].color;
    const oldValue = tmpWordPuzzle[i][j].value;
    const oldHorizontal = tmpWordPuzzle[i][j].horizontal;
    const oldVertical = tmpWordPuzzle[i][j].vertical;
    switch (directionMapping[wordToVoid.cellDirection].type) {
      case "horizontal":
           tmpWordPuzzle[i][j] = {
             value: oldColor === "gray" ? oldValue : undefined,
             color: oldColor === "gray" ? "tomato" : undefined,
             vertical: oldVertical
           };
      break;
      case "vertical":
           tmpWordPuzzle[i][j] = {
             value: oldColor === "gray" ? oldValue : undefined,
             color: oldColor === "gray" ? "lightgreen" : undefined,
             horizontal: oldHorizontal
           };
      break;
    }
  }
  return tmpWordPuzzle;
}

/*
  Read Word Value From Puzzle
*/
function readWordValueFromPuzzle(wordToVoid, tmpWordPuzzle, directionMapping) {
  let deletedWord = "";
  for (let k=0;k<wordToVoid.word.length;k++) {
    const i = wordToVoid.startingCell.row + directionMapping[wordToVoid.cellDirection].i(k);
    const j = wordToVoid.startingCell.col + directionMapping[wordToVoid.cellDirection].j(k);
    const oldCellValue = tmpWordPuzzle[i][j].value;
    deletedWord += oldCellValue ? oldCellValue : "";
  }
  return deletedWord;
}

/*
  Removes Word Value from Puzzle
*/
function removeWordValueFromPuzzle(wordToVoid, tmpWordPuzzle, directionMapping) {
  for (let k=0;k<wordToVoid.word.length;k++) {
    const i = wordToVoid.startingCell.row + directionMapping[wordToVoid.cellDirection].i(k);
    const j = wordToVoid.startingCell.col + directionMapping[wordToVoid.cellDirection].j(k);
    if ((tmpWordPuzzle[i][j].horizontal && !tmpWordPuzzle[i][j].vertical) ||
      (!tmpWordPuzzle[i][j].horizontal && tmpWordPuzzle[i][j].vertical)) {
      tmpWordPuzzle[i][j].value = undefined;
    }
  }
  return tmpWordPuzzle;
}

/*
  General purpose load all words Word into Puzzle
*/
function loadWordsInWordPuzzle(allWords, wordPuzzle, directionMapping) {
  const iterator = (wordList, tmpWordPuzzle) => {
    for (let w=0;w<wordList.length;w++) {
      const wordToLoad =  wordList[w];
      for (let k=0;k<wordToLoad.word.length;k++) {
        const i = wordToLoad.startingCell.row + directionMapping[wordToLoad.cellDirection].i(k);
        const j = wordToLoad.startingCell.col + directionMapping[wordToLoad.cellDirection].j(k);
        let newValue = wordToLoad.word.charAt(k);
        let newCell = {};
        const oldCell = tmpWordPuzzle[i][j];
        switch (directionMapping[wordToLoad.cellDirection].type) {
          case "horizontal":
               newCell = {
                 answer: oldCell.value || newValue, // honors old value (it must never change char value)
                 color: oldCell.color === "tomato" ? "gray" : directionMapping[wordToLoad.cellDirection].color,
                 horizontal: oldCell.horizontal || (w+1), // honors old value (it must never override directions)
                 vertical: oldCell.vertical,
                 attempts: 0,
                 valid: false
               };
          break;
          case "vertical":
               newCell = {
                 answer: oldCell.value || newValue, // honors old value (it must never change char value)
                 color: oldCell.color === "lightgreen" ? "gray" : directionMapping[wordToLoad.cellDirection].color,
                 vertical: oldCell.vertical || (w+1), // honors old value (it must never override directions)
                 horizontal: oldCell.horizontal,
                 attempts: 0,
                 valid: false
               };
          break;
        }
        tmpWordPuzzle[i][j] = newCell;
      }
    }
    return tmpWordPuzzle;
  }
  let newWordPuzzle = wordPuzzle.map(row => row.map(cell => cell));
  newWordPuzzle = iterator(allWords.horizontal, newWordPuzzle);
  newWordPuzzle = iterator(allWords.vertical, newWordPuzzle);
  return newWordPuzzle;
}

/*
  General purpose load all words Word into Puzzle
*/
function verifyWordsInWordPuzzle(allWords, wordPuzzle, directionMapping) {
  const iterator = (wordList, rawWordPuzzle) => {
    let tmpWordPuzzle = rawWordPuzzle.map(row => row.map(cell => cell))
    for (let w=0;w<wordList.length;w++) {
      const wordToVerify =  wordList[w];
      let wordIsValid = true;
      for (let k=0;k<wordToVerify.word.length;k++) {
        const i = wordToVerify.startingCell.row + directionMapping[wordToVerify.cellDirection].i(k);
        const j = wordToVerify.startingCell.col + directionMapping[wordToVerify.cellDirection].j(k);
        const expectedValue = wordToVerify.word.charAt(k);
        const answeredValue = tmpWordPuzzle[i][j].value;
        if (answeredValue == undefined) {
          wordIsValid = false;
          break;
        }
        if (expectedValue !== answeredValue) {
          wordIsValid = false;
          break;
        }
      }
      console.log(`verifying ${wordToVerify.word} = ${wordIsValid}`)
      if (wordIsValid) {
          for (let k=0;k<wordToVerify.word.length;k++) {
            const i = wordToVerify.startingCell.row + directionMapping[wordToVerify.cellDirection].i(k);
            const j = wordToVerify.startingCell.col + directionMapping[wordToVerify.cellDirection].j(k);
            tmpWordPuzzle[i][j]["valid"] = true;
          }
      }
    }
    return tmpWordPuzzle;
  }
  let newWordPuzzle = wordPuzzle.map(row => row.map(cell => cell));
  newWordPuzzle = iterator(allWords.horizontal, newWordPuzzle);
  newWordPuzzle = iterator(allWords.vertical, newWordPuzzle);
  return newWordPuzzle;
}

export function PuzzleV2(props) {

  const inputReference = useRef(null);

  const rows = props.options[0].size.rows;
  const cols = props.options[0].size.cols;
  const cellWidth = 40;
  const cellHeight = 25;
  const validChars = props.options[0].validChars;

  const settings = props.settings || {};
  // records the answer(s) that have already been hinted in order to keep track of the max allowed
  const [hintedAnswers, setHintedAnswers] = useState([]);
  // max allowed answers to hint by default its all of them unless indicated other wise
  const [maxHintedAnswers, setMaxHintedAnswers] = useState(settings.maxHintedAnswers);

  // header instructions
  const [editType, setEditType] = useState("Read");
  // initial empty puzzle matrix rendered in the canvas with size = rows x cols
  const [wordPuzzle, setWordPuzzle] = useState([]);
  // the collected horizontal or vertical words
  const [allWords, setAllWords] = useState({ vertical: [], horizontal: [] });
  // the cell where the input is selected for edition
  const [startingCell, setStartingCell] = useState(false);
  // the direction arrow of the input to be created
  const [cellDirection, setCellDirection] = useState(undefined);
  const [cellUpdateDirection, setCellUpdateDirection] = useState(undefined);
  // the maximum amount of cells to display when editing depending on the starting cell and the direction up to the matrix borders
  const [newMaxWordLength, setNewMaxWordLength] = useState(0);
  // whether the edition modal can be displayed
  const [visiblePuzzleModal, setVisiblePuzzleModal] = useState(false);
  // an ephemeral word puzzle (copy) required for updating, meaning pre removes words that are being updated
  const [baseLinePuzzle, setBaseLinePuzzle] = useState([]);
  // the word puzzle copy where the temporary changes are made while in the editing modal
  const [copyWordPuzzle, setCopyWordPuzzle] = useState([])
  // an indicator when the new word is being created/edited on the edit modal
  const [loadingWord, setLoadingWord] = useState(false);
  // the new word being created on the edit modal
  const [newWord, setNewWord] = useState("");
  // [DEPRECATED ???] whether to display a reason for not allowing word creation
  const [warningMessage, setWarningMessage] = useState(false);
  // the description or hint for the word being created
  const [description, setDescription] = useState("");
  // whether to show i,j indexes on the empty matrix puzzle for position reference
  const [showIndexes, setShowIndexes] = useState(false);
  // whether to shon only the created answers on the matrix puzzle and ignore the rest
  const [answersOnly, setAnswersOnly] = useState(true);
  const [verifyingAnswer, setVerifyingAnswer] = useState(false);

  const [horizontalHint, setHorizontalHint] = useState(undefined);
  const [verticalHint, setVerticalHint] = useState(undefined);
  const [showAnswers, setShowAnswers] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] =  useState(undefined);
  const [isReplay, setIsReplay] = useState(props.roundState ? true : false);

  // a mapping of instructions based out of the starting cell and direction arrow to be used on the edition modal
  const directionMapping = {
    "ArrowDown": {
                   name: "Vertical->Down",
                   type: "vertical",
                   i: (k) => k, j: (k) => 0,
                   maxWords: (cell) => rows - cell.row,
                   maxWidth: (maxLen) => cellWidth * 3,
                   hasRow: (i, cell) => i >= cell.row,
                   hasRowEnd: (i, cell, limit) => i >= cell.row  && i < (cell.row + limit),
                   hasCol: (j, cell) => j == cell.col,
                   hasColEnd: (j, cell, limit) => j == cell.col,
                   color: "tomato",
                   crossColor: "lightgreen"
               },
    "ArrowUp": {
                 name: "Vertical->Up",
                 type: "vertical",
                 i: (k) => -k, j: (k) => 0,
                 maxWords: (cell) => cell.row + 1,
                 maxWidth: (maxLen) => cellWidth * 3,
                 hasRow: (i, cell) => i <= cell.row,
                 hasRowEnd: (i, cell, limit) => i <= cell.row  && i > (cell.row - limit),
                 hasCol: (j, cell) => j == cell.col,
                 hasColEnd: (j, cell, limit) => j == cell.col,
                 color: "tomato",
                 crossColor: "lightgreen"
               },
    "ArrowRight": {
                    name: "Horizontal->Right",
                    type: "horizontal",
                    i: (k) => 0, j: (k) => k,
                    maxWords: (cell) => cols - cell.col,
                    maxWidth: (maxLen) => cellWidth * (maxLen+2),
                    hasRow: (i, cell) => i == cell.row,
                    hasRowEnd: (i, cell, limit) => i == cell.row,
                    hasCol: (j, cell) => j >= cell.col,
                    hasColEnd: (j, cell, limit) => j >= cell.col && j < (cell.col + limit),
                    color: "lightgreen",
                    crossColor: "tomato"
                  },
    "ArrowLeft": {
                   name: "Horizontal->Left",
                   type: "horizontal",
                   i: (k) => 0,
                   j: (k) => -k,
                   maxWords: (cell) => cell.col + 1,
                   maxWidth: (maxLen) => cellWidth * (maxLen+2),
                   hasRow: (i, cell) => i == cell.row,
                   hasRowEnd: (i, cell, limit) => i == cell.row,
                   hasCol: (j, cell) => j <= cell.col,
                   hasColEnd: (j, cell, limit) => j <= cell.col && j > (cell.col - limit),
                   color: "lightgreen",
                   crossColor: "tomato"
                 },
  }

  const HelperSplitPanel = (helperProps) => (
    <HelpPanel
        header={<h2>Puzzle Hint(s)</h2>}
      >
      <SpaceBetween direction="vertical" sze="s">
        {(helperProps.horizontalHint || helperProps.verticalHint) && <Container>
        {helperProps.horizontalHint &&
          <TextContent>
          {allWords.horizontal[helperProps.horizontalHint-1].cellDirection === "ArrowRight" && <a href="#">➡️ Right:</a>}
          {allWords.horizontal[helperProps.horizontalHint-1].cellDirection === "ArrowLeft" && <a href="#">⬅️ Left:</a>}
          {` ${allWords.horizontal[helperProps.horizontalHint-1].description}`}
          </TextContent>
        }
        {helperProps.verticalHint &&
          <TextContent>
          {allWords.vertical[helperProps.verticalHint-1].cellDirection === "ArrowDown" && <a href="#">⬇️ Down:</a>}
          {allWords.vertical[helperProps.verticalHint-1].cellDirection === "ArrowUp" && <a href="#">⬆️ Up:</a>}
          {` ${allWords.vertical[helperProps.verticalHint-1].description}`}
          </TextContent>
        }
        </Container>}
          <ul>
          <li><TextContent><strong>Wizard input</strong>: <em>click any </em><em style={{color:'green'}}>green</em> | <em style={{color:'red'}}>red</em><em> color cell then press Enter to update the word</em></TextContent></li>
          <li><TextContent><strong>Manual input</strong>: <em>click any colored cell and start typing [does not support special chars, accents, umlauts, etc]</em></TextContent></li>
          <li><TextContent>Click on <strong>Verify Answers</strong>: <em>to check inputs, on success cells are marked white and blocked, else nothing happens</em></TextContent></li>
          </ul>
      </SpaceBetween>
    </HelpPanel>
  );

  // loads the cross word on component create
  useEffect(() => {
    let initWordPuzzle = Array.from(Array(rows)).map((_, k) => k + 1).map(k =>
         Array.from(Array(cols)).map((_, i) => i + 1).map(i => ({ value: undefined }))
      );
    const initAllWords = props.options[0].qs;
    setWordPuzzle(isReplay ? props.roundState[0] : loadWordsInWordPuzzle(initAllWords, initWordPuzzle, directionMapping));
    setAllWords(initAllWords);
    setMaxHintedAnswers(settings.maxHintedAnswers === undefined ?
      (initAllWords.horizontal.length + initAllWords.vertical.length) : settings.maxHintedAnswers)
    // and reset the split panel
    props.routerProps.setSplitPanelItems(() => <HelperSplitPanel/>);
    props.routerProps.setSplitPanelToggle(true);
  }, []);

  function cleanPuzzleHints(puzzleToClean) {
    return puzzleToClean.map(row => row.map(cell => {
      let newCell = {};
      Object.keys(cell).forEach(key => newCell[key] = cell[key]);
      newCell.reveal = false;
      return newCell;
    }));
  }

  useEffect(() => {
    let newWordPuzzle = cleanPuzzleHints(wordPuzzle);
    if (showAnswers && (horizontalHint || verticalHint)) {
       let tmpHintedAnswers = [];
       hintedAnswers.forEach(item => tmpHintedAnswers.push(item));
       newWordPuzzle = newWordPuzzle.map(row => row.map(cell => {
         if (cell.horizontal && horizontalHint && cell.horizontal == horizontalHint) {
           const hasBeenHinted = tmpHintedAnswers.some(item => item === `h.${horizontalHint}`)
           if (!hasBeenHinted) tmpHintedAnswers.push(`h.${horizontalHint}`);
           cell.reveal = true;
         }
         if (cell.vertical && verticalHint && cell.vertical == verticalHint) {
           const hasBeenHinted = tmpHintedAnswers.some(item => item === `v.${verticalHint}`)
           if (!hasBeenHinted) tmpHintedAnswers.push(`v.${verticalHint}`);
           cell.reveal = true;
         }
         return cell;
       }));
       if (tmpHintedAnswers.length <= maxHintedAnswers) {
         // set puzzle with new hinted words and updates hinted answers
         setHintedAnswers(tmpHintedAnswers);
         setWordPuzzle(newWordPuzzle);
       } else {
         // unset puzzle back without hinted words
         setWordPuzzle(cleanPuzzleHints(wordPuzzle));
       }
    }
    if (!showAnswers && (horizontalHint || verticalHint)) {
       setWordPuzzle(newWordPuzzle);
    }
  }, [showAnswers, horizontalHint, verticalHint]);

  /*
    in order to disable clicks out of the word canvas:
      => https://stackoverflow.com/questions/28083708/how-to-disable-clicking-inside-div
      => https://developer.mozilla.org/en-US/docs/Web/CSS/pointer-events
    css color names:
      => https://www.w3.org/wiki/CSS/Properties/color/keywords
    flex direction and align items on div:
      => https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_flexible_box_layout/Aligning_items_in_a_flex_container
      => https://developer.mozilla.org/en-US/docs/Web/CSS/align-items
  */

  function exitCreate() {
    setNewWord("");
    setDescription("");
    setStartingCell(undefined);
    setCellUpdateDirection(undefined);
    setLoadingWord(false);
    setVisiblePuzzleModal(false);
    if (inputReference.current) inputReference.current.focus();
  }

  function doUpdateWordPuzzle() {
    setLoadingWord(true);
    let newWordPuzzle = baseLinePuzzle.map(wpRow => wpRow.map(wpCell => wpCell));

    // cant update a word that is not complete and doesn't match the expected length
    if (newWord.length != newMaxWordLength) {
      setLoadingWord(false);
      return;
    }

    for (let k=0;k<newWord.length;k++) {
      const i = startingCell.row + directionMapping[cellUpdateDirection].i(k);
      const j = startingCell.col + directionMapping[cellUpdateDirection].j(k);
      // if its a crossed word all chars must match
      if (newWordPuzzle[i][j].value) {
        if (newWordPuzzle[i][j].value !== `${newWord.charAt(k)}`) {
          setLoadingWord(false);
          return;
        }
      }
      newWordPuzzle[i][j].value = `${newWord.charAt(k)}`;
    }
    setWordPuzzle(newWordPuzzle);
    setTimeout(() => {
      exitCreate();
    }, 750);
  }

  function processWizardUpdate(cell) {
    // if both horizontal and vertical indexes are present then it can not update cause of the ambiguity
    if (cell.horizontal && cell.vertical) return;
    // edge case if it got here without any cell direction
    if (!(cell.horizontal || cell.vertical)) return;
    // now it will identify the word to remove
    let newHorizontal = [];
    let newVertical = [];
    let wordToVoid = {};
    let wordToUpdate = {};

    // makes a copy of the base line
    let tmpBaselineWordPuzzle = wordPuzzle.map(rowCopy => rowCopy.map(cellCopy =>
    ({ value: cellCopy.value, answer: cellCopy.answer, color: cellCopy.color, horizontal: cellCopy.horizontal, vertical: cellCopy.vertical, valid: cellCopy.valid, reveal: cellCopy.reveal })));

    if (cell.horizontal) {
        // if cell is horizontal remove only this horizontal word from all words
        wordToVoid = allWords.horizontal[cell.horizontal - 1];
        const actualWord = readWordValueFromPuzzle(wordToVoid, tmpBaselineWordPuzzle, directionMapping);
        Object.keys(wordToVoid).forEach(key => wordToUpdate[key] = wordToVoid[key]);
        wordToUpdate.word = actualWord;
    }
    if (cell.vertical) {
        // if cell is vertical remove only this vertical word from all words
        wordToVoid = allWords.vertical[cell.vertical - 1];
        const actualWord = readWordValueFromPuzzle(wordToVoid, tmpBaselineWordPuzzle, directionMapping);
        Object.keys(wordToVoid).forEach(key => wordToUpdate[key] = wordToVoid[key]);
        wordToUpdate.word = actualWord;
    }
    // establishes the cell start and the cell direction
    setStartingCell(wordToVoid.startingCell);
    setCellUpdateDirection(wordToVoid.cellDirection);
    // sets the max only to the word being modified
    setNewMaxWordLength(wordToVoid.word.length);
    // this is the word that is being modified and displayed IS THE USER ACTUAL VALUE AND NOT THE ANSWER
    setNewWord(wordToUpdate.word);
    // this is the corresponding description or hint of that word
    setDescription(wordToVoid.description);
    // makes a copy of the word puzzle
    setCopyWordPuzzle(wordPuzzle.map(rowCopy => rowCopy.map(cellCopy =>
    ({ value: cellCopy.value, answer: cellCopy.answer, color: cellCopy.color, horizontal: cellCopy.horizontal, vertical: cellCopy.vertical, valid: cellCopy.valid, reveal: cellCopy.reveal }))));
    // cleans up word cell values from puzzle
    const cleanedPuzzle = removeWordValueFromPuzzle(wordToVoid, tmpBaselineWordPuzzle, directionMapping);
    setBaseLinePuzzle(cleanedPuzzle);
    setWordPuzzle(cleanedPuzzle);
    setEditType("Update");
    props.routerProps.setSplitPanelToggle(false);

//========
  // TODO: investigate further
  // Workaround change, on the production site when Enter key pressed is not behaving as test environment
  // it is actually closing the side panel and focusing on verify answers
  // apparently the enter kicks in and transfers the key pressed to the escape X button on the modal
//========
    setTimeout(() => setVisiblePuzzleModal(true), 500);
  }

  function publishCallback(response) {
    if (response.err) setSubmitMessage(response.err);
    else { setSubmitMessage("You are good to go!"); setIsReplay(true); }
    setTimeout(() => {
      setIsSubmitting(false);
    }, 500);
  }

  return (
    <SpaceBetween direction="vertical" size="s">
      {submitMessage && <Alert
        onDismiss={() => { /* do nothing */ }}
        type={"info"}
        statusIconAriaLabel={"Info"}
        header="Submit result"
      ><TextContent>{submitMessage}</TextContent>
      </Alert>}
      <div>
        {visiblePuzzleModal && <Modal
          visible={visiblePuzzleModal}
          closeAriaLabel="Close"
          onDismiss={() => {
            exitCreate();
          }}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  disabled={newWord.length > newMaxWordLength || description.length == 0}
                  variant="primary"
                  loading={loadingWord}
                  onClick={() => {
                    doUpdateWordPuzzle();
                  }}
                  >{editType}
                </Button>
                <Button variant="normal" onClick={() => {
                    exitCreate();
                  }}
                  >Cancel
                </Button>
              </SpaceBetween>
            </Box>
          }
          header={
            <SpaceBetween size="l" direction="horizontal">
              {`${editType} Word @Cell(${startingCell ? (startingCell.row + 1) : ""},${startingCell ? (startingCell.col + 1) : ""})`}
            </SpaceBetween>}
        >
          <SpaceBetween direction="vertical" size="s">
            <ColumnLayout columns={2}>
              <div>{`Word [max length: ${newMaxWordLength}]`}</div>
              <div>
                <Input disabled={loadingWord}
                  autoFocus
                  onChange={({ detail }) => {
                    let tmpCopyWP = baseLinePuzzle.map(rowCopy => rowCopy.map(cellCopy =>
                      ({ value: cellCopy.value, color: cellCopy.color, horizontal: cellCopy.horizontal, vertical: cellCopy.vertical })));
                    const tmpNewWord = detail.value;
                    let truncatedWord = "";
                    for (let k=0;k<newMaxWordLength;k++) {
                      const i = startingCell.row + directionMapping[cellUpdateDirection].i(k);
                      const j = startingCell.col + directionMapping[cellUpdateDirection].j(k);
                      if (k < tmpNewWord.length) {
                        // if cell direction already exist from another word with the same direction
                        if (baseLinePuzzle[i][j].value) {
                          // or cell doesn't match the value from another direction then discard
                          if (baseLinePuzzle[i][j].value !== `${tmpNewWord.charAt(k)}`) {
                            setNewWord(truncatedWord);
                            setCopyWordPuzzle(tmpCopyWP);
                            return;
                          }
                        }
                        tmpCopyWP[i][j].value = `${tmpNewWord.charAt(k)}`;
                        truncatedWord += `${tmpNewWord.charAt(k)}`;
                      } else {
                        tmpCopyWP[i][j] = baseLinePuzzle[i][j];
                      }
                    }
                    setNewWord(tmpNewWord);
                    setCopyWordPuzzle(tmpCopyWP);
                  }}
                  value={newWord}
                  invalid={newWord.length > newMaxWordLength || newWord.length <= 1}
                />
              </div>
              <div>{`Puzzle Hint`}</div>
              <div>
                <TextContent>{`${description}`}</TextContent>
              </div>
            </ColumnLayout>
            <Container fitHeight={true}>
            {<div>
            {(startingCell && cellUpdateDirection) && copyWordPuzzle
              .filter((row, iRow) => directionMapping[cellUpdateDirection].hasRowEnd(iRow, startingCell, newMaxWordLength))
              .map((row, iRow) => (
                <div style={{width: `${directionMapping[cellUpdateDirection].maxWidth(newMaxWordLength)}px`, display: 'flex',
                  flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}
                >
                {row
                  .filter((cell, jCell) => directionMapping[cellUpdateDirection].hasColEnd(jCell, startingCell, newMaxWordLength))
                  .map((cell, jCell) =>
                    (<div style={{
                       borderStyle: 'solid', borderColor: 'gray', borderWidth: '1px',
                       height:`${cellHeight}px`, width:`${cellWidth}px`, color: (cell.value ? 'black' : 'gray'),
                       backgroundColor: cell.color || 'white', textAlign:'center', overflow: 'hidden',
                       whiteSpace: 'nowrap', display: 'block'}}
                     >
                     {cell.value}
                     </div>)
                )}
                </div>
              ))}
            </div>}
            </Container>
          </SpaceBetween>
        </Modal>}
      </div>
    <Header variant="h1">
      <SpaceBetween direction={"horizontal"} size={"s"}>
        {props.header}
        {/*<Button variant="inline-icon" disabled
                onClick={() => {
                  // setTestConfigModalVisible(true);
                }}
                iconName={"settings"}
        />*/}
      </SpaceBetween>
    </Header>
    <Container
      header={
        <SpaceBetween direction={"horizontal"} size={"xxl"} alignItems="end">
          <Header variant="h2" description={props.title}>
            Puzzle
          </Header>
          {(maxHintedAnswers > 0) && <SpaceBetween direction={"horizontal"} size={"l"} alignItems="center">
            <ProgressBar
              value={hintedAnswers.length/maxHintedAnswers*100}
              additionalInfo={`[Hints(s): ${hintedAnswers.length}/${maxHintedAnswers}]`}
            />
            <Toggle
              disabled={(allWords.vertical.length == 0 && allWords.horizontal.length == 0)}
              onChange={({ detail }) => {
                setShowAnswers(detail.checked);
              }}
              checked={showAnswers}
            >
              Hint Answers
            </Toggle>
          </SpaceBetween>}
        </SpaceBetween>
      }
      fitHeight={true}
      fitWidth={true}
      >
      <SpaceBetween direction="vertical" size="s">
      <SpaceBetween direction="horizontal" size="s">
      <div style={{borderRadius: '5px', backgroundColor: 'white', borderStyle: 'solid', borderWidth: '1px', width: `${cellWidth * (cols+2)}px`, display:'flex', "flexDirection": 'row', "flexWrap": 'wrap', "justifyContent": 'center'}}>
      {wordPuzzle
      .map((row, iRow) => (
        <div style={{width: `${cellWidth * (cols+2)}px`, display: 'flex', "flexDirection": 'row', "flexWrap": 'wrap', "justifyContent": 'center'}}>
        {row.map((cell, jCell) =>
          (<div style={{"pointer-events":cell.answer ? (cell.valid ? 'none' : undefined) : 'none',"borderRadius": (!cell.valid && startingCell && iRow == startingCell.row && jCell == startingCell.col) ? '0px' : '5px', "border-style": 'groove', "borderColor": (answersOnly && !cell.value) ? 'white' : 'black', "borderWidth": (startingCell && iRow == startingCell.row && jCell == startingCell.col) ? '2px' : '1px', height:`${cellHeight}px`, width:`${cellWidth}px`, 'color':cell.reveal ? 'black' : (cell.value !== undefined ? 'black' : 'gray'), 'background-color': (cell.valid ? 'white' : cell.color) || 'white', textAlign:'center', overflow: 'hidden', "whiteSpace": 'nowrap', "display": 'block'}}
              disabled={true}
              onClick={() => {
                if (isReplay) return;
                if (cell.answer) {
                  setStartingCell({ row: iRow, col: jCell });
                  setHorizontalHint(cell.horizontal);
                  setVerticalHint(cell.vertical);
                  setCellDirection(`${cell.horizontal ? directionMapping[allWords.horizontal[cell.horizontal-1].cellDirection].type : ""}${cell.vertical ? directionMapping[allWords.vertical[cell.vertical-1].cellDirection].type: ""}`);
                  props.routerProps.setSplitPanelItems(() => <HelperSplitPanel horizontalHint={cell.horizontal} verticalHint={cell.vertical}/>);
                  props.routerProps.setSplitPanelToggle(true);
                } else {
                  setStartingCell(undefined);
                  setHorizontalHint(undefined);
                  setVerticalHint(undefined);
                }
              }}
              onKeyDown={(e) => {
                if (isReplay) return;
                setShowAnswers(false);
                // edge case if it got here without any starting cell value or if it landed out
                if (!startingCell) return;
                const tmpI = startingCell.row;
                const tmpJ = startingCell.col
                if (!wordPuzzle[tmpI][tmpJ] || !wordPuzzle[tmpI][tmpJ].answer) setStartingCell(undefined);

                let newValue = `${e.key}`;
                const validI = (newI) => 0 <= newI && newI < rows;
                const validJ = (newJ) => 0 <= newJ && newJ < cols;

                // you can NOT enter a valid cell (cell that was already verified) by clicking in
                  // but you can cross run into it moving from a cross word cell,
                  // so move to the next one and ignore modification
                // at this point is attempting an updating over an existing cell
                // WHAT THIS MEANS IS: if the character is any metadata or direction "except a backspace" then...
                if (!(validChars.includes(newValue) || newValue === "Backspace")) {
                  // on any of ArrowDown ArrowUp ArrowRight ArrowLeft check whether is a valid move
                  if (/*newValue === "Enter" || newValue === "Meta" ||*/
                  newValue === "ArrowDown" || newValue === "ArrowUp" || newValue === "ArrowRight" || newValue === "ArrowLeft") {
                    const nextI = tmpI + directionMapping[newValue].i(1);
                    const nextJ = tmpJ + directionMapping[newValue].j(1);
                    if (validI(nextI) && validJ(nextJ) && wordPuzzle[nextI][nextJ].answer) {
                      if (cellDirection == "horizontalvertical") { // in order to disambiguate the direction
                        setStartingCell({ row: nextI, col: nextJ });
                        setCellDirection(directionMapping[newValue].type);
                      } else if ((cellDirection == "horizontal" && cellDirection == directionMapping[newValue].type)
                        // meaning you cant jump from one direction to another unrelated direction
                        || (cellDirection == "vertical" && cellDirection == directionMapping[newValue].type)) {
                        setStartingCell({ row: nextI, col: nextJ });
                      }
                    }
                    return;
                  }

                  // assisted edit is only accessible through an Enter
                  if (newValue === "Enter") {
                    //
                      processWizardUpdate(cell);
                      return;
                    //
                  }

                  // any other meta character is not a valid key, exit do nothing
                  return;
                }
                // else set the new letter
                let newWordPuzzle = wordPuzzle.map(newRow => newRow.map(newCell => newCell));
                const validNewValue = newWordPuzzle[tmpI][tmpJ].valid ? newWordPuzzle[tmpI][tmpJ].value : newValue;
                newWordPuzzle[tmpI][tmpJ]["value"] = validNewValue === "Backspace" ? undefined : validNewValue;

                switch (`${cellDirection}`) {
                  case "horizontal":
                    const hCellDirection = allWords.horizontal[cell.horizontal-1].cellDirection;
                    const nextHi = tmpI + directionMapping[hCellDirection].i(1);
                    const nextHj = tmpJ + directionMapping[hCellDirection].j(1);
                    if (validI(nextHi) && validJ(nextHj) && wordPuzzle[nextHi][nextHj].answer) {
                      setStartingCell({ row: nextHi, col: nextHj });
                      //setCellDirection(hCellDirection);
                      setWordPuzzle(newWordPuzzle);
                      return;
                    }
                  break;
                  case "vertical":
                    const vCellDirection = allWords.vertical[cell.vertical-1].cellDirection;
                    const nextVi = tmpI + directionMapping[vCellDirection].i(1);
                    const nextVj = tmpJ + directionMapping[vCellDirection].j(1);
                    if (validI(nextVi) && validJ(nextVj) && wordPuzzle[nextVi][nextVj].answer) {
                      setStartingCell({ row: nextVi, col: nextVj });
                      //setCellDirection(vCellDirection);
                      setWordPuzzle(newWordPuzzle);
                      return;
                    }
                  break;
                  case "horizontalvertical":
                      // ambiguity so no movement
                      //setCellDirection(undefined);
                      setWordPuzzle(newWordPuzzle);
                      return;
                  break;
                }
                setStartingCell(undefined);
                setCellDirection(undefined);
                setWordPuzzle(newWordPuzzle);
                if (inputReference && inputReference.current) inputReference.current.focus();
              }}
              tabIndex="0"
           >
            {cell.reveal ? cell.answer : (cell.value ? cell.value : ((answersOnly || !showIndexes) ? cell.value : `${iRow+1},${jCell+1}`))}
          </div>))}
        </div>
      ))}
      </div>

      </SpaceBetween>
      <SpaceBetween direction="horizontal" size="s">

      </SpaceBetween>
      <SpaceBetween direction="horizontal" size="s">
            <Button variant="primary"
              ref={inputReference}
              loading={verifyingAnswer}
              disabled={isReplay}
              onClick={() => {
                setShowAnswers(false);
                setStartingCell(false);
                setCellDirection(false);
                setVerifyingAnswer(true);
                setTimeout(() => {
                  setWordPuzzle(verifyWordsInWordPuzzle(allWords, wordPuzzle, directionMapping));
                  setVerifyingAnswer(false);
                }, 500)
              }}
            >
              Verify Answers
            </Button>
            <Button variant="secondary"
              disabled={isReplay}
              onClick={() => {
                setShowAnswers(false);
                setStartingCell(false);
                setCellDirection(false);
                setWordPuzzle(
                  wordPuzzle.map(r =>
                    r.map(c => ({ value: undefined, answer: c.answer, color: c.color, horizontal: c.horizontal, vertical: c.vertical }))
                  ));
              }}
            >
              Clear Answers
            </Button>
            {(props.onlineAssignmentPublish) &&
            <Button variant="secondary"
              loading={isSubmitting}
              disabled={isReplay}
              onClick={() => {
                    setIsSubmitting(true);
                    setTimeout(() => {
                      let submitData = {}
                      Object.keys(props.onlineAssignmentConfig)
                        .forEach(key => { submitData[key] = props.onlineAssignmentConfig[key]; });
                      submitData.roundState = [ wordPuzzle ];
                      uploadConfig(
                        props.onlineAssignmentPublish.author, props.onlineAssignmentPublish.newResource, submitData, publishCallback);
                    }, 500)
              }}
            >Submit
            </Button>}
      </SpaceBetween>
    </SpaceBetween>
    </Container>
    </SpaceBetween>
  );

}
