import React, {useEffect, useRef, useState} from 'react';
import {BrowserRouter, Outlet, Route, Routes, useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom';
import './App.css';
import "@cloudscape-design/global-styles/index.css"
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import {AppLayout, SplitPanel, TextContent} from "@cloudscape-design/components";
import ExpandableSection from "@cloudscape-design/components/expandable-section";
import SideNavigation from '@cloudscape-design/components/side-navigation';
import ColumnLayout from "@cloudscape-design/components/column-layout";
import Form from "@cloudscape-design/components/form";
import FormField from "@cloudscape-design/components/form-field";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import Spinner from "@cloudscape-design/components/spinner";
import Textarea from "@cloudscape-design/components/textarea";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Modal from "@cloudscape-design/components/modal";
import Box from "@cloudscape-design/components/box";
import Badge from "@cloudscape-design/components/badge";
import Link from "@cloudscape-design/components/link";

import {configs} from "./configs";
import {getAuthCookie, getUser, setAuthCookie, setPrefCookie, getAuthData} from './cookies/cookies';
import HomeV2, {navHeaderV2, navItemsV2} from './home';
import { MultiOptionsQuestionPanelV2 } from './question/multi-options';
import { SingleQuestionMultiOptionsPanelV2 } from './question/single-question-multi-options';
import { MultiQuestionMultiOptionsPanelV2 } from './question/multi-question-multi-options';
import { PuzzleV2 } from './puzzle/puzzle';
import { BreadcrumbsV2, StaticTableV2, DismissibleError, DismissibleInfo, gpTopicMdControlV2 } from './common/common';
import { downLoadConfigOn, listConfigs } from './question/question-common';
import { CommonEditorV2 } from './question/common-editor';
import { MultiTopicCommonEditorV2 } from './question/multi-topic-common-editor';
import { MultiTopicPanelContainerV2 } from './question/multi-topic-panel-container'

function DragAndDropOptionsPanel(props) {

  const dragItemDiv = useRef();
  const dragOverItemDiv = useRef();
  const [answerValues, setAnswerValues] = useState([undefined, undefined, undefined, undefined]);
  const [listDiv, setListDiv] = useState(['Div 1','Div 2','Div 3','Div 4']);

  const dragItemButton = useRef();
  const dragOverItemButton = useRef();
  const [listButton, setListButton] = useState(['Button 1','Button 2','Button 3','Button 4']);

  const dragStartButton = (e, position) => {
    dragItemButton.current = position;
    console.log(`dragging ${e.target.innerHTML}`);
  };

  const dragEnterButton = (e, position) => {
    dragOverItemButton.current = position;
    console.log(`dropping ${e.target.innerHTML}`);
  };

  const dropButton = (e) => {
    console.log(`performing drag and drop action`);
    const copyListItems = [...listButton];
    const dragItemContent = copyListItems[dragItemButton.current];
    copyListItems.splice(dragItemButton.current, 1);
    copyListItems.splice(dragOverItemButton.current, 0, dragItemContent);
    dragItemButton.current = null;
    dragOverItemButton.current = null;
    setListButton(copyListItems);
  };

  function allowDrop(ev) {
    console.log(`on allow drop`);
    ev.preventDefault();
  }

  function drag(ev) {
    console.log(`on drag`);
    ev.dataTransfer.setData("text", ev.target.id);
  }

  function drop(ev) {
    console.log(`on drop`);
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    ev.target.appendChild(document.getElementById(data));
  }

  const [inputValue, setInputValue] = useState("");
  const [qs, setQs] = useState([]);
  const [children, setChildren] = useState(undefined);

  const [items, setItems] = useState([]);
  const [columnDefinitions, setColumnDefinitions] = useState([]);

  useEffect(() => {
    setColumnDefinitions(['type', 'name', 'location']);
    setItems([
                  {
                    type: "audio",
                    name: "audio - 1",
                    location: "s3://asd"
                  }
                ]);
  }, []);

  return (
  <SpaceBetween direction="vertical" size="xs">
    <ColumnLayout columns={2}>
      <div>
        <Container>
            <SpaceBetween>
            <h2>Question Edit (Danke Schön)</h2>
            <TextContent id="textEdit-1"></TextContent>
            {qs.length > 0 ?
              qs.map(q => (<TextContent id="textEdit">
                            {q.pre}{q.ans}{q.ans && <Button iconName="edit"/>}{q.ans && <Button iconName="delete"/>}{q.post}
                           </TextContent>))
              :
              <em>Start typing a question on the Text Area</em>
            }
            </SpaceBetween>
        </Container>
      </div>
      <div>
        <SpaceBetween size="xs">
            <StaticTableV2
              headerVariant={"h4"}
              columnDefinitions={columnDefinitions
                  .map(item => ({
                        id: item,
                        header: item,
                        sortingField: item,
                        cell: e => e[item]
                      })
                  )}
              loading={false}
              items={items}
            />
            <form onSubmit={e => e.preventDefault()}>
              <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button formAction="none" variant="link">Clear</Button>
                    <Button variant="primary">Insert</Button>
                  </SpaceBetween>
                }
              >
                <Container
                  header={
                    <Header variant="h2">
                      Challenge Editor Form
                    </Header>
                  }
                >
                  <SpaceBetween direction="vertical" size="l">
                    <FormField label="Answer">
                      <Input />
                    </FormField>
                    <FormField label="Image">
                      <Input />
                    </FormField>
                    <FormField label="Drop Down Options">
                      <Input />
                    </FormField>
                  </SpaceBetween>
                </Container>
              </Form>
            </form>
        </SpaceBetween>
      </div>
    </ColumnLayout>
    <Textarea
      onChange={({ detail }) => {
        setInputValue(detail.value);
      }}
      value={inputValue}
      placeholder="This is a placeholder"
    />
    <SpaceBetween direction="horizontal" size="xs">
    <Button variant="primary"
      onClick={() => {
        document.getElementById("textEdit-1").appendChild(document.createTextNode("Water"));
        const currentIndex = qs.length;
        const nextIndex = qs.length + 1;
        if (currentIndex > 0) {
          if (qs[currentIndex].pre && !qs[currentIndex].post) {
            qs[currentIndex]['post'] = `${inputValue}`;
            setInputValue(undefined);
          }
          if (qs[currentIndex].pre && qs[currentIndex].post) {
            qs.push({
              pre: `${inputValue}`
            });
            setInputValue(undefined);
          }
        } else {
          qs.push({
            pre: `${inputValue}`
          });
          setInputValue(undefined);
        }
      }
      }
    >+ Answer</Button><Button variant="primary">+ Line Break</Button>
    </SpaceBetween>
  </SpaceBetween>
  )
}

function withRouter() {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
        <Navigation
            {...props}
            router={{ location, navigate, params }}
        />
    );
  }

  return ComponentWithRouterProp;
}

function UserButton(props) {
  const [changeButtonColor, setChangeButtonColor] = useState(false);

  function getColor(newChangeButtonColor) {
    return (newChangeButtonColor ? 'dodgerblue' : 'blue');
  }

  return (<button
    key={props.key}
    style={{ borderRadius: '5px', backgroundColor: getColor(changeButtonColor), borderColor: getColor(changeButtonColor) }}
    onMouseOver={() => setChangeButtonColor(true)}
    onMouseOut={() => setChangeButtonColor(false)}
    onClick={() => props.navigate(`/${props.playModeNavigation}&userId=${props.user}`)}
  >{props.user}</button>);
}

/*
  Per Language Level Lessons Menu, each language comes with different level categories and each contains a list of lessons to display

  references:
  - local date: https://www.freecodecamp.org/news/how-to-format-dates-in-javascript/
*/
function ExpandableMenu(props) {
  const navigate = useNavigate();
  const totalItems = props.totalItems;
  const selectionType = undefined;

  const [isLoadingPath, setIsLoadingPath] = useState(null);
  const [isLoadingEditPath, setIsLoadingEditPath] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoadingSection, setIsLoadingSection] = useState(false);

  return (
    <ExpandableSection
      key={`exp-sec-${props.level}`}
      headerText={props.author ? `${props.level} - @${props.author}` : props.level}
      headerDescription={`${totalItems} assignment(s)`}
      expanded={isExpanded}
      onChange={(event) => setIsExpanded(event.detail.expanded) }
    >
      <SpaceBetween key={`exp-sec-sb-${props.level}`} direction="vertical" size="xxxs">
          <StaticTableV2
            key={`exp-sec-table-${props.level}`}
            headerVariant={"h4"}
            variant="embedded"
            columnDefinitions={["Assignment", "Version", "Authorized Users", "Last Updated", "Due On"]
              .map(item => ({
                id: item,
                header: item,
                cell: e => (
                  <div key={`section-item-${props.level}-${e.index}`}>
                  {(item === "Version") && <div key={`section-item-version-${props.level}-${e.index}`}>{e.version}</div>}
                  {(item === "Due On") && <div key={`section-item-due-on-${props.level}-${e.index}`}>{`${new Date(e.dueOn).toLocaleString()}`}</div>}
                  {item === "Last Updated" &&
                  <div key={`section-item-updated-${props.level}-${e.index}`}>{`${new Date(e.lastUpdated).toLocaleString()}`}</div>
                  }
                  {item === "Assignment" && <Button
                      key={`section-item-play-btn-${props.level}-${e.index}`}
                      variant="link"
                      iconName="caret-right-filled"
                      iconAlign="left"
                      wrapText={false}
                      loading={isLoadingPath == e.index}
                      onClick={() => {
                          setIsLoadingPath(e.index);
                          setTimeout(() => {
                              navigate(`/${e.assignment.playModeNavigation}`);
                              setIsLoadingPath(null);
                          }, 500);
                  }}>{e.assignment.title}</Button>}
                  {(item === "Assignment" && e.assignment.editModeNavigation) &&
                  <Button
                      key={`section-item-edit-btn-${props.level}-${e.index}`}
                      variant="secondary"
                      iconName="edit"
                      iconAlign="right"
                      wrapText={false}
                      loading={isLoadingEditPath == e.index}
                      onClick={() => {
                          setIsLoadingEditPath(e.index);
                          setTimeout(() => {
                              navigate(`/${e.assignment.editModeNavigation}`);
                              setIsLoadingEditPath(null);
                          }, 500);
                  }}>Edit</Button>}
                  {item === "Authorized Users" &&
                  <SpaceBetween
                    key={`section-item-users-sb-${props.level}-${e.index}`}
                    direction="horizontal"
                    alignItems="center"
                    size="xxs">
                    {(e.assignment.authorizedUsers || []).map((user, j) =>
                      <UserButton
                        key={`section-item-users-nav-button-${props.level}-${e.index}-${j}`}
                        playModeNavigation={e.assignment.playModeNavigation}
                        navigate={navigate}
                        user={user}
                      />
                    )}
                  </SpaceBetween>}
                  </div>
                )
              }))
            }
            loading={isLoadingSection}
            selectionType={selectionType}
            selectedItems={[]}
            setSelectedItems={(items) => {}}
            trackBy="Assignment"
            items={props.lesson.map((assignment, index) => ({
                assignment: assignment,
                index: index,
                lastUpdated: parseInt(assignment.lastUpdated || 0),
                dueOn: parseInt(assignment.dueOn || 0),
                version: (assignment.version || 0),
              })
            ).sort((a, b) => a.lastUpdated - b.lastUpdated)}
          />
      </SpaceBetween>
    </ExpandableSection>
  );
}

/*
  Dynamic Lessons render since lessons components are loaded depending on the type of configuration
*/
function LanguageLesson(props) {

  const navigate = useNavigate();

  const lesson = props.lesson
  const itemsRender = {}
  props.configs
      .filter(value => value.resource === lesson)
      .forEach(value => {
          const itemConfig = value;
          itemsRender[itemConfig.resource] = itemConfig
      });
  const itemConfig = itemsRender[lesson];

  // if a lesson resource is not found then 404
  if (!itemConfig || !itemConfig.type) {
    return (<DismissibleError error={"404 Not Found"} setError={(x) => { }}/>)
  }

  const parentResourceMenu = props.parentResourceMenu || itemConfig.language;
  const header = () => (
    <BreadcrumbsV2
      parentResourceMenu={parentResourceMenu}
      language={itemConfig.language || getUser() || 'none'}
      title={`${itemConfig.title} (v.${itemConfig.version || 0})`}
    />
  );
  const onExit = () => {
    navigate(`/${parentResourceMenu}`);
  };

  switch (itemConfig.type) {
    case "MultiOptionsQuestionPanel": return (
    <MultiOptionsQuestionPanelV2
      routerProps={props.routerProps}
      key={`moqp-${itemConfig.level}`}
      header={header()}
      onExit={onExit}
      title={itemConfig.title}
      options={itemConfig.options}
      settings={itemConfig.settings}
      renderChallengeControl={(option, renderConfigs) => {
          switch (itemConfig.renderControl.type) {
              case "img" :
                  return (
                  <img
                      src={require(`./resources/${itemConfig.renderControl.path}/${itemConfig.renderControl.file ? itemConfig.renderControl.file : option[itemConfig.renderControl.optionIndex]}${itemConfig.renderControl.extension || ""}`)}
                      height={renderConfigs.height}
                      className="question-img"
                      alt="logo"
                  />)
              case "text":
                  return (<TextContent>
                    <h3>{option[itemConfig.renderControl.optionIndex]}</h3>
                  </TextContent>)
              case "toggleable":
                  if (renderConfigs.imageOnly)
                      return (<img
                          src={require(`./resources/${itemConfig.renderControl.path}/${option[itemConfig.renderControl.imgOptionIndex]}${itemConfig.renderControl.extension || ""}`)}
                          height={renderConfigs.height}
                          className="question-img"
                          alt="logo"
                      />)
                  else
                      return (<TextContent>
                        <h3>{option[itemConfig.renderControl.textOptionIndex]}</h3>
                      </TextContent>)
              case "mixed":
                  if (option[itemConfig.renderControl.functionalIndex] === "pic")
                    return (<img
                      src={require(`./resources/${itemConfig.renderControl.path}/${option[itemConfig.renderControl.optionIndex]}${itemConfig.renderControl.extension || ""}`)}
                      height={renderConfigs.height}
                      className="question-img"
                      alt="logo"
                    />)
                  else
                    return (<TextContent>
                      <h3>{option[itemConfig.renderControl.optionIndex]}</h3>
                    </TextContent>)
          }
      }}
      questionArray={itemConfig.questionsArray}
      getQuestion={(option) => itemConfig.questionOptionIndex ? option[itemConfig.questionOptionIndex] : itemConfig.questionTitle}
      newQuestionArray={itemConfig.placeHolder ? (option) => option[itemConfig.placeHolder.optionIndex] : null}
      getFunctionalQuestionPre={
          itemConfig.functionalQuestionPre ?
          (option, i) => itemConfig.functionalQuestionPre.fixedList ?
              itemConfig.functionalQuestionPre.fixedList[i] : option[itemConfig.functionalQuestionPre.optionIndex + i]
          :
          undefined
      }
      getFunctionalQuestionPost={
          itemConfig.functionalQuestionPost ?
          (option, i) => itemConfig.functionalQuestionPost.fixedList ?
              itemConfig.functionalQuestionPost.fixedList[i] : option[itemConfig.functionalQuestionPost.optionIndex + i]
          :
          undefined
      }
      getFunctionalImageQuestionPost={
          itemConfig.functionalImageQuestionPost ?
          (option, i, configs) => <img src={require(`./resources/${itemConfig.functionalImageQuestionPost.path}/${option[itemConfig.functionalImageQuestionPost.optionIndex + i]}${itemConfig.functionalImageQuestionPost.extension || ""}`)} height={configs.height} className="question-img" alt="logo"/>
          :
          undefined
      }
      getAnswer={(option, i) => option[itemConfig.answerIndexStart + i]}
      getHint={(option, i) => option[itemConfig.hintIndexStart + i]}
    />
    );
    break
    case "SingleQuestionMultiOptionsPanel": return (
      <SingleQuestionMultiOptionsPanelV2
        routerProps={props.routerProps}
        key={`sqmop-${itemConfig.level}`}
      header={header()}
      onExit={onExit}
        title={itemConfig.title}
        options={itemConfig.options}
        imgMd={(imgName, imgSrc, configs) => {
          if (imgSrc) return          { img: { src: imgSrc, height: configs.height, class: "question-img", alt: "logo" } };
          if (!itemConfig.img) return { img: { src: "#", height: configs.height, class: "question-img", alt: "logo" } };
          const path = itemConfig.img.path ? `${itemConfig.img.path}/` : "";
          const extension = itemConfig.img.extension ? itemConfig.img.extension : "";
          return                      { img: { path: `${path}${imgName}`, extension: extension, height: configs.height, class: "question-img", alt: "logo" } }
        }}
        imgPath={(imgName, imgSrc) => {
          if (imgSrc) return imgSrc;
          if (!itemConfig.img) return "#";
          const path = itemConfig.img.path ? `${itemConfig.img.path}/` : "";
          const extension = itemConfig.img.extension ? itemConfig.img.extension : "";
          return require(`./${path}${imgName}${extension}`)
        }}
        topicMdControl={gpTopicMdControlV2}
        renderSplitPanelItems={(v) => {
            //setView(itemConfig.title);
        }}
        settings={itemConfig.settings}
        getQuestion={(v) => `${itemConfig.questionTitle}`}
    />);
    break;
    case "MultiQuestionMultiOptionsPanel": return (
      <MultiQuestionMultiOptionsPanelV2
        routerProps={props.routerProps}
        key={`mqmop-${itemConfig.level}`}
        roundState={itemConfig.roundState}
        header={header()}
        onExit={onExit}
        title={itemConfig.title}
        options={itemConfig.options}
        imgMd={(imgName, imgSrc, configs) => {
          if (imgSrc) return          { img: { src: imgSrc, height: configs.height, class: "question-img", alt: "logo" } };
          if (!itemConfig.img) return { img: { src: "#", height: configs.height, class: "question-img", alt: "logo" } };
          const path = itemConfig.img.path ? `${itemConfig.img.path}/` : "";
          const extension = itemConfig.img.extension ? itemConfig.img.extension : "";
          return                      { img: { path: `${path}${imgName}`, extension: extension, height: configs.height, class: "question-img", alt: "logo" } }
        }}
        imgPath={(imgName, imgSrc) => {
          if (imgSrc) return imgSrc;
          if (!itemConfig.img) return "#";
          const path = itemConfig.img.path ? `${itemConfig.img.path}/` : "";
          const extension = itemConfig.img.extension ? itemConfig.img.extension : "";
          return require(`./${path}${imgName}${extension}`)
        }}
        topicMdControl={gpTopicMdControlV2}
        renderSplitPanelItems={(v) => {
            //setView(itemConfig.title);
        }}
        settings={itemConfig.settings}
        getQuestion={(v) => `${itemConfig.questionTitle}`}
        onlineAssignmentPublish={props.onlineAssignmentPublish}
        onlineAssignmentConfig={itemConfig}
    />);
    case "MultiTopicPanelContainer": return (
      <MultiTopicPanelContainerV2
        routerProps={props.routerProps}
        key={`mqmop-${itemConfig.level}`}
        roundState={itemConfig.roundState}
        header={header()}
        onExit={onExit}
        title={itemConfig.title}
        options={itemConfig.options}
        imgMd={(imgName, imgSrc, configs) => {
          if (imgSrc) return          { img: { src: imgSrc, height: configs.height, class: "question-img", alt: "logo" } };
          if (!itemConfig.img) return { img: { src: "#", height: configs.height, class: "question-img", alt: "logo" } };
          const path = itemConfig.img.path ? `${itemConfig.img.path}/` : "";
          const extension = itemConfig.img.extension ? itemConfig.img.extension : "";
          return                      { img: { path: `${path}${imgName}`, extension: extension, height: configs.height, class: "question-img", alt: "logo" } }
        }}
        imgPath={(imgName, imgSrc) => {
          if (imgSrc) return imgSrc;
          if (!itemConfig.img) return "#";
          const path = itemConfig.img.path ? `${itemConfig.img.path}/` : "";
          const extension = itemConfig.img.extension ? itemConfig.img.extension : "";
          return require(`./${path}${imgName}${extension}`)
        }}
        topicMdControl={gpTopicMdControlV2}
        renderSplitPanelItems={(v) => {
            //setView(itemConfig.title);
        }}
        settings={itemConfig.settings}
        getQuestion={(v) => `${itemConfig.questionTitle}`}
        onlineAssignmentPublish={props.onlineAssignmentPublish}
        onlineAssignmentConfig={itemConfig}
    />);
    break;
    case "Puzzle": return (
      <PuzzleV2
        routerProps={props.routerProps}
        key={`puzzle-${itemConfig.level}`}
        roundState={itemConfig.roundState}
        header={header()}
        onExit={onExit}
        title={itemConfig.title}
        options={itemConfig.options}
        settings={itemConfig.settings}
        onlineAssignmentPublish={props.onlineAssignmentPublish}
        onlineAssignmentConfig={itemConfig}
    />);
    break;
  }
}

async function handleLessonConfigDownload(key, handleError, handleSuccess, handleCompletion) {
  const callback = (response) => {
    if (!response.err) {
      // if no error response read the payload
      const newLessonConfig = JSON.parse(response.data.Body.toString('utf-8'));
      handleSuccess(newLessonConfig);
    } else {
      // disguises other 4xx/5xx errors => report error responses better
      handleError("404 Not Found");
    }
    handleCompletion();
  }
  const keySplit = key.split("/");
  const author = keySplit[0];
  const publishId = keySplit[1];
  const searchKey = `${author}/${publishId}`
  new Promise(resolve => setTimeout(() => {
      downLoadConfigOn(searchKey, callback);
      resolve();
    }, 500));
}

/*
  Default item config indexer for expandable menu
*/
function onlineIndexer(itemConfig) {
  let editorResource = "";
  switch (itemConfig.type) {
    case "MultiTopicPanelContainer":
      editorResource = "editor";
      break;
    case "Puzzle":
      editorResource = "crosswordeditor";
      break;
    case "MultiQuestionMultiOptionsPanel":
    default:
      editorResource = "lessoneditor";
      break;
  };

  return {
    title: itemConfig.title,
    playModeNavigation: `onlineassignments/${itemConfig.author}?id=${itemConfig.resource}`,
    editModeNavigation: `${editorResource}/${itemConfig.author}?id=${itemConfig.resource}`,
    author: itemConfig.author,
    authorizedUsers: itemConfig.authorizedUsers,
    lastUpdated: itemConfig.lastUpdated,
    version: itemConfig.version,
    dueOn: itemConfig.dueOn,
  }
}

function handleUserConfigsList(searchKey, setErrorMessage, setConfigIndexList, setIsLoading) {
  let tmpErrors = undefined
  let tmpConfigs = [];

  // object list call back
  const callback = (response) => {
    if (!response.err) {
      // short list assignment objects
      const objectKeyList = response.data.Contents.map(summary => summary.Key.replace(".json", ""));
      setConfigIndexList(objectKeyList);

    } else {
      // display list objects errors -> then clock out spinner only cause its a terminal state
      setErrorMessage(response.err);
      setTimeout(() => setIsLoading(false), 500);
    }
  }

  // list and download
  new Promise(resolve => setTimeout(() => {
      listConfigs(searchKey, callback);
      resolve();
    }, 500));
}

function handleAuthorConfigsList(setErrorMessage, setConfigLessonList, setIsLoading) {
  const queryKeys = [
    "type", "version", "resource", "language", "program", "title", "level", "author", "authorizedUsers", "dueOn", "lastUpdated"
  ];

  let tmpErrors = undefined
  let tmpConfigs = [];

  // object list call back
  const callback = (response) => {
    if (!response.err) {
      // short list assignment objects
      const objectKeyList = response.data.Contents.map(summary => summary.Key.replace(".json", ""));
      const totalItems = objectKeyList.length
      if (totalItems == 0) {
        setConfigLessonList([]);
        setTimeout(() => setIsLoading(false), 500);
        return;
      }
      // iterates through each key, downloads and extracts relevant attributes only
      objectKeyList.forEach(async(objectKey, k) => {
        await handleLessonConfigDownload(
            objectKey,
            (errMsg) => {
              tmpErrors = tmpErrors ? (tmpErrors + "," + errMsg) : errMsg;
              // collects per object key errors on error and updates state realtime
              setErrorMessage(tmpErrors);
            },
            (data) => {
              let filteredData = {}
              Object.keys(data)
                .filter(key => queryKeys.includes(key))
                .forEach(key => { filteredData[key] = data[key]; });
              tmpConfigs.push(filteredData);
              // collects per object key configs on success and updates state realtime
              setConfigLessonList(tmpConfigs);
            },
            () => {
              console.log(`done with ${objectKey}`);
              // displays only when completion for all keys is met
              if ((k + 1) == totalItems) setTimeout(() => setIsLoading(false), 500);
            }
          );
      });

    } else {
      // display list objects errors -> then clock out spinner only cause its a terminal state
      setErrorMessage(response.err);
      setTimeout(() => setIsLoading(false), 500);
    }
  }

  // list and download
  new Promise(resolve => setTimeout(() => {
      listConfigs(getUser(), callback);
      resolve();
    }, 500));
}

function OnlineLanguageMenu(props) {

  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(undefined);
  const [configLessonList, setConfigLessonList] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    props.routerProps.resetSplitPanel();

    // in order to force sign in after user log in is expired
    if (!getAuthCookie()) {
      props.routerProps.setAuthUser(undefined); navigate(`/`); setIsLoading(false);
    } else {
      // set empty state
      setErrorMessage(undefined);
      setConfigLessonList(undefined);

      handleAuthorConfigsList(setErrorMessage, setConfigLessonList, setIsLoading);
    }

  }, []);

  return (
    <div>
      {(isLoading) && <Spinner size="large"/>}
      {(!isLoading && errorMessage) &&
      <ContentLayout>
        <DismissibleError error={errorMessage} setError={setErrorMessage} header="Assignments List"/>
      </ContentLayout>}
      {(!isLoading && !errorMessage && configLessonList && configLessonList.length > 0) &&
        <LanguageMenu routerProps={props.routerProps} author={getUser()} configs={configLessonList} indexer={onlineIndexer}/>
      }
      {(!isLoading && !errorMessage && configLessonList && configLessonList.length == 0) &&
      <DismissibleInfo
        header={"Assignments List"}
        info={
        <TextContent>There are no assignments for <b><em style={{color:'orange'}}>{getUser()}</em></b> created yet. Head over <Link href="/lessoneditor">Lesson editor</Link> or <Link href="/crosswordeditor">Crossword editor</Link> to publish the first!</TextContent>
        }
        setInfo={(x) => { }}
      />}
    </div>
  );

}

/*
  Online lessons student assignment version, downloads the configuration first and then loads the question player
*/
function OnlineLanguageLesson(props) {
  const navigate = useNavigate();
  const { author } = useParams();
  const [searchParams] = useSearchParams();
  const publishId = searchParams.get('id');
  const userId = searchParams.get('userId');

  const [isLoading, setIsLoading] = useState(true);
  const [lessonConfig, setLessonConfig] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const [userAuth, setUserAuth] = useState(undefined);
  const [visitor, setVisitor] = useState(undefined);
  const [token, setToken] = useState(undefined);
  const [loggingIn, setLoggingIn] = useState(false);
  const [loggingInCredentials, setLoggingInCredentials] = useState(false);
  const [showVisitorAuthModal, setShowVisitorAuthModal] = useState(false);
  const [tmpLessonConfig, setTmpLessonConfig]= useState(undefined);
  const [onlineAssignmentPublish, setOnlineAssignmentPublish] = useState(undefined);
  const [userConfigIndexList, setUserConfigIndexList] = useState(undefined);
  const [visitorGranted, setVisitorGranted] = useState(false);

  function validInputString(input) {
    return input && input.length > 0;
  }

  function validInputList(input) {
    return input && input.length > 0;
  }

  function invalidInputList(input) {
    return !input || input.length == 0;
  }

  function closeModalAndClockOutLoading(errorMessage) {
    setShowVisitorAuthModal(false);
    if (errorMessage) setErrorMessage(errorMessage);
    setVisitor(undefined);
    setToken(undefined);
    setLoggingIn(false);
    setIsLoading(false); // clock out spinner, its terminal state
  }

  const callbackUserAuth = (response) => {
    // if no error response read the payload
    if (!response.err) {
      const newLessonConfig = JSON.parse(response.data.Body.toString('utf-8'));
      if (newLessonConfig) {
        // make sure the user auth matches the author
          // case: user authorized, show assignment -> then clock out spinner
        const tmpUserAuth = getUser();
        if (tmpUserAuth === newLessonConfig.author) setLessonConfig(newLessonConfig);
          // case: logged user logged is not authorized, only same author can authorize ext access -> then clock out spinner
        else setErrorMessage("403 Not Authorized");

        setIsLoading(false);
        return;
      }

      // display server error if no valid object contents
      setErrorMessage("500 Server error");
      setIsLoading(false);
      return;
    }

    // display shown errors while attempting object download
    setErrorMessage(response.err);
    setIsLoading(false);
  }

  const callbackVisitorAuth = (response) => {
    // if no error response read the payload
    if (!response.err) {
      const newLessonConfig = JSON.parse(response.data.Body.toString('utf-8'));
      if (newLessonConfig) {
        // make sure the user auth matches the author
          // case: user authorized, show assignment -> then clock out spinner
        if (newLessonConfig.authorizedUsers.some(user => user === visitor) && newLessonConfig.token === token) {
            setOnlineAssignmentPublish({
              newResource: `${publishId}/${visitor}/${(new Date()).getTime()}`,
              author: newLessonConfig.author,
            });
            setLessonConfig(newLessonConfig);
        }
          // case: logged user logged is not authorized, only same author can authorize ext access -> then clock out spinner
        else setErrorMessage("403 Not Authorized");

        closeModalAndClockOutLoading(undefined);
        return;
      }

      // display server error if no valid object contents
      closeModalAndClockOutLoading("500 Server error");
      return;
    }

    // display shown errors while attempting object download
    closeModalAndClockOutLoading(response.err);
    setErrorMessage(response.err);
  }

  const handleListUserAuthAndUserId = (indexList) => {
    if (validInputList(indexList)) {
      const callbackRetrieveResults = (response) => {
        if (!response.err) {
        // if no error response read the payload and if its valid set it up for display and clock out or else server error
          const tmpNewLessonConfig = JSON.parse(response.data.Body.toString('utf-8'));
          if (tmpNewLessonConfig) setLessonConfig(tmpNewLessonConfig);
          else setErrorMessage("500 Server error");
          setIsLoading(false);
          return;
        }
        // display shown errors while attempting object download
        setErrorMessage(response.err);
        setIsLoading(false);
        return;
      }
      // download first found object config inside the index list
      new Promise(resolve => setTimeout(() => {
          downLoadConfigOn(indexList[0], callbackRetrieveResults);
          resolve();
        }, 500));
      return;
    }

    // after list configs due to authorized author and assignment for userId NOT found -> then Error out and clock out
    if (invalidInputList(indexList)) {
      setErrorMessage(`404 Not Found`);
      setIsLoading(false);
      return;
    }
  }

  const handleListUserAuthWithVisitor = (indexList) => {
    // after list configs of assignment for visitor NOT found -> then plain take the assignment it
    // after list configs of assignment for visitor found -> then plain replay the results of the assignment
    const tmpConfigKey = invalidInputList(indexList) ? `${author}/${publishId}` : indexList[0];

    new Promise(resolve => setTimeout(() => {
        downLoadConfigOn(tmpConfigKey, callbackVisitorAuth);
        resolve();
      }, 500));
    return;
  }

  useEffect(() => {
    setIsLoading(true);
    props.routerProps.resetSplitPanel();

    // if valid publishId then start challenging user access permissions
    if (publishId) {

    // does not enforce log in, but check whether a logged in user or not and then
      // check whether author matches user or whether visitor credentials matches tokens accordingly

      const tmpUserAuth = getUser();
      setUserAuth(tmpUserAuth);

      if (validInputString(tmpUserAuth) && tmpUserAuth === author) {
        // if user id is provided means retrieve its config results
        if (validInputString(userId)) {
          handleUserConfigsList(`${author}/${publishId}/${userId}`, setErrorMessage, handleListUserAuthAndUserId, setIsLoading);
          return;
        }

        // else plain download and the assignment since user param is provided
        new Promise(resolve => setTimeout(() => {
            const searchKey = `${author}/${publishId}`
            downLoadConfigOn(searchKey, callbackUserAuth);
            resolve();
          }, 500));
        return;
      }

      // after here it is required first to get temp visitor credentials before anything
      if (!validInputString(userId)) {
        setIsLoading(false);
        setShowVisitorAuthModal(true);
        return;
      }

      setErrorMessage("403 Not Authorized");
      setTimeout(() => setIsLoading(false), 500);
      return;
    }

    // incorrect path, or missing publish id => 404 Not Found -> then clock out from spinner as its terminal state
    setErrorMessage("404 Not Found");
    setTimeout(() => setIsLoading(false), 500);

  }, [author]);

  return (
    <div>
    {(isLoading) && <Spinner size="large"/>}
    {(!isLoading) && <ContentLayout>
    <SpaceBetween>
      {!(errorMessage) &&
      <Modal
        visible={showVisitorAuthModal}
        onDismiss={() => {
          closeModalAndClockOutLoading("403 Not Authorized");
        }}
        closeAriaLabel="Close"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="primary"
                loading={loggingIn}
                disabled={!validInputString(visitor) || !validInputString(token)}
                onClick={() => {
                  setIsLoading(true);
                  setLoggingIn(true);
                  // first check whether it already exists
                  handleUserConfigsList(
                    `${author}/${publishId}/${visitor}`, setErrorMessage, handleListUserAuthWithVisitor, setIsLoading);

                }}
              >Authorize
              </Button>
              <Button variant="normal"
                onClick={() => {
                  closeModalAndClockOutLoading("403 Not Authorized");
              }}
              >Cancel
              </Button>
            </SpaceBetween>
          </Box>
        }
      header={
          <SpaceBetween size="l" direction="horizontal">
            Visitor Authorization
          </SpaceBetween>}
      >
        <SpaceBetween direction="vertical" size="s">
          <FormField label="Visitor Login">
          <Input
              value={visitor}
              disabled={loggingIn}
              onChange={(event) => {
                setVisitor(event.detail.value);
              }}
          />
          </FormField>
          <FormField label="Visitor Token">
          <Input
              value={token}
              disabled={loggingIn}
              type={"password"}
              onChange={(event) => {
                setToken(event.detail.value);
              }}
          />
          </FormField>
        </SpaceBetween>
      </Modal>}
      {(errorMessage) &&
      <DismissibleError error={errorMessage} setError={setErrorMessage}  header="Assignment View"/>}
      {(!errorMessage && lessonConfig) &&
      <LanguageLesson
        routerProps={props.routerProps}
        lesson={lessonConfig.resource}
        configs={[lessonConfig]}
        parentResourceMenu={"onlineassignments"}
        onlineAssignmentPublish={onlineAssignmentPublish}
      />}
      </SpaceBetween>
    </ContentLayout>}
    </div>
  );
}

/*
  Online lessons author assignment version, downloads the configuration first and then loads the editor
*/
function OnlineLanguageLessonEditor(props) {
  const navigate = useNavigate();
  const { author } = useParams();
  const [searchParams] = useSearchParams();
  const publishId = searchParams.get('id')

  const [isLoading, setIsLoading] = useState(true);
  const [lessonConfig, setLessonConfig] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const [tmpLessonConfig, setTmpLessonConfig]= useState(undefined);

  useEffect(() => {
    setIsLoading(true);
    props.routerProps.resetSplitPanel();

    // in order to force sign in after user log in is expired
    if (!getAuthCookie()) {
      props.routerProps.setAuthUser(undefined); navigate(`/`); setIsLoading(false);
    } else {
      const userAuth = getUser();
      // if there is a suspected publishId then attempt config download
      if (publishId) {
        const callback = (response) => {
          // if error response
          if (response.err) {
            setErrorMessage(response.err); return;
          }
          // if no error response read the payload
          const newLessonConfig = JSON.parse(response.data.Body.toString('utf-8'));
          if (!newLessonConfig) {
            // edge case but it could actually be disguising 500 errors => handle better error responses here
            setErrorMessage("404 Not Found"); return;
          }
          // make sure the user auth matches the author
          if (userAuth && userAuth === newLessonConfig.author) {
            // all good, show assignment
            setLessonConfig(newLessonConfig); return;
          }
          // logged user logged is not authorized, needs author to authorize access
          setErrorMessage("403 Not Authorized");
        }

        new Promise(resolve => setTimeout(() => {
            const searchKey = `${author}/${publishId}`
            downLoadConfigOn(searchKey, callback);
            resolve();
          }, 500))
          .then(() => setIsLoading(false));
        return;
      }
    }

    // incorrect path, or missing publish id => 404 Not Found
    setErrorMessage("404 Not Found");
    setTimeout(() => setIsLoading(false), 500);

  }, [author]);

  // CommonEditor already brings ContentLayout thus segregating clearly across mutually exclusive views
  return (
    <div>
      {isLoading && <Spinner size="large"/>}
      {(!isLoading && errorMessage) &&
      <ContentLayout>
        <DismissibleError error={errorMessage} setError={setErrorMessage}/>
      </ContentLayout>}
      {(!isLoading && !errorMessage && lessonConfig) && lessonConfig.type !== "MultiTopicPanelContainer" &&
        <CommonEditorV2 routerProps={props.routerProps} type={lessonConfig.type} configs={lessonConfig}/>}
      {(!isLoading && !errorMessage && lessonConfig) && lessonConfig.type === "MultiTopicPanelContainer" &&
        <MultiTopicCommonEditorV2 routerProps={props.routerProps} type={lessonConfig.type} configs={lessonConfig}/>}
    </div>
  );
}

function LanguageEditorV2(props) {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    props.routerProps.resetSplitPanel();

    // in order to force sign in after user log in is expired
    if (!getAuthCookie()) { props.routerProps.setAuthUser(undefined); navigate(`/`); }
    else setTimeout(() => setIsLoading(false), 500);

  }, [props.type]);

  // CommonEditor already brings ContentLayout thus segregating clearly across mutually exclusive views
  return (
    <div>
    {isLoading && <Spinner size="large"/>}
    {!isLoading && props.type === "MultiQuestionMultiOptionsPanel" &&
      <CommonEditorV2
        routerProps={props.routerProps}
        type={props.type}
      />
    }
    {!isLoading && props.type === "Puzzle" &&
      <CommonEditorV2
        routerProps={props.routerProps}
        type={props.type}
      />
    }
    {!isLoading && props.type === "MultiTopicPanelContainer" &&
      <MultiTopicCommonEditorV2
        routerProps={props.routerProps}
        type={props.type}
      />
    }
    </div>);
}

/*
  Default item config indexer for expandable menu
*/
function localIndexer(itemConfig) {
  return {
    title: itemConfig.title,
    playModeNavigation: `${itemConfig.language}/${itemConfig.resource}`
  }
}

/*
  Each Menu language comes with different level categories and each level contains a list of lessons to display
  Reads all existing configurations and populates the menu and its different routing resources
*/
function LanguageMenu(props) {
  const navigate = useNavigate();
  const { lesson } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    props.routerProps.resetSplitPanel();
    // in order to force sign in after user log in is expired
    if (!getAuthCookie()) { props.routerProps.setAuthUser(undefined); navigate(`/`); }
    else setTimeout(() => setIsLoading(false), 500);
  }, [lesson, props.configs]);

  const itemsMenu = {}
  const itemsRender = {}
  Object.keys(props.configs)
      .forEach((key, i) => {
          const itemConfig = props.configs[key];
          itemsMenu[itemConfig.level] = itemsMenu[itemConfig.level] ?
              [ ...itemsMenu[itemConfig.level], props.indexer(itemConfig) ]
              :
              [ props.indexer(itemConfig) ]
      });

  return (
    <div>
      {(isLoading) && <Spinner size="large"/>}
      {(!isLoading) &&
      <ContentLayout>
        <SpaceBetween>
          {(!lesson) &&
            <Container>
            <SpaceBetween>
              {Object.keys(itemsMenu)
                .sort()
                .map(level => <ExpandableMenu
                  key={`exp-menu-${level}`}
                  routerProps={props.routerProps}
                  totalItems={itemsMenu[level].reduce((a, b) => a + 1, 0)}
                  level={level}
                  lesson={itemsMenu[level]}
                  author={props.author}
              />)}
            </SpaceBetween>
            </Container>
          }
          {(lesson) && <LanguageLesson routerProps={props.routerProps} lesson={lesson} configs={props.configs}/>}
        </SpaceBetween>
      </ContentLayout>}
    </div>
  );
}

async function getBlob() {
  const blob = new Blob(["Hello World"], { type: "text/plain" }); // I'm creating a blob inline just for the sake of demonstration
  return await blob.arrayBuffer();
}

function App(props) {
  const splitPageWidth = window.innerWidth / 4;
  const splitPageHeight = window.innerHeight / 2;
  const expectedUList = [
    { name: "Alejandro P.", user:"paraleja", email: "paraleja@outlook.com", expands:[true, true, true], pwd: "73c4cb5" },
    { name: "Diana R.", user:"rosatodiana", email: "rosatodiana@gmail.com", expands:[false, true, false], pwd: "73c4cb5" },
    { name: "Irina S.", user:"irina.spinosa", email: "irina.spinosa@city.edu", expands:[false, false, true], pwd: "73c4cb5" },
    { name: "Alexander B.", user:"boykooleksandr", email: "boykooleksandr@city.edu", expands:[true, false, false], pwd: "73c4cb5" },
  ]
  const unsetMenuExpanded = () => setMenuExpanded([false, false, false, false]);

  const appLayoutRef = useRef();

  const [navigationOpen, setNavigationOpen] = useState(true);
  const [notifications, setNotifications] = useState();
  const [splitPanelItems, setSplitPanelItems] = useState(<Container/>);
  const [splitPanelToggle, setSplitPanelToggle] = useState(false);
  const [splitPanelHeader, setSplitPanelHeader] = useState("Miscellaneous");
  const [breadcrumbs, setBreadcrumbs] = useState(() => (<div/>));
  const [toolsOpen, setToolsOpen] = useState(false);
  const [splitPanelPreferences, setSplitPanelPreferences] = useState({position: "side"});
  const [splitPanelSize, setSplitPanelSize] = useState(splitPageWidth);
  const [navigationItems, setNavigationItems] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuExpanded, setMenuExpanded] = useState([false, false, false, false]);
  const resetSplitPanel = () => {
    setSplitPanelToggle(false);
    setSplitPanelHeader("Miscellaneous");
    setSplitPanelItems(() => <div/>);
    setSplitPanelPreferences({position: "side"});
  }
  const routerProps = {
    navigationOpen: navigationOpen,
    setNavigationOpen: setNavigationOpen,
    notifications: notifications,
    setNotifications: setNotifications,
    splitPanelItems: splitPanelItems,
    setSplitPanelItems: setSplitPanelItems,
    splitPanelHeader: splitPanelHeader,
    setSplitPanelHeader: setSplitPanelHeader,
    splitPanelToggle: splitPanelToggle,
    setSplitPanelToggle: setSplitPanelToggle,
    resetSplitPanel: resetSplitPanel,
    breadcrumbs: breadcrumbs,
    setBreadcrumbs: setBreadcrumbs,
    toolsOpen: toolsOpen,
    setToolsOpen: setToolsOpen,
    navigationItems: navigationItems,
    setNavigationItems: setNavigationItems,
    menuOpen: menuOpen,
    setMenuOpen: setMenuOpen,
    authUser: props.authUser,
    setAuthUser: props.setAuthUser,
    darkMode: props.darkMode,
    setDarkMode: props.setDarkMode,
    location: {
      "pathname": ""
    }
  }

  useEffect(() => {
    const grantedUserSession = getAuthCookie();
    if (grantedUserSession) {
        const cData = getAuthData();
        props.setAuthUser(cData);
        setNavigationItems(
          navItemsV2(expectedUList.find(eu => eu.user === cData.user).expands));
    }
  }, []);

  return (
    <BrowserRouter>
      <AppLayout
          ref={appLayoutRef}
          navigation={<Navigation
              navItems={navigationItems}
          />}
          navigationOpen={menuOpen}
          onNavigationChange={(event) => {
            setMenuOpen(event.detail.open);
          }}
          notifications={<div/>}
          splitPanelPreferences={splitPanelPreferences}
          splitPanelSize={splitPanelSize}
          onSplitPanelPreferencesChange={(preference) => setSplitPanelPreferences(preference.detail)}
          onSplitPanelResize={(event) => setSplitPanelSize(event.detail.size)}
          splitPanelOpen={splitPanelToggle}
          onSplitPanelToggle={({detail}) => setSplitPanelToggle(detail.open) }
          splitPanel={
            <SplitPanel
                i18nStrings={{
                  preferencesTitle: "Preferences",
                  preferencesPositionLabel: "Split panel position",
                  preferencesPositionDescription:
                      "Choose the default split panel position for the service.",
                  preferencesPositionSide: "Side",
                  preferencesPositionBottom: "Bottom",
                  preferencesConfirm: "Confirm",
                  preferencesCancel: "Cancel",
                  closeButtonLabel: "Close",
                  openButtonLabel: "Open",
                  sliderLabel: "Slider"
                }}
                hidePreferencesButton={false}
                header={splitPanelHeader}
            >
              <div>
                {splitPanelItems}
              </div>
            </SplitPanel>
          }
          content={
            <Routes>
              <Route path="/"                         element={<HomeV2                     routerProps={routerProps} expectedUList={expectedUList}/>}/>
              <Route path="deutsch"                   element={<LanguageMenu               routerProps={routerProps} title={'Deutsch'} configs={configs.deutsch} indexer={localIndexer}/>}/>
              <Route path="deutsch/:lesson"           element={<LanguageMenu               routerProps={routerProps} configs={configs.deutsch} indexer={localIndexer}/>}/>
              <Route path="italiano"                  element={<LanguageMenu               routerProps={routerProps} title={'Italiano'} configs={configs.italiano} indexer={localIndexer}/>}/>
              <Route path="italiano/:lesson"          element={<LanguageMenu               routerProps={routerProps} configs={configs.italiano} indexer={localIndexer}/>}/>
              <Route path="russian"                   element={<LanguageMenu               routerProps={routerProps} title={'Russian'} configs={configs.russian} indexer={localIndexer}/>}/>
              <Route path="russian/:lesson"           element={<LanguageMenu               routerProps={routerProps} configs={configs.russian} indexer={localIndexer}/>}/>
              <Route path="lessoneditor"              element={<LanguageEditorV2           routerProps={routerProps} type="MultiQuestionMultiOptionsPanel"/>}/>
              <Route path="crosswordeditor"           element={<LanguageEditorV2           routerProps={routerProps} type="Puzzle"/>}/>
              <Route path="lessoneditor/:author"      element={<OnlineLanguageLessonEditor routerProps={routerProps} type="MultiQuestionMultiOptionsPanel"/>}/>
              <Route path="crosswordeditor/:author"   element={<OnlineLanguageLessonEditor routerProps={routerProps} type="Puzzle"/>}/>
              <Route path="editor"                    element={<LanguageEditorV2           routerProps={routerProps} type="MultiTopicPanelContainer"/>}/>
              <Route path="editor/:author"            element={<OnlineLanguageLessonEditor routerProps={routerProps} type="MultiTopicPanelContainer"/>}/>
              <Route path="onlineassignments"         element={<OnlineLanguageMenu         routerProps={routerProps}/>}/>
              <Route path="onlineassignments/:author" element={<OnlineLanguageLesson       routerProps={routerProps}/>}/>
              <Route path="test0"                     element={<DragAndDropOptionsPanel    routerProps={routerProps}/>}/>
              <Route path='*'                         element={<DismissibleError error={"404 Not Found"} setError={(x) => { }}/>}/>
              {/*</Route>*/}
            </Routes>
          }
          contentType="default"
          tools={<div/>}
          toolsOpen={toolsOpen}
          onToolsChange={({ detail }) => setToolsOpen(detail.open)}
          stickyNotifications={true}
      />
    </BrowserRouter>);
}

function Navigation(props) {
  const navigate = useNavigate();

  return (
      <div>
        <SideNavigation
            header={navHeaderV2}
            items={props.navItems}
            activeHref={`#`}
            onFollow={(ev) => {
              ev.preventDefault();
              if (ev.detail.href) {
                navigate(ev.detail.href.substring(1));
              }
            }}
        />
        <Outlet />
      </div>
  );
}

export default App;
