import {useState} from 'react';
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import { StaticTableV2 } from '../common/common';
import Alert from "@cloudscape-design/components/alert";
import { TextContent } from "@cloudscape-design/components";
import { uploadConfig } from './question-common';

export function DisplaySummaryV2({
    summaryTestRound,
    selectedQuestionOptions,
    getTotalQuestions,
    testRound,
    setTestRound,
    setDisplaySummary,
    inputReference,
    setTestConfigModalVisible,
    doNext,
    isSingle,
    onExit,
    onReview,
    isReplay,
    setIsReplay,
    onlineAssignmentPublish,
    onlineAssignmentConfig
  }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submitMessage, setSubmitMessage] =  useState(undefined);

    let sumTotalAssertions = 0, sumSuccessQuestions = 0, sumFailQuestions = 0, sumEmptyQuestions = 0;
    if (!selectedQuestionOptions) {
        if (isSingle) {
            sumTotalAssertions = summaryTestRound
                              .map(test => test.answersIsMatch
                                  .map(ans => 1)
                                  .reduce((acc, val) => acc + val, 0))
                              .reduce((acc, val) => acc + val, 0);
            sumSuccessQuestions = summaryTestRound
                              .map(test => test.answersIsMatch
                                  .map(ans => ans === "success" ? 1 : 0)
                                  .reduce((acc, val) => acc + val, 0))
                              .reduce((acc, val) => acc + val, 0);
            sumFailQuestions = summaryTestRound
                              .map(test => test.answersIsMatch
                                  .map(ans => ans === "error" ? 1 : 0)
                                  .reduce((acc, val) => acc + val, 0))
                              .reduce((acc, val) => acc + val, 0);
            sumEmptyQuestions = summaryTestRound
                              .map(test => test.answersIsMatch
                                  .map(ans => ans === undefined || ans === null ? 1 : 0)
                                  .reduce((acc, val) => acc + val, 0))
                              .reduce((acc, val) => acc + val, 0);
        } else {
            sumTotalAssertions = summaryTestRound
                                    .map(test => test.answersIsMatch.map(answersIsMatch =>
                                      answersIsMatch.map(ans => 1).reduce((acc, val) => acc + val, 0))
                                        .reduce((acc, val) => acc + val, 0))
                                    .reduce((acc, val) => acc + val, 0);
            sumSuccessQuestions = summaryTestRound
                                    .map(test => test.answersIsMatch.map(answersIsMatch =>
                                      answersIsMatch.map(ans => ans === "success" ? 1 : 0).reduce((acc, val) => acc + val, 0))
                                        .reduce((acc, val) => acc + val, 0))
                                    .reduce((acc, val) => acc + val, 0);
            sumFailQuestions = summaryTestRound
                                    .map(test => test.answersIsMatch.map(answersIsMatch =>
                                      answersIsMatch.map(ans => ans === "error" ? 1 : 0).reduce((acc, val) => acc + val, 0))
                                        .reduce((acc, val) => acc + val, 0))
                                    .reduce((acc, val) => acc + val, 0);
            sumEmptyQuestions = summaryTestRound
                                    .map(test => test.answersIsMatch.map(answersIsMatch =>
                                      answersIsMatch.map(ans => ans === undefined || ans === null ? 1 : 0).reduce((acc, val) => acc + val, 0))
                                        .reduce((acc, val) => acc + val, 0))
                                    .reduce((acc, val) => acc + val, 0);
        }
    } else {
        sumTotalAssertions = summaryTestRound
                               .map(test => test.answersIsMatchArray
                                   .filter((value, i) => (selectedQuestionOptions.some(opt => opt.index === i)))
                                   .map(ans => 1)
                                   .reduce((acc, val) => acc + val, 0))
                               .reduce((acc, val) => acc + val, 0);
        sumSuccessQuestions = summaryTestRound
                                .map(test => test.answersIsMatchArray
                                    .filter((value, i) => (selectedQuestionOptions.some(opt => opt.index === i)))
                                    .map(ans => ans === "success" ? 1 : 0)
                                    .reduce((acc, val) => acc + val, 0))
                                .reduce((acc, val) => acc + val, 0);
        sumFailQuestions = summaryTestRound
                             .map(test => test.answersIsMatchArray
                                 .filter((value, i) => (selectedQuestionOptions.some(opt => opt.index === i)))
                                 .map(ans => ans === "error" ? 1 : 0)
                                 .reduce((acc, val) => acc + val, 0))
                             .reduce((acc, val) => acc + val, 0);
        sumEmptyQuestions = summaryTestRound
                              .map(test => test.answersIsMatchArray
                                  .filter((value, i) => (selectedQuestionOptions.some(opt => opt.index === i)))
                                  .map(ans => ans === undefined || ans === null ? 1 : 0)
                                  .reduce((acc, val) => acc + val, 0))
                              .reduce((acc, val) => acc + val, 0);
    }

    function publishCallback(response) {
      setTimeout(() => {
        setIsSubmitting(false);
      }, 500);
      if (response.err) { setSubmitMessage(response.err); }
      else { setSubmitMessage("You are good to go!"); setSubmitted(true); setIsReplay(true); }
    }

    return (
      <SpaceBetween direction={"vertical"} size={"l"}>
          {submitMessage && <Alert
            onDismiss={() => onExit()}
            dismissible
            type={"info"}
            statusIconAriaLabel={"Info"}
            header="Submit result"
          ><TextContent>{submitMessage}</TextContent>
          </Alert>}
          <SpaceBetween direction={"horizontal"} size={"s"}>
            <h2>{`Summary`}</h2>
            <h4>{`[Round: ${testRound}]`}</h4>
            <h4>{`[Question(s): ${getTotalQuestions()}]`}</h4>
          </SpaceBetween>
          <StaticTableV2
              headerVariant={"h4"}
              columnDefinitions={['assertions', 'success', 'fail', 'empty']
                  .map(item => ({
                        id: item,
                        header: item,
                        sortingField: item,
                        cell: e => (!onlineAssignmentPublish || isReplay || (onlineAssignmentPublish && submitted)) ?
                          e[item]
                          :
                          (onlineAssignmentPublish && !submitted && ['success', 'fail'].includes(item)) ? "*" : e[item]
                      })
                  )}
              loading={false}
              items={[
                { assertions: sumTotalAssertions,
                  success: sumSuccessQuestions,
                  fail: sumFailQuestions,
                  empty: sumEmptyQuestions
                }
              ]}
          />
          <SpaceBetween direction={"horizontal"} size={"s"}>
              <Button
                  variant="primary"
                  disabled={!onReview || !isReplay}
                  onClick={() => {
                    if (onReview) onReview(summaryTestRound);
                    setDisplaySummary(false);
                  }}
              >
                Review
              </Button>
              <Button
                  variant="primary"
                  ref={inputReference}
                  loading={isSubmitting}
                  disabled={isReplay}
                  onClick={() => {
                    if (!onlineAssignmentPublish) {
                      setTestRound(testRound + 1);
                      setDisplaySummary(false);
                      setTimeout(() => { if (inputReference && inputReference.current) inputReference.current.focus(); }, 75);
                      setTestConfigModalVisible(true);
                      return;
                    }
                    setIsSubmitting(true);
                    setTimeout(() => {
                      let submitData = {}
                      Object.keys(onlineAssignmentConfig)
                        .forEach(key => { submitData[key] = onlineAssignmentConfig[key]; });
                      submitData.roundState = [...summaryTestRound];
                      uploadConfig(
                        onlineAssignmentPublish.author, onlineAssignmentPublish.newResource, submitData, publishCallback);
                    }, 500)
                  }}
              >
                Submit
              </Button>
          </SpaceBetween>
      </SpaceBetween>
    );
}