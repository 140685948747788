const userAuthKey="Strana";
const preferencesKey="StranaPrefs"

export function getAuthCookie() {
  return getCookie(userAuthKey);
}

function getSafeValue(unSafeValue, index) {
  return (unSafeValue && unSafeValue.length > index) ? unSafeValue[index] : undefined;
}

function getAuthValue(index) {
  const unSafeValue = getAuthCookie().split(";")[0].split(",");
  return getSafeValue(unSafeValue, index);
}

export function getAuthData() {
  const unSafeValue = getAuthCookie().split(";")[0].split(",");
  return ({ name: getSafeValue(unSafeValue, 0), user: getSafeValue(unSafeValue, 1), email: getSafeValue(unSafeValue, 2) });
}

export function getName() {
  return getAuthValue(0);
}

export function getUser() {
  return getAuthValue(1);
}

export function getEmail() {
  return getAuthValue(2);
}

export function getPrefCookie() {
  return getCookie(preferencesKey);
}

function getPrefValue(index) {
  const unSafeValue = getPrefCookie().split(";")[0].split(",");
  return getSafeValue(unSafeValue, index);
}

export function getMode() {
  return getPrefValue(0);
}

export function setAuthCookie({ name, user, email }, exmins) {
  const cname = userAuthKey;
  const d = new Date();
  d.setTime(d.getTime() + (exmins*60*1000));
  document.cookie = `${cname}=${name},${user},${email};expires=${d.toUTCString()};path=/`
}

export function setPrefCookie({ mode }, exmins) {
  const cname = preferencesKey;
  const d = new Date();
  d.setTime(d.getTime() + (exmins*60*1000));
  document.cookie = `${cname}=${mode};expires=${d.toUTCString()};path=/`
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}