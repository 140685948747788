import {useEffect, useState} from 'react';
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import Toggle from "@cloudscape-design/components/toggle";
import Form from "@cloudscape-design/components/form";
import FormField from "@cloudscape-design/components/form-field";

export function AnswerEditorV2(props) {
  const [answer, setAnswer] = useState(undefined);
  const [attribute, setAttribute] = useState(undefined);
  const [dropDownOptions, setDropDownOptions] = useState(undefined);
  const [isImage, setIsImage] = useState(undefined);
  const [imageSrc, setImageSrc] = useState(undefined);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
      setAnswer(props.item.qs.answer);
      setAttribute(props.item.qs.attribute);
      setDropDownOptions(props.item.qs.dropDownOptions);
      setIsImage(props.item.qs.isImage);
      setImageSrc(props.item.qs.imageSrc);
  },
  [props.id]);

  function getValidDropDownOptions(inputDropDownOptions, inputAnswer) {
     return inputDropDownOptions
              && inputDropDownOptions.length > 1
              && inputDropDownOptions[0] === inputAnswer
              && inputDropDownOptions
                   .filter(opt => opt === inputAnswer)
                   .length == 1
              ? inputDropDownOptions : undefined;
  }

  return (
    <form onSubmit={e => e.preventDefault()}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button formAction="none" variant="link"
              onClick={() => {
                  props.routerProps.resetSplitPanel();
              }}
            >Cancel</Button>
            <Button variant="primary"
              loading={isUpdating}
              onClick={() => {
                  props.setIsUpdatingQuestions(true);
                  setIsUpdating(true);

                  setTimeout(() => {
                  // copy the list of editable questions
                  let tmpEditableQuestionList = [...props.editableQuestionList]
                  let tmpEditableQuestionRow = [...tmpEditableQuestionList[props.row]]
                  // modify the specific item in the list
                  const rawQuestion = `{ "answer": "${answer}", "attribute": "${attribute}", "isImage": ${isImage}, "dropDownOptions": ${JSON.stringify(dropDownOptions)}, "imageSrc": "${isImage ? imageSrc : ""}" }`;
                  tmpEditableQuestionRow[props.k] = {
                    k: props.k,
                    raw: rawQuestion,
                    pre: tmpEditableQuestionRow[props.k].pre,
                    qs: { "answer": answer, "attribute": attribute, "dropDownOptions": getValidDropDownOptions(dropDownOptions, answer) || [], "isImage": isImage, "imageSrc": isImage ? imageSrc : undefined },
                    post: tmpEditableQuestionRow[props.k].post
                  }
                  // re-insert in main list
                  tmpEditableQuestionList[props.row] = tmpEditableQuestionRow;

                  // re-collect: rightAnswers, questionList (structured) and rawQuestions (text)
                  let tmpRightAnswerList = []
                  let tmpQuestionList = [];
                  let tmpRawQuestions = [];
                  for (let i=0; i<tmpEditableQuestionList.length ;i++) {
                    const tmpEditableQuestionRowI = tmpEditableQuestionList[i];
                    let tmpQuestion = "";
                    let tmpRawQuestion = "";
                    for (let j = 0; j<tmpEditableQuestionRowI.length ; j++) {
                      const tmpEditableQuestion = tmpEditableQuestionRowI[j];
                      // there is always pre, append to structured question and raw text question
                      // TODO: remove " " when fixed interpretation without pre
                      tmpQuestion += tmpEditableQuestion.pre && tmpEditableQuestion.pre.length > 0 ?
                        tmpEditableQuestion.pre : " ";
                      tmpRawQuestion += tmpEditableQuestion.pre;
                      // there may or may not be a question
                      if (tmpEditableQuestion.qs) {
                        // collect right answers only to show in drag and drop pool
                        tmpRightAnswerList.push(tmpEditableQuestion.qs.answer);
                        // raw answers with drop down options substitutions, must be more than 1,
                          // right answer be the first one and not be duplicated
                        const rawAnswer = [...(getValidDropDownOptions(
                                            tmpEditableQuestion.qs.dropDownOptions, tmpEditableQuestion.qs.answer)
                                            || [tmpEditableQuestion.qs.answer])].join("/")
                        // append raw text answers
                        tmpRawQuestion += `_${rawAnswer}_`;
                        // append raw answer struct
                        tmpQuestion += tmpEditableQuestion.raw;
                      }
                      // there is normally only one post, append to structured question and raw text question
                      if (tmpEditableQuestion.post) {
                        tmpQuestion += tmpEditableQuestion.post;
                        tmpRawQuestion += tmpEditableQuestion.post;
                      }
                    }
                    // append structured questions and raw text questions
                    tmpQuestionList.push(tmpQuestion);
                    tmpRawQuestions.push(tmpRawQuestion);
                    // perform clean up
                    props.doCleanUp();
                  }

                  // re-set the state
                  props.setEditableQuestionList(tmpEditableQuestionList);
                  props.setQuestionList(tmpQuestionList);
                  props.setRightAnswerList(tmpRightAnswerList);
                  if (props.setAttributesPool && props.attributesPool) {
                    if (attribute && attribute.length && !props.attributesPool.find(item => item === attribute)) {
                      let tmpAttributesPool = [...props.attributesPool];
                      tmpAttributesPool.push(attribute);
                      props.setAttributesPool(tmpAttributesPool);
                    }
                  }
                  props.setRawQuestion(tmpRawQuestions.join("\n"));
                  props.setIsUpdatingQuestions(false);
                  setIsUpdating(false);
                  }, 500);
              }}
            >Submit</Button>
          </SpaceBetween>
        }
      >
        <Container
          header={
            <Header>
              Answer Editor [{props.id}]
            </Header>
          }
          fitHeight={true}
        >
          <SpaceBetween size="s">
            <FormField label="answer">
              <Input
                disabled={isUpdating}
                invalid={!answer || !answer.length}
                value={answer}
                onChange={({detail}) => {
                  setAnswer(detail.value)
                }}
              />
            </FormField>
            {(props.setAttributesPool && props.attributesPool) &&
            <FormField label="attribute">
              <Input
                disabled={isUpdating}
                invalid={!attribute || !attribute.length}
                value={attribute}
                onChange={({detail}) => {
                  setAttribute(detail.value)
                }}
              />
            </FormField>}
            <FormField label="Dropdown Options">
              <Input
                disabled={isUpdating}
                invalid={!getValidDropDownOptions(dropDownOptions, answer)}
                value={dropDownOptions}
                onChange={({detail}) => {
                  const newOptions = detail.value;
                  setDropDownOptions(newOptions ? newOptions.split(",") : [])
                }}
              />
            </FormField>
            <FormField label="is Image">
              <Toggle
                disabled={isUpdating}
                checked={isImage}
                onChange={ ({ detail }) => {
                  setIsImage(detail.checked);
                }}
              >{"is Image"}</Toggle>
            </FormField>
            {isImage && <FormField label="Image Src">
              <Input
                disabled={isUpdating}
                invalid={!imageSrc || !imageSrc.length}
                value={imageSrc}
                onChange={({detail}) => {
                  setImageSrc(props.getRightUrl(detail.value));
                }}
              />
            </FormField>}
            {isImage && imageSrc && imageSrc.length && <img width={180} src={`${imageSrc}`} alt={`${props.id}_IMG`}/>}
          </SpaceBetween>
        </Container>
      </Form>
    </form>
  );
}