import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import {Select, TextContent, ButtonDropdown} from "@cloudscape-design/components";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import FormField from "@cloudscape-design/components/form-field";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import Spinner from "@cloudscape-design/components/spinner";
import ContentLayout from "@cloudscape-design/components/content-layout";
import Alert from "@cloudscape-design/components/alert";
import Link from "@cloudscape-design/components/link";
import Modal from "@cloudscape-design/components/modal";
import Box from "@cloudscape-design/components/box";
import Toggle from "@cloudscape-design/components/toggle";

import { getAuthCookie, getUser } from '../cookies/cookies';
import { StaticTableV2, gpTopicMdControlV2, uuidV4 } from '../common/common'
import { QuestionWizardV2 } from './question-wizard';
import { uploadConfig } from './question-common';
import { MultiQuestionMultiOptionsPanelV2 } from './multi-question-multi-options';
import { PuzzleWizardV2 } from '../puzzle/puzzle-wizard';
import { PuzzleV2 } from '../puzzle/puzzle';
import { PuzzleWizardComponentV2 } from '../puzzle/puzzle-wizard-component';
import { QuestionWizardComponentV2 } from './question-wizard-component';
import { MultiTopicPanelContainerV2 } from './multi-topic-panel-container';
import { AttributeWizardComponentV2 } from './attribute-wizard-component';

export function MultiTopicCommonEditorV2(props) {
  const navigate = useNavigate();

  const languageOptions = [
    { label: "Italiano", value: "italiano" },
    { label: "Français", value: "french" },
    { label: "Deutsch", value: "deutsch" },
    { label: "Русский", value: "russian" }
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [resource, setResource] = useState(undefined);
  const [version, setVersion] = useState(0);
  const [language, setLanguage] = useState(undefined);
  const [level, setLevel] = useState(undefined);
  const [program, setProgram] = useState("undefined");
  const [testTitle, setTestTitle] = useState(undefined);
  const [defaultTopicTitle, setDefaultTopicTitle] = useState(undefined);
  const [addTopicQuestion, setAddTopicQuestion] = useState(undefined);
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState([]);
  const [previewMode, setPreviewMode] = useState(false);
  const [settings, setSettings] = useState({
    maxHintedAnswers: 0, attempts: 0, height: 30, imgHeight: 30
  })

  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertSuccessMessage, setAlertSuccessMessage] = useState(undefined);
  const [alertSuccessToken, setAlertSuccessToken] = useState(undefined);
  const [alertError, setAlertError] = useState(false);
  const [alertErrorMessage, setAlertErrorMessage] = useState(undefined);
  const [publishing, setPublishing] = useState(false);
  const [resourceSuffixId, setResourceSuffixId] = useState((new Date()).getTime());

  const [configureModal, setConfigureModal] = useState(false);
  const [selectedMaxHintsOption, setSelectedMaxHintsOption] = useState({ label: 0, value: 0 });
  const [isDropDown, setIsDropDown] = useState(false);
  const [isDragAndDrop, setIsDragAndDrop] = useState(false);
  const [isRadioGroup, setIsRadioGroup] = useState(false);
  const [doShuffle, setDoShuffle] = useState(false);
  const [maxQuestionsOption, setMaxQuestionsOption] = useState(undefined);
  const [maxAttemptsOption, setMaxAttemptsOption] = useState({ label: 3, value: 3 });
  const [hasImage, setHasImage] = useState(false);
  const [imgHeightOption, setImgHeightOption] = useState({ label: 30, value: 30 });

  const [showPublishModal, setShowPublishModal] = useState(false);
  const [authorizedUsers, setAuthorizedUsers] = useState(undefined);
  const [selectedDueDaysOption, setSelectedDueDaysOption] = useState(undefined);
  const [lastUpdated, setLastUpdated] = useState(undefined);
  const [token, setToken] = useState(undefined);

  useEffect(() => {
    setSelectedTopic([]);
    if (props.configs) {
      setResource(props.configs.resource);
      setVersion(props.configs.version || 0);
      setLanguage(languageOptions.find(option => option.value === props.configs.language));
      setLevel(props.configs.level);
      setProgram(props.configs.program)
      setTestTitle(props.configs.title);
      setDefaultTopicTitle(props.configs.commonTopicTitle);
      let newTopics = props.configs.options.map(item => {
        let newTopic = {}
        newTopic.option = {}
        Object.keys(item.edit).forEach(key => {
          newTopic[key] = item.edit[key];
        });
        Object.keys(item).filter(key => key !== "edit").forEach(key => {
          newTopic.option[key] = item[key];
        });
        return newTopic;
      });
      setTopics(newTopics);

      const tmpSettings = props.configs.settings || {};
      if (tmpSettings) {
        setSelectedMaxHintsOption(tmpSettings.maxHintedAnswers ?
          { label: tmpSettings.maxHintedAnswers, value: tmpSettings.maxHintedAnswers } : { label: 0, value: 0 });
        setIsDropDown(tmpSettings.dropDown || false);
        setIsDragAndDrop(tmpSettings.dragAndDrop || false);
        setIsRadioGroup(tmpSettings.radioGroup || false);
        setDoShuffle(tmpSettings.doShuffle || false);
        setMaxQuestionsOption(tmpSettings.maxQuestions ?
          { label: tmpSettings.maxQuestions, value: tmpSettings.maxQuestions } : undefined);
        setMaxAttemptsOption(tmpSettings.attempts ?
          { label: tmpSettings.attempts, value: tmpSettings.attempts } : { label: 3, value: 3 });
        setHasImage(tmpSettings.hasImage || false);
        setImgHeightOption(tmpSettings.imgHeight ?
          { label: tmpSettings.imgHeight, value: tmpSettings.imgHeight } : { label: 30, value: 30 });
      }
      setSettings(tmpSettings);
      setAuthorizedUsers(props.configs.authorizedUsers.join(","));
      setToken(props.configs.token);
      // due days pending
    }
  }, []);

  useEffect(() => {
    //    setIsLoading(true);
    //    props.routerProps.resetSplitPanel();
    setAlertSuccess(false); setAlertSuccessMessage(undefined); setAlertSuccessToken(undefined);
    setAlertError(false); setAlertErrorMessage(undefined);

    // in order to force sign in after user log in is expired
    if (!getAuthCookie()) { props.routerProps.setAuthUser(undefined); navigate(`/`); }
    else setTimeout(() => setIsLoading(false), 500);

  }, [previewMode]);

  // reset the selected items every time add topic => after launching "Question Wizard" gets restarted or else
  // the selected list gets corrupted after any change
  useEffect(() => {
    if (!addTopicQuestion) {
      setSelectedTopic([]);
      setAlertSuccess(false); setAlertSuccessMessage(undefined); setAlertSuccessToken(undefined);
      setAlertError(false); setAlertErrorMessage(undefined);
    }
  }, [addTopicQuestion]);

  function isNotValidLesson(resource, language, level, program, testTitle) {
    return (!resource || resource.length == 0) || (!language) || (!level || level.length == 0) || (!program || program.length == 0) || (!testTitle || testTitle.length == 0)
  }

  return (
  <div>
  {isLoading && <Spinner />}
  {(!isLoading && !addTopicQuestion && !previewMode) &&
  <ContentLayout>
  <SpaceBetween direction="vertical" size="l">
    {(alertSuccess || alertError) && <Alert
      onDismiss={() => {
        setAlertSuccess(false);
        setAlertSuccessMessage(undefined);
        setAlertSuccessToken(undefined);
        setAlertError(false);
        setAlertErrorMessage(undefined);
        setPublishing(false);
      }}
      dismissible
      type={alertSuccess ? "success" : (alertError ? "error" : "info")}
      statusIconAriaLabel={alertSuccess ? "Success" : (alertError ? "Error" : "Info")}
      header="Publish result"
    >
    <div>
      {alertSuccess && <SpaceBetween direction="vertical">
        <SpaceBetween direction="horizontal" size="xxs">
          <TextContent>Review the online assignment (version <b><em style={{color:"orange"}}>v.{version}</em></b>) at</TextContent>
          <Link href={alertSuccessMessage}>{alertSuccessMessage}</Link>
          <Button
            variant="inline-icon"
            iconName="copy"
            onClick={() => navigator.clipboard.writeText(alertSuccessMessage) }
          >Copy</Button>
        </SpaceBetween>
        <SpaceBetween direction="horizontal" size="xxs">
          <TextContent>Temporary token {alertSuccessToken}</TextContent>
          <Button
            variant="inline-icon"
            iconName="copy"
            onClick={() => navigator.clipboard.writeText(alertSuccessToken) }
          >Copy</Button>
        </SpaceBetween>
      </SpaceBetween>}
      {alertError && <SpaceBetween direction="horizontal" size="xs">
        <TextContent>{alertErrorMessage}</TextContent>
      </SpaceBetween>}
    </div>
    </Alert>}
    <Modal
      onDismiss={() => setShowPublishModal(false)}
      visible={showPublishModal}
      closeAriaLabel="Close"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="normal"
              onClick={() => setShowPublishModal(false)}
              >Cancel
            </Button>
            <Button
              variant="primary"
              loading={publishing}
              onClick={() => {
                const readUser = getUser();
                const tmpToken = uuidV4();
                const tmpVersion = (version + 1);

                const callback = (response) => {
                  if (!response.err) {
                    setAlertSuccessMessage('https://strana-us.com/onlineassignments/'
                      + `${response.key.replace("/", "?id=")}`);
                    setAlertSuccessToken(tmpToken);
                    setAlertSuccess(true);
                    setToken(tmpToken);
                    setVersion(tmpVersion);
                  } else {
                    setAlertErrorMessage(response.err);
                    setAlertError(true);
                  }
                  setPublishing(false);
                }

                let dueDate = new Date();
                const plusDays = (selectedDueDaysOption ? selectedDueDaysOption.value : 1);
                dueDate.setDate(dueDate.getDate() + plusDays);

                const configData = {
                  type: props.type,
                  resource: resource,
                  version: tmpVersion,
                  language: language.value,
                  level: level,
                  program: readUser,
                  title: testTitle,
                  commonTopicTitle: defaultTopicTitle,
                  settings: settings,
                  options: topics.map(topic => {
                    let bulkedOption = {}
                    bulkedOption.edit = {}
                    Object.keys(topic)
                      .filter(key => key !== "option").forEach(key => { bulkedOption.edit[key] = topic[key]; });
                    Object.keys(topic.option)
                      .forEach(key => { bulkedOption[key] = topic.option[key]; });
                    return bulkedOption;
                  }),
                  author: readUser,
                  authorizedUsers: authorizedUsers ? authorizedUsers.split(",") : [],
                  token: tmpToken,
                  dueOn: `${dueDate.getTime()}`,
                  lastUpdated: `${(new Date()).getTime()}`
                }

                setPublishing(true);
                new Promise(resolve => setTimeout(() => {
                    uploadConfig(readUser, `${resource}`, configData, callback);
                    setTimeout(() => setShowPublishModal(false), 500)
                    resolve();
                  }, 500));
              }}
              >Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={
        <SpaceBetween size="l" direction="horizontal">
          Publish Authorization
        </SpaceBetween>}
    >
      <SpaceBetween direction="vertical" size="s">
        <FormField label="Authorized users" description={"comma separated user logins, if none specified only the author can access"}>
          <Input
            value={authorizedUsers}
            onChange={({ detail }) => {
              setAuthorizedUsers(detail.value);
            }}
          />
        </FormField>
        <FormField label="Due days" description={"days before the assignment is due"}>
          <Select
            filteringType={"auto"}
            selectedOption={selectedDueDaysOption}
            onChange={({ detail }) => {
              setSelectedDueDaysOption(detail.selectedOption);
            }}
            options={Array.from(Array(7))
                      .map((_, i) => i + 1).map(v => ({ label: `${v}`, value: v }))}
            placeholder="Choose due days"
            selectedAriaLabel="Selected"
          />
        </FormField>
      </SpaceBetween>
    </Modal>
    <Modal
      onDismiss={() => setConfigureModal(false)}
      visible={configureModal}
      closeAriaLabel="Close"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="normal"
              onClick={() => setConfigureModal(false)}
              >Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => setConfigureModal(false)}
              >Set
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={
        <SpaceBetween size="l" direction="horizontal">
          Launch Settings
        </SpaceBetween>}
    >
      <SpaceBetween direction="vertical" size="s">
        {false &&
        <div>
        <Toggle
          onChange={({ detail }) => {
            setIsDropDown(detail.checked);
            if (detail.checked) { setIsDragAndDrop(!detail.checked); setIsRadioGroup(!detail.checked); }
            let newSettings = settings;
            newSettings["dropDown"] = detail.checked;
            if (detail.checked) { newSettings["dragAndDrop"] = !detail.checked; newSettings["radioGroup"] = !detail.checked; }
            setSettings(newSettings);
          }}
          checked={isDropDown}
        >
          ALL as Drop-down
        </Toggle>
        <Toggle
          onChange={({ detail }) => {
            setIsDragAndDrop(detail.checked);
            if (detail.checked) { setIsDropDown(!detail.checked); setIsRadioGroup(!detail.checked); }
            let newSettings = settings;
            newSettings["dragAndDrop"] = detail.checked;
            if (detail.checked) { newSettings["dropDown"] = !detail.checked; newSettings["radioGroup"] = !detail.checked; }
            setSettings(newSettings);
          }}
          checked={isDragAndDrop}
        >
          ALL as Drag n' Drop
        </Toggle>
        <Toggle
          onChange={({ detail }) => {
            setIsRadioGroup(detail.checked);
            if (detail.checked) { setIsDropDown(!detail.checked); setIsDragAndDrop(!detail.checked); }
            let newSettings = settings;
            newSettings["radioGroup"] = detail.checked;
            if (detail.checked) { newSettings["dropDown"] = !detail.checked; newSettings["dragAndDrop"] = !detail.checked; }
            setSettings(newSettings);
          }}
          checked={isRadioGroup}
        >
          ALL as Radio Groups (simple selection)
        </Toggle>
        </div>}
        <Toggle
          onChange={({ detail }) => {
            setDoShuffle(detail.checked);
            let newSettings = settings;
            newSettings["doShuffle"] = detail.checked;
            setSettings(newSettings);
          }}
          checked={doShuffle}
        >
          Shuffle Topics
        </Toggle>
        <FormField
          description="Max Questions"
        >
        <Select
            filteringType={"auto"}
            selectedOption={maxQuestionsOption}
            onChange={({ detail }) => {
              setMaxQuestionsOption(detail.selectedOption);
              let newSettings = settings;
              newSettings["maxQuestions"] = detail.selectedOption.value;
              setSettings(newSettings);
            }}
            options={Array.from(Array(topics.length + 1))
              .map((_, i) => i).map(v => (v == 0) ? ({ label: `ALL`, value: undefined }) : ({ label: `${v}`, value: v }) )}
            placeholder="Choose Max Questions"
            selectedAriaLabel="Selected"
        />
        </FormField>
        <FormField
          description="Max Attempts per question"
        >
        <Select
            filteringType={"auto"}
            selectedOption={maxAttemptsOption}
            onChange={({ detail }) => {
              setMaxAttemptsOption(detail.selectedOption);
              let newSettings = settings;
              newSettings["attempts"] = detail.selectedOption.value;
              setSettings(newSettings);
            }}
            options={[1, 2, 3].map(v => ({ label: `${v}`, value: v }))}
            placeholder="Choose Max Attempts per question"
            selectedAriaLabel="Selected"
        />
        </FormField>
        <FormField
          description="Max answer(s) Hints"
        >
        <Select
            filteringType={"auto"}
            selectedOption={selectedMaxHintsOption}
            onChange={({ detail }) => {
              setSelectedMaxHintsOption(detail.selectedOption);
              let newSettings = settings;
              newSettings["maxHintedAnswers"] = detail.selectedOption.value;
              setSettings(newSettings);
              /*
              should be used on a puzzle topic only
              Array.from(Array(
              ((topics.length && topics[0].option.qs.vertical) ? topics[0].option.qs.vertical.length : 0) +
              ((topics.length && topics[0].option.qs.horizontal) ? topics[0].option.qs.horizontal.length : 0) + 1))
                      .map((_, i) => i).map(v => ({ label: `${v}`, value: v }))
              */
            }}
            options={Array.from(Array(topics.length + 1))
                      .map((_, i) => i).map(v => ({ label: `${v}`, value: v }))}
            placeholder="Choose Max answer(s) Hints"
            selectedAriaLabel="Selected"
        />
        </FormField>
        <Toggle
          onChange={({ detail }) => {
            setHasImage(detail.checked);
            let newSettings = settings;
            newSettings["imageOnly"] = detail.checked;
            newSettings["hasImage"] = detail.checked;
            setSettings(newSettings);
          }}
          checked={hasImage}
        >
          Show inline Images
        </Toggle>
        <FormField
          description="Image Height"
        >
        <Select
            filteringType={"auto"}
            selectedOption={imgHeightOption}
            onChange={({ detail }) => {
              setImgHeightOption(detail.selectedOption);
              let newSettings = settings;
              newSettings["height"] = detail.selectedOption.value;
              newSettings["imgHeight"] = detail.selectedOption.value;
              setSettings(newSettings);
            }}
            options={[30, 60, 90, 120].map(v => ({ label: `${v}`, value: v }))}
            placeholder="Choose Image Height"
            selectedAriaLabel="Selected"
        />
        </FormField>
      </SpaceBetween>
    </Modal>
    <Container fitHeight={true}
      header={
        <Header variant="h2" description={"Fill in the required information, then start adding topics|questions..."}>
          <div>
              {props.type === "Puzzle" ? `Puzzle Editor`: `Lesson Editor`}
              {(token) &&
                <Button
                  variant="link"
                  onClick={() => {
                    setAlertSuccessMessage('https://strana-us.com/onlineassignments/'
                      + `${getUser()}?id=${props.configs.resource}`);
                    setAlertSuccessToken(token);
                    setAlertSuccess(true);
                  }}
                >Retrieve Token
                </Button>
              }
          </div>
        </Header>
      }
    >
      <ColumnLayout columns={2} variant="text-grid">
      <div>
        <FormField label="Resource" description={<span>resource unique id i.e: homeworkId-{resourceSuffixId} {(!props.configs) && <Button variant="inline-icon" iconName="thumbs-up-filled" onClick={() => setResource(`homeworkId-${resourceSuffixId}`)}/>}</span>}>
          <Input
            invalid={!resource || resource.length == 0}
            value={resource}
            onChange={({ detail }) => {
              setResource(detail.value);
            }}
          />
        </FormField>
        <FormField label="Language">
          <Select
            expandToViewport={true}
            selectedOption={language}
            invalid={!language}
            onChange={({ detail }) =>
              setLanguage(detail.selectedOption)
            }
            options={languageOptions}
          />
        </FormField>
      </div>
      <div>
        <FormField label="Level" description={`program level, ie.: Deutsch A1/2 B1/2 C1/2 or Italian beginner 2023 Q1, etc.`}>
          <Input
            invalid={!level || level.length == 0}
            value={level}
            onChange={({ detail }) => {
              setLevel(detail.value);
            }}
          />
        </FormField>
        {false &&
        <FormField label="Program">
          <Input
            invalid={!program || program.length == 0}
            value={program}
            onChange={({ detail }) => {
              setProgram(detail.value);
            }}
          />
        </FormField>}
        <FormField label="Lesson Title">
          <Input
            invalid={!testTitle || testTitle.length == 0}
            value={testTitle}
            onChange={({ detail }) => {
              setTestTitle(detail.value);
            }}
          />
        </FormField>
      </div>
      </ColumnLayout>
    </Container>
    <FormField label="Common topic title">
      <Input
        value={defaultTopicTitle}
        onChange={({ detail }) => {
          setDefaultTopicTitle(detail.value);
        }}
      />
    </FormField>
    <StaticTableV2
      headerVariant="h4"
      tableHeader="Topics"
      actions={
          <SpaceBetween direction="horizontal" size="s">
            <ButtonDropdown
              items={
              (selectedTopic.length == 0) ?
              [
                { text: "Add Q/A Topic", id: "MultiQuestionMultiOptionsComponent" },
                { text: "Add Puzzle Topic", id: "PuzzleComponent" },
                { text: "Add Grammar Topic", id: "MultiQuestionMultiAttributesComponent" }
              ]
              :
              ((selectedTopic.length == 1) ?
                [
                  { text: "Update", id: "update" },
                  { text: "Copy", id: "copy" },
                  { text: "Delete", id: "delete" }
                ]
                :
                [
                  { text: "Delete", id: "delete" }
                ]
              )}
              onItemClick={({ detail }) => {
                  switch (detail.id) {
                    case "MultiQuestionMultiOptionsComponent": setAddTopicQuestion(detail.id); break
                    case "PuzzleComponent": setAddTopicQuestion(detail.id); break;
                    case "MultiQuestionMultiAttributesComponent": setAddTopicQuestion(detail.id); break;
                    case "update": setAddTopicQuestion(selectedTopic[0].type); break;
                    case "copy":
                      let newCopyTopic = { ...topics[selectedTopic[0].id] }
                      newCopyTopic.index = topics.length
                      let newCopyTopics = [ ...topics, newCopyTopic ]
                      setSelectedTopic([]);
                      setTopics(newCopyTopics);
                      break;
                    case "delete":
                      let newTopics = topics
                        .filter(topic => !selectedTopic.some(item => item.id == topic.index))
                        .map((topic, newIndex) => { topic.index = newIndex; return topic })
                      setSelectedTopic([]);
                      setTopics(newTopics);
                      break;
                  }
              }}
            >Actions
            </ButtonDropdown>
            {(topics && topics.length > 0) &&
            <Button
              variant="primary"
              onClick={() => {
                setConfigureModal(true);
              }}
            >Configure</Button>}
            {(topics && topics.length > 0) &&
            <Button
              disabled={isNotValidLesson(resource, language, level, program, testTitle)}
              onClick={() => {
                setPreviewMode(true);
              }}
            >Preview</Button>}
            {(topics && topics.length > 0) &&
            <Button
              disabled={isNotValidLesson(resource, language, level, program, testTitle)}
              loading={publishing}
              onClick={() => {
                setAlertSuccess(false); setAlertSuccessMessage(undefined); setAlertSuccessToken(undefined);
                setAlertError(false); setAlertErrorMessage(undefined);
                setShowPublishModal(true);
              }}
            >Publish</Button>}
          </SpaceBetween>
      }
      columnDefinitions={["id", "topic", "type"]
          .map(item => ({
                id: item,
                header: item,
                cell: e => e[item]
              })
          )}
      loading={false}
      selectionType={'multi'}
      selectedItems={selectedTopic}
      setSelectedItems={setSelectedTopic}
      trackBy="id"
      items={topics.map(topic => ({ id: topic.index, type: topic.option.md.type, topic: topic.option.md.titleOverride }))}
    />
  </SpaceBetween>
  </ContentLayout>
  }
  {(!isLoading && addTopicQuestion && !previewMode) &&
  <div>
    {addTopicQuestion === "MultiQuestionMultiOptionsComponent" && <QuestionWizardComponentV2
      routerProps={props.routerProps}
      topics={topics}
      setTopics={setTopics}
      topicIndex={(selectedTopic && selectedTopic.length == 1) ? (selectedTopic[0].id + 1) : undefined}
      setAddTopicQuestion={setAddTopicQuestion}
      defaultTopicTitle={defaultTopicTitle}
    />}
    {addTopicQuestion === "PuzzleComponent" && <PuzzleWizardComponentV2
      routerProps={props.routerProps}
      topics={topics}
      setTopics={setTopics}
      topicIndex={(selectedTopic && selectedTopic.length == 1) ? (selectedTopic[0].id + 1) : undefined}
      setAddTopicQuestion={setAddTopicQuestion}
      defaultTopicTitle={defaultTopicTitle}
    />}
    {addTopicQuestion === "MultiQuestionMultiAttributesComponent" && <AttributeWizardComponentV2
      routerProps={props.routerProps}
      topics={topics}
      setTopics={setTopics}
      topicIndex={(selectedTopic && selectedTopic.length == 1) ? (selectedTopic[0].id + 1) : undefined}
      setAddTopicQuestion={setAddTopicQuestion}
      defaultTopicTitle={defaultTopicTitle}
    />}
  </div>
  }
  {(!isLoading && !addTopicQuestion && previewMode && topics && topics.length > 0) &&
      <ContentLayout>
      {<MultiTopicPanelContainerV2
          routerProps={props.routerProps}
          header={
            <SpaceBetween>
              <BreadcrumbGroup
                items={[
                  { text: "Lesson Editor", href: "#" },
                  { text: "Preview", href: "#" },
                ]}
                onClick={({ detail }) => {
                  if (detail.text === "Lesson Editor" ) {
                    props.routerProps.resetSplitPanel()
                    setPreviewMode(false);
                  }
                }}
                ariaLabel="Breadcrumbs"
              />
            </SpaceBetween>
          }
          onExit={() => {
            props.routerProps.resetSplitPanel();
            setPreviewMode(false);
          }}
          title={`${testTitle} - Try Mode`}
          options={topics.map(topic => topic.option)}
          imgPath={(imgName, imgSrc) => {
            if (imgSrc) return imgSrc;
            return "#";
          }}
          topicMdControl={gpTopicMdControlV2}
          renderSplitPanelItems={(v) => {
          }}
          settings={settings}
          getQuestion={(v) => `${defaultTopicTitle}`}
      />}
      </ContentLayout>
  }
  </div>
  );
}