import {useEffect, useRef, useState} from 'react';
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import {StatusIndicator, TextContent} from "@cloudscape-design/components";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import ProgressBar from "@cloudscape-design/components/progress-bar";
import { cloneOptionsV2 } from '../common/common'
import { RoundSettingsV2 } from './round-settings'
import { DisplaySummaryV2 } from './display-summary'
import { dropDivSingleV2, dragStartDivV2, dragEnterDivV2, calculateTotalAttemptsV2 } from './question-common';

export function MultiOptionsQuestionPanelV2(props) {
  const inputReference = useRef(null);

  const dragItemDiv = useRef();
  const dragOverItemDiv = useRef();
  const [listAnswerDiv, setListAnswerDiv] = useState([]);
  const [listDiv, setListDiv] = useState([]);

  // displayable state attributes
  const [shuffledArray, setShuffledArray] = useState([]);
  const [actualAnswers, setActualAnswers] = useState([]);
  const [answersIsMatch, setAnswersIsMatch] = useState([]);
  const [isLoadingTest, setIsLoadingTest] = useState(false);

  // test state attributes
  const newTestOptions = cloneOptionsV2(props.options);
  const newRandOptionIndex = Math.floor(Math.random() * newTestOptions.length);
  const [testOptions, setTestOptions] = useState(newTestOptions.filter((value, i) => i !== newRandOptionIndex));
  const [testIndex, setTestIndex] = useState(0);
  const [randOptionIndex, setRandOptionIndex] = useState(newRandOptionIndex);
  const [testOption, setTestOption] = useState(newTestOptions[newRandOptionIndex]);
  const [testRound, setTestRound] = useState(1);

  // round state attributes
  const [roundRandOptionIndexes, setRoundRandOptionIndexes] = useState([newRandOptionIndex]);
  const [roundState, setRoundState] = useState([]);
  const [displaySummary, setDisplaySummary] = useState(false);
  const [summaryTestRound, setSummaryTestRound] = useState(undefined);

  // starting configs values
  const startingAttempts = props.settings && props.settings.maxAttempts ? props.settings.maxAttempts : 3;
  const startingHasImage = props.settings && props.settings.hasImage ? props.settings.hasImage : false;
  const startingImgHeight = props.settings && props.settings.height ? props.settings.height : 200;
  const startingMaxQuestions = props.settings && props.settings.maxQuestions
    && (props.settings.maxQuestions > 0 && props.settings.maxQuestions < props.options.length) ?
    props.settings.maxQuestions : props.options.length;
  const canDropDown = props.settings && props.settings.dropDown ? props.settings.dropDown : false;
  const canDragAndDrop = props.settings && props.settings.dragAndDrop ? props.settings.dragAndDrop : false;
  const startingDoShuffle = props.settings && props.settings.doShuffle ? props.settings.doShuffle : false;
  const startingImageOnly = props.settings && props.settings.imageOnly ? props.settings.imageOnly : false;

  // settings, yet more to be defined
  const [configs, setConfigs] =  useState({
    attempts: startingAttempts,
    hasImage: startingHasImage,
    height: startingImgHeight,
    maxQuestions: startingMaxQuestions,
    dropDown: canDropDown,
    dragAndDrop: canDragAndDrop,
    doShuffle: startingDoShuffle,
    imageOnly: startingImageOnly,
  });
  const [selectedAttemptsOption, setSelectedAttemptsOption] =  useState({ label: `${startingAttempts}`, value: startingAttempts });
  const [selectedHeightOption, setSelectedHeightOption] =  useState({ label: `${startingImgHeight}px`, value: startingImgHeight });
  const [selectedMaxQuestionsOption, setSelectedMaxQuestionsOption] =  useState({ label: `${startingMaxQuestions}`, value: startingMaxQuestions });
  const [dragAndDrop, setDragAndDrop ] = useState(canDragAndDrop);
  const [dropDown, setDropDown ] = useState(canDropDown);
  const multiQuestionOptions = props.questionArray.map((question, i) => ({
    label: question,
    value: question,
    index: i
  }));
  const multiQuestionGroupedOptions = [{ label: "Question option(s)", options: multiQuestionOptions }];
  const [selectedQuestionOptions, setSelectedQuestionOptions] = useState(multiQuestionOptions);
  const [doShuffle, setDoShuffle] = useState(startingDoShuffle);
  const [imageOnly, setImageOnly] = useState(startingImageOnly);
  const [testConfigModalVisible, setTestConfigModalVisible] = useState(true);

  const [submitSummaryCallback, setSubmitSummaryCallback] = useState(() => {});
  const [reviewSummaryCallback, setReviewSummaryCallback] = useState(() => {});

  useEffect(() => {
    setNextTestMultiInputState(testOption, []);
    setTimeout(() => { if (inputReference.current) inputReference.current.focus(); }, 100);
  }, [])

  function setNextTestMultiInputState(currentTestOption, currentRoundState) {
    const newQuestionArray = props.questionArray.map((answer, i) => i);
    // re-shuffles the multi options for the next test
    const newShuffledArray = newQuestionArray
        .sort(() => doShuffle ? Math.random() - 0.5 : undefined)
    setShuffledArray(newShuffledArray);
    // voids answer(s) match array
    const newAnswersIsMatchArray = props.questionArray.map((answerIsMatch) => undefined);
    setAnswersIsMatch(newAnswersIsMatchArray);
    // voids answer(s) array
    const newAnswersArray = props.questionArray.map((answer) => undefined);
    setActualAnswers(newAnswersArray);
    // set new list div
    const newListDiv = cloneOptionsV2(newQuestionArray
        .map((index) => props.getAnswer(currentTestOption, index))
        .sort(() => doShuffle ? Math.random() - 0.5 : undefined))
    setListDiv(newListDiv);

    // set new input into round state
    let newRoundState = [...currentRoundState,
      {
        testOption: currentTestOption,
        shuffledArray: newShuffledArray,
        answersIsMatchArray: newAnswersIsMatchArray,
        answersArray: newAnswersArray,
        attempts: 0,
        listDiv: newListDiv
      }
    ]
    setRoundState(newRoundState);
  }

  function loadMultiInputFromRoundState(index, currentRoundState) {
    const oldRoundState = currentRoundState[index];
    setShuffledArray(oldRoundState.shuffledArray);
    setAnswersIsMatch(oldRoundState.answersIsMatchArray);
    setActualAnswers(oldRoundState.answersArray);
    setTestOption(oldRoundState.testOption);
    setListDiv(oldRoundState.listDiv);
  }

  function calcMatches(tmpAnswersArray) {
    return tmpAnswersArray
        .map((value, i) => ({ index: i, answer: value }))
        .map((value) => (value.answer === props.getAnswer(testOption, value.index) ? "success" : "error"));
  }

  function saveMultiInputIntoState(index, newAnswerArray) {
    let newRoundState = [...roundState];
    let oldInputState = roundState[index];
    const newAnswersIsMatch = [...calcMatches(newAnswerArray)];
    let newInputState = {
      testOption: testOption,
      shuffledArray: [...shuffledArray],
      answersIsMatchArray: newAnswersIsMatch,
      answersArray: [...newAnswerArray],
      attempts: (newAnswersIsMatch
          .filter((value, i) => (selectedQuestionOptions.some(opt => opt.index === i)))
          .every(isMatch => isMatch === "success") ? configs.attempts : oldInputState.attempts + 1),
      listDiv: listDiv
    }
    newRoundState[index] = newInputState;
    return newRoundState;
  }

  function doBack(tmpTestIndex) {
      setIsLoadingTest(true);
      const newTestIndex = tmpTestIndex - 1;
      setTestIndex(newTestIndex);
      loadMultiInputFromRoundState(newTestIndex, roundState);
      setTimeout(() => {
        setIsLoadingTest(false);
        if (selectedQuestionOptions.length > 0 && inputReference && inputReference.current) {
          inputReference.current.focus();
        }
      }, 75);
  }

  function commitNext(
    isLastTest, currentRoundState, tmpPropsOptions, tmpTestOptions,
    tmpSetRandOptionIndex, tmpSetTestOption, tmpSetTestOptions, tmpSetRoundRandOptionIndexes, tmpSetRoundState, tmpSetNextTestMultiInputState) {
      const newTestOptions = isLastTest ? cloneOptionsV2(tmpPropsOptions) : tmpTestOptions;
      // calculates another random question index
      const newRandOptionIndex = Math.floor(Math.random() * newTestOptions.length);
      tmpSetRandOptionIndex(newRandOptionIndex);
      // sets new test option
      const newTestOption = newTestOptions[newRandOptionIndex];
      tmpSetTestOption(newTestOption);

      // voids answer(s) match array
      // voids answer(s) array
      if (isLastTest) {
        tmpSetRoundRandOptionIndexes([newRandOptionIndex]);
        currentRoundState = [];
        tmpSetRoundState([]);
        // newTestIndex = 0;
      }
      tmpSetNextTestMultiInputState(newTestOption, currentRoundState);
      // setTestRound(testOptions.length === 1 ? testRound + 1 : testRound);

      // removes the question from the options or restore all the questions in a new round
      tmpSetTestOptions(newTestOptions.filter((value, i) => i !== newRandOptionIndex));
  }

  const doNext = async (doStoreCurrentState) => {
    let newTestIndex = testIndex + 1;
    // save state before moving forward
    let currentRoundState = doStoreCurrentState ? saveMultiInputIntoState(testIndex, actualAnswers) : roundState;
    setRoundState(currentRoundState);

    if (newTestIndex < currentRoundState.length) {
      setRandOptionIndex(roundRandOptionIndexes[newTestIndex]);
      loadMultiInputFromRoundState(newTestIndex, currentRoundState);
    } else {
      // removes the question from the options or restore all the questions in a new round
      const isLastTest = (selectedMaxQuestionsOption.value < props.options.length) ?
        testOptions.length <= (props.options.length - selectedMaxQuestionsOption.value) : testOptions.length === 0;

      if (isLastTest) {
        setDisplaySummary(true);
        setSummaryTestRound(currentRoundState);
      }
      const newTestOptions = isLastTest ? cloneOptionsV2(props.options) : testOptions;
      // calculates another random question index
      const newRandOptionIndex = Math.floor(Math.random() * newTestOptions.length);
      setRandOptionIndex(newRandOptionIndex);
      // sets new test option
      const newTestOption = newTestOptions[newRandOptionIndex];
      setTestOption(newTestOption);

      // voids answer(s) match array
      // voids answer(s) array
      if (isLastTest) {
        setRoundRandOptionIndexes([newRandOptionIndex]);
        currentRoundState = [];
        setRoundState([]);
        newTestIndex = 0;
      }
      setNextTestMultiInputState(newTestOption, currentRoundState);
      // setTestRound(testOptions.length === 1 ? testRound + 1 : testRound);

      // removes the question from the options or restore all the questions in a new round
      setTestOptions(newTestOptions.filter((value, i) => i !== newRandOptionIndex));
    }

    setTestIndex(newTestIndex);
  };

  function getTotalAttempts() {
    return calculateTotalAttemptsV2(roundState, testIndex);
  }

  function getTotalQuestions() {
    return (selectedMaxQuestionsOption.value < props.options.length ? selectedMaxQuestionsOption.value : props.options.length);
  }

  return (
      <SpaceBetween size="m">
        <Header variant="h1">
          <SpaceBetween direction={"horizontal"} size={"s"} alignItems="center">
          {props.header}
          {/*<Button variant="inline-icon" disabled
                  onClick={() => {
                    setTestConfigModalVisible(true);
                  }}
                  iconName={"settings"}
          />*/}
          </SpaceBetween>
        </Header>
        <Container>
          <RoundSettingsV2
               onExit={props.onExit}
               title={props.title}
               configs={configs}
               setConfigs={setConfigs}
               options={props.options}
               questionArray={props.questionArray}
               testConfigModalVisible={testConfigModalVisible}
               setTestConfigModalVisible={setTestConfigModalVisible}
               doShuffle={doShuffle}
               setDoShuffle={setDoShuffle}
               dropDown={dropDown}
               setDropDown={setDropDown}
               dragAndDrop={dragAndDrop}
               setDragAndDrop={setDragAndDrop}
               cantDropDown={!canDropDown}
               cantDragAndDrop={!canDragAndDrop}
               selectedMaxQuestionsOption={selectedMaxQuestionsOption}
               setSelectedMaxQuestionsOption={setSelectedMaxQuestionsOption}
               selectedQuestionOptions={selectedQuestionOptions}
               setSelectedQuestionOptions={setSelectedQuestionOptions}
               multiQuestionGroupedOptions={multiQuestionGroupedOptions}
               selectedHeightOption={selectedHeightOption}
               setSelectedHeightOption={setSelectedHeightOption}
               selectedAttemptsOption={selectedAttemptsOption}
               setSelectedAttemptsOption={setSelectedAttemptsOption}
               imageOnly={imageOnly}
               setImageOnly={setImageOnly}
          />
          <SpaceBetween direction={"vertical"} size={"s"}>
            {displaySummary ?
            <DisplaySummaryV2
              summaryTestRound={summaryTestRound}
              selectedQuestionOptions={selectedQuestionOptions}
              getTotalQuestions={getTotalQuestions}
              testRound={testRound}
              setTestRound={setTestRound}
              setDisplaySummary={setDisplaySummary}
              inputReference={inputReference}
              setTestConfigModalVisible={setTestConfigModalVisible}
              submitSummaryCallback={submitSummaryCallback}
              reviewSummaryCallback={reviewSummaryCallback}
            />
            :
            <SpaceBetween size="s">
              <SpaceBetween size="s" direction="vertical">
                <SpaceBetween direction="horizontal" size="s">
                  <h2>{`${props.getQuestion ? props.getQuestion(testOption) : 'Как по-русский'}`}</h2>
                  <h4>{` [Round: ${testRound}]`}</h4>
                  <ProgressBar
                    value={(testIndex + 1)/getTotalQuestions()*100}
                    additionalInfo={`[Question: ${testIndex + 1}/${getTotalQuestions()}]`}
                  />
                  <ProgressBar
                    value={getTotalAttempts()/configs.attempts*100}
                    additionalInfo={`[Attempt(s): ${getTotalAttempts()}/${configs.attempts}]`}
                  />
                </SpaceBetween>
                {props.renderChallengeControl(testOption, configs)}
              </SpaceBetween>
              <SpaceBetween size="s" direction="vertical">
                {shuffledArray
                    .map((index) => ({answer: actualAnswers[index], index: index}))
                    .filter((value) => selectedQuestionOptions.some(opt => opt.index === value.index))
                    .map((value, i) => (
                        <ColumnLayout key={`columns-${value.index}`}
                                      columns={3 + (props.getFunctionalQuestionPre ? 1 : 0) + (props.getFunctionalQuestionPost ? 1 : 0)}>
                          {props.getFunctionalQuestionPre &&
                          <div key={`sb-${value.index}-01`}>
                            <TextContent
                                key={`textPre-${value.index}`}>{props.getFunctionalQuestionPre(testOption, value.index)}
                            </TextContent>
                          </div>}
                          <div key={`sb-${value.index}-02`}>
                            <div
                               onDragStart={(e) => dragStartDivV2(e, value.answer, value.index+1000, dragItemDiv)}
                               onDragEnter={(e) => dragEnterDivV2(e, value.answer, value.index+1000, dragOverItemDiv)}
                               onDragEnd={(e) => dropDivSingleV2(e, dragItemDiv, dragOverItemDiv, listDiv, setListDiv, actualAnswers, setActualAnswers, answersIsMatch, setAnswersIsMatch)}
                               draggable={
                                 (dragAndDrop && actualAnswers.length > 1) &&
                                 !(getTotalAttempts() >= configs.attempts) &&
                                 !isLoadingTest
                               }>
                                <Input
                                    key={`input-${value.index}`}
                                    ref={i === 0 ? inputReference : undefined}
                                    value={value.answer}
                                    disabled={
                                      getTotalAttempts() >= configs.attempts
                                      || answersIsMatch[value.index] === "success"
                                      || isLoadingTest || (dragAndDrop && actualAnswers.length > 1)
                                    }
                                    onChange={(event) => {
                                      let newValueArray = cloneOptionsV2(actualAnswers);
                                      newValueArray[value.index] = event.detail.value
                                      setActualAnswers(newValueArray);
                                      let newIsMatchArray = cloneOptionsV2(answersIsMatch);
                                      newIsMatchArray[value.index] = undefined;
                                      setAnswersIsMatch(newIsMatchArray);
                                    }}
                                    placeholder={(dragAndDrop && actualAnswers.length > 1) ?
                                        undefined
                                        :
                                        (props.skipPlaceholder ? undefined : (props.newQuestionArray ? props.newQuestionArray(testOption) : props.questionArray[value.index]))
                                    }
                                />
                            </div>
                          </div>
                          {props.getFunctionalQuestionPost &&
                          <div key={`sb-${value.index}-03`}>
                            <SpaceBetween direction={"horizontal"} size={"s"}>
                              {!configs.imageOnly &&
                              <TextContent key={`textPost-${value.index}`}>
                                {props.getFunctionalQuestionPost(testOption, value.index)}
                              </TextContent>
                              }
                              {props.getFunctionalImageQuestionPost && props.getFunctionalImageQuestionPost(testOption, value.index, configs)}
                            </SpaceBetween>
                          </div>}
                          <div key={`sb-${value.index}-04`}>
                            {((value.answer && answersIsMatch[value.index])
                                || answersIsMatch[value.index] === "info")
                            &&
                            <StatusIndicator key={`status-${value.index}`} type={answersIsMatch[value.index]}>
                              {(answersIsMatch[value.index] === "info"
                              || (getTotalAttempts() >= configs.attempts && answersIsMatch[value.index] !== "success"))
                                  ? props.getHint(testOption, value.index) : answersIsMatch[value.index]}
                            </StatusIndicator>}
                          </div>
                        </ColumnLayout>
                    ))}
              </SpaceBetween>
              <SpaceBetween>
              {(dragAndDrop && actualAnswers.length > 1) &&
                <div style={{"border-radius": '25px', "border-style":'dotted', margin:'5px 10%'}}
                  onDragEnter={(e) => dragEnterDivV2(e, "Answers", 0, dragOverItemDiv)}
                  onDragEnd={(e) => dropDivSingleV2(e, dragItemDiv, dragOverItemDiv, listDiv, setListDiv, actualAnswers, setActualAnswers, answersIsMatch, setAnswersIsMatch)}
                  key={`baseDiv`}>
                      <div style={{textAlign:'center'}}><h4>{`Answers (${listDiv.length})`}</h4></div>
                      <div style={{width: '100%', display: 'flex', "flex-direction": 'row', "flex-wrap": 'wrap', "justify-content": 'center'}}>
                      {listDiv.map((item, index) => (
                          <div style={{margin:'10px 1%', textAlign:'center', overflow: 'hidden', "white-space": 'nowrap', "display": 'inline-block'}}
                               onDragStart={(e) => dragStartDivV2(e, item, index, dragItemDiv)}
                               onDragEnter={(e) => dragEnterDivV2(e, item, index, dragOverItemDiv)}
                               onDragEnd={(e) => dropDivSingleV2(e, dragItemDiv, dragOverItemDiv, listDiv, setListDiv, listAnswerDiv, setListAnswerDiv, answersIsMatch, setAnswersIsMatch)}
                               id={index}
                               key={index}
                               draggable={
                                 (dragAndDrop && actualAnswers.length > 1) &&
                                 !(getTotalAttempts() >= configs.attempts) &&
                                 !isLoadingTest
                               }>
                               <Button wrapText={false}>{item}</Button>
                          </div>
                      ))}
                      </div>
                </div>}
              </SpaceBetween>

              <SpaceBetween size="s" direction="horizontal">
                <Button variant="primary"
                        loading={isLoadingTest}
                        disabled={
                          (roundState[testIndex] && roundState[testIndex].answerArray && roundState[testIndex].answerArray.length > 0 &&
                              calcMatches(roundState[testIndex].answerArray)
                                  .filter((value, i) => (selectedQuestionOptions.some(opt => opt.index === i)))
                                  .every(isMatch => isMatch === "success"))
                          ||
                          (getTotalAttempts() >= configs.attempts)
                        }
                        onClick={() => {
                          setIsLoadingTest(true);
                          const newAnswerIsMatchArray = calcMatches(actualAnswers);
                          setAnswersIsMatch(newAnswerIsMatchArray);
                          const shouldDoNext = newAnswerIsMatchArray
                              .filter((value, i) => (selectedQuestionOptions.some(opt => opt.index === i)))
                              .every(isMatch => isMatch === "success") || getTotalAttempts() + 1 >= configs.attempts
                          new Promise(resolve =>
                              setTimeout(() => resolve(), 500)).then(() => console.log("await 1/2 sec(s)"))
                              .then(() => {
                                setIsLoadingTest(false);
                                if (shouldDoNext) {
                                  doNext(true)
                                      .then(() => {
                                        if (selectedQuestionOptions.length > 0) {
                                          setTimeout(() => inputReference.current.focus(), 2000);
                                        }
                                      });
                                } else {
                                  const currentRoundState = saveMultiInputIntoState(testIndex, actualAnswers);
                                  setRoundState(currentRoundState);
                                  setTimeout(() => inputReference.current.focus(), 250);
                                }
                              });
                        }}
                        iconName={"check"}
                >Try</Button>
                <Button variant="normal"
                        loading={isLoadingTest}
                        disabled={
                          getTotalAttempts() >= configs.attempts
                        }
                        onClick={() => {
                          setAnswersIsMatch(props.questionArray.map((question) => undefined));
                          // const newActualAnswers = props.questionArray.map((question) => undefined)
                          // setActualAnswers(newActualAnswers);
                        }}
                >Clear</Button>
                <Button variant="normal"
                        loading={isLoadingTest}
                        disabled={
                          getTotalAttempts() >= configs.attempts
                        }
                        onClick={() => {
                          setAnswersIsMatch(props.questionArray.map((question) => ("info")));
                        }}
                >Show</Button>
                <Button variant="primary"
                        loading={isLoadingTest}
                        disabled={testIndex === 0}
                        onClick={() => {
                          setIsLoadingTest(true);
                          const newTestIndex = testIndex - 1;
                          setTestIndex(newTestIndex);
                          loadMultiInputFromRoundState(newTestIndex, roundState);
                          setTimeout(() => {
                            setIsLoadingTest(false);
                            if (selectedQuestionOptions.length > 0 && inputReference && inputReference.current) {
                              inputReference.current.focus();
                            }
                          }, 75);
                        }}
                        iconName={"angle-left"}
                >Back</Button>
                <Button variant="primary"
                        loading={isLoadingTest}
                        onClick={() => {
                          setIsLoadingTest(true);
                          doNext(false)
                              .then(() => {
                                setIsLoadingTest(false);
                                if (selectedQuestionOptions.length > 0) {
                                  setTimeout(() => { if (inputReference.current) inputReference.current.focus(); }, 75);
                                }
                              });
                        }}
                        iconName={"angle-right"}
                >Next</Button>
              </SpaceBetween>
            </SpaceBetween>}
          </SpaceBetween>
        </Container>
      </SpaceBetween>
  );
}