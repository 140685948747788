import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SideNavigation } from "@cloudscape-design/components";

//class PortalNavigation extends React.Component {
//  onFollowHandler(ev) {
//    ev.preventDefault();
//    if (ev.detail.href) {
//      this.props.history.push(ev.detail.href.substring(1));
//    }
//  }
//
//  render() {
//    return (
//        <div>
//          <SideNavigation
//              header={navHeader}
//              items={navItems}
//              activeHref={`#${this.props}`}
//              onFollow={this.onFollowHandler.bind(this)}
//          />
//        </div>
//    );
//  }
//}

const navHeader = {text: 'Strana', href: `#`};
const navItems = [
  {
    text: 'Russian',
    type: 'section',
    defaultExpanded: true,
    items: [
      {type: 'link', href: `#/russian`, text: 'Russian'},
    ]
  },
  {
    text: 'German',
    type: 'section',
    defaultExpanded: true,
    items: [
      {type: 'link', href: `#/german`, text: 'German'}
    ]
  }

]

const defaultOnFollowHandler: SideNavigationProps['onFollow'] = event => {
  // keep the locked href for our demo pages
  event.preventDefault();
};

interface NavigationProps {
  activeHref?: string;
  header?: SideNavigationProps['header'];
  items?: SideNavigationProps['items'];
  onFollowHandler?: SideNavigationProps['onFollow'];
}

export function PortalNavigation({
  activeHref,
  header = navHeader,
  items = navItems,
  onFollowHandler = defaultOnFollowHandler,
}: NavigationProps) {
  return <SideNavigation items={items} header={header} activeHref={activeHref} onFollow={onFollowHandler} />;
}


export default withRouter(PortalNavigation);

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}
