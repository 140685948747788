import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Header from "@cloudscape-design/components/header";
import { TextContent, Table } from "@cloudscape-design/components";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import Alert from "@cloudscape-design/components/alert";

function coalesce(value, defaultValue) {
  return value || defaultValue
}

export function cloneOptionsV2(options) {
  return options.map((value) => (value));
}

export function cloneShuffleArrayV2(input) {
  let cloneInput = input.map(item => item)
  let newCloneShuffledArray = [];
  for (let i=0;i<input.length;i++) {
    if (cloneInput.length == 1) {
      newCloneShuffledArray.push(cloneInput[0]);
      break;
    }
    const randIndex = Math.floor(Math.random() * cloneInput.length);
    newCloneShuffledArray.push(cloneInput[randIndex]);
    cloneInput = cloneInput.filter((item, i) => i !== randIndex);
  }
  return newCloneShuffledArray;
}

export function gpTopicMdControlV2(md) {
  return gpTopicMdControlV2Keyed({ md: md, key: undefined });
}

export function gpTopicMdControlV2Keyed({ md, key }) {
  // remote path youtube iframe
  if (md && md.embed && md.embed.src) {
      const width = md.overrideWidth || md.embed.width || 560;
      const height = md.overrideHeight || md.embed.height || 315;
      return (
          <iframe width={`${width}`} height={`${height}`}
              id={"youtube"}
              key={key}
              src={md.embed.src}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen>
          </iframe>
      );
  }
  // local path
  if (md && md.audio && md.audio.path && md.audio.extension) {
      return (
          <audio key={key} src={require(`${md.audio.path}${md.audio.extension}`)} controls>
          <p>Your browser does not support the audio element.</p>
          </audio>
      );
  }
  // remote path
  if (md && md.audio && md.audio.src) {
      const width = md.overrideWidth || md.audio.width || 315;
      const height = md.overrideHeight || md.audio.height || 160;
      return (
        <iframe
          frameBorder="0" width={width} height={height} src={md.audio.src}>
        </iframe>
      );
  }
  // local path
  if (md && md.img && md.img.path && md.img.extension) {
      const height = md.overrideHeight || md.img.height || 315;
      return (
          <img key={key} height={height} src={require(`../resources/${md.img.path}${md.img.extension}`)} className={`${md.img.class}`} alt={md.img.alt}/>
      );
  }
  // remote path
  if (md && md.img && md.img.src) {
      const height = md.overrideHeight || md.img.height || 315;
      return (
          <img key={key} height={height} src={`${md.img.src}`} className={`${md.img.class}`} alt={md.img.alt}/>
      );
  }
  // remote path
  if (md && md.video && md.video.src) {
      const width = md.overrideWidth || md.video.width || 560;
      const height = md.overrideHeight || md.video.height || 315;
      return (
        <video
          id="picInPicVideo"
          frameBorder="0" width={width} height={height} src={md.video.src} controls>
        </video>
      );
  }
  // none
  return (<div key={key}></div>);
}

/*
  Breadcrumbs for all child routers
  props = { language, title }
*/
export function BreadcrumbsV2(props) {
  return (
    <SpaceBetween>
      <BreadcrumbGroup
        items={[
          { text: props.language, href: `/${props.parentResourceMenu}` },
          { text: props.title, href: "#" },
        ]}
        ariaLabel="Breadcrumbs"
      />
    </SpaceBetween>
  );
}

function EmptyState({title, subtitle, action}) {
  return (
      <Box textAlign="center" color="inherit">
        <Box variant="strong" textAlign="center" color="inherit">{title}</Box>
        <Box variant="p" padding={{bottom: 's'}} color="inherit">{subtitle}</Box>
        {action}
      </Box>
  );
}
export function StaticTableV2({
  tableHeader, actions, headerVariant, headerSpacingSize, items, columnDefinitions, loading, selectionType, selectedItems, setSelectedItems, trackBy, variant, key
  }) {
  // table header component
  const TableHeader = (tableHeader && headerVariant &&
      <SpaceBetween direction="vertical" size={coalesce(headerSpacingSize, "xxxs")}>
        <Header variant={coalesce(headerVariant, "h2")}
          counter={
            selectedItems.length
              ? "(" + selectedItems.length + "/" + items.length + ")"
              : "(" + items.length + ")"
          }
          actions={actions}
        >
          {tableHeader}
        </Header>
      </SpaceBetween>
  );

  return (
      <Table
          key={key}
          header={TableHeader}
          variant={variant}
          ariaLabels={{
            selectionGroupLabel: "Items selection",
            allItemsSelectionLabel: ({ selectedItems }) =>
              `${selectedItems.length} ${
                selectedItems.length === 1 ? "item" : "items"
              } selected`,
            itemSelectionLabel: ({ selectedItems }, item) =>
              item[trackBy]
          }}
          trackBy={trackBy}
          items={items}
          columnDefinitions={columnDefinitions}
          loading={loading}
          loadingText="Loading resources"
          sortingDisabled
          wrapLines={false}
          resizableColumns={false}
          selectionType={selectionType}
          onSelectionChange={({ detail }) => {
            setSelectedItems(detail.selectedItems)
          }}
          selectedItems={selectedItems}
          empty={
            <EmptyState
                title="No items"
                action={<Button disabled onClick={(event) => console.log(`No items to refresh`)}>Refresh</Button>}
            />
          }
      />
  );
}

export function uuidV4() {
  const newUUID = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
  return newUUID;
}

export function DismissibleError(props) {
  return (
    <Alert
      onDismiss={() => props.setError(undefined)}
      dismissible={props.dismissible}
      type={"error"}
      statusIconAriaLabel={"Error"}
      header={props.header}
    ><TextContent>{props.error}</TextContent>
    </Alert>
  );
}

export function DismissibleInfo(props) {
  return (
    <Alert
      onDismiss={() => props.setInfo(undefined)}
      dismissible={props.dismissible}
      type={"info"}
      statusIconAriaLabel={"Info"}
      header={props.header}
    >{props.info}
    </Alert>
  );
}

export function overrideTopicMd(rawTopicMd, overrideHeight, overrideWidth) {
  let tmpOverrideTopicMd = {};
  Object.keys(rawTopicMd).forEach(key => tmpOverrideTopicMd[key] = rawTopicMd[key]);
  tmpOverrideTopicMd.overrideHeight = overrideHeight;
  tmpOverrideTopicMd.overrideWidth = overrideWidth;
  return tmpOverrideTopicMd;
}