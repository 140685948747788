import {useEffect, useRef, useState} from 'react';
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import {Select, StatusIndicator, TextContent} from "@cloudscape-design/components";
import ProgressBar from "@cloudscape-design/components/progress-bar";

import { cloneOptionsV2, cloneShuffleArrayV2, gpTopicMdControlV2Keyed } from '../common/common'
import { RoundSettingsV2 } from './round-settings'
import { DisplaySummaryV2 } from './display-summary'
import { dropDivSingleV2, dragStartDivV2, dragEnterDivV2, calculateTotalAttemptsV2 } from './question-common';

export function SingleQuestionMultiOptionsPanelV2(props) {
  const inputReference = useRef(null);

  const dragItemDiv = useRef();
  const dragOverItemDiv = useRef();
  const [listAnswerDiv, setListAnswerDiv] = useState([]);
  const [listDiv, setListDiv] = useState([]);

  const startingDoShuffle = props.settings && props.settings.doShuffle ? props.settings.doShuffle : false;
  const startingImgHeight = props.settings && props.settings.imgHeight ? props.settings.imgHeight : 30;
  const startingAttempts = props.settings && props.settings.attempts ? props.settings.attempts : 3;
  const maxQuestions = props.settings && props.settings.maxQuestions
    && (props.settings.maxQuestions > 0 && props.settings.maxQuestions < props.options.length) ?
    props.settings.maxQuestions : props.options.length;
  const canDragAndDrop = props.settings && props.settings.dragAndDrop ? props.settings.dragAndDrop : false;
  const canDropDown = props.settings && props.settings.dropDown ? props.settings.dropDown : false;
  const lowerCaseEnabled = false;

  // displayable state attributes
  const [nonBlanksPre, setNonBlanksPre] = useState([]);
  const [nonBlanksPost, setNonBlanksPost] = useState([]);
  const [actualAnswers, setActualAnswers] = useState([]);
  const [imagedAnswers, setImagedAnswers] = useState([]);
  const [dropDownAnswers, setDropDownAnswers] = useState([]);
  const [expectedAnswers, setExpectedAnswers] = useState([]);
  const [answersIsMatch, setAnswersIsMatch] = useState([]);

  // test state attributes
  const newTestOptions = cloneOptionsV2(props.options);

  const newRandOptionIndex = startingDoShuffle ? Math.floor(Math.random() * newTestOptions.length) : 0;
  const [testOptions, setTestOptions] = useState(newTestOptions.filter((v, i) => i !== newRandOptionIndex));
  const [testIndex, setTestIndex] = useState(0);
  const [randOptionIndex, setRandOptionIndex] = useState(newRandOptionIndex);
  const [testOption, setTestOption] = useState(newTestOptions[newRandOptionIndex]);
  const [isLoadingTest, setIsLoadingTest] = useState(false);
  const [testRound, setTestRound] = useState(1);

  // round state attributes
  const [roundRandOptionIndexes, setRoundRandOptionIndexes] = useState([newRandOptionIndex]);
  const [roundState, setRoundState] = useState([]);
  const [displaySummary, setDisplaySummary] = useState(false);
  const [summaryTestRound, setSummaryTestRound] = useState(undefined);

  // yet to be defined
  const [doShuffle, setDoShuffle] = useState(startingDoShuffle);
  const [dragAndDrop, setDragAndDrop ] = useState(canDragAndDrop);
  const [dropDown, setDropDown ] = useState(canDropDown);
  const [configs, setConfigs] =  useState({
    height: startingImgHeight,
    attempts: startingAttempts,
    shuffle: startingDoShuffle,
    maxQuestions: maxQuestions,
    dropDown: canDropDown,
    dragAndDrop: canDragAndDrop,
  });
  const [selectedHeightOption, setSelectedHeightOption] =  useState({ label: `${startingImgHeight}`, value: startingImgHeight });
  const [selectedAttemptsOption, setSelectedAttemptsOption] =  useState({ label: `${startingAttempts}`, value: startingAttempts });
  const [selectedMaxQuestionsOption, setSelectedMaxQuestionsOption] =  useState({
    label: `${maxQuestions}`, value: maxQuestions
  });
  const [testConfigModalVisible, setTestConfigModalVisible] = useState(true);

  const setNextTestInputState = (nextQuestion, currentRoundState) => {
    let optionOpen = undefined;
    let nonBlankOpen = 0;
    let newNonBlanksPre = [];
    let newNonBlanksPost = [];
    let newExpectedAnswers = [];
    let newImagedAnswers = [];
    let newDropDownAnswers = [];

    // deserialize question(s) configuration(s)
    for (let j = 0; j < nextQuestion.length; j++) {
      if (!optionOpen && nextQuestion.substr(j, 1) === '{') {
        optionOpen = j;
        // handling tail text from previous question
        const nonBlankText = nextQuestion.substr(nonBlankOpen,  j - nonBlankOpen);
        newNonBlanksPre = [...newNonBlanksPre, nonBlankText]
        newNonBlanksPost = [...newNonBlanksPost, undefined]
        nonBlankOpen = undefined;
      }
      if (optionOpen && nextQuestion.substr(j, 1) === '}') {
        const expectedAnswerText = nextQuestion.substr(optionOpen,  j - optionOpen + 1);
        const expectedAnswerJson = JSON.parse(expectedAnswerText);
        newExpectedAnswers = [...newExpectedAnswers, expectedAnswerJson.answer]
        newImagedAnswers = [...newImagedAnswers,
          expectedAnswerJson.isImage ?
            props.imgMd(expectedAnswerJson.imageFileName || expectedAnswerJson.answer, expectedAnswerJson.imageSrc, configs)
            :
            undefined
        ]
        newDropDownAnswers = [...newDropDownAnswers, cloneShuffleArrayV2(expectedAnswerJson.dropDownOptions || [])]
        nonBlankOpen = j + 1;
        optionOpen = undefined;
      }
    }

    // handling tail text from last question
    if (nonBlankOpen < nextQuestion.length - 1) {
      const nonBlankText = nextQuestion.substr(nonBlankOpen,   nextQuestion.length - nonBlankOpen);
      newNonBlanksPost[newNonBlanksPost.length - 1] = nonBlankText;
    }

    // set new input state
    setExpectedAnswers(newExpectedAnswers);
    setImagedAnswers(newImagedAnswers);
    setDropDownAnswers(newDropDownAnswers);
    setActualAnswers(newExpectedAnswers.map(answer => undefined));
    setAnswersIsMatch(newExpectedAnswers.map(answer => undefined));
    setNonBlanksPre(newNonBlanksPre);
    setNonBlanksPost(newNonBlanksPost);

    // state in case is drag and drop
    setListAnswerDiv([]);
    const tmpListDiv = cloneShuffleArrayV2(newExpectedAnswers);
    setListDiv(tmpListDiv);

    // set new input into round state
    let newRoundState = [...currentRoundState,
      {
        expectedAnswers: newExpectedAnswers,
        imagedAnswers: newImagedAnswers,
        dropDownAnswers: cloneShuffleArrayV2(newDropDownAnswers),
        actualAnswers: newExpectedAnswers.map(answer => undefined),
        answersIsMatch: newExpectedAnswers.map(answer => undefined),
        nonBlanksPre: newNonBlanksPre,
        nonBlanksPost: newNonBlanksPost,
        listDiv: tmpListDiv,
        attempts: 0,
      }
    ]
    setRoundState(newRoundState);
  }

  function loadTestInputFromRoundState(index, currentRoundState) {
    const oldRoundState = currentRoundState[index];
    setExpectedAnswers(oldRoundState.expectedAnswers);
    setImagedAnswers(oldRoundState.imagedAnswers);
    setDropDownAnswers(oldRoundState.dropDownAnswers);
    setActualAnswers(oldRoundState.actualAnswers);
    setAnswersIsMatch(oldRoundState.answersIsMatch);
    setNonBlanksPre(oldRoundState.nonBlanksPre);
    setNonBlanksPost(oldRoundState.nonBlanksPost);
    setListDiv(oldRoundState.listDiv);
  }

  function saveTestInputIntoState(index, newActualAnswers) {
    let newRoundState = [...roundState];
    let oldInputState = newRoundState[index]
    const newAnswersIsMatch = [...newActualAnswers
        .map((value, i) => (newActualAnswers[i] === toLowerCase(expectedAnswers[i]) ? "success" : "error"))];
    newRoundState[index] = {
      expectedAnswers: expectedAnswers,
      imagedAnswers: imagedAnswers,
      dropDownAnswers: dropDownAnswers,
      actualAnswers: [...newActualAnswers],
      answersIsMatch: newAnswersIsMatch,
      nonBlanksPre: nonBlanksPre,
      nonBlanksPost: nonBlanksPost,
      listDiv: listDiv,
      attempts: newAnswersIsMatch.every(isMatch => isMatch === "success") ? configs.attempts : oldInputState.attempts + 1
    };
    return newRoundState;
  }

  function calcRoundRandOptionIndexes() {
    let tmpAvailableIndexes = newTestOptions.map((answer, i) => i);
    let tmpRoundRandOptionIndex = [];
    do {
      const newTmpRoundRandOptionIndex = configs.shuffle ? Math.floor(Math.random() * tmpAvailableIndexes.length) : 0;
      tmpRoundRandOptionIndex.push(tmpAvailableIndexes[newTmpRoundRandOptionIndex]);
      const totalAvailableIndexes = tmpAvailableIndexes.length;
      tmpAvailableIndexes = [
        ...tmpAvailableIndexes.slice(0, newTmpRoundRandOptionIndex),
        ...tmpAvailableIndexes.slice(newTmpRoundRandOptionIndex + 1, totalAvailableIndexes)
      ];
    } while (tmpAvailableIndexes.length >= 1);
    return tmpRoundRandOptionIndex;
  }

  useEffect(() => {
    setNextTestInputState(testOption, []);
    setTimeout(() => { if (inputReference.current) inputReference.current.focus(); }, 1000);
//    if (props.renderSplitPanelItems) {
//      props.renderSplitPanelItems({});
//    }
  }, [])

  const doNext = async (doStoreCurrentState) => {
    let newTestIndex = testIndex + 1;
    // save state before moving forward
    let currentRoundState = doStoreCurrentState ? saveTestInputIntoState(testIndex, actualAnswers) : roundState;
    setRoundState(currentRoundState);
    if (newTestIndex < currentRoundState.length) {
      setRandOptionIndex(roundRandOptionIndexes[newTestIndex]);
      loadTestInputFromRoundState(newTestIndex, currentRoundState);
    } else {
      // removes the question from the options or restore all the questions in a new round
      const isLastTest = (selectedMaxQuestionsOption.value < props.options.length) ?
        testOptions.length <= (props.options.length - selectedMaxQuestionsOption.value) : testOptions.length === 0;
      if (isLastTest) {
        setDisplaySummary(true);
        setSummaryTestRound(currentRoundState);
      }
      const newTestOptions = isLastTest ? cloneOptionsV2(props.options) : testOptions;
      // calculates another random question index
      const newRandOptionIndex = configs.shuffle ? Math.floor(Math.random() * newTestOptions.length) : 0;
      setRandOptionIndex(newRandOptionIndex);
      // sets new test option
      const newTestOption = newTestOptions[newRandOptionIndex];
      setTestOption(newTestOption);

      // voids answer(s) match array
      // voids answer(s) array
      if (isLastTest) {
        setRoundRandOptionIndexes([newRandOptionIndex]);
        currentRoundState = [];
        setRoundState([]);
        newTestIndex = 0;
      }
      setNextTestInputState(newTestOption, currentRoundState);
      setTestOptions(newTestOptions.filter((value, i) => i !== newRandOptionIndex));
    }
    setTestIndex(newTestIndex);
  };

  function toLowerCase(answer) {
    return lowerCaseEnabled ? answer.toLowerCase() : answer;
  }

  function getTotalQuestions() {
    return (selectedMaxQuestionsOption.value < props.options.length ? selectedMaxQuestionsOption.value : props.options.length);
  }

  function getTotalAttempts() {
    return calculateTotalAttemptsV2(roundState, testIndex);
  }

  return (
      <SpaceBetween size="m">
        <Header variant="h1">
          <SpaceBetween direction={"horizontal"} size={"s"}>
            {props.header}
            {/*<Button variant="inline-icon" disabled
                    onClick={() => {
                      setTestConfigModalVisible(true);
                    }}
                    iconName={"settings"}
            />*/}
          </SpaceBetween>
        </Header>
        <Container>
          <RoundSettingsV2
               onExit={props.onExit}
               title={props.title}
               configs={configs}
               setConfigs={setConfigs}
               options={props.options}
               questionArray={props.questionArray}
               testConfigModalVisible={testConfigModalVisible}
               setTestConfigModalVisible={setTestConfigModalVisible}
               doShuffle={doShuffle}
               setDoShuffle={setDoShuffle}
               imageOnly={false}
               setImageOnly={(input) => { /*does nothing*/ }}
               dropDown={dropDown}
               setDropDown={setDropDown}
               dragAndDrop={dragAndDrop}
               setDragAndDrop={setDragAndDrop}
               cantDropDown={!canDropDown}
               cantDragAndDrop={!canDragAndDrop}
               selectedMaxQuestionsOption={selectedMaxQuestionsOption}
               setSelectedMaxQuestionsOption={setSelectedMaxQuestionsOption}
               selectedHeightOption={selectedHeightOption}
               setSelectedHeightOption={setSelectedHeightOption}
               selectedAttemptsOption={selectedAttemptsOption}
               setSelectedAttemptsOption={setSelectedAttemptsOption}
          />
          <SpaceBetween direction={"vertical"} size={"l"}>
            {displaySummary ?
            <DisplaySummaryV2
              summaryTestRound={summaryTestRound}
              getTotalQuestions={getTotalQuestions}
              testRound={testRound}
              setTestRound={setTestRound}
              setDisplaySummary={setDisplaySummary}
              inputReference={inputReference}
              setTestConfigModalVisible={setTestConfigModalVisible}
              isSingle={true}
            />
            :
            <SpaceBetween direction={"vertical"} size={"l"}>
            <SpaceBetween direction="horizontal" size="s">
              <h2>{`${props.getQuestion ? props.getQuestion(testOption) : 'Как по-русский'}`}</h2>
              <h4>{` [Round: ${testRound}]`}</h4>
              <ProgressBar
                value={(testIndex + 1)/getTotalQuestions()*100}
                additionalInfo={`[Question: ${testIndex + 1}/${getTotalQuestions()}]`}
              />
              <ProgressBar
                value={getTotalAttempts()/configs.attempts*100}
                additionalInfo={`[Attempt(s): ${getTotalAttempts()}/${configs.attempts}]`}
              />
            </SpaceBetween>
            {props.topicMdControl()}
            <SpaceBetween size="s" direction="horizontal" alignItems="center">
              {actualAnswers
                  .map((answer, i) => (
                      <SpaceBetween key={`sb-${i}`} direction={"horizontal"} size={"xxs"} alignItems="center">
                        <TextContent key={`text-pre-${i}`}>{nonBlanksPre[i]}</TextContent>
                        {(dropDown && dropDownAnswers && dropDownAnswers.length > 0 && dropDownAnswers[i].length > 0) && <Select
                          key={`select-${i}`}
                          ref={i === 0 ? inputReference : undefined}
                          selectedOption={ answer ? { label: answer, value: answer } : undefined }
                          disabled={
                            getTotalAttempts() >= configs.attempts
                            || roundState[testIndex] && roundState[testIndex].actualAnswers && roundState[testIndex].actualAnswers[i] === toLowerCase(expectedAnswers[i])
                            || isLoadingTest || (dragAndDrop && actualAnswers.length > 1)
                          }
                          onChange={({ detail }) => {
                              let newValueArray = cloneOptionsV2(actualAnswers);
                              newValueArray[i] = detail.selectedOption.value
                              setActualAnswers(newValueArray);
                              let newIsMatchArray = cloneOptionsV2(answersIsMatch);
                              newIsMatchArray[i] = undefined;
                              setAnswersIsMatch(newIsMatchArray);
                          }}
                          options={dropDownAnswers[i].map(answer => ({ label: answer, value: answer }))}
                        />}
                        {!(dropDown && dropDownAnswers && dropDownAnswers.length > 0 && dropDownAnswers[i].length > 0) && <div
                           onDragStart={(e) => dragStartDivV2(e, answer, i+1000, dragItemDiv)}
                           onDragEnter={(e) => dragEnterDivV2(e, answer, i+1000, dragOverItemDiv)}
                           onDragEnd={(e) => dropDivSingleV2(e, dragItemDiv, dragOverItemDiv, listDiv, setListDiv, actualAnswers, setActualAnswers, answersIsMatch, setAnswersIsMatch)}
                           draggable={
                             (dragAndDrop && actualAnswers.length > 1) &&
                             !(getTotalAttempts() >= configs.attempts) &&
                             !isLoadingTest
                           }>
                        <Input
                            key={`input-${i}`}
                            ref={i === 0 ? inputReference : undefined}
                            value={answer}
                            disabled={
                              getTotalAttempts() >= configs.attempts
                              || roundState[testIndex] && roundState[testIndex].actualAnswers && roundState[testIndex].actualAnswers[i] === toLowerCase(expectedAnswers[i])
                              || isLoadingTest || (dragAndDrop && actualAnswers.length > 1)
                            }
                            onChange={(event) => {
                              let newValueArray = cloneOptionsV2(actualAnswers);
                              newValueArray[i] = event.detail.value
                              setActualAnswers(newValueArray);
                              let newIsMatchArray = cloneOptionsV2(answersIsMatch);
                              newIsMatchArray[i] = undefined;
                              setAnswersIsMatch(newIsMatchArray);
                            }}
                        />
                        </div>}
                        {((answer && answersIsMatch[i]) || answersIsMatch[i] === "info") &&
                        <StatusIndicator key={`status-${i}`} type={answersIsMatch[i]}>
                          {(answersIsMatch[i] === "info" || (getTotalAttempts() >= configs.attempts && answersIsMatch[i] !== "success")) ? toLowerCase(expectedAnswers[i]) : undefined}
                        </StatusIndicator>}
                        {imagedAnswers[i] && gpTopicMdControlV2Keyed({ md: imagedAnswers[i], key: `image-${i}` })}
                        {/*imagedAnswers[i] && <img key={`image-${i}`} src={`${imagedAnswers[i]}`} height={configs.height} className="question-img" alt="logo"/>*/}
                        {nonBlanksPost[i] && <TextContent key={`text-post-${i}`}>{nonBlanksPost[i]}</TextContent>}
                      </SpaceBetween>
                  ))}
            </SpaceBetween>
            <SpaceBetween>
              {(dragAndDrop && actualAnswers.length > 1) &&
                <div style={{"border-radius": '25px', "border-style":'dotted', margin:'5px 10%'}}
                  onDragEnter={(e) => dragEnterDivV2(e, "Answers", 0, dragOverItemDiv)}
                  onDragEnd={(e) => dropDivSingleV2(e, dragItemDiv, dragOverItemDiv, listDiv, setListDiv, actualAnswers, setActualAnswers, answersIsMatch, setAnswersIsMatch)}
                  key={`baseDiv`}>
                      <div style={{textAlign:'center'}}><h4>{`Answers (${listDiv.length})`}</h4></div>
                      <div style={{width: '100%', display: 'flex', "flex-direction": 'row', "flex-wrap": 'wrap', "justify-content": 'center'}}>
                      {listDiv.map((item, index) => (
                          <div style={{margin:'10px 1%', textAlign:'center', overflow: 'hidden', "white-space": 'nowrap', "display": 'inline-block'}}
                               onDragStart={(e) => dragStartDivV2(e, item, index, dragItemDiv)}
                               onDragEnter={(e) => dragEnterDivV2(e, item, index, dragOverItemDiv)}
                               onDragEnd={(e) => dropDivSingleV2(e, dragItemDiv, dragOverItemDiv, listDiv, setListDiv, listAnswerDiv, setListAnswerDiv, answersIsMatch, setAnswersIsMatch)}
                               id={index}
                               key={index}
                               draggable={
                                 (dragAndDrop && actualAnswers.length > 1) &&
                                 !(getTotalAttempts() >= configs.attempts) &&
                                 !isLoadingTest
                               }>
                               <Button wrapText={false}>{item}</Button>
                          </div>
                      ))}
                      </div>
                </div>}
            </SpaceBetween>
            <SpaceBetween size="s" direction="horizontal">
              <Button variant="primary"
                      loading={isLoadingTest}
                      disabled={
                        (roundState[testIndex] && !roundState[testIndex].actualAnswers
                            .some((value, i) => (value !== toLowerCase(expectedAnswers[i]))))
                            ||
                        (getTotalAttempts() >= configs.attempts)
                      }
                      onClick={() => {
                        setIsLoadingTest(true);
                        setAnswersIsMatch(expectedAnswers.map(answer => undefined));
                        const newAnswerIsMatch = actualAnswers
                            .map((value, i) => (actualAnswers[i] === toLowerCase(expectedAnswers[i]) ? "success" : "error"));
                        setAnswersIsMatch(newAnswerIsMatch);
                        new Promise(resolve =>
                            setTimeout(() => resolve(), 500)).then(() => console.log("await 1/2 sec(s)"))
                            .then(() => {
                              setIsLoadingTest(false);
                              if (newAnswerIsMatch.every(isMatch => isMatch === "success") || getTotalAttempts() + 1 >= configs.attempts) {
                                doNext(true);
                              } else {
                                setRoundState(saveTestInputIntoState(testIndex, actualAnswers));
                              }
                            })
                            .then(() => setTimeout(() => { if (inputReference.current) inputReference.current.focus(); }, 250));
                      }}
                      iconName={"check"}
              >Try</Button>
              <Button variant="normal"
                      loading={isLoadingTest}
                      disabled={
                        getTotalAttempts() >= configs.attempts
                      }
                      onClick={() => {
                        setAnswersIsMatch(actualAnswers.map((question) => undefined));
                        // const newActualAnswers = actualAnswers.map((question) => undefined);
                        // setActualAnswers(newActualAnswers);
                      }}
              >Clear</Button>
              <Button variant="normal"
                      loading={isLoadingTest}
                      disabled={
                        getTotalAttempts() >= configs.attempts
                      }
                      onClick={() => {
                        setAnswersIsMatch(actualAnswers.map((question) => ("info")));
                      }}
              >Show</Button>
              <Button variant="primary"
                      loading={isLoadingTest}
                      disabled={testIndex === 0}
                      onClick={() => {
                        setIsLoadingTest(true);
                        const newTestIndex = testIndex - 1;
                        setTestIndex(newTestIndex);
                        loadTestInputFromRoundState(newTestIndex, roundState);
                        new Promise(resolve =>
                            setTimeout(() => resolve(), 75)).then(() => console.log("await 0.075 sec(s)"))
                            .then(() => {
                              setIsLoadingTest(false);
                            })
                            .then(() => setTimeout(() => { if (inputReference.current) inputReference.current.focus(); }, 75));
                      }}
                      iconName={"angle-left"}
              >Back</Button>
              <Button variant="primary"
                      loading={isLoadingTest}
                      onClick={() => {
                        setIsLoadingTest(true);
                        setAnswersIsMatch(expectedAnswers.map(answer => undefined));
                        new Promise(resolve =>
                            setTimeout(() => resolve(), 75)).then(() => console.log("await 0.075 sec(s)"))
                            .then(() => {
                              setIsLoadingTest(false);
                              doNext(false);
                            })
                            .then(() => setTimeout(() => { if (inputReference.current) inputReference.current.focus(); }, 75));
                      }}
                      iconName={"angle-right"}
              >Next</Button>
            </SpaceBetween>
            </SpaceBetween>
            }
          </SpaceBetween>
        </Container>
      </SpaceBetween>
  );
}
