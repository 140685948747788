import React, {useState} from 'react';
import {AppLayout, SplitPanel} from '@cloudscape-design/components';
import './App.css';

export default function PortalLayout(props) {

  const [splitPanelPreferencesPosition, setSplitPanelPreferencesPosition] = useState({position: "side"});
  const [splitPanelSize, setSplitPanelSize] = useState(window.innerHeight / 2.75);

  return (
      <div className="App">
        <AppLayout
            className='awsui'
            toolsHide={true}
            navigationOpen={props.navigationOpen}
            onNavigationChange={({detail}) => props.setNavigationOpen(detail.open)}
            splitPanelPreferences={splitPanelPreferencesPosition}
            onSplitPanelPreferencesChange={(preference) => setSplitPanelPreferencesPosition(preference.position)}
            onSplitPanelResize={(event) => setSplitPanelSize(event.detail.size)}
            splitPanelOpen={props.splitPanelToggle}
            onSplitPanelToggle={({detail}) => props.setSplitPanelToggle(detail.open) }
            splitPanelSize={splitPanelSize}
            contentType={"default"}
            notifications={props.notifications}
            splitPanel={
              <SplitPanel
                  i18nStrings={{
                    preferencesTitle: "Preferences",
                    preferencesPositionLabel: "Split panel position",
                    preferencesPositionDescription:
                        "Choose the default split panel position for the service.",
                    preferencesPositionSide: "Side",
                    preferencesPositionBottom: "Bottom",
                    preferencesConfirm: "Confirm",
                    preferencesCancel: "Cancel",
                    closeButtonLabel: "Close",
                    openButtonLabel: "Open",
                    sliderLabel: "Slider"
                  }}
                  hidePreferencesButton={false}
                  header={"Selection Info"}
              >
                <div>
                  {props.splitPanelItems}
                </div>
              </SplitPanel>
              }
            navigation={props.navigation}
            breadcrumbs={props.breadcrumbs}
            content={props.content}
        />
      </div>
  );
}
