import React, {useEffect, useState} from 'react';
import PortalContent from './portal-content';
import {
  Container, Header, SpaceBetween, ContentLayout, TextContent, Form, FormField, Button, Input, Badge, Box, Link
  } from "@cloudscape-design/components";

import {getAuthCookie, getUser, setAuthCookie, setPrefCookie} from './cookies/cookies';

export const navHeaderV2 = {text: 'Menu', href: '#/'};
export const navItemsV2 = (expands) => {
  let items = [
    {type: 'link', href: `#/`, text: 'Home'}
  ];

  if (expands[0]) {
    items.push(
        {
          type: 'section',
          text: 'German',
          defaultExpanded: false, //expands[0],
          items: [
            { type: 'link', href: "#/deutsch", text: "Deutsch", info: <Badge color="grey">demo</Badge> },
          ]
        }
    );
  }

  if (expands[1]) {
    items.push(
        {
          type: 'section',
          text: 'Italian',
          defaultExpanded: false, //expands[1],
          items: [
            { type: 'link', href: "#/italiano", text: "Italiano", info: <Badge color="grey">demo</Badge> },
          ]
        }
    );
  }

  if (expands[2]) {
    items.push(
        {
          type: 'section',
          text: 'Russian',
          defaultExpanded: false, //expands[2],
          items: [
            { type: 'link', href: "#/russian", text: "Русский Язык", info: <Badge color="grey">demo</Badge> },
          ]
        }
    );
  }

  items.push(
      {
        type: 'section',
        text: 'Assignments',
        items: [
          { type: 'link', href: "#/onlineassignments", text: "Online Assignments", info: <Badge color="green">new</Badge> },
        ]
      }
  );

  items.push(
      {
        type: 'section',
        text: 'Editors',
        items: [
          { type: 'link', href: "#/editor", text: "Lesson Editor", info: <Badge color="green">new</Badge> },
          // { type: 'link', href: "#/lessoneditor", text: "Lesson Editor" },
          // { type: 'link', href: "#/crosswordeditor", text: "Crossword Editor" },
        ]
      }
  );

  return items;
}

/*
  Landing Window
  https://www.w3schools.com/js/js_cookies.asp
  https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
*/
function HomeV2 (props) {
  const [loggingIn, setLoggingIn] = useState(false);
  const [user, setUser] = useState(undefined);
  const [pwd, setPwd] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);

  useEffect(() => {
    props.routerProps.resetSplitPanel();
    if(getAuthCookie()) {
      const tmpUser = getUser();
      props.routerProps.setNavigationItems(
        navItemsV2(props.expectedUList.find(eu => eu.user === tmpUser).expands));
      props.routerProps.setMenuOpen(true);
    } else {
      props.routerProps.setNavigationItems([]);
    }
  }, []);

  return (
      <div>{
        getAuthCookie() ?
            <ContentLayout
              header={<Header><h4>{`Logged in as ${getUser()}`}</h4></Header>}
            >
            <Container
              media={{
                content: (<img src={require("./Flag_Map_of_World_2022.png")} alt="Flag_Map_of_World"/>),
                position: "side",
                width: "33%"
              }}
            >
            <SpaceBetween direction={"vertical"} size="s">
              <SpaceBetween direction="vertical" size="xxs">
                <Box variant="h2">
                  <Link fontSize="heading-m" href="#">
                    Welcome!
                  </Link>
                </Box>
                <Box fontSize="body-s">Strana</Box>
              </SpaceBetween>
              <Box variant="p">
                 Are you a teacher or a student craving for a self service online tool
                 that helps you quickly run exercises and compose your own evaluation assignments?
                 Then this is the right place to be at.
              </Box>
              <Box variant="p">
                Start browsing demos or online assignments in the Menu...
              </Box>
              {(false) &&
              <div>
                <SpaceBetween direction="vertical" size="xxs">
                  <Box fontSize="body-s">Start at</Box>
                  <Box fontWeight="bold">$0.0/month</Box>
                </SpaceBetween>
                <Button>Shop now</Button>
              </div>}
            </SpaceBetween>
            </Container>
            </ContentLayout>
            :
            <ContentLayout>
              <form onSubmit={e => e.preventDefault()}>
                <Form
                    actions={
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            variant="primary"
                            loading={loggingIn}
                            onClick={() => {
                              setLoggingIn(true);
                              setTimeout(() => {
                                  if (props.expectedUList.some(eu => eu.user === user && eu.pwd === pwd)) {
                                    const eu = props.expectedUList.find(eu => eu.user === user && eu.pwd === pwd);
                                    const authUser = { name: eu.name, user: eu.user, email: eu.email };
                                    setAuthCookie(authUser, 120);
                                    setPrefCookie({ mode: 'dark'} , 120);
                                    props.routerProps.setAuthUser(authUser);
                                    setUser(undefined);
                                    setPwd(undefined);
                                    props.routerProps.setNavigationItems(
                                      navItemsV2(props.expectedUList.find(eu => eu.user === user).expands));
                                    props.routerProps.setMenuOpen(true);
                                  } else {
                                    setErrorMessage("403 Not Authorized");
                                  }
                                  setLoggingIn(false);
                              }, 1000);
                            }}
                        >
                          Submit
                        </Button>
                        <Button formAction="none" variant="link">
                          Cancel
                        </Button>
                      </SpaceBetween>
                    }
                    header={
                      <Header variant="h2">
                        <SpaceBetween>
                          <div>Welcome to Strana</div>
                        </SpaceBetween>
                      </Header>}
                >
                  <Container
                      header={<Header variant="h2">Session sign-in</Header>}
                  >
                    <SpaceBetween direction="vertical" size="l">
                      <FormField label="User Id">
                        <Input
                            value={user}
                            disabled={loggingIn}
                            onChange={(event) => {
                              setUser(event.detail.value);
                            }}
                        />
                      </FormField>
                      <FormField label="Password">
                        <Input
                            value={pwd}
                            invalid={errorMessage}
                            disabled={loggingIn}
                            type={"password"}
                            onChange={(event) => {
                              setErrorMessage(undefined);
                              setPwd(event.detail.value);
                            }}
                        />
                      </FormField>
                    </SpaceBetween>
                  </Container>
                </Form>
              </form>
            </ContentLayout>
      }
      </div>)
};

function HomeV1(props) {

  useEffect(() => {
    props.setSplitPanelItems(<Container><Box variant="p">Strana - German</Box></Container>);
    props.setBreadcrumbs(<div/>)
  }, []);

  return (
      <SpaceBetween direction={"vertical"} size={"l"}>
        <PortalContent
            header={<Header>Strana - Russian</Header>}
            content={
              <SpaceBetween direction={"horizontal"} size={"l"}>
                <div>
                <p>Welcome to Strana landing page!</p>
                  <div>
                    Strana is part of the Language Learning initiative
                  </div>
                </div>
              </SpaceBetween>
            }
        />
      </SpaceBetween>
  );
}

export default HomeV2;
