import React, {useEffect, useState} from 'react';
// Apparently the clients gets a cached instance and thus the service could return old cached object versions
// thus instantiating and destroying clients right before its call
//import * as s3Client from "aws-sdk/clients/s3";
//import * as stsClient from "aws-sdk/clients/sts";
import FileUpload from "@cloudscape-design/components/file-upload";
import FormField from "@cloudscape-design/components/form-field";

import { Header, SpaceBetween, Link } from "@cloudscape-design/components";

import { cloneShuffleArrayV2 } from '../common/common'

export function calculateTotalAttemptsV2(roundState, testIndex) {
  return roundState[testIndex] && roundState[testIndex].attempts ? roundState[testIndex].attempts : 0;
}

function toLowerCase(settings, answer) {
  return settings.lowerCaseEnabled ? answer.toLowerCase() : answer;
}

export function canDisableWhenInteractiveMode(settings, roundState, testIndex, k, i, expectedAnswers) {
  return (
    settings.interactiveMode
    && roundState[testIndex]
    && roundState[testIndex].actualAnswers
    && roundState[testIndex].actualAnswers[k][i] === toLowerCase(settings, expectedAnswers[k][i])
  );
}

export function showStatusIndicator(settings, isReplay, showAnswers, roundState, testIndex, k, i, answersIsMatchKI, expectedAnswers) {
  return (
    (!settings.interactiveMode && (isReplay || answersIsMatchKI === "info" || showAnswers))
      ||
    (settings.interactiveMode && (answersIsMatchKI !== undefined || calculateTotalAttemptsV2(roundState, testIndex) >= settings.attempts))
  )
  && expectedAnswers[k].length > 0 && expectedAnswers[k][i]
}

export function typeOfStatusIndicator(showAnswers, answersIsMatchKI) {
  return showAnswers ? "info" : (answersIsMatchKI ? answersIsMatchKI : "info")
}

export function answerOfStatusIndicator(settings, roundState, testIndex, k, i, answersIsMatchKI, expectedAnswers) {
  return settings.interactiveMode ?
    (
      calculateTotalAttemptsV2(roundState, testIndex) >= settings.attempts ?
        toLowerCase(settings, expectedAnswers[k][i])
          :
        answersIsMatchKI
    )
      :
    toLowerCase(settings, expectedAnswers[k][i])
}

export function gpImgPathV2(imgPath, imgName, imgExt, imgSrc) {
  if (imgSrc) return imgSrc;
  return imgPath && imgName ? require(`../resources/${imgPath}/${imgName}${imgExt || ""}`) : ""
}

export function gpImgMdV2(imgPath, imgName, imgExt, imgSrc, configs) {
  if (imgSrc) return          { img: { src: imgSrc, height: configs.height, class: "question-img", alt: "logo" } };
  const safePath = imgPath ? `${imgPath}/` : "";
  const safeExt = imgExt ? imgExt : "";
  return { img: { path: `${safePath}${imgName}`, extension: safeExt, height: configs.height, class: "question-img", alt: "logo" } }
}

function gpSetNextTestSingleInputState(nextQuestion, imgMd, configs) {
    let optionOpen = undefined;
    let nonBlankOpen = 0;
    let newNonBlanksPre = [];
    let newNonBlanksPost = [];
    let newExpectedAnswers = [];
    let newExpectedAttributes = [];
    let newImagedAnswers = [];
    let newDropDownAnswers = [];

    // deserialize question(s) configuration(s)
    for (let j = 0; j < nextQuestion.length; j++) {
      if (!optionOpen && nextQuestion.substr(j, 1) === '{') {
        optionOpen = j;
        // handling tail text from previous question
        const nonBlankText = nextQuestion.substr(nonBlankOpen,  j - nonBlankOpen);
        newNonBlanksPre = [...newNonBlanksPre, nonBlankText]
        newNonBlanksPost = [...newNonBlanksPost, undefined]
        nonBlankOpen = undefined;
      }
      if (optionOpen && nextQuestion.substr(j, 1) === '}') {
        const expectedAnswerText = nextQuestion.substr(optionOpen,  j - optionOpen + 1);
        const expectedAnswerJson = JSON.parse(expectedAnswerText);
        newExpectedAnswers = [...newExpectedAnswers, expectedAnswerJson.answer]
        newExpectedAttributes = [...newExpectedAttributes, expectedAnswerJson.attribute]

        newImagedAnswers = [...newImagedAnswers,
          expectedAnswerJson.isImage ? imgMd(expectedAnswerJson.imageFileName || expectedAnswerJson.answer, expectedAnswerJson.imageSrc, configs) : undefined]
        newDropDownAnswers = [...newDropDownAnswers, cloneShuffleArrayV2(expectedAnswerJson.dropDownOptions || [])]
        nonBlankOpen = j + 1;
        optionOpen = undefined;
      }
    }

    // handling head text without any answers
    if (nonBlankOpen == 0) {
      newNonBlanksPre[0] = nextQuestion;
    // handling tail text from last question
    } else if (nonBlankOpen < nextQuestion.length - 1) {
      const nonBlankText = nextQuestion.substr(nonBlankOpen,   nextQuestion.length - nonBlankOpen);
      newNonBlanksPost[newNonBlanksPost.length - 1] = nonBlankText;
    }

    // new item input into round state
    let newItemRoundState =
      {
        expectedAnswers: newExpectedAnswers,
        expectedAttributes: newExpectedAttributes,
        imagedAnswers: newImagedAnswers,
        dropDownAnswers: newDropDownAnswers,
        actualAnswers: newExpectedAnswers.map(answer => undefined),
        answersIsMatch: newExpectedAnswers.map(answer => undefined),
        nonBlanksPre: newNonBlanksPre,
        nonBlanksPost: newNonBlanksPost,
      }
    return newItemRoundState;
  }

export function gpSetNextTestInputStateV2(nextQuestionList, currentState, imgMd, configs, setters) {
      let newExpectedAnswers = [];
      let newImagedAnswers = [];
      let newDropDownAnswers = [];
      let newActualAnswers = [];
      let lineItems = [];
      let newAnswersIsMatch = [];
      let newNonBlanksPre = [];
      let newNonBlanksPost = [];
      let newListDiv = [];
      let newTopicMd = nextQuestionList.md || {};

      nextQuestionList.qs.forEach((nextQuestion, i) => {
        const newItemState = gpSetNextTestSingleInputState(nextQuestion, imgMd, configs);

        newExpectedAnswers.push(newItemState.expectedAnswers);
        newImagedAnswers.push(newItemState.imagedAnswers);
        newDropDownAnswers.push(newItemState.dropDownAnswers);
        newActualAnswers.push(newItemState.actualAnswers);
        newAnswersIsMatch.push(newItemState.answersIsMatch);
        newNonBlanksPre.push(newItemState.nonBlanksPre);
        newNonBlanksPost.push(newItemState.nonBlanksPost);
        newListDiv = [...newListDiv, ...newItemState.expectedAnswers];
      });

      // set new input state
      setters.setExpectedAnswers(newExpectedAnswers);
      setters.setImagedAnswers(newImagedAnswers);
      setters.setDropDownAnswers(newDropDownAnswers);
      setters.setActualAnswers(newActualAnswers);
      setters.setAnswersIsMatch(newAnswersIsMatch);
      setters.setNonBlanksPre(newNonBlanksPre);
      setters.setNonBlanksPost(newNonBlanksPost);

      // state in case is drag and drop
      const tmpListDiv = cloneShuffleArrayV2(nextQuestionList.pool && nextQuestionList.pool.length >= newListDiv.length ?
        nextQuestionList.pool : newListDiv);
      setters.setListDiv(tmpListDiv);
      setters.setListAnswerDiv(tmpListDiv);

      setters.setTopicMd(newTopicMd);

      let newRoundState = {
        expectedAnswers: newExpectedAnswers,
        imagedAnswers: newImagedAnswers,
        dropDownAnswers: newDropDownAnswers,
        actualAnswers: newActualAnswers,
        answersIsMatch: newAnswersIsMatch,
        nonBlanksPre: newNonBlanksPre,
        nonBlanksPost: newNonBlanksPost,
        listDiv: tmpListDiv,
        listAnswerDiv: tmpListDiv,
        topicMd: newTopicMd,
        attempts: 0,
      };
      setters.setRoundState([...currentState, newRoundState]);
  }

export function gpGetNextTestInputStateV2(nextQuestionList, imgMd, configs) {
    let newExpectedAnswers = [];
    let newImagedAnswers = [];
    let newDropDownAnswers = [];
    let newActualAnswers = [];
    let lineItems = [];
    let newAnswersIsMatch = [];
    let newNonBlanksPre = [];
    let newNonBlanksPost = [];
    let newListDiv = [];
    let newTopicMd = nextQuestionList.md || {};

    nextQuestionList.qs.forEach((nextQuestion, i) => {
      const newItemState = gpSetNextTestSingleInputState(nextQuestion, imgMd, configs);

      newExpectedAnswers.push(newItemState.expectedAnswers);
      newImagedAnswers.push(newItemState.imagedAnswers);
      newDropDownAnswers.push(newItemState.dropDownAnswers);
      newActualAnswers.push(newItemState.actualAnswers);
      newAnswersIsMatch.push(newItemState.answersIsMatch);
      newNonBlanksPre.push(newItemState.nonBlanksPre);
      newNonBlanksPost.push(newItemState.nonBlanksPost);
      newListDiv = [...newListDiv, ...newItemState.expectedAnswers];
    });

    // state in case is drag and drop
    const tmpListDiv = cloneShuffleArrayV2(nextQuestionList.pool && nextQuestionList.pool.length >= newListDiv.length ?
      nextQuestionList.pool : newListDiv);

    let newRoundState = {
      type: "MultiQuestionMultiOptionsComponent",
      expectedAnswers: newExpectedAnswers,
      imagedAnswers: newImagedAnswers,
      dropDownAnswers: newDropDownAnswers,
      actualAnswers: newActualAnswers,
      answersIsMatch: newAnswersIsMatch,
      nonBlanksPre: newNonBlanksPre,
      nonBlanksPost: newNonBlanksPost,
      listDiv: tmpListDiv,
      listAnswerDiv: tmpListDiv,
      topicMd: newTopicMd,
      attempts: 0,
    };
    return newRoundState;
}

function wordValueMapper(value, attribute) {
  return ({
    value: value,
    expectedAttribute: attribute,
    actualAttribute: undefined
  });
}

export function gpGetNextTestAttributeStateV2(nextQuestionList, imgMd, configs) {
    let newExpectedAnswers = [];
    let newExpectedAttributes = [];
    let newAnswersIsMatch = [];
    let newNonBlanksPre = [];
    let newNonBlanksPost = [];
    let newAttributes = [];
    let newTopicMd = nextQuestionList.md || {};

    nextQuestionList.qs.forEach((nextQuestion, i) => {
      const newItemState = gpSetNextTestSingleInputState(nextQuestion, imgMd, configs);

      newExpectedAnswers.push(newItemState.expectedAnswers);
      newExpectedAttributes.push(newItemState.expectedAttributes);
      newNonBlanksPre.push(newItemState.nonBlanksPre);
      newNonBlanksPost.push(newItemState.nonBlanksPost);
    });

    const newDisplayableText = newNonBlanksPre.map((nonBlanksPreK, k) => {
      return nonBlanksPreK.flatMap((nonBlanksPreKI, i) => {
        let tmpPreKIList = (nonBlanksPreKI || "").split(" ").map(value => wordValueMapper(value, undefined));
        let tmpActualAnswer = newExpectedAnswers[k][i] ? [wordValueMapper(newExpectedAnswers[k][i], newExpectedAttributes[k][i])] : [];
        let tmpPostKIList = (newNonBlanksPost[k][i] || "").split(" ").map(value => wordValueMapper(value, undefined));
        return [
          ...tmpPreKIList, ...tmpActualAnswer, ...tmpPostKIList
        ].filter(tmpItem => tmpItem.value.length > 0);
      });
    });
    newAttributes = cloneShuffleArrayV2(nextQuestionList.attributesPool || []);

    newAnswersIsMatch = newDisplayableText.map((itemRow, k) =>
      itemRow.filter((itemCell, i) => itemCell.expectedAttribute)
              .map((itemCell, i) => undefined)
      );

    let newRoundState = {
      type: "MultiQuestionMultiAttributesComponent",
      attributes: newAttributes,
      displayableText: newDisplayableText,
      answersIsMatch: newAnswersIsMatch,
      topicMd: newTopicMd,
      attempts: 0,
    };
    return newRoundState;
}

/*
  General purpose load all words Word into Puzzle
*/
function loadWordsInWordPuzzle(allWords, wordPuzzle, directionMapping) {
  const iterator = (wordList, tmpWordPuzzle) => {
    for (let w=0;w<wordList.length;w++) {
      const wordToLoad =  wordList[w];
      for (let k=0;k<wordToLoad.word.length;k++) {
        const i = wordToLoad.startingCell.row + directionMapping[wordToLoad.cellDirection].i(k);
        const j = wordToLoad.startingCell.col + directionMapping[wordToLoad.cellDirection].j(k);
        let newValue = wordToLoad.word.charAt(k);
        let newCell = {};
        const oldCell = tmpWordPuzzle[i][j];
        switch (directionMapping[wordToLoad.cellDirection].type) {
          case "horizontal":
               newCell = {
                 answer: oldCell.value || newValue, // honors old value (it must never change char value)
                 color: oldCell.color === "tomato" ? "gray" : directionMapping[wordToLoad.cellDirection].color,
                 horizontal: oldCell.horizontal || (w+1), // honors old value (it must never override directions)
                 vertical: oldCell.vertical,
                 attempts: 0,
                 valid: false
               };
          break;
          case "vertical":
               newCell = {
                 answer: oldCell.value || newValue, // honors old value (it must never change char value)
                 color: oldCell.color === "lightgreen" ? "gray" : directionMapping[wordToLoad.cellDirection].color,
                 vertical: oldCell.vertical || (w+1), // honors old value (it must never override directions)
                 horizontal: oldCell.horizontal,
                 attempts: 0,
                 valid: false
               };
          break;
        }
        tmpWordPuzzle[i][j] = newCell;
      }
    }
    return tmpWordPuzzle;
  }
  let newWordPuzzle = wordPuzzle.map(row => row.map(cell => cell));
  newWordPuzzle = iterator(allWords.horizontal, newWordPuzzle);
  newWordPuzzle = iterator(allWords.vertical, newWordPuzzle);
  return newWordPuzzle;
}

export function gpGetNextPuzzleInputStateV2(nextQuestionList, settings) {

  const cellWidth = 40;
  const cellHeight = 25;
  const rows = nextQuestionList.size.rows;
  const cols = nextQuestionList.size.cols;
  const blankWordPuzzle = Array.from(Array(rows)).map((_, k) => k + 1).map(k =>
    Array.from(Array(cols)).map((_, i) => i + 1).map(i => ({ value: undefined }))
  );
  const initAllWords = nextQuestionList.qs;
  const validChars = nextQuestionList.validChars;
  const directionMapping = {
    "ArrowDown": {
      name: "Vertical->Down",
      type: "vertical",
      i: (k) => k, j: (k) => 0,
      maxWords: (cell) => rows - cell.row,
      maxWidth: (maxLen) => cellWidth * 3,
      hasRow: (i, cell) => i >= cell.row,
      hasRowEnd: (i, cell, limit) => i >= cell.row  && i < (cell.row + limit),
      hasCol: (j, cell) => j == cell.col,
      hasColEnd: (j, cell, limit) => j == cell.col,
      color: "tomato",
      crossColor: "lightgreen"
    },
    "ArrowUp": {
      name: "Vertical->Up",
      type: "vertical",
      i: (k) => -k, j: (k) => 0,
      maxWords: (cell) => cell.row + 1,
      maxWidth: (maxLen) => cellWidth * 3,
      hasRow: (i, cell) => i <= cell.row,
      hasRowEnd: (i, cell, limit) => i <= cell.row  && i > (cell.row - limit),
      hasCol: (j, cell) => j == cell.col,
      hasColEnd: (j, cell, limit) => j == cell.col,
      color: "tomato",
      crossColor: "lightgreen"
    },
    "ArrowRight": {
      name: "Horizontal->Right",
      type: "horizontal",
      i: (k) => 0, j: (k) => k,
      maxWords: (cell) => cols - cell.col,
      maxWidth: (maxLen) => cellWidth * (maxLen+2),
      hasRow: (i, cell) => i == cell.row,
      hasRowEnd: (i, cell, limit) => i == cell.row,
      hasCol: (j, cell) => j >= cell.col,
      hasColEnd: (j, cell, limit) => j >= cell.col && j < (cell.col + limit),
      color: "lightgreen",
      crossColor: "tomato"
    },
    "ArrowLeft": {
      name: "Horizontal->Left",
      type: "horizontal",
      i: (k) => 0,
      j: (k) => -k,
      maxWords: (cell) => cell.col + 1,
      maxWidth: (maxLen) => cellWidth * (maxLen+2),
      hasRow: (i, cell) => i == cell.row,
      hasRowEnd: (i, cell, limit) => i == cell.row,
      hasCol: (j, cell) => j <= cell.col,
      hasColEnd: (j, cell, limit) => j <= cell.col && j > (cell.col - limit),
      color: "lightgreen",
      crossColor: "tomato"
    }
  };
  const topicMd = nextQuestionList.md || {};

  const initWordPuzzle = loadWordsInWordPuzzle(initAllWords, blankWordPuzzle, directionMapping);

  let newRoundState = {
    type: "PuzzleComponent",
    cellWidth: cellWidth,
    cellHeight: cellHeight,
    rows: rows,
    cols: cols,
    validChars: validChars,
    directionMapping: directionMapping,
    topicMd: topicMd,
    initWordPuzzle: initWordPuzzle,
    initAllWords: initAllWords,
    answersIsMatch: [[...initAllWords.horizontal.map(item => undefined), ...initAllWords.vertical.map(item => undefined)]],
    expectedAnswers: [[...initAllWords.horizontal.map(item => item.word), ...initAllWords.vertical.map(item => item.word)]],
    attempts: 0,
  };
  return newRoundState;
}

export function dragStartDivV2(e, item, position, dragItemDiv) {
  dragItemDiv.current = { value: item, index: position };
//  console.log(`dragging ${JSON.stringify(dragItemDiv.current)} ${e.target.innerHTML}`);
};

export function dragEnterDivV2(e, item, position, dragOverItemDiv) {
  dragOverItemDiv.current = { value: item, index: position };
//  console.log(`dropping ${JSON.stringify(dragOverItemDiv.current)} ${e.target.innerHTML}`);
}

export function dropDivV2(
  e, dragItemDiv, dragOverItemDiv, listDiv, setListDiv, answerValues, setAnswerValues, answersIsMatch, setAnswersIsMatch
  ) {
  console.log(`drag and drop from ${JSON.stringify(dragItemDiv.current)}::${dragItemDiv.current.index < 1000}(box) to ${JSON.stringify(dragOverItemDiv.current)}::${dragOverItemDiv.current.index == 100}(box)`);

  const copyListItems = [...listDiv];

  const fromK = Math.floor(dragItemDiv.current.index / 1000);
  const toK =  Math.floor(dragOverItemDiv.current.index / 1000);

  // input box to input box
  if (dragItemDiv.current.index >= 1000 && dragOverItemDiv.current.index >= 1000) {
      const answerValuesCopy = [...answerValues];
      let newIsMatchArray = [...answersIsMatch];

      const inputValueFrom = answerValuesCopy[fromK-1][dragItemDiv.current.index - (fromK * 1000)];
      const inputValueTo = answerValuesCopy[toK-1][dragOverItemDiv.current.index - (toK * 1000)];
      answerValuesCopy[fromK-1][dragItemDiv.current.index - (fromK * 1000)] = inputValueTo;
      answerValuesCopy[toK-1][dragOverItemDiv.current.index - (toK * 1000)] = inputValueFrom;
      newIsMatchArray[fromK-1][dragItemDiv.current.index - (fromK * 1000)] = undefined;
      newIsMatchArray[toK-1][dragOverItemDiv.current.index - (toK * 1000)] = undefined;
      setAnswerValues(answerValuesCopy);
      setAnswersIsMatch(newIsMatchArray);
      return;
  }

  // answers pool item to input box
  if (dragOverItemDiv.current.index >= 1000) {
    const answerValuesCopy = [...answerValues.map(item => [...item])];
    let newIsMatchArray = [...answersIsMatch.map(item => [...item])];

    const previousInputValue = answerValuesCopy[toK-1][dragOverItemDiv.current.index - (toK * 1000)];
    answerValuesCopy[toK-1][dragOverItemDiv.current.index - (toK * 1000)] = dragItemDiv.current.value;
    newIsMatchArray[toK-1][dragOverItemDiv.current.index - (toK * 1000)] = undefined;

    setAnswerValues(answerValuesCopy);
    const filteredCopyListItems = copyListItems.filter((value, index) => index !== dragItemDiv.current.index);
    setAnswersIsMatch(newIsMatchArray);

    if (previousInputValue !== undefined && previousInputValue !== null && previousInputValue !== "") {
      filteredCopyListItems.push(previousInputValue);
    }
    setListDiv(filteredCopyListItems);
    return;
  }

  // input box to answers pool item or pool box when value is something but not undefined so that it doesn't add empty boxes
  if (dragItemDiv.current.index >= 1000) {
    if (dragItemDiv.current.value !== undefined) {
      const copyListItems = [...listDiv];
      const answerValuesCopy = [...answerValues];
      let newIsMatchArray = [...answersIsMatch];

      const inputValue = answerValuesCopy[fromK-1][dragItemDiv.current.index - (fromK * 1000)];
      answerValuesCopy[fromK-1][dragItemDiv.current.index - (fromK * 1000)] = undefined;
      copyListItems.push(inputValue);
      newIsMatchArray[fromK-1][dragItemDiv.current.index - (fromK * 1000)] = undefined;

      setAnswerValues(answerValuesCopy);
      setListDiv(copyListItems);
      setAnswersIsMatch(newIsMatchArray);
    }
    // do nothing if empty answer
    return;
  }

  // answers pool item into answers pool item but not the pool box
  if (dragOverItemDiv.current.value !== "Answers") {
      copyListItems[dragItemDiv.current.index] = dragOverItemDiv.current.value;
      copyListItems[dragOverItemDiv.current.index] = dragItemDiv.current.value;
      dragItemDiv.current = null;
      dragOverItemDiv.current = null;
      setListDiv(copyListItems);
  }
}

export function dropDivSingleV2(
  e, dragItemDiv, dragOverItemDiv, listDiv, setListDiv, answerValues, setAnswerValues, answersIsMatch, setAnswersIsMatch
  ) {
  console.log(`drag and drop from ${JSON.stringify(dragItemDiv.current)}::${dragItemDiv.current.index < 1000}(box) to ${JSON.stringify(dragOverItemDiv.current)}::${dragOverItemDiv.current.index == 100}(box)`);

  const copyListItems = [...listDiv];

  // input box to input box
  if (dragItemDiv.current.index >= 1000 && dragOverItemDiv.current.index >= 1000) {
      const answerValuesCopy = [...answerValues];
      let newIsMatchArray = [...answersIsMatch];

      const inputValueFrom = answerValuesCopy[dragItemDiv.current.index - (1000)];
      const inputValueTo = answerValuesCopy[dragOverItemDiv.current.index - (1000)];
      answerValuesCopy[dragItemDiv.current.index - (1000)] = inputValueTo;
      answerValuesCopy[dragOverItemDiv.current.index - (1000)] = inputValueFrom;
      newIsMatchArray[dragItemDiv.current.index - (1000)] = undefined;
      newIsMatchArray[dragOverItemDiv.current.index - (1000)] = undefined;
      setAnswerValues(answerValuesCopy);
      setAnswersIsMatch(newIsMatchArray);
      return;
  }

  // answers pool item to input box
  if (dragOverItemDiv.current.index >= 1000) {
    const answerValuesCopy = [...answerValues];
    let newIsMatchArray = [...answersIsMatch];

    const previousInputValue = answerValuesCopy[dragOverItemDiv.current.index - (1000)];
    answerValuesCopy[dragOverItemDiv.current.index - (1000)] = dragItemDiv.current.value;
    newIsMatchArray[dragOverItemDiv.current.index - (1000)] = undefined;

    setAnswerValues(answerValuesCopy);
    const filteredCopyListItems = copyListItems.filter((value, index) => index !== dragItemDiv.current.index);
    setAnswersIsMatch(newIsMatchArray);

    if (previousInputValue !== undefined && previousInputValue !== null && previousInputValue !== "") {
      filteredCopyListItems.push(previousInputValue);
    }
    setListDiv(filteredCopyListItems);
    return;
  }

  // input box to answers pool item or pool box when value is something but not undefined so that it doesn't add empty boxes
  if (dragItemDiv.current.index >= 1000) {
    if (dragItemDiv.current.value !== undefined) {
      const copyListItems = [...listDiv];
      const answerValuesCopy = [...answerValues];
      let newIsMatchArray = [...answersIsMatch];

      const inputValue = answerValuesCopy[dragItemDiv.current.index - (1000)];
      answerValuesCopy[dragItemDiv.current.index - (1000)] = undefined;
      copyListItems.push(inputValue);
      newIsMatchArray[dragItemDiv.current.index - (1000)] = undefined;

      setAnswerValues(answerValuesCopy);
      setListDiv(copyListItems);
      setAnswersIsMatch(newIsMatchArray);
    }
    // do nothing if empty answer
    return;
  }

  // answers pool item into answers pool item but not the pool box
  if (dragOverItemDiv.current.value !== "Answers") {
      copyListItems[dragItemDiv.current.index] = dragOverItemDiv.current.value;
      copyListItems[dragOverItemDiv.current.index] = dragItemDiv.current.value;
      dragItemDiv.current = null;
      dragOverItemDiv.current = null;
      setListDiv(copyListItems);
  }
}

/*
  Executes the aws service requiring the output Sts assumed role credential from the chain step

  references:
    - (permissions) https://aws.amazon.com/blogs/security/how-to-restrict-amazon-s3-bucket-access-to-a-specific-iam-role/
*/
function commitAwsWithStsClient(chainStep, parentCallback) {
  const timestamp = (new Date()).getTime();
  const commonRegion = "us-west-2";
  const roleToAssume = {
    RoleArn: 'arn:aws:iam::321144776408:role/strana-assets-us-west-2',
    RoleSessionName: `strana-assets-us-west-2-${timestamp}`,
    DurationSeconds: 900
  };
  const StsClient = require("aws-sdk/clients/sts")
  const sts = new StsClient({
    apiVersion: '2011-06-15',
    accessKeyId: 'AKIAUVRNYM3MEMSPFKPM',
    secretAccessKey: '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',
    region: commonRegion
  });
  sts.assumeRole(roleToAssume, (err, stsData) => {
    if (err) parentCallback({ err: `${err.stack}`, data: `${stsData}` });
    else {
      const newTmpCredentials = {
        accessKeyId: stsData.Credentials.AccessKeyId,
        secretAccessKey: stsData.Credentials.SecretAccessKey,
        sessionToken: stsData.Credentials.SessionToken,
        region: commonRegion
      };
      chainStep(newTmpCredentials);
    }
  });
}

/*
  Uploads an s3 object after assuming role session

  * refs:
    - (sdk install) https://aws.amazon.com/blogs/mobile/integrate-the-aws-sdk-for-javascript-into-a-react-app/
    - (react s3 install) https://www.npmjs.com/package/react-aws-s3
    - (sts client use) https://docs.aws.amazon.com/code-library/latest/ug/javascript_2_sts_code_examples.html
    - (sts client use) https://medium.com/@priytamk/how-to-assume-role-using-iam-role-in-node-js-sdk-bf4ab7031577
    - (sts client examples) https://github.com/awsdocs/aws-doc-sdk-examples/blob/main/javascript/example_code/sts/sts_assumerole.js
    // TODO: take a look at this for later
    - (Sts HTTP Client) https://github.com/aliartiza75/load-credentials-from-iam-role/blob/master/scripts/node-script.js
    - (s3 client use) https://stackoverflow.com/questions/61028751/missing-credentials-in-config-if-using-aws-config-file-set-aws-sdk-load-config
    - (s3 client use) https://stackoverflow.com/questions/62612082/credential-is-missing-error-on-instantiating-s3-class-using-aws-sdk-js-v3
    - (wrong region) https://stackoverflow.com/questions/41132683/aws-s3-400-bad-request
    - (buffer blob) https://stackoverflow.com/questions/34158497/convert-blob-data-to-raw-buffer-in-javascript-or-node
    - (buffer blob) https://github.com/aws/aws-sdk-js-v3/issues/4930
    - (valid blobs) https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/types/_aws_sdk_types.BlobTypes.html
*/
export function uploadConfig(user, resourceId, configData, callback) {
  const newKey = `${user}/${resourceId}`

  const chainStep = (newTmpCredentials) => {
      const S3Client = require('aws-sdk/clients/s3')
      const s3 = new S3Client(newTmpCredentials);
      const putObjectParams = {
        Body: JSON.stringify(configData),
        Bucket: "strana-assets-us-west-2",
        Key: `${newKey}.json`
      };
      /*
        s3.putObject(params)
          .on('httpUploadProgress', (evt) => {
            console.log(`upload progress: ${Math.round((evt.loaded / evt.total) * 100)}`)
          }).send((err) => { if (err) console.log(`upload error: ${err}`) });
      */
      s3.putObject(putObjectParams, (err, s3Data) => {
          if (err) callback({ err: `${err.stack}`, data: `${s3Data}` });
          else callback({ data: `${s3Data}`, key: `${newKey}`, token: newTmpCredentials.sessionToken.substring(0, 10) });
        });
  }
  commitAwsWithStsClient(chainStep, callback);
}

/*
  Downloads an s3 object after assuming role session
  * references:
    - (s3 client use) https://stackoverflow.com/questions/36942442/how-to-get-response-from-s3-getobject-in-node-js
    - (se client use) https://www.tabnine.com/code/javascript/functions/aws-sdk/S3/getObject
*/
export function downLoadConfig(author, keyId, callback) {
  const newKey = `${author}/${keyId}`

  const chainStep = (newTmpCredentials) => {
      const S3Client = require('aws-sdk/clients/s3')
      const s3 = new S3Client(newTmpCredentials);
      const getObjectParams = {
        Bucket: "strana-assets-us-west-2",
        Key: `${newKey}.json`
      };
      s3.getObject(getObjectParams, (err, s3Data) => {
          if (err) callback({ err: `${err.stack}`, data: s3Data });
          else callback({ data: s3Data, key: `${newKey}` });
        });
  }
  commitAwsWithStsClient(chainStep, callback);
}

/*
  Downloads an s3 object after assuming role session
  * references:
    - (s3 client use) https://stackoverflow.com/questions/36942442/how-to-get-response-from-s3-getobject-in-node-js
    - (se client use) https://www.tabnine.com/code/javascript/functions/aws-sdk/S3/getObject
*/
export function downLoadConfigOn(searchKey, callback) {
  const chainStep = (newTmpCredentials) => {
      const S3Client = require('aws-sdk/clients/s3')
      const s3 = new S3Client(newTmpCredentials);
      const getObjectParams = {
        Bucket: "strana-assets-us-west-2",
        Key: `${searchKey}.json`
      };
      s3.getObject(getObjectParams, (err, s3Data) => {
          if (err) callback({ err: `${err.stack}`, data: s3Data });
          else callback({ data: s3Data, key: `${searchKey}` });
        });
  }
  commitAwsWithStsClient(chainStep, callback);
}

/*
  Lists s3 objects after assuming role session

  * ref:
    - https://stackoverflow.com/questions/30726079/aws-s3-object-listing
*/
export function listConfigs(author, callback) {
  const chainStep = (newTmpCredentials) => {
      const S3Client = require('aws-sdk/clients/s3')
      const s3 = new S3Client(newTmpCredentials);
      const listObjectsParams = {
        Bucket: "strana-assets-us-west-2",
        Delimiter: '/',
        Prefix: `${author}/`
      }
      s3.listObjects(listObjectsParams, function (err, s3Data) {
        if(err) callback({ err: `${err.stack}`, data: s3Data });
        else callback({ data: s3Data });
      });
  }
  commitAwsWithStsClient(chainStep, callback);
}

export function MediaTypeHeader({user, uploadValue, setUploadValue, currentUrl, setCurrentUrl}) {
  // TODO: improve response management
  useEffect(() => {
    if (uploadValue.length > 0) {
      const callback = (response) => {
        if (!response.err) {
          console.log(`Upload Success ${JSON.stringify(response)}`)
          setCurrentUrl('https://strana-us-west-2.s3.us-west-2.amazonaws.com/' + `${response.key}`)
        } else {
          console.log(`Upload Failure ${response.err}`)
        }
      }
      new Promise((resolve) => setTimeout(async () => {
        let buffer = await uploadValue[0].arrayBuffer();
        uploadFile(user, uploadValue[0].name, buffer, callback)
        /*
            setTimeout(() => setShowUploadModal(false), 500)
        */
        resolve();
      }, 500));
    }
  }, [uploadValue]);

  return (<Header
    variant="h2"
    description={
    <SpaceBetween direction="vertical">
    <SpaceBetween direction="horizontal" size="xs">
      <Link external href="https://player.vimeo.com/video/888136403?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479">Add from Google Drive?</Link>
      <Link external href="https://player.vimeo.com/video/888158049?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479">Add from DropBox?</Link>
    </SpaceBetween>
    <FormField
      label="File Upload"
      description="browse user media files"
    >
      <FileUpload
        onChange={({ detail }) => setUploadValue(detail.value)}
        value={uploadValue}
        i18nStrings={{
          uploadButtonText: e => e ? "Choose files" : "Choose file",
          dropzoneText: e => e ? "Drop files to upload" : "Drop file to upload",
          removeFileAriaLabel: e => `Remove file ${e + 1}`,
          limitShowFewer: "Show fewer files",
          limitShowMore: "Show more files",
          errorIconAriaLabel: "Error"
        }}
        showFileLastModified
        showFileSize
        showFileThumbnail
        tokenLimit={1}
        constraintText="png|jpg|jpeg|mp4|mp3"
      />
    </FormField>
    </SpaceBetween>
    }
  >Media Type</Header>)
}

function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = crypto.getRandomValues(new Uint8Array(1))[0] % 16;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function getFileExtension(filename) {
  const parts = filename.split('.');
  return parts.length > 1 ? parts.pop() : '';
}

export function uploadFile(user, resourceId, rawData, callback) {
  const newKey = `static/media/${user}/${generateUUID()}.${getFileExtension(resourceId)}`

  const chainStep = (newTmpCredentials) => {
      const S3Client = require('aws-sdk/clients/s3')
      const s3 = new S3Client(newTmpCredentials);
      const putObjectParams = {
        Body: rawData,
        Bucket: "strana-us-west-2",
        Key: `${newKey}`
      };
      s3.putObject(putObjectParams, (err, s3Data) => {
          if (err) callback({ err: `${err.stack}`, data: `${s3Data}` });
          else callback({ data: `${s3Data}`, key: `${newKey}`, token: newTmpCredentials.sessionToken.substring(0, 10) });
        });
  }
  commitAwsWithStsClient(chainStep, callback);
}