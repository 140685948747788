import {useState} from 'react';
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Box from "@cloudscape-design/components/box";
import {Select, TextContent} from "@cloudscape-design/components";
import Multiselect from "@cloudscape-design/components/multiselect";
import Modal from "@cloudscape-design/components/modal";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import Toggle from "@cloudscape-design/components/toggle";
import Spinner from "@cloudscape-design/components/spinner";
import RadioGroup from "@cloudscape-design/components/radio-group";

export function RoundSettingsV2({
    onExit, title, configs, setConfigs, hideDisplayToggles, options, questionArray,
    testConfigModalVisible, setTestConfigModalVisible,
    doShuffle, setDoShuffle,
    dragAndDrop, setDragAndDrop, dropDown, setDropDown, cantDropDown, cantDragAndDrop,
    selectedQuestionOptions, setSelectedQuestionOptions, multiQuestionGroupedOptions,
    selectedMaxQuestionsOption, setSelectedMaxQuestionsOption,
    selectedHeightOption, setSelectedHeightOption,
    selectedAttemptsOption, setSelectedAttemptsOption,
    imageOnly, setImageOnly,
    inputReference
  }) {

  const isSingle = !multiQuestionGroupedOptions;
  const imagePixelSizeOptions = isSingle ? [150, 200, 300, 400, 500] : [30, 40, 80, 120];
  const [isLoading, setIsLoading] = useState(false);
  const [challengeTypeValue, setChallengeTypeValue] = useState(dropDown ? 2 : (dragAndDrop ? 3 : 1));
  const [interactiveMode, setInteractiveMode] = useState(configs.interactiveMode);

  return (<Modal
              onDismiss={() => {
                if (onExit) { onExit(); return; }
                setIsLoading(true);
                new Promise(resolve => setTimeout(() => resolve(), 2000))
                  .then(() => {
                    setIsLoading(false);
                    setTestConfigModalVisible(false);
                    if (inputReference && inputReference.current) inputReference.current.focus();
                });
              }}
              visible={testConfigModalVisible}
              closeAriaLabel="Close"
              footer={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="normal" onClick={() => {
                        if (onExit) { onExit(); return; }
                        setIsLoading(true);
                        new Promise(resolve => setTimeout(() => resolve(), 2000))
                          .then(() => {
                            setIsLoading(false);
                            setTestConfigModalVisible(false);
                            if (inputReference && inputReference.current) inputReference.current.focus();
                        });
                      }}
                      >Cancel
                    </Button>
                    <Button variant="primary" onClick={() => {
                        setIsLoading(true);
                        new Promise(resolve => setTimeout(() => resolve(), 2000))
                          .then(() => {
                            setIsLoading(false);
                            setTestConfigModalVisible(false);
                            if (inputReference && inputReference.current) inputReference.current.focus();
                        });
                      }}
                      >Start
                    </Button>
                  </SpaceBetween>
                </Box>
              }
              header={<SpaceBetween size="l" direction="horizontal"><div>{`${title} - Round Settings`}</div>{isLoading && <Spinner size="large"/>}</SpaceBetween>}
          >
           {<SpaceBetween direction="vertical" size="xl">
            <Toggle
                onChange={({ detail }) => {
                  let newConfigs = configs;
                  newConfigs["interactiveMode"] = detail.checked;
                  setConfigs(newConfigs);
                  setInteractiveMode(detail.checked);
                }}
                checked={interactiveMode}
            >
              Interactive Mode
            </Toggle>
            <Toggle
                onChange={({ detail }) => {
                  setDoShuffle(detail.checked);
                }}
                checked={doShuffle}
            >
              Shuffle
            </Toggle>
            {(!hideDisplayToggles) &&
            <RadioGroup
              onChange={({ detail }) => {
                setChallengeTypeValue(detail.value);
                switch (detail.value) {
                  case 1:
                    setDropDown(false);
                    setDragAndDrop(false);
                  break;
                  case 2:
                    setDropDown(true);
                    setDragAndDrop(false);
                  break;
                  case 3:
                    setDropDown(false);
                    setDragAndDrop(true);
                  break;
                }
              }}
              value={challengeTypeValue}
              items={[
                { value: 1, label: "Text-Write (hard)" },
                { value: 2, label: "Drop-Down (medium)", disabled: cantDropDown },
                { value: 3, label: "Drag & Drop (easy)", disabled: cantDragAndDrop }
              ]}
            />}
            {!challengeTypeValue && <Toggle
                onChange={({ detail }) => {
                  setDragAndDrop(detail.checked);
                }}
                checked={dragAndDrop}
            >
              Drag & Drop
            </Toggle>}
            <Toggle
                onChange={({ detail }) => {
                  setImageOnly(detail.checked);
                  let newConfigs = configs;
                  newConfigs["imageOnly"] = detail.checked;
                  setConfigs(newConfigs);
                }}
                checked={imageOnly}
            >
              Image only
            </Toggle>
            <ColumnLayout columns={2}>
              <div>
                <TextContent><h5>Max Questions</h5></TextContent>
              </div>
              <div>
                <Select
                    filteringType={"auto"}
                    selectedOption={selectedMaxQuestionsOption}
                    onChange={({ detail }) => {
                      setSelectedMaxQuestionsOption(detail.selectedOption);
                      let newConfigs = configs;
                      newConfigs["maxQuestions"] = detail.selectedOption.value;
                      setConfigs(newConfigs);
                    }}
                    options={Array.from(Array(options.length)).map((_, i) => i + 1).map(v => ({ label: `${v}`, value: v }))}
                    placeholder="Choose Max Questions"
                    selectedAriaLabel="Selected"
                />
              </div>
              {!isSingle && <div>
                <TextContent><h5>Questions</h5></TextContent>
              </div>}
              {!isSingle && <div>
                <Multiselect
                    filteringType={"auto"}
                    disabled={selectedQuestionOptions.length === 1 && questionArray.filter(opt => opt === "").length > 0}
                    selectedOptions={selectedQuestionOptions}
                    onChange={({ detail }) => {
                      setSelectedQuestionOptions(detail.selectedOptions)
                    }}
                    deselectAriaLabel={e => `Remove ${e.label}`}
                    options={multiQuestionGroupedOptions}
                    placeholder="Choose questions"
                    selectedAriaLabel="Selected"
                />
              </div>}
              {configs.hasImage && <div>
                <TextContent><h5>Height</h5></TextContent>
              </div>}
              {configs.hasImage && <div>
                <Select
                    filteringType={"auto"}
                    selectedOption={selectedHeightOption}
                    onChange={({ detail }) => {
                      setSelectedHeightOption(detail.selectedOption);
                      let newConfigs = configs;
                      newConfigs["height"] = detail.selectedOption.value;
                      setConfigs(newConfigs);
                    }}
                    options={imagePixelSizeOptions.map(v => ({ label: `${v}px`, value: v }))}
                    placeholder="Choose height"
                    selectedAriaLabel="Selected"
                />
              </div>}
              <div>
                <TextContent><h5>Max Attempts</h5></TextContent>
              </div>
              <div>
                <Select
                    filteringType={"auto"}
                    selectedOption={selectedAttemptsOption}
                    onChange={({ detail }) => {
                      setSelectedAttemptsOption(detail.selectedOption);
                      let newConfigs = configs;
                      newConfigs["attempts"] = detail.selectedOption.value;
                      setConfigs(newConfigs);
                    }}
                    options={Array(20).fill(1).map( (_, i) => ({ label: i + 1, value: i + 1 }))}
                    placeholder="Choose max attempts"
                    selectedAriaLabel="Selected"
                />
              </div>
            </ColumnLayout>
            </SpaceBetween>}
          </Modal>);
}