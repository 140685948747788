import {useEffect, useRef, useState} from 'react';
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import HelpPanel from "@cloudscape-design/components/help-panel";
import {Select, StatusIndicator, TextContent, Spinner} from "@cloudscape-design/components";
import Toggle from "@cloudscape-design/components/toggle";
import ProgressBar from "@cloudscape-design/components/progress-bar";
import Modal from "@cloudscape-design/components/modal";
import Box from "@cloudscape-design/components/box";

import { useContainerQuery } from '@cloudscape-design/component-toolkit';
import { cloneOptionsV2, gpTopicMdControlV2Keyed, overrideTopicMd } from '../common/common'
import { RoundSettingsV2 } from './round-settings'
import { DisplaySummaryV2 } from './display-summary'
import { dropDivV2, dragStartDivV2, dragEnterDivV2, gpSetNextTestInputStateV2, calculateTotalAttemptsV2,
  gpGetNextTestInputStateV2, gpGetNextPuzzleInputStateV2, gpGetNextTestAttributeStateV2
} from './question-common';
import { MultiQuestionMultiOptionsComponentV2 } from './multi-question-multi-options-component';
import { PuzzleComponentV2 } from '../puzzle/puzzle-component';
import { MultiQuestionMultiAttributesComponentV2 } from './multi-question-multi-attributes-component';

function ChildComponent(props) {
  if (props.roundState.length > 0 && props.testIndex < props.roundState.length) {
    switch (props.roundState[props.testIndex].type) {
      case "MultiQuestionMultiOptionsComponent":
        return (
        <MultiQuestionMultiOptionsComponentV2
          topicMdControl={props.topicMdControl}
          topicMd={props.topicMd}
          setTopicMd={props.setTopicMd}
          roundState={props.roundState}
          setRoundState={props.setRoundState}
          testIndex={props.testIndex}
          setTestIndex={props.setTestIndex}
          isLoadingTest={props.isLoadingTest}
          setIsLoadingTest={props.setIsLoadingTest}
          commitTry={props.commitTry}
          setCommitTry={props.setCommitTry}
          commitClear={props.commitClear}
          setCommitClear={props.setCommitClear}
          commitShow={props.commitShow}
          setCommitShow={props.setCommitShow}
          commitPrevious={props.commitPrevious}
          setCommitPrevious={props.setCommitPrevious}
          commitNext={props.commitNext}
          setCommitNext={props.setCommitNext}
          settings={props.settings}
          isReplay={props.isReplay}
          interactiveMode={props.interactiveMode}
          testOption={props.testOption}
          imgMd={props.imgMd}
          routerProps={props.routerProps}
          totalHintedAnswers={props.totalHintedAnswers}
          setTotalHintedAnswers={props.setTotalHintedAnswers}
        />
      );
      case "PuzzleComponent":
        return (
        <PuzzleComponentV2
          topicMdControl={props.topicMdControl}
          topicMd={props.topicMd}
          setTopicMd={props.setTopicMd}
          roundState={props.roundState}
          setRoundState={props.setRoundState}
          testIndex={props.testIndex}
          setTestIndex={props.setTestIndex}
          isLoadingTest={props.isLoadingTest}
          setIsLoadingTest={props.setIsLoadingTest}
          commitTry={props.commitTry}
          setCommitTry={props.setCommitTry}
          commitClear={props.commitClear}
          setCommitClear={props.setCommitClear}
          commitShow={props.commitShow}
          setCommitShow={props.setCommitShow}
          commitPrevious={props.commitPrevious}
          setCommitPrevious={props.setCommitPrevious}
          commitNext={props.commitNext}
          setCommitNext={props.setCommitNext}a
          settings={props.settings}
          isReplay={props.isReplay}
          interactiveMode={props.interactiveMode}
          testOption={props.testOption}
          imgMd={props.imgMd}
          routerProps={props.routerProps}
          totalHintedAnswers={props.totalHintedAnswers}
          setTotalHintedAnswers={props.setTotalHintedAnswers}
        />
      );
      case "MultiQuestionMultiAttributesComponent":
        return (
        <MultiQuestionMultiAttributesComponentV2
          topicMdControl={props.topicMdControl}
          topicMd={props.topicMd}
          setTopicMd={props.setTopicMd}
          roundState={props.roundState}
          setRoundState={props.setRoundState}
          testIndex={props.testIndex}
          setTestIndex={props.setTestIndex}
          isLoadingTest={props.isLoadingTest}
          setIsLoadingTest={props.setIsLoadingTest}
          commitTry={props.commitTry}
          setCommitTry={props.setCommitTry}
          commitClear={props.commitClear}
          setCommitClear={props.setCommitClear}
          commitShow={props.commitShow}
          setCommitShow={props.setCommitShow}
          commitPrevious={props.commitPrevious}
          setCommitPrevious={props.setCommitPrevious}
          commitNext={props.commitNext}
          setCommitNext={props.setCommitNext}
          settings={props.settings}
          isReplay={props.isReplay}
          interactiveMode={props.interactiveMode}
          testOption={props.testOption}
          imgMd={props.imgMd}
          routerProps={props.routerProps}
          totalHintedAnswers={props.totalHintedAnswers}
          setTotalHintedAnswers={props.setTotalHintedAnswers}
        />
      );
    }
  }
  return (<div/>)
}

export function MultiTopicPanelContainerV2(props) {

  const startingDoShuffle = props.settings && props.settings.doShuffle ? props.settings.doShuffle : false;
  const startingImgHeight = props.settings && props.settings.imgHeight ? props.settings.imgHeight : 30;
  const startingAttempts = props.settings && props.settings.attempts ? props.settings.attempts : 3;
  const maxQuestions = props.settings && props.settings.maxQuestions
    && (props.settings.maxQuestions > 0 && props.settings.maxQuestions < props.options.length) ?
    props.settings.maxQuestions : props.options.length;
  const maxHintedAnswers = props.settings && props.settings.maxHintedAnswers ?
    props.settings.maxHintedAnswers : 0;
  const canDragAndDrop = props.settings && props.settings.dragAndDrop ? props.settings.dragAndDrop : false;
  const canDropDown = props.settings && props.settings.dropDown ? props.settings.dropDown : false;
  const canRadioGroup = props.settings && props.settings.radioGroup ? props.settings.radioGroup : false;
  const lowerCaseEnabled = false;
  const interactiveMode = true;

  const [testOptions, setTestOptions] = useState([]);
  const [testIndex, setTestIndex] = useState(undefined);
  const [randOptionIndex, setRandOptionIndex] = useState(undefined);
  const [testOption, setTestOption] = useState(undefined);
  const [isLoadingTest, setIsLoadingTest] = useState(false);
  const [testRound, setTestRound] = useState(1);

  // round state attributes
  const [roundRandOptionIndexes, setRoundRandOptionIndexes] = useState([]);
  const [roundState, setRoundState] = useState([]);
  const [displaySummary, setDisplaySummary] = useState(false);
  const [summaryTestRound, setSummaryTestRound] = useState(undefined);
  const [warnReview, setWarnReview] = useState(false);

  // yet to be defined
  const [doShuffle, setDoShuffle] = useState(startingDoShuffle);
  const [dragAndDrop, setDragAndDrop ] = useState(canDragAndDrop);
  const [dropDown, setDropDown ] = useState(canDropDown);
  const [radioGroup, setRadioGroup] = useState(canRadioGroup);
  const [configs, setConfigs] =  useState({
    height: startingImgHeight,
    attempts: startingAttempts,
    shuffle: startingDoShuffle,
    maxQuestions: maxQuestions,
    maxHintedAnswers: maxHintedAnswers,
    dropDown: canDropDown,
    dragAndDrop: canDragAndDrop,
    radioGroup: canRadioGroup,
    lowerCaseEnabled: lowerCaseEnabled,
    interactiveMode: interactiveMode
  });
  const [selectedHeightOption, setSelectedHeightOption] =  useState({ label: `${startingImgHeight}`, value: startingImgHeight });
  const [selectedAttemptsOption, setSelectedAttemptsOption] =  useState({ label: `${startingAttempts}`, value: startingAttempts });
  const [selectedMaxQuestionsOption, setSelectedMaxQuestionsOption] =  useState({
    label: `${maxQuestions}`, value: maxQuestions
  });
  const [totalHintedAnswers, setTotalHintedAnswers] = useState(0);

  const [isReplay, setIsReplay] = useState(props.roundState ? true : false);
  const [testConfigModalVisible, setTestConfigModalVisible] = useState(props.roundState ? false : true);
  const [topicMd, setTopicMd] = useState(undefined);
  const [videoPip, setVideoPip] = useState(undefined);
  const [mediaOnToolBox, setMediaOnToolBox] = useState(false);

  const [commitTry, setCommitTry] = useState(false);
  const [commitClear, setCommitClear] = useState(false);
  const [commitShow, setCommitShow] = useState(false);
  const [commitNext, setCommitNext] = useState(false);
  const [commitPrevious, setCommitPrevious] = useState(false);
  const [allActualAnswersAreMatch, setAllActualAnswersAreMatch] = useState(false);

  function composeNextTestInputState(nextTestOption) {
    switch (nextTestOption.md.type) {
      case "MultiQuestionMultiOptionsComponent":
            const newMOQInputState = gpGetNextTestInputStateV2(nextTestOption, props.imgMd, configs);
        return newMOQInputState;
      break;
      case "PuzzleComponent":
        const newPuzzleInputState = gpGetNextPuzzleInputStateV2(nextTestOption, configs);
        return newPuzzleInputState;
      break;
      case "MultiQuestionMultiAttributesComponent":
        const newMOQAttributeState = gpGetNextTestAttributeStateV2(nextTestOption, configs);
        return newMOQAttributeState;
      break;
    }
    return [];
  }

  function fabricatesNextState() {
    // if it's either starting fresh or finishing up a full round then it is considered latest so that it starts over
    const isLastTest = roundState.length == configs.maxQuestions || roundState.length == 0;
    const newTestOptions = isLastTest ? cloneOptionsV2(props.options) : testOptions;

    // randomly picks the next test option index
    const newRandOptionIndex = configs.shuffle ? Math.floor(Math.random() * newTestOptions.length) : 0;

    // sets the remaining test options dropping the one just randomly picked
    const remainingTestOptions = newTestOptions.filter((v, i) => i !== newRandOptionIndex);
    setTestOptions(remainingTestOptions);

    // sets new test option
    const newTestOption = newTestOptions[newRandOptionIndex];
    setTestOption(newTestOption);

    // voids answer(s) match array
    // voids answer(s) array
    let currentRoundState = roundState;
    if (isLastTest) {
      currentRoundState = [];
      setTestIndex(0);
    } else {
      setTestIndex(testIndex + 1);
    }

    setRoundState([...currentRoundState, composeNextTestInputState(newTestOption)]);
  }

  useEffect(() => {
    resetSplitPanel();
    // if its replay load the state
    if (isReplay) {
      const newTestIndex = 0;
      setTestIndex(newTestIndex);
      setRoundState(props.roundState);
      return;
    }
    // fabricates the state
    fabricatesNextState();
  }, [])

  function resetSplitPanel() {
    // reset panel on testOption change and media on tool box toggle as well
      // the resulting effect is to reset panel on try success/fail, next or back
    props.routerProps.resetSplitPanel();
    setMediaOnToolBox(false);
    if (videoPip && document.pictureInPictureElement) document.exitPictureInPicture();
  }

  function getTotalQuestions() {
    return (selectedMaxQuestionsOption.value < props.options.length ? selectedMaxQuestionsOption.value : props.options.length);
  }

  function getTotalAttempts() {
    return calculateTotalAttemptsV2(roundState, testIndex);
  }

  function getTopicTitle(tmpTopicMd, tmpGetQuestion, tmpTestOption) {
    return tmpTopicMd && tmpTopicMd.titleOverride ?
    tmpTopicMd.titleOverride
    :
    (tmpGetQuestion ? tmpGetQuestion(tmpTestOption) : 'Do the exercise')
  }

  function doCommitPrevious() {
    const newTestIndex = testIndex - 1;
    setTestIndex(newTestIndex);
  }

  function doCommitNext() {
    let newTestIndex = testIndex + 1;
    // save state before moving forward
    let currentRoundState = [...roundState];
    if (newTestIndex < currentRoundState.length) {
      setTestIndex(newTestIndex);
    } else {
      // removes the question from the options or restore all the questions in a new round
      const isLastTest = isReplay || ((selectedMaxQuestionsOption.value < props.options.length) ?
        testOptions.length <= (props.options.length - selectedMaxQuestionsOption.value) : testOptions.length === 0);
      if (isLastTest) {
        setDisplaySummary(true);
        setSummaryTestRound(currentRoundState);
      }
      fabricatesNextState();
    }
  }

  function doCommitTry() {
    setIsLoadingTest(true);
    setCommitTry(true);
  }

  function doCommitShow() {
    setCommitShow(true);
  }

  function doCommitClear() {
    setCommitClear(true);
  }

  const [useMobileView, ref] = useContainerQuery(entry => entry.contentBoxWidth < 688);

  useEffect(() => {
    if (topicMd && topicMd.video && topicMd.video.src &&
          !(topicMd.embed && topicMd.embed.src) &&
          !(topicMd.audio && topicMd.audio.src) &&
          !(topicMd.img && topicMd.img.src)) {
      const embedVideoPip = document.getElementById("picInPicVideo");
      function onExitPip() { setMediaOnToolBox(false); }
      function onEnterPip() { setMediaOnToolBox(true); }
      embedVideoPip.addEventListener("leavepictureinpicture", onExitPip, false);
      embedVideoPip.addEventListener("enterpictureinpicture", onEnterPip, false);
      setVideoPip(embedVideoPip);
      return;
    }
    setVideoPip(undefined);
  }, [topicMd]);

  return (
    <div>
      <SpaceBetween size="m">
        <Header variant="h1">
          <SpaceBetween direction={"horizontal"} size={"s"}>
            {props.header}
            {/*<Button variant="inline-icon" disabled
                    onClick={() => {
                      setTestConfigModalVisible(true);
                    }}
                    iconName={"settings"}
            />*/}
          </SpaceBetween>
        </Header>
        <Modal
          onDismiss={() => setWarnReview(false)}
          visible={warnReview}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" onChange={() => setWarnReview(false)}>Cancel</Button>
                <Button variant="primary" onChange={() => commitNext()}>Ok</Button>
              </SpaceBetween>
            </Box>
          }
          header="Review"
        >
          Unless you want to keep reviewing your answers press Ok to Submit the results
        </Modal>
        <Container fitHeight={true}>
          {(!props.onlineAssignmentPublish) && <RoundSettingsV2
               onExit={props.onExit}
               title={props.title}
               configs={configs}
               setConfigs={setConfigs}
               hideDisplayToggles={true}
               options={props.options}
               questionArray={props.questionArray}
               testConfigModalVisible={testConfigModalVisible}
               setTestConfigModalVisible={setTestConfigModalVisible}
               doShuffle={doShuffle}
               setDoShuffle={setDoShuffle}
               imageOnly={false}
               setImageOnly={(input) => { /*does nothing*/ }}
               dropDown={dropDown}
               setDropDown={setDropDown}
               dragAndDrop={dragAndDrop}
               setDragAndDrop={setDragAndDrop}
               radioGroup={radioGroup}
               setRadioGroup={setRadioGroup}
               cantDropDown={!canDropDown}
               cantDragAndDrop={!canDragAndDrop}
               selectedMaxQuestionsOption={selectedMaxQuestionsOption}
               setSelectedMaxQuestionsOption={setSelectedMaxQuestionsOption}
               selectedHeightOption={selectedHeightOption}
               setSelectedHeightOption={setSelectedHeightOption}
               selectedAttemptsOption={selectedAttemptsOption}
               setSelectedAttemptsOption={setSelectedAttemptsOption}
               inputReference={undefined/*inputReference*/}
          />}
          <SpaceBetween direction={"vertical"} size={"l"}>
            {displaySummary ?
            <DisplaySummaryV2
              summaryTestRound={summaryTestRound}
              getTotalQuestions={getTotalQuestions}
              testRound={testRound}
              setTestRound={setTestRound}
              displaySummary={displaySummary}
              setDisplaySummary={setDisplaySummary}
              inputReference={undefined/*inputReference*/}
              setTestConfigModalVisible={setTestConfigModalVisible}
              isReplay={isReplay}
              setIsReplay={setIsReplay}
              onlineAssignmentPublish={props.onlineAssignmentPublish}
              onlineAssignmentConfig={props.onlineAssignmentConfig}
              onExit={props.onExit}
              onReview={(lastRoundState) => {
                const newTestIndex = 0;
                setTestIndex(newTestIndex);
                setRoundState(lastRoundState);
                resetSplitPanel();
                setIsLoadingTest(true);
                new Promise(resolve => setTimeout(() => resolve(), 75))
                    .then(() => setIsLoadingTest(false));
              }}
            />
            :
            <div>
            <SpaceBetween direction={"vertical"} size={"l"}>
            {!(topicMd && topicMd.titleOverride) ?
            <Spinner/>
            :
            <SpaceBetween direction={"horizontal"} size={"s"}>
              <h2>{`${topicMd.titleOverride}`}</h2>
              <h4>{` [Round: ${testRound}]`}</h4>
              <ProgressBar
                value={(testIndex + 1)/getTotalQuestions()*100}
                additionalInfo={`[Question: ${testIndex + 1}/${getTotalQuestions()}]`}
              />
              <ProgressBar
                value={getTotalAttempts()/configs.attempts*100}
                additionalInfo={`[Attempt(s): ${getTotalAttempts()}/${configs.attempts}]`}
              />
              <Toggle
                id="webGlExperiments"
                checked={mediaOnToolBox}
                disabled={!topicMd || (!topicMd.embed && !topicMd.audio && !topicMd.video && !topicMd.img)
                  || !(    (topicMd.embed && Object.keys(topicMd.embed).length > 0)
                        || (topicMd.audio && Object.keys(topicMd.audio).length > 0)
                        || (topicMd.video && Object.keys(topicMd.video).length > 0)
                        || (topicMd.img   && Object.keys(topicMd.img).length   > 0)
                      )
                }
                onChange={({ detail }) => {
                  const tmpMediaOnToolBox = detail.checked;
                  setMediaOnToolBox(tmpMediaOnToolBox);
                  if (tmpMediaOnToolBox) {
                    if (videoPip) videoPip.requestPictureInPicture().catch(e => console.error(e.message));
                  } else {
                    if (videoPip && document.pictureInPictureElement) document.exitPictureInPicture();
                    props.routerProps.resetSplitPanel();
                  }
                }}
              >Media on Toolbox</Toggle>
            </SpaceBetween>}
            {(videoPip || !mediaOnToolBox) && props.topicMdControl(topicMd)}
            <div>
            {<ChildComponent
              topicMdControl={props.topicMdControl}
              topicMd={topicMd}
              setTopicMd={setTopicMd}
              roundState={roundState}
              setRoundState={setRoundState}
              testIndex={testIndex}
              setTestIndex={setTestIndex}
              isLoadingTest={isLoadingTest}
              setIsLoadingTest={setIsLoadingTest}
              allActualAnswersAreMatch={allActualAnswersAreMatch}
              setAllActualAnswersAreMatch={setAllActualAnswersAreMatch}
              commitTry={commitTry}
              setCommitTry={setCommitTry}
              commitClear={commitClear}
              setCommitClear={setCommitClear}
              commitShow={commitShow}
              setCommitShow={setCommitShow}
              commitPrevious={commitPrevious}
              setCommitPrevious={setCommitPrevious}
              commitNext={commitNext}
              setCommitNext={setCommitNext}
              settings={configs}
              isReplay={isReplay}
              interactiveMode={configs.interactiveMode}
              testOption={testOption}
              imgMd={props.imgMd}
              routerProps={props.routerProps}
              totalHintedAnswers={totalHintedAnswers}
              setTotalHintedAnswers={setTotalHintedAnswers}
              />}
            </div>
            <SpaceBetween size="s" direction="horizontal">
              <Button variant="primary"
                      loading={isLoadingTest}
                      disabled={
                        (getTotalAttempts() >= configs.attempts)
                            ||
                        (isReplay)
                      }
                      onClick={() => {
                        doCommitTry();
                      }}
                      iconName={"check"}
              >Save</Button>
              <Button variant="normal"
                      loading={isLoadingTest}
                      disabled={
                        getTotalAttempts() >= configs.attempts || isReplay
                      }
                      onClick={() => {
                        doCommitClear();
                      }}
              >Clear</Button>
              <Button variant="normal"
                      loading={isLoadingTest}
                      disabled={
                        (getTotalAttempts() >= configs.attempts)
                        || isReplay
                        || (totalHintedAnswers >= configs.maxHintedAnswers)
                      }
                      onClick={() => {
                        // let child components decide whether to increase total hinted answers
                        // setTotalHintedAnswers(totalHintedAnswers + 1);
                        doCommitShow();
                      }}
              >Show</Button>
              <Button variant="primary"
                      loading={isLoadingTest}
                      disabled={testIndex === 0}
                      onClick={() => {
                        setIsLoadingTest(true);
                        new Promise(resolve =>
                            setTimeout(() => resolve(), 250)).then(() => console.log("await 1/2 sec(s)"))
                            .then(() => {
                              doCommitPrevious();
                              resetSplitPanel();
                              setIsLoadingTest(false);
                              setCommitPrevious(true);
                            });
                      }}
                      iconName={"angle-left"}
              >Back</Button>
              <Button variant="primary"
                      loading={isLoadingTest}
                      onClick={() => {
                        setIsLoadingTest(true);
                        new Promise(resolve =>
                            setTimeout(() => resolve(), 250)).then(() => console.log("await 1/2 sec(s)"))
                            .then(() => {
                              doCommitNext();
                              resetSplitPanel();
                              setIsLoadingTest(false);
                              setCommitNext(true);
                            });
                      }}
                      iconName={"angle-right"}
              >Next</Button>
            </SpaceBetween>
            </SpaceBetween>
            </div>
            }
          </SpaceBetween>
        </Container>
      </SpaceBetween>
      {mediaOnToolBox && topicMd && !videoPip &&
      <div className="content" style={{
        borderRadius: '5px',
        position: "fixed", bottom: "1px", right: "1px", height: 315/2, width: 560/2, padding: '2px',
        zIndex: 1, background: "gray", color: "gray" }}
      >
       {props.topicMdControl(overrideTopicMd(topicMd, 315/2, 560/2))}
      </div>}
    </div>
  );
}