import {useEffect, useRef, useState} from 'react';
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import {Select, TextContent} from "@cloudscape-design/components";

import { cloneOptionsV2, gpTopicMdControlV2Keyed } from '../common/common';
import { dropDivV2, dragStartDivV2, dragEnterDivV2, gpImgMdV2, gpSetNextTestInputStateV2 } from './question-common';

/*

  * radio button ref:
    - https://www.w3schools.com/tags/tryit.asp?filename=tryhtml5_input_type_radio
    - https://stackoverflow.com/questions/5592345/how-to-select-a-radio-button-by-default
*/
export function PreviewTopicV2(props) {
  const inputReference = useRef();
  const dragItemDiv = useRef();
  const dragOverItemDiv = useRef();
  const configs = props.settings

  const dropDown = props.dropDown;
  const dragAndDrop = props.dragAndDrop;
  const radioGroup = props.radioGroup;
  const testIndex = 0;

  const [expectedAnswers, setExpectedAnswers] = useState([]);
  const [imagedAnswers, setImagedAnswers] = useState([]);
  const [dropDownAnswers, setDropDownAnswers] = useState([]);
  const [actualAnswers, setActualAnswers] = useState([]);
  const [answersIsMatch, setAnswersIsMatch] = useState([]);
  const [nonBlanksPre, setNonBlanksPre] = useState([]);
  const [nonBlanksPost, setNonBlanksPost] = useState([]);
  const [listAnswerDiv, setListAnswerDiv] = useState([]);
  const [listDiv, setListDiv] = useState([]);
  const [topicMd, setTopicMd] = useState({});
  const [roundState, setRoundState] = useState([]);

  const setters = {
    setExpectedAnswers: setExpectedAnswers,
    setImagedAnswers: setImagedAnswers,
    setDropDownAnswers: setDropDownAnswers,
    setActualAnswers: setActualAnswers,
    setAnswersIsMatch: setAnswersIsMatch,
    setNonBlanksPre: setNonBlanksPre,
    setNonBlanksPost: setNonBlanksPost,
    setListAnswerDiv: setListAnswerDiv,
    setListDiv: setListDiv,
    setTopicMd: setTopicMd,
    setRoundState: setRoundState
  }

  useEffect(() => {
    // testOption, currentState, imgPathFunction, currentStateSetters
    gpSetNextTestInputStateV2(
      props.options[testIndex],
      [],
      (imgName, imgSrc, imgConfigs) => gpImgMdV2(props.imgPath, imgName, props.imgExt, imgSrc, imgConfigs),
      configs,
      setters);
  }, []);

  return (
  <Container>
    <SpaceBetween direction="vertical" size="l">
      <SpaceBetween direction={"horizontal"} size={"s"}>
        <h2>{`${topicMd && topicMd.titleOverride ? topicMd.titleOverride : 'Fill in the blanks'}`}</h2>
      </SpaceBetween>
      {props.topicMdControl(topicMd)}
      <SpaceBetween size="s" direction="vertical">
        {nonBlanksPre.map((nonBlanksPreK, k) => (
          <SpaceBetween key={`ans-sb-${k}`} size="s" direction="horizontal" alignItems="center">
            {nonBlanksPreK
                .map((nonBlanksPreKI, i) => (
                    <SpaceBetween key={`sb-${k}-${i}`} direction={"horizontal"} size={"xxs"} alignItems="center">
                      <TextContent key={`text-pre-${k}-${i}`}>{nonBlanksPreKI}</TextContent>
                      {(dropDown && dropDownAnswers && dropDownAnswers.length > 0 && dropDownAnswers[k].length > 0 && dropDownAnswers[k][i].length > 0)
                        && <Select
                        key={`select-${k}-${i}`}
                        ref={(k == 0 && i == 0) ? inputReference : undefined}
                        selectedOption={ actualAnswers[k][i] ? { label: actualAnswers[k][i], value: actualAnswers[k][i] } : undefined }
                        disabled={(dragAndDrop && actualAnswers[k].length > 1)}
                        onChange={({ detail }) => {
                            let newValueArray = cloneOptionsV2(actualAnswers[k]);
                            newValueArray[i] = detail.selectedOption.value
                            let newItemsValueArray = cloneOptionsV2(actualAnswers);
                            newItemsValueArray[k] = newValueArray;
                            setActualAnswers(newItemsValueArray);

                            let newIsMatchArray = cloneOptionsV2(answersIsMatch[k]);
                            newIsMatchArray[i] = undefined;
                            let newItemsIsMatchArray = cloneOptionsV2(answersIsMatch);
                            newItemsIsMatchArray[k] = newIsMatchArray;
                            setAnswersIsMatch(newItemsIsMatchArray);
                        }}
                        options={dropDownAnswers[k][i].map(answer => ({ label: answer, value: answer }))}
                      />}
                      {(!(dropDown && dropDownAnswers && dropDownAnswers.length > 0 && dropDownAnswers[k].length > 0 && dropDownAnswers[k][i].length > 0)
                         && actualAnswers[k].length > 0) &&
                      <div
                         key={`dragOver-div-${k}-${i}`}
                         onDragStart={(e) => dragStartDivV2(e, actualAnswers[k][i], i+(k+1)*1000, dragItemDiv)}
                         onDragEnter={(e) => dragEnterDivV2(e, actualAnswers[k][i], i+(k+1)*1000, dragOverItemDiv)}
                         onDragEnd={(e) => dropDivV2(e, dragItemDiv, dragOverItemDiv, listDiv, setListDiv, actualAnswers, setActualAnswers, answersIsMatch, setAnswersIsMatch)}
                         draggable={(dragAndDrop && listAnswerDiv.length > 1)}>
                      {!(radioGroup && listAnswerDiv.length) ?
                      <Input
                          key={`input-${k}-${i}`}
                          ref={(k == 0 && i == 0) ? inputReference : undefined}
                          value={actualAnswers[k][i]}
                          disabled={(dragAndDrop && listAnswerDiv.length > 1)}
                          onChange={(event) => {
                            let newValueArray = cloneOptionsV2(actualAnswers[k]);
                            newValueArray[i] = event.detail.value
                            let newItemsValueArray = cloneOptionsV2(actualAnswers);
                            newItemsValueArray[k] = newValueArray;
                            setActualAnswers(newItemsValueArray);

                            let newIsMatchArray = cloneOptionsV2(answersIsMatch[k]);
                            newIsMatchArray[i] = undefined;
                            let newItemsIsMatchArray = cloneOptionsV2(answersIsMatch);
                            newItemsIsMatchArray[k] = newIsMatchArray;
                            setAnswersIsMatch(newItemsIsMatchArray);
                          }}
                      />
                      :
                      <form key={`form-${k}-${i}`}>
                        <SpaceBetween key={`form-sb-${k}-${i}`} direction="horizontal" size="xs" alignItems="start">
                        {listDiv.map((item, index) => (
                        <SpaceBetween key={`form-sb-group-${k}-${i}-${index}`} direction="horizontal" size="xxs" alignItems="start">
                          <input
                            key={`form-sb-group-input-${k}-${i}-${index}`}
                            type="radio" id={index} name={`(${k},${i})`} value={item} checked={actualAnswers[k][i] === item}
                            onChange={() => {}}
                          />
                          <label key={`form-sb-group-label-${k}-${i}-${index}`} for={index}>
                            {item}
                          </label>
                        </SpaceBetween>
                        ))}
                        </SpaceBetween>
                      </form>
                      }
                      </div>}
                      {(actualAnswers[k].length > 0 && imagedAnswers[k][i]) && gpTopicMdControlV2Keyed({ md: imagedAnswers[k][i], key: `image-${k}-${i}` })}
                      {(actualAnswers[k].length > 0 && nonBlanksPost[k][i]) && <TextContent key={`text-post-${k}-${i}`}>{nonBlanksPost[k][i]}</TextContent>}
                    </SpaceBetween>
                ))}
          </SpaceBetween>))
        }
      </SpaceBetween>
      {<SpaceBetween>
        {!(radioGroup && listAnswerDiv.length > 1) && (dragAndDrop && listAnswerDiv.length > 1) &&
          <div style={{"borderRadius": '25px', "borderStyle":'dotted', margin:'5px 10%'}}
            onDragEnter={(e) => dragEnterDivV2(e, "Answers", 0, dragOverItemDiv)}
            onDragEnd={(e) => dropDivV2(e, dragItemDiv, dragOverItemDiv, listDiv, setListDiv, actualAnswers, setActualAnswers, answersIsMatch, setAnswersIsMatch)}
            key={`baseDiv`}>
                <div style={{textAlign:'center'}}><h4>{`Answers (${listDiv.length})`}</h4></div>
                <div style={{width: '100%', display: 'flex', "flexDirection": 'row', "flexWrap": 'wrap', "justifyContent": 'center'}}>
                {listDiv.map((item, index) => (
                    <div style={{margin:'10px 1%', textAlign:'center', overflow: 'hidden', "whiteSpace": 'nowrap', "display": 'inlineBlock'}}
                         onDragStart={(e) => dragStartDivV2(e, item, index, dragItemDiv)}
                         onDragEnter={(e) => dragEnterDivV2(e, item, index, dragOverItemDiv)}
                         onDragEnd={(e) => dropDivV2(e, dragItemDiv, dragOverItemDiv, listDiv, setListDiv, actualAnswers, setActualAnswers, answersIsMatch, setAnswersIsMatch)}
                         id={index}
                         key={`pool-${index}`}
                          draggable={
                           (dragAndDrop && listAnswerDiv.length > 1)}>
                         <Button wrapText={false}>{item}</Button>
                    </div>
                ))}
                </div>
          </div>}
      </SpaceBetween>}
    </SpaceBetween>
  </Container>
  );
}