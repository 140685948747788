import {useEffect, useRef, useState} from 'react';
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import HelpPanel from "@cloudscape-design/components/help-panel";
import {Select, StatusIndicator, TextContent} from "@cloudscape-design/components";
import Toggle from "@cloudscape-design/components/toggle";
import ProgressBar from "@cloudscape-design/components/progress-bar";
import Modal from "@cloudscape-design/components/modal";
import Box from "@cloudscape-design/components/box";

import { useContainerQuery } from '@cloudscape-design/component-toolkit';
import { cloneOptionsV2, gpTopicMdControlV2Keyed, overrideTopicMd } from '../common/common'
import { RoundSettingsV2 } from './round-settings'
import { DisplaySummaryV2 } from './display-summary'
import { dropDivV2, dragStartDivV2, dragEnterDivV2, gpSetNextTestInputStateV2, calculateTotalAttemptsV2,
  canDisableWhenInteractiveMode, showStatusIndicator, typeOfStatusIndicator, answerOfStatusIndicator
} from './question-common';

export function MultiQuestionMultiOptionsPanelV2(props) {
  const inputReference = useRef(null);

  const dragItemDiv = useRef();
  const dragOverItemDiv = useRef();
  const [listAnswerDiv, setListAnswerDiv] = useState([]);
  const [listDiv, setListDiv] = useState([]);

  const startingDoShuffle = props.settings && props.settings.doShuffle ? props.settings.doShuffle : false;
  const startingImgHeight = props.settings && props.settings.imgHeight ? props.settings.imgHeight : 30;
  const startingAttempts = props.settings && props.settings.attempts ? props.settings.attempts : 3;
  const maxQuestions = props.settings && props.settings.maxQuestions
    && (props.settings.maxQuestions > 0 && props.settings.maxQuestions < props.options.length) ?
    props.settings.maxQuestions : props.options.length;
  const maxHintedAnswers = props.settings && props.settings.maxHintedAnswers ?
    props.settings.maxHintedAnswers : 0;
  const canDragAndDrop = props.settings && props.settings.dragAndDrop ? props.settings.dragAndDrop : false;
  const canDropDown = props.settings && props.settings.dropDown ? props.settings.dropDown : false;
  const canRadioGroup = props.settings && props.settings.radioGroup ? props.settings.radioGroup : false;
  const lowerCaseEnabled = false;
  const interactiveMode = true;

  // displayable state attributes
  const [nonBlanksPre, setNonBlanksPre] = useState([]);
  const [nonBlanksPost, setNonBlanksPost] = useState([]);
  const [actualAnswers, setActualAnswers] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [imagedAnswers, setImagedAnswers] = useState([]);
  const [dropDownAnswers, setDropDownAnswers] = useState([]);
  const [expectedAnswers, setExpectedAnswers] = useState([]);
  const [answersIsMatch, setAnswersIsMatch] = useState([]);

  // test state attributes
  const newTestOptions = cloneOptionsV2(props.options);

  const newRandOptionIndex = startingDoShuffle ? Math.floor(Math.random() * newTestOptions.length) : 0;
  const [testOptions, setTestOptions] = useState(newTestOptions.filter((v, i) => i !== newRandOptionIndex));
  const [testIndex, setTestIndex] = useState(0);
  const [randOptionIndex, setRandOptionIndex] = useState(newRandOptionIndex);
  const [testOption, setTestOption] = useState(newTestOptions[newRandOptionIndex]);
  const [isLoadingTest, setIsLoadingTest] = useState(false);
  const [testRound, setTestRound] = useState(1);

  // round state attributes
  const [roundRandOptionIndexes, setRoundRandOptionIndexes] = useState([newRandOptionIndex]);
  const [roundState, setRoundState] = useState([]);
  const [displaySummary, setDisplaySummary] = useState(false);
  const [summaryTestRound, setSummaryTestRound] = useState(undefined);
  const [warnReview, setWarnReview] = useState(false);

  // yet to be defined
  const [doShuffle, setDoShuffle] = useState(startingDoShuffle);
  const [dragAndDrop, setDragAndDrop ] = useState(canDragAndDrop);
  const [dropDown, setDropDown ] = useState(canDropDown);
  const [radioGroup, setRadioGroup] = useState(canRadioGroup);
  const [configs, setConfigs] =  useState({
    height: startingImgHeight,
    attempts: startingAttempts,
    shuffle: startingDoShuffle,
    maxQuestions: maxQuestions,
    maxHintedAnswers: maxHintedAnswers,
    dropDown: canDropDown,
    dragAndDrop: canDragAndDrop,
    radioGroup: canRadioGroup,
    lowerCaseEnabled: lowerCaseEnabled,
    interactiveMode: interactiveMode
  });
  const [selectedHeightOption, setSelectedHeightOption] =  useState({ label: `${startingImgHeight}`, value: startingImgHeight });
  const [selectedAttemptsOption, setSelectedAttemptsOption] =  useState({ label: `${startingAttempts}`, value: startingAttempts });
  const [selectedMaxQuestionsOption, setSelectedMaxQuestionsOption] =  useState({
    label: `${maxQuestions}`, value: maxQuestions
  });
  const [totalHintedAnswers, setTotalHintedAnswers] = useState(0);

  const [isReplay, setIsReplay] = useState(props.roundState ? true : false);
  const [testConfigModalVisible, setTestConfigModalVisible] = useState(props.roundState ? false : true);
  const [topicMd, setTopicMd] = useState({});
  const [videoPip, setVideoPip] = useState(undefined);
  const [mediaOnToolBox, setMediaOnToolBox] = useState(false);

  const setters = {
    setExpectedAnswers: setExpectedAnswers,
    setImagedAnswers: setImagedAnswers,
    setDropDownAnswers: setDropDownAnswers,
    setActualAnswers: setActualAnswers,
    setAnswersIsMatch: setAnswersIsMatch,
    setNonBlanksPre: setNonBlanksPre,
    setNonBlanksPost: setNonBlanksPost,
    setListAnswerDiv: setListAnswerDiv,
    setListDiv: setListDiv,
    setTopicMd: setTopicMd,
    setRoundState: setRoundState
  }

  const setNextTestInputState = (nextQuestionList, currentState) => {
      gpSetNextTestInputStateV2(nextQuestionList, currentState, props.imgMd, configs, setters);
  }

  function loadTestInputFromRoundState(index, currentRoundState) {
    const oldRoundState = currentRoundState[index];
    setExpectedAnswers(oldRoundState.expectedAnswers);
    setImagedAnswers(oldRoundState.imagedAnswers);
    setDropDownAnswers(oldRoundState.dropDownAnswers);
    setActualAnswers(oldRoundState.actualAnswers);
    setAnswersIsMatch(oldRoundState.answersIsMatch);
    setNonBlanksPre(oldRoundState.nonBlanksPre);
    setNonBlanksPost(oldRoundState.nonBlanksPost);
    setListDiv(oldRoundState.listDiv);
    setListAnswerDiv(oldRoundState.listAnswerDiv);
    setTopicMd(oldRoundState.topicMd);
  }

  function saveTestInputIntoState(index, newActualAnswers) {
    let newRoundState = [...roundState];
    let oldInputState = newRoundState[index]
    const newAnswersIsMatch = actualAnswers.map((item, k) =>
      item.map((value, i) => actualAnswers[k][i] ? (actualAnswers[k][i] === toLowerCase(expectedAnswers[k][i]) ? "success" : "error") : undefined));
    newRoundState[index] = {
      expectedAnswers: expectedAnswers,
      imagedAnswers: imagedAnswers,
      dropDownAnswers: dropDownAnswers,
      actualAnswers: [...newActualAnswers],
      answersIsMatch: newAnswersIsMatch,
      nonBlanksPre: nonBlanksPre,
      nonBlanksPost: nonBlanksPost,
      listDiv: listDiv,
      listAnswerDiv: listAnswerDiv,
      topicMd: topicMd,
      attempts: newAnswersIsMatch.every(item => item.every(isMatch => isMatch === "success")) ? configs.attempts : oldInputState.attempts + 1
    };
    return newRoundState;
  }

  function calcRoundRandOptionIndexes() {
    let tmpAvailableIndexes = newTestOptions.map((answer, i) => i);
    let tmpRoundRandOptionIndex = [];
    do {
      const newTmpRoundRandOptionIndex = configs.shuffle ? Math.floor(Math.random() * tmpAvailableIndexes.length) : 0;
      tmpRoundRandOptionIndex.push(tmpAvailableIndexes[newTmpRoundRandOptionIndex]);
      const totalAvailableIndexes = tmpAvailableIndexes.length;
      tmpAvailableIndexes = [
        ...tmpAvailableIndexes.slice(0, newTmpRoundRandOptionIndex),
        ...tmpAvailableIndexes.slice(newTmpRoundRandOptionIndex + 1, totalAvailableIndexes)
      ];
    } while (tmpAvailableIndexes.length >= 1);
    return tmpRoundRandOptionIndex;
  }

  useEffect(() => {
    if (isReplay) {
      const newTestIndex = 0;
      setTestIndex(newTestIndex);
      setRoundState(props.roundState);
      resetSplitPanel();
      setIsLoadingTest(true);
      loadTestInputFromRoundState(newTestIndex, props.roundState);
      new Promise(resolve => setTimeout(() => resolve(), 75))
          .then(() => setIsLoadingTest(false))
          .then(() => setTimeout(() => { if (inputReference.current) inputReference.current.focus(); }, 75));
      return;
    }
    // else create next input state
    setNextTestInputState(testOption, []);
    setTimeout(() => { if (inputReference.current) inputReference.current.focus(); }, 1000);
//    if (props.renderSplitPanelItems) {
//      props.renderSplitPanelItems({});
//    }
  }, [])

    function resetSplitPanel() {
      // reset panel on testOption change and media on tool box toggle as well
        // the resulting effect is to reset panel on try success/fail, next or back
      props.routerProps.resetSplitPanel();
      setMediaOnToolBox(false);
      if (videoPip && document.pictureInPictureElement) document.exitPictureInPicture();
    }

  const doNext = async (doStoreCurrentState) => {
    let newTestIndex = testIndex + 1;
    // save state before moving forward
    let currentRoundState = doStoreCurrentState ? saveTestInputIntoState(testIndex, actualAnswers) : roundState;
    setRoundState(currentRoundState);
    if (newTestIndex < currentRoundState.length) {
      setRandOptionIndex(roundRandOptionIndexes[newTestIndex]);
      loadTestInputFromRoundState(newTestIndex, currentRoundState);
    } else {
      // removes the question from the options or restore all the questions in a new round
      const isLastTest = isReplay || ((selectedMaxQuestionsOption.value < props.options.length) ?
        testOptions.length <= (props.options.length - selectedMaxQuestionsOption.value) : testOptions.length === 0);
      if (isLastTest) {
        setDisplaySummary(true);
        setSummaryTestRound(currentRoundState);
      }
      const newTestOptions = isLastTest ? cloneOptionsV2(props.options) : testOptions;
      // calculates another random question index
      const newRandOptionIndex = configs.shuffle ? Math.floor(Math.random() * newTestOptions.length) : 0;
      setRandOptionIndex(newRandOptionIndex);
      // sets new test option
      const newTestOption = newTestOptions[newRandOptionIndex];
      setTestOption(newTestOption);

      // voids answer(s) match array
      // voids answer(s) array
      if (isLastTest) {
        setRoundRandOptionIndexes([newRandOptionIndex]);
        currentRoundState = [];
        setRoundState([]);
        newTestIndex = 0;
      }
      setNextTestInputState(newTestOption, currentRoundState);
      setTestOptions(newTestOptions.filter((value, i) => i !== newRandOptionIndex));
    }
    setTestIndex(newTestIndex);
  };

  function toLowerCase(answer) {
    return lowerCaseEnabled ? answer.toLowerCase() : answer;
  }

  function getTotalQuestions() {
    return (selectedMaxQuestionsOption.value < props.options.length ? selectedMaxQuestionsOption.value : props.options.length);
  }

  function getTotalAttempts() {
    return calculateTotalAttemptsV2(roundState, testIndex);
  }

  function getTotalAnswers() {
    return actualAnswers.map(aaItem => aaItem.length).reduce((acc, val) => acc + val, 0);
  }

  function getTopicTitle(tmpTopicMd, tmpGetQuestion, tmpTestOption) {
    return tmpTopicMd && tmpTopicMd.titleOverride ?
    tmpTopicMd.titleOverride
    :
    (tmpGetQuestion ? tmpGetQuestion(tmpTestOption) : 'Do the exercise')
  }

  function commitPrevious() {
    // reset media on tool box on back
    resetSplitPanel();
    setIsLoadingTest(true);
    const newTestIndex = testIndex - 1;
    setTestIndex(newTestIndex);
    loadTestInputFromRoundState(newTestIndex, roundState);
    new Promise(resolve =>
        setTimeout(() => resolve(), 75)).then(() => console.log("await 0.075 sec(s)"))
        .then(() => {
          setIsLoadingTest(false);
        })
        .then(() => setTimeout(() => { if (inputReference.current) inputReference.current.focus(); }, 75));
  }

  function commitNext() {
    // reset media on tool box on next
    resetSplitPanel();
    setIsLoadingTest(true);
    setAnswersIsMatch(expectedAnswers.map(item => item.map(answer => undefined)));
    new Promise(resolve =>
        setTimeout(() => resolve(), 75)).then(() => console.log("await 0.075 sec(s)"))
        .then(() => {
          setIsLoadingTest(false);
          doNext(false);
        })
        .then(() => setTimeout(() => { if (inputReference.current) inputReference.current.focus(); }, 75));
  }

  function commitTry() {
    setIsLoadingTest(true);
    setAnswersIsMatch(expectedAnswers.map(item => item.map(answer => undefined)));
    const newAnswerIsMatch = actualAnswers.map((item, k) => item
        .map((value, i) => actualAnswers[k][i] ? (actualAnswers[k][i] === toLowerCase(expectedAnswers[k][i]) ? "success" : "error") : undefined));
    setAnswersIsMatch(newAnswerIsMatch);
    new Promise(resolve =>
        setTimeout(() => resolve(), 500)).then(() => console.log("await 1/2 sec(s)"))
        .then(() => {
          setIsLoadingTest(false);
          if (newAnswerIsMatch.every((item, k) => item.every(isMatch => isMatch === "success")) || getTotalAttempts() + 1 >= configs.attempts) {
            // reset media on tool box on forced forward
            resetSplitPanel();
            doNext(true);
          } else {
            setRoundState(saveTestInputIntoState(testIndex, actualAnswers));
          }
        })
        .then(() => setTimeout(() => { if (inputReference.current) inputReference.current.focus(); }, 250));
  }

  function getIsDropDown(stateDropDown, stateTopicMd) {
    if (stateDropDown) return stateDropDown;
    return (stateTopicMd.displayModeOverride && stateTopicMd.displayModeOverride === "dropDown");
  }

  function getIsDragAndDrop(stateDragAndDrop, stateTopicMd) {
    if (stateDragAndDrop) return stateDragAndDrop;
    return (stateTopicMd.displayModeOverride && stateTopicMd.displayModeOverride === "dragAndDrop");
  }

  function getIsRadioGroup(stateRadioGroup, stateTopicMd) {
    if (stateRadioGroup) return stateRadioGroup;
    return (stateTopicMd.displayModeOverride && stateTopicMd.displayModeOverride === "radioGroup");
  }

  const [useMobileView, ref] = useContainerQuery(entry => entry.contentBoxWidth < 688);

  useEffect(() => {
    if (topicMd && topicMd.video && topicMd.video.src &&
          !(topicMd.embed && topicMd.embed.src) &&
          !(topicMd.audio && topicMd.audio.src) &&
          !(topicMd.img && topicMd.img.src)) {
      const embedVideoPip = document.getElementById("picInPicVideo");
      function onExitPip() { setMediaOnToolBox(false); }
      function onEnterPip() { setMediaOnToolBox(true); }
      embedVideoPip.addEventListener("leavepictureinpicture", onExitPip, false);
      embedVideoPip.addEventListener("enterpictureinpicture", onEnterPip, false);
      setVideoPip(embedVideoPip);
      return;
    }
    setVideoPip(undefined);
  }, [topicMd]);

  /**
  * // =================================
  * // EXPERIMENTAL RESULTS TO AUTO SWITCH MEDIA,
  * // =================================
  * references:
  * - https://www.javascripttutorial.net/dom/css/check-if-an-element-is-visible-in-the-viewport/
  * - https://stackoverflow.com/questions/10041433/how-to-detect-when-certain-div-is-out-of-view
  * - https://dev.to/spankyed/enable-pip-on-youtube-video-embeds-2gel
  * - https://www.youtube.com/watch?v=Y1Tu3m06j-M
  * - https://www.forasoft.com/blog/article/picture-in-picture-react-js-695
  * - https://developer.mozilla.org/en-US/docs/Web/API/Picture-in-Picture_API
  * - https://developer.mozilla.org/en-US/docs/Web/API/HTMLVideoElement/leavepictureinpicture_event
  * - https://developer.mozilla.org/en-US/docs/Web/API/DocumentPictureInPicture
  * - https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetParent
  * - https://developer.chrome.com/docs/web-platform/document-picture-in-picture
  * - https://www.w3schools.com/howto/howto_css_fullscreen_video.asp
  * - https://www.w3schools.com/howto/howto_css_modals.asp
  * - https://blog.openreplay.com/picture-in-picture-video-with-html5-and-javascript/
  * - https://www.w3.org/TR/picture-in-picture/
  * - https://css-tricks.com/an-introduction-to-the-picture-in-picture-web-api/

   // EXPERIMENT 1, 2 - does not auto play and logs even when its been already open or closed

   function isVisibleInViewport(elem) {
     var y = elem.offsetTop;
     var height = elem.offsetHeight;

     while ( elem = elem.offsetParent )
        y += elem.offsetTop;

     var maxHeight = y + height;
     var isVisible = ( y < ( window.pageYOffset + window.innerHeight ) ) && ( maxHeight >= window.pageYOffset );
     return isVisible;
   }

   function isElementVisible(element) {
     let elementTopOffset = element.offsetTop;
     let elementBottomOffset = element.offsetHeight + elementTopOffset;

     let screenTopOffset = window.scrollY;
     let screenBottomOffset = screenTopOffset + window.innerHeight;

     return elementTopOffset > screenTopOffset && screenBottomOffset > elementBottomOffset;
   }

   function isInViewport(el) {
     const rect = el.getBoundingClientRect();
     return (
         rect.top >= 0 &&
         rect.left >= 0 &&
         rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
         rect.right <= (window.innerWidth || document.documentElement.clientWidth)
     );
   }

   function check() {
       var canvas = document.getElementById("webGlExperiments");
       if(isVisibleInViewport(canvas)) {
           if (mediaOnToolBox) {
             setMediaOnToolBox(false);
           }
       } else if (!isInViewport(canvas)) {
           if (!mediaOnToolBox) {
             setMediaOnToolBox(true);
           }
       }
   }
   window.addEventListener("scroll", check, false);

   // EXPERIMENT 3 - works when it reaches the top of the component
   // https://www.youtube.com/watch?v=bcnRB8Q5UEM

    function isElementVisible(query) {
      let element = document.getElementById(query);
      let elementTopOffset = element.offsetTop + element.offsetParent.offsetTop;
      let elementBottomOffset = element.offsetHeight + elementTopOffset;

      let screenTopOffset = window.scrollY;
      let screenBottomOffset = screenTopOffset + window.innerHeight;

      return elementTopOffset > screenTopOffset && screenBottomOffset > elementBottomOffset;
    }
     function check(query) {
         var element = document.getElementById(query);
         if (element) {
             if(isElementVisible(query)) {
                 if (mediaOnToolBox) {
                   setMediaOnToolBox(false);
                   if (videoPip && document.pictureInPictureElement) document.exitPictureInPicture();
                 }
             } else {
                 if (!mediaOnToolBox) {
                   setMediaOnToolBox(true);
                   if (videoPip) videoPip.requestPictureInPicture();
                 }
             }
         }
     }
     document.addEventListener("scroll", check.bind(null, "topicMdTracker"));
  */

  return (
    <div>
      <SpaceBetween size="m">
        <Header variant="h1">
          <SpaceBetween direction={"horizontal"} size={"s"}>
            {props.header}
            {/*<Button variant="inline-icon" disabled
                    onClick={() => {
                      setTestConfigModalVisible(true);
                    }}
                    iconName={"settings"}
            />*/}
          </SpaceBetween>
        </Header>
        <Modal
          onDismiss={() => setWarnReview(false)}
          visible={warnReview}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" onChange={() => setWarnReview(false)}>Cancel</Button>
                <Button variant="primary" onChange={() => commitNext()}>Ok</Button>
              </SpaceBetween>
            </Box>
          }
          header="Review"
        >
          Unless you want to keep reviewing your answers press Ok to Submit the results
        </Modal>
        <Container fitHeight={true}>
          {(!props.onlineAssignmentPublish) && <RoundSettingsV2
               onExit={props.onExit}
               title={props.title}
               configs={configs}
               setConfigs={setConfigs}
               hideDisplayToggles={true}
               options={props.options}
               questionArray={props.questionArray}
               testConfigModalVisible={testConfigModalVisible}
               setTestConfigModalVisible={setTestConfigModalVisible}
               doShuffle={doShuffle}
               setDoShuffle={setDoShuffle}
               imageOnly={false}
               setImageOnly={(input) => { /*does nothing*/ }}
               dropDown={dropDown}
               setDropDown={setDropDown}
               dragAndDrop={dragAndDrop}
               setDragAndDrop={setDragAndDrop}
               radioGroup={radioGroup}
               setRadioGroup={setRadioGroup}
               cantDropDown={!canDropDown}
               cantDragAndDrop={!canDragAndDrop}
               selectedMaxQuestionsOption={selectedMaxQuestionsOption}
               setSelectedMaxQuestionsOption={setSelectedMaxQuestionsOption}
               selectedHeightOption={selectedHeightOption}
               setSelectedHeightOption={setSelectedHeightOption}
               selectedAttemptsOption={selectedAttemptsOption}
               setSelectedAttemptsOption={setSelectedAttemptsOption}
               inputReference={inputReference}
          />}
          <SpaceBetween direction={"vertical"} size={"l"}>
            {displaySummary ?
            <DisplaySummaryV2
              summaryTestRound={summaryTestRound}
              getTotalQuestions={getTotalQuestions}
              testRound={testRound}
              setTestRound={setTestRound}
              displaySummary={displaySummary}
              setDisplaySummary={setDisplaySummary}
              inputReference={inputReference}
              setTestConfigModalVisible={setTestConfigModalVisible}
              isReplay={isReplay}
              setIsReplay={setIsReplay}
              onlineAssignmentPublish={props.onlineAssignmentPublish}
              onlineAssignmentConfig={props.onlineAssignmentConfig}
              onExit={props.onExit}
              onReview={(lastRoundState) => {
                const newTestIndex = 0;
                setTestIndex(newTestIndex);
                setRoundState(lastRoundState);
                resetSplitPanel();
                setIsLoadingTest(true);
                loadTestInputFromRoundState(newTestIndex, lastRoundState);
                new Promise(resolve => setTimeout(() => resolve(), 75))
                    .then(() => setIsLoadingTest(false))
                    .then(() => setTimeout(() => { if (inputReference.current) inputReference.current.focus(); }, 75));
              }}
            />
            :
            <SpaceBetween direction={"vertical"} size={"l"}>
            <SpaceBetween direction={"horizontal"} size={"s"}>
              <h2>{`${getTopicTitle(topicMd, props.getQuestion, testOption)}`}</h2>
              <h4>{` [Round: ${testRound}]`}</h4>
              <ProgressBar
                value={(testIndex + 1)/getTotalQuestions()*100}
                additionalInfo={`[Question: ${testIndex + 1}/${getTotalQuestions()}]`}
              />
              <ProgressBar
                value={getTotalAttempts()/configs.attempts*100}
                additionalInfo={`[Attempt(s): ${getTotalAttempts()}/${configs.attempts}]`}
              />
              <Toggle
                checked={mediaOnToolBox}
                disabled={!topicMd || (!topicMd.embed && !topicMd.audio && !topicMd.video && !topicMd.img)
                  || !(    (topicMd.embed && Object.keys(topicMd.embed).length > 0)
                        || (topicMd.audio && Object.keys(topicMd.audio).length > 0)
                        || (topicMd.video && Object.keys(topicMd.video).length > 0)
                        || (topicMd.img   && Object.keys(topicMd.img).length   > 0)
                      )
                }
                onChange={({ detail }) => {
                  const tmpMediaOnToolBox = detail.checked;
                  setMediaOnToolBox(tmpMediaOnToolBox);
                  if (tmpMediaOnToolBox) {
                    if (videoPip) videoPip.requestPictureInPicture().catch(e => console.error(e.message));
                  } else {
                    if (videoPip && document.pictureInPictureElement) document.exitPictureInPicture();
                    props.routerProps.resetSplitPanel();
                  }
                }}
              >Media on Toolbox</Toggle>
            </SpaceBetween>
            <div id="topicMdTracker">{!mediaOnToolBox && props.topicMdControl(topicMd)}</div>
            <SpaceBetween size="s" direction="vertical">
              {nonBlanksPre.map((nonBlanksPreK, k) => (
                <SpaceBetween key={`ans-sb-${k}`} size="s" direction="horizontal" alignItems="center">
                  {nonBlanksPreK
                      .map((nonBlanksPreKI, i) => (
                          <SpaceBetween key={`sb-${k}-${i}`} direction={"horizontal"} size={"xxs"} alignItems="center">
                            <TextContent key={`text-pre-${k}-${i}`}>{nonBlanksPreKI}</TextContent>
                            {(getIsDropDown(dropDown, topicMd) && dropDownAnswers && dropDownAnswers.length > 0 && dropDownAnswers[k].length > 0 && dropDownAnswers[k][i].length > 0)
                              && <Select
                              key={`select-${k}-${i}`}
                              ref={(k == 0 && i == 0) ? inputReference : undefined}
                              selectedOption={ actualAnswers[k][i] ? { label: actualAnswers[k][i], value: actualAnswers[k][i] } : undefined }
                              disabled={
                                getTotalAttempts() >= configs.attempts
                                || (roundState[testIndex] && roundState[testIndex].actualAnswers && roundState[testIndex].actualAnswers[k][i] === toLowerCase(expectedAnswers[k][i]))
                                || canDisableWhenInteractiveMode(configs, roundState, testIndex, k, i, expectedAnswers)
                                || isLoadingTest
                                || (getIsDragAndDrop(dragAndDrop, topicMd) && actualAnswers[k].length > 1)
                                || (getIsRadioGroup(radioGroup, topicMd) && actualAnswers[k].length > 1)
                                || isReplay
                              }
                              onChange={({ detail }) => {
                                  let newValueArray = cloneOptionsV2(actualAnswers[k]);
                                  newValueArray[i] = detail.selectedOption.value
                                  let newItemsValueArray = cloneOptionsV2(actualAnswers);
                                  newItemsValueArray[k] = newValueArray;
                                  setActualAnswers(newItemsValueArray);

                                  let newIsMatchArray = cloneOptionsV2(answersIsMatch[k]);
                                  newIsMatchArray[i] = undefined;
                                  let newItemsIsMatchArray = cloneOptionsV2(answersIsMatch);
                                  newItemsIsMatchArray[k] = newIsMatchArray;
                                  setAnswersIsMatch(newItemsIsMatchArray);
                              }}
                              options={dropDownAnswers[k][i].map(answer => ({ label: answer, value: answer }))}
                            />}
                            {(!(getIsDropDown(dropDown, topicMd) && dropDownAnswers && dropDownAnswers.length > 0 && dropDownAnswers[k].length > 0 && dropDownAnswers[k][i].length > 0)
                               && !(getIsRadioGroup(radioGroup, topicMd) && listAnswerDiv.length)
                               && actualAnswers[k].length > 0) &&
                            <div
                               key={`dragOver-div-${k}-${i}`}
                               onDragStart={(e) => dragStartDivV2(e, actualAnswers[k][i], i+(k+1)*1000, dragItemDiv)}
                               onDragEnter={(e) => dragEnterDivV2(e, actualAnswers[k][i], i+(k+1)*1000, dragOverItemDiv)}
                               onDragEnd={(e) => dropDivV2(e, dragItemDiv, dragOverItemDiv, listDiv, setListDiv, actualAnswers, setActualAnswers, answersIsMatch, setAnswersIsMatch)}
                               draggable={
                                 (getIsDragAndDrop(dragAndDrop, topicMd) && listAnswerDiv.length > 1) &&
                                 !(getTotalAttempts() >= configs.attempts) &&
                                 !isLoadingTest && !isReplay
                            }>
                            <Input
                                key={`input-${k}-${i}`}
                                ref={(k == 0 && i == 0) ? inputReference : undefined}
                                value={actualAnswers[k][i]}
                                disabled={
                                  getTotalAttempts() >= configs.attempts
                                  || roundState[testIndex] && roundState[testIndex].actualAnswers && roundState[testIndex].actualAnswers[k][i] === toLowerCase(expectedAnswers[k][i])
                                  || canDisableWhenInteractiveMode(configs, roundState, testIndex, k, i, expectedAnswers)
                                  || isLoadingTest
                                  || (getIsDragAndDrop(dragAndDrop, topicMd) && listAnswerDiv.length > 1)
                                  || (getIsRadioGroup(radioGroup, topicMd) && listAnswerDiv.length > 1)
                                  || isReplay
                                }
                                onChange={(event) => {
                                  let newValueArray = cloneOptionsV2(actualAnswers[k]);
                                  newValueArray[i] = event.detail.value
                                  let newItemsValueArray = cloneOptionsV2(actualAnswers);
                                  newItemsValueArray[k] = newValueArray;
                                  setActualAnswers(newItemsValueArray);

                                  let newIsMatchArray = cloneOptionsV2(answersIsMatch[k]);
                                  newIsMatchArray[i] = undefined;
                                  let newItemsIsMatchArray = cloneOptionsV2(answersIsMatch);
                                  newItemsIsMatchArray[k] = newIsMatchArray;
                                  setAnswersIsMatch(newItemsIsMatchArray);
                                }}
                            />
                            </div>}
                            {(!getIsDropDown(dropDown, topicMd) &&
                              !getIsDragAndDrop(dragAndDrop, topicMd) &&
                              getIsRadioGroup(radioGroup, topicMd) && listAnswerDiv.length &&
                              actualAnswers[k].length > 0) &&
                            <form key={`form-${k}-${i}`}>
                              <SpaceBetween key={`form-sb-${k}-${i}`} direction="horizontal" size="xs" alignItems="start">
                              {listDiv.map((item, index) => (
                              <SpaceBetween key={`form-sb-group-${k}-${i}-${index}`} direction="horizontal" size="xxs" alignItems="start">
                                <input
                                  disabled={
                                    getTotalAttempts() >= configs.attempts
                                    || roundState[testIndex] && roundState[testIndex].actualAnswers && roundState[testIndex].actualAnswers[k][i] === toLowerCase(expectedAnswers[k][i])
                                    || canDisableWhenInteractiveMode(configs, roundState, testIndex, k, i, expectedAnswers)
                                    || isLoadingTest
                                    || isReplay
                                  }
                                  key={`form-sb-group-input-${k}-${i}-${index}`}
                                  type="radio" id={index} name={`(${k},${i})`} value={item}
                                  checked={actualAnswers[k][i] === item}
                                  onChange={() => {
                                    let newValueArray = cloneOptionsV2(actualAnswers[k]);
                                    newValueArray[i] = item
                                    let newItemsValueArray = cloneOptionsV2(actualAnswers);
                                    newItemsValueArray[k] = newValueArray;
                                    setActualAnswers(newItemsValueArray);

                                    let newIsMatchArray = cloneOptionsV2(answersIsMatch[k]);
                                    newIsMatchArray[i] = undefined;
                                    let newItemsIsMatchArray = cloneOptionsV2(answersIsMatch);
                                    newItemsIsMatchArray[k] = newIsMatchArray;
                                    setAnswersIsMatch(newItemsIsMatchArray);
                                  }}
                                />
                                <label key={`form-sb-group-label-${k}-${i}-${index}`} for={index}>
                                  {item}
                                </label>
                              </SpaceBetween>
                              ))}
                              </SpaceBetween>
                            </form>}
                            {showStatusIndicator(configs, isReplay, false, roundState, testIndex, k, i, answersIsMatch[k][i], expectedAnswers) &&
                            <StatusIndicator key={`status-${k}-${i}`} type={typeOfStatusIndicator(false, answersIsMatch[k][i])}>
                              {answerOfStatusIndicator(configs, roundState, testIndex, k, i, answersIsMatch[k][i], expectedAnswers)}
                            </StatusIndicator>}
                            {(actualAnswers[k].length > 0 && imagedAnswers[k][i]) && gpTopicMdControlV2Keyed({ md: imagedAnswers[k][i], key: `image-${k}-${i}` })}
                            {(actualAnswers[k].length > 0 && nonBlanksPost[k][i]) && <TextContent key={`text-post-${k}-${i}`}>{nonBlanksPost[k][i]}</TextContent>}
                          </SpaceBetween>
                      ))}
                </SpaceBetween>))
              }
            </SpaceBetween>
            <SpaceBetween>
              {!(getIsRadioGroup(radioGroup, topicMd) && listAnswerDiv.length > 1) &&
                (getIsDragAndDrop(dragAndDrop, topicMd) && listAnswerDiv.length > 1) &&
                <div style={{"borderRadius": '25px', "borderStyle":'dotted', margin:'5px 10%'}}
                  onDragEnter={(e) => dragEnterDivV2(e, "Answers", 0, dragOverItemDiv)}
                  onDragEnd={(e) => dropDivV2(e, dragItemDiv, dragOverItemDiv, listDiv, setListDiv, actualAnswers, setActualAnswers, answersIsMatch, setAnswersIsMatch)}
                  key={`baseDiv`}>
                      <div style={{textAlign:'center'}}><h4>{`Answers (${listDiv.length})`}</h4></div>
                      <div style={{width: '100%', display: 'flex', "flexDirection": 'row', "flexWrap": 'wrap', "justifyContent": 'center'}}>
                      {listDiv.map((item, index) => (
                          <div style={{margin:'10px 1%', textAlign:'center', overflow: 'hidden', "whiteSpace": 'nowrap', "display": 'inlineBlock'}}
                               onDragStart={(e) => dragStartDivV2(e, item, index, dragItemDiv)}
                               onDragEnter={(e) => dragEnterDivV2(e, item, index, dragOverItemDiv)}
                               onDragEnd={(e) => dropDivV2(e, dragItemDiv, dragOverItemDiv, listDiv, setListDiv, actualAnswers, setActualAnswers, answersIsMatch, setAnswersIsMatch)}
                               id={index}
                               key={`pool-${index}`}
                               draggable={
                                 (getIsDragAndDrop(dragAndDrop, topicMd) && listAnswerDiv.length > 1) &&
                                 !(getTotalAttempts() >= configs.attempts) &&
                                 !isLoadingTest && !isReplay
                               }>
                               <Button wrapText={false}>{item}</Button>
                          </div>
                      ))}
                      </div>
                </div>}
            </SpaceBetween>
            <SpaceBetween size="s" direction="horizontal">
              <Button variant="primary"
                      loading={isLoadingTest}
                      disabled={
                        (roundState[testIndex] && !roundState[testIndex].actualAnswers
                            .some((item, k) => item.some((value, i) => (value !== toLowerCase(expectedAnswers[k][i])))))
                            ||
                        (getTotalAttempts() >= configs.attempts)
                            ||
                        (isReplay)
                      }
                      onClick={() => {
                        commitTry();
                      }}
                      iconName={"check"}
              >Try</Button>
              <Button variant="normal"
                      loading={isLoadingTest}
                      disabled={
                        getTotalAttempts() >= configs.attempts || isReplay
                      }
                      onClick={() => {
                        setAnswersIsMatch(actualAnswers.map(item => item.map((question) => undefined)));
                        // const newActualAnswers = actualAnswers.map((question) => undefined);
                        // setActualAnswers(newActualAnswers);
                      }}
              >Clear</Button>
              <Button variant="normal"
                      loading={isLoadingTest}
                      disabled={
                        (getTotalAttempts() >= configs.attempts)
                        || isReplay
                        || (totalHintedAnswers >= configs.maxHintedAnswers)
                      }
                      onClick={() => {
                        setTotalHintedAnswers(totalHintedAnswers + 1);
                        setAnswersIsMatch(actualAnswers.map(item => item.map((question) => ("info"))));
                      }}
              >Show</Button>
              <Button variant="primary"
                      loading={isLoadingTest}
                      disabled={testIndex === 0}
                      onClick={() => {
                        commitPrevious();
                      }}
                      iconName={"angle-left"}
              >Back</Button>
              <Button variant="primary"
                      loading={isLoadingTest}
                      onClick={() => {
                        commitNext();
                      }}
                      iconName={"angle-right"}
              >Next</Button>
            </SpaceBetween>
            </SpaceBetween>
            }
          </SpaceBetween>
        </Container>
      </SpaceBetween>
      {mediaOnToolBox && topicMd && !videoPip &&
      <div className="content" style={{
        borderRadius: '5px',
        position: "fixed", bottom: "1px", right: "1px", height: 315/2, width: 560/2, padding: '2px',
        zIndex: 1, background: "gray", color: "gray" }}
      >
       {props.topicMdControl(overrideTopicMd(topicMd, 315/2, 560/2))}
      </div>}
    </div>
  );
}