import React from 'react';

export const configs = {
  russian: [
    {
        type: "MultiOptionsQuestionPanel",
        resource: "verbsp3",
        language: "russian",
        level: "[demos] Russian",
        title:"Глаголы [Русский Язык 3 - A1]",
        options: [
                     ["get up", "Вставать", "встаю́", "встаёшь", "встаёт", "встаём", "встаёте", "встаю́т", "вставать", "встаю", "встаёшь", "встаёт", "встаём", "встаёте", "встают"],
                     ["wash hands/face", "Умываться", "умыва́юсь", "умыва́ешься", "умыва́ется", "умыва́емся", "умыва́етесь", "умыва́ются", "умываться", "умываюсь", "умываешься", "умывается", "умываемся", "умываетесь", "умываются"],
                     ["brush teeth", "Чистить", "чи́щу", "чи́стишь", "чи́стит", "чи́стим", "чи́стите", "чи́стят", "чистить", "чищу", "чистишь", "чистит", "чистим", "чистите", "чистят"],
                     ["wash", "Мыть", "мо́ю", "мо́ешь", "мо́ет", "мо́ем", "мо́ете", "мо́ют", "мыть", "мою", "моешь", "моет", "моем", "моете", "моют"],
                     ["wear", "Надевать", "надеваю", "надеваешь", "надевает", "надеваем", "надеваете", "надевают", "надевать", "надеваю", "надеваешь", "надевает", "надеваем", "надеваете", "надевают"],
                     ["study", "Изучать", "изуча́ю", "изуча́ешь", "изуча́ет", "изуча́ем", "изуча́ете", "изуча́ют", "изучать", "изучаю", "изучаешь", "изучает", "изучаем", "изучаете", "изучают"],
                     ["learn/teach", "Учить", "учу́", "у́чишь", "у́чит", "у́чим", "у́чите", "у́чат", "учить", "учу", "учишь", "учит", "учим", "учите", "учат"],
                     ["attend school", "Учиться", "учу́сь", "у́чишься", "у́чится", "у́чимся", "у́читесь", "у́чатся", "учиться", "учусь", "учишься", "учится", "учимся", "учитесь", "учатся"],
                     ["occupy oneself with", "Заниматься", "занима́юсь", "занима́ешься", "занима́ется", "занима́емся", "занима́етесь", "занима́ются", "заниматься", "занимаюсь", "занимаешься", "занимается", "занимаемся", "занимаетесь", "занимаются"],
                     ["see", "Ви́деть", "ви́жу", "ви́дишь", "ви́дит", "ви́дим", "ви́дите", "ви́дят", "видеть", "вижу", "видишь", "видит", "видим", "видите", "видят"],
                     ["eat", "Есть", "е́м", "е́шь", "е́ст", "еди́м", "еди́те", "едя́т", "есть", "ем", "ешь", "ест", "едим", "едите", "едят"],
                     ["eat", "Кушать", "ку́шаю", "ку́шаешь", "ку́шает", "ку́шаем", "ку́шаете", "ку́шают", "кушать", "кушаю", "кушаешь", "кушает", "кушаем", "кушаете", "кушают"],
                     ["dream", "Мечтать", "мечта́ю", "мечта́ешь", "мечта́ет", "мечта́ем", "мечта́ете", "мечта́ют", "мечтать", "мечтаю", "мечтаешь", "мечтает", "мечтаем", "мечтаете", "мечтают"],
                     ["bring", "Приноси́ть (принести)", "приношу́", "прино́сишь", "прино́сит", "прино́сим", "прино́сите", "прино́сят", "приносить", "приношу", "приносишь", "приносит", "приносим", "приносите", "приносят"],
                     ["kiss", "Целова́ть (поцелова́ть)", "целу́ю", "целу́ешь", "целу́ет", "целу́ем", "целу́ете", "целу́ют", "целовать", "целую", "целуешь", "целует", "целуем", "целуете", "целуют"],
                     ["fly", "Лета́ть", "лета́ю", "лета́ешь", "лета́ет", "лета́ем", "лета́ете", "лета́ют", "летать", "летаю", "летаешь", "летает", "летаем", "летаете", "летают"],
                     ["fly", "Лете́ть", "лечу́", "лети́шь", "лети́т", "лети́м", "лети́те", "летя́т", "лететь", "лечу", "летишь", "летит", "летим", "летите", "летят"],
                     ["look out", "Выгля́дывать (вы́глянуть)", "выгля́дываю", "выгля́дываешь", "выгля́дывает", "выгля́дываем", "выгля́дываете", "выгля́дывают", "выглядывать", "выглядываю", "выглядываешь", "выглядывает", "выглядываем", "выглядываете", "выглядывают"],
                     ["call out", "Звать (позвать)", "зову́", "зовёшь", "зовёт", "зовём", "зовёте", "зову́т", "звать", "зову", "зовёшь", "зовёт", "зовём", "зовёте", "зовут"],
                     ["wait", "Ждать", "жду́", "ждёшь", "ждёт", "ждём", "ждёте", "жду́т", "ждать", "жду", "ждёшь", "ждёт", "ждём", "ждёте", "ждут"]
                   ],
        renderControl: {
            type: "toggleable",
            path: "глоголы-ч3",
            imgOptionIndex: 1,
            extension: ".jpeg",
            textOptionIndex: 0
        },
        settings: { imageOnly: true, doShuffle: true },
        questionsArray: ["что это значит", "я", "ты", "он/она́/оно́", "мы", "вы", "они́"],
        questionTitle: "Спряга́ть",
        functionalQuestionPre: {
            fixedList: ["что это значит", "я", "ты", "он/она́/оно́", "мы", "вы", "они́"]
        },
        answerIndexStart: 8,
        hintIndexStart: 1
    },
    {
        type: "MultiOptionsQuestionPanel",
        resource: "adjectivesp1",
        language: "russian",
        level: "[demos] Russian",
        title:"Прилагательное [Русский Язык 3 - A1]",
        options: [
                     // -ый (-ой), -ая, -ое, -ые
                     // мой, моя, мое, мои
                     ["new", "Но́вый (new)", "дом", "книга", "платье", "книги", "но́вый", "но́вая", "но́вое", "но́вые", "новый", "новая", "новое", "новые"],
                     ["young", "Молодой (young)", "врач", "артистка", "дерево", "люди", "молодо́й", "молода́я", "молодо́е", "молодые", "молодой", "молодая", "молодое", "молодые"],
                     ["new", "Но́вый (new)", "дом", "улица", "здание", "магазины", "но́вый", "но́вая", "но́вое", "но́вые", "новый", "новая", "новое", "новые"],
                     ["old", "Старый (old)", "район", "площадь", "здание", "машины", "старый", "старая", "старое", "старые", "старый", "старая", "старое", "старые"],
                     ["white", "Белый (white)", "снег", "снежинка", "окно", "деревья", "белый", "белая", "белое", "белые", "белый", "белая", "белое", "белые"],
                     ["black", "Чёрный (black)", "карандаш", "ручка", "кресло", "портфели", "чёрный", "чёрная", "чёрное", "чёрные", "чёрный", "чёрная", "чёрное", "чёрные"],
                     ["interesting", "Интересный (interesting)", "роман", "книга", "кино", "рассказы", "интересный", "интересная", "интересное", "интересные", "интересный", "интересная", "интересное", "интересные"],
                     ["lovely", "Любимый (lovely)", "фильм", "машина", "платье", "времена года", "любимый", "любимая", "любимое", "любимые", "любимый", "любимая", "любимое", "любимые"],
                     ["elegant", "Элегантный (elegant)", "пуловер", "юбка", "платье", "туфли", "элегантный", "элегантная", "элегантное", "элегантные", "элегантный", "элегантная", "элегантное", "элегантные"],
                     // -ий, -яя, -ее, -ие
                     ["blue", "Си́ний (blue)", "костюм", "рубашка", "пальто", "костюмы", "си́ний", "си́няя", "си́нее", "си́ние", "синий", "синяя", "синее", "синие"],
                     ["last", "Последний (last)", "автобус", "страница", "письмо", "слова", "последний", "последняя", "последнее", "последние", "последний", "последняя", "последнее", "последние"],
                     ["yesterday’s", "Вчерашний (yesterday’s)", "разговор", "лекция", "собрание", "газеты", "вчерашний", "вчерашняя", "вчерашнее", "вчерашние", "вчерашний", "вчерашняя", "вчерашнее", "вчерашние"],
                     ["neighboring, adjacent", "Соседний (neighboring, adjacent) ", "класс", "комната", "здание", "дома", "соседний", "соседняя", "соседнее", "соседние", "соседний", "соседняя", "соседнее", "соседние"],
                     ["summer(adjective)", "Летний (summer(adjective))", "день", "погода", "платье", "каникулы", "летний", "летняя", "летнее", "летние", "летний", "летняя", "летнее", "летние"],
                     ["autumn(adjective)", "Осенний (autumn(adjective))", "семестр", "погода", "солнце", "туфли", "осенний", "осенняя", "осеннее", "осенние", "осенний", "осенняя", "осеннее", "осенние"],
                     ["spring(adjective)", "Весенний (spring(adjective)) ", "дождь", "погода", "настроение", "каникулы", "весенний", "весенняя", "весеннее", "весенние", "весенний", "весенняя", "весеннее", "весенние"],
                     ["winter(adjective)", "Зимний (winter(adjective))", "спорт", "шапка", "пальто", "месяцы", "зимний", "зимняя", "зимнее", "зимние", "зимний", "зимняя", "зимнее", "зимние"],
                     ["last", "После́дний (last)", "трамва́й", "страница", "задание", "носки", "последний", "последняя", "последнее", "последние", "последний", "последняя", "последнее", "последние"],
                     ["today's", "Сегодняшний (today's)", "гость", "мода", "событие", "газеты", "сегодняшний", "сегодняшняя", "сегодняшнее", "сегодняшние", "сегодняшний", "сегодняшняя", "сегодняшнее", "сегодняшние"],
                   ],
        renderControl: {
            type: "text",
            optionIndex: 0
        },
        settings: { hasImage: true, skipPlaceholder: true, imageOnly: false, doShuffle: true, height: 80 },
        questionsArray: ["м", "ж", "с", "пл"],
        questionTitle: "Составьте фразы из данных слов",
        functionalQuestionPre: {
            fixedList: ["Это", "Это", "Это", "Это"]
        },
        functionalQuestionPost: {
            optionIndex: 2
        },
        functionalImageQuestionPost: {
            path: "прилоготельное-ч1",
            extension: ".jpeg",
            optionIndex: 2
        },
        answerIndexStart: 10,
        hintIndexStart: 6
    },
    {
        type: "Puzzle",
        resource: "puzzlev1",
        language: "russian",
        level: "[demos] Russian",
        title: "Cross Words - Квартире [Русский Язык 5 - A1]",
        program: "Irina Spinosa",
        settings: { doShuffle: true, maxQuestions: 10, maxHintedAnswers: 3 },
        options: [
          {
            size: {
              rows: 13, cols: 17
            },
            validChars: "йцукенгшщзхъёфывапролджэячсмитьбю",
            qs: {
                  "vertical": [
                    {
                      "startingCell": {
                        "row": 0,
                        "col": 5
                      },
                      "word": "люстра",
                      "description": "Висит на потолоке. Даёт свет",
                      "cellDirection": "ArrowDown"
                    },
                    {
                      "startingCell": {
                        "row": 3,
                        "col": 8
                      },
                      "word": "гостиная",
                      "description": "Комната, где мы отдыхаем, смотрим телевизор",
                      "cellDirection": "ArrowDown"
                    },
                    {
                      "startingCell": {
                        "row": 3,
                        "col": 12
                      },
                      "word": "балкон",
                      "description": "Место в доме, где можно стоять или сидеть на улица",
                      "cellDirection": "ArrowDown"
                    },
                    {
                      "startingCell": {
                        "row": 6,
                        "col": 4
                      },
                      "word": "подъезд",
                      "description": "Главный вход.",
                      "cellDirection": "ArrowDown"
                    },
                    {
                      "startingCell": {
                        "row": 8,
                        "col": 10
                      },
                      "word": "ковер",
                      "description": "Лежит на полу. Мягкий и тёплый",
                      "cellDirection": "ArrowDown"
                    },
                    {
                      "startingCell": {
                        "row": 1,
                        "col": 16
                      },
                      "word": "плита",
                      "description": "Стоит на кухне. На ней мы готовим. Горячая",
                      "cellDirection": "ArrowDown"
                    }
                  ],
                  "horizontal": [
                    {
                      "startingCell": {
                        "row": 0,
                        "col": 2
                      },
                      "word": "спальня",
                      "description": "Комната, где мы спим",
                      "cellDirection": "ArrowRight"
                    },
                    {
                      "startingCell": {
                        "row": 2,
                        "col": 14
                      },
                      "word": "пол",
                      "description": "Находится внизу в доме или в квартире",
                      "cellDirection": "ArrowRight"
                    },
                    {
                      "startingCell": {
                        "row": 4,
                        "col": 3
                      },
                      "word": "коридор",
                      "description": "Находится в доме лил в квартире. Обычно длинный",
                      "cellDirection": "ArrowRight"
                    },
                    {
                      "startingCell": {
                        "row": 4,
                        "col": 11
                      },
                      "word": "паркет",
                      "description": "Лежит на полу. Деревянный, твёрдый",
                      "cellDirection": "ArrowRight"
                    },
                    {
                      "startingCell": {
                        "row": 8,
                        "col": 0
                      },
                      "word": "холодильник",
                      "description": "Стоит на кухне. В нём еда. Холодный",
                      "cellDirection": "ArrowRight"
                    },
                    {
                      "startingCell": {
                        "row": 10,
                        "col": 0
                      },
                      "word": "туалет",
                      "description": "Маленькая комната, где стоит унитаз",
                      "cellDirection": "ArrowRight"
                    },
                    {
                      "startingCell": {
                        "row": 10,
                        "col": 10
                      },
                      "word": "ванная",
                      "description": "Небольшая комната, где находится ванна лил...",
                      "cellDirection": "ArrowRight"
                    },
                    {
                      "startingCell": {
                        "row": 6,
                        "col": 6
                      },
                      "word": "потолок",
                      "description": "Находится наверху в доме или квартире",
                      "cellDirection": "ArrowRight"
                    }
                  ]
                }
          }
        ]
    },
  ],
  italiano: [
    {
        type: "MultiQuestionMultiOptionsPanel",
        resource: "doveeilgatto",
        language: "italiano",
        level: "[demos] Italiano",
        program: "Passione Italiana",
        title: "Preposizioni [Italiano A2]",
        settings: { doShuffle: true, maxQuestions: 10, imgHeight: 120, attempts: 12, dragAndDrop: true },
        img: {
            path:"italian/Dov'è il gatto",
            extension: ".png"
        },
        options:[
            {
              qs: [
                `Il gatto è {"answer":"tra la bambina e il bambino","isImage":true,"imageFileName":"tra la bambina e il bambino"}.`,
                `Il gatto è {"answer":"tra il divano e le tende","isImage":true}.`,
                `Il gatto è {"answer":"sulla sedia","isImage":true}.`,
              ]
            },
            {
              qs: [
                `Il gatto è {"answer":"sul divano","isImage":true}.`,
                `Il gatto è {"answer":"fuori dallo scatolone","isImage":true}.`,
                `Il gatto è {"answer":"fuori dalla stanza","isImage":true}.`,
              ]
            },
            {
              qs: [
                `Il gatto è {"answer":"lontano dal letto","isImage":true}.`,
                `Il gatto è {"answer":"al centro del tavolo","isImage":true}.`,
                `Il gatto è {"answer":"al centro della stanza","isImage":true}.`,
              ]
            },
            {
              qs: [
                `Il gatto è {"answer":"a sinistra del camino","isImage":true}.`,
                `Il gatto è {"answer":"a destra del camino","isImage":true}.`,
                `Il gatto è {"answer":"in fondo al corridoio","isImage":true}.`,
              ]
            },
            {
              qs: [
                `Il gatto è {"answer":"accanto alla finestra","isImage":true}.`,
                `Il gatto è {"answer":"di fianco alla finestra","isImage":true}.`,
                `Il gatto è {"answer":"accanto all'armadio","isImage":true}.`,
              ]
            },
            {
              qs: [
                `Il gatto è {"answer":"di fianco all'armadio","isImage":true}.`,
                `Il gatto è {"answer":"di fronte alla tv","isImage":true}.`,
                `Il gatto è {"answer":"di fronte al camino","isImage":true}.`,
              ]
            },
            {
              qs: [
                `Il gatto è {"answer":"davanti alla poltrona","isImage":true}.`,
                `Il gatto è {"answer":"davanti al divano","isImage":true}.`,
                `Il gatto è {"answer":"vicino alla poltrona","isImage":true}.`,
              ]
            },
            {
              qs: [
                `Il gatto è {"answer":"vicino alla poltrona","isImage":true}.`,
                `Il gatto è {"answer":"vicino al divano","isImage":true}.`,
                `Il gatto è {"answer":"dentro lo scatolone","isImage":true}.`,
              ]
            },
            {
              qs: [
                `Il gatto è {"answer":"nello scatolone","isImage":true}.`,
                `Il gatto è {"answer":"dentro la cesta","isImage":true}.`,
                `Il gatto è {"answer":"nella cesta","isImage":true}.`,
              ]
            },
            {
              qs: [
                `Il gatto è {"answer":"nello scatolone","isImage":true}.`,
                `Il gatto è {"answer":"dentro la cesta","isImage":true}.`,
                `Il gatto è {"answer":"nella cesta","isImage":true}.`,
              ]
            },
            {
              qs: [
                `Il gatto è {"answer":"nello scatolone","isImage":true}.`,
                `Il gatto è {"answer":"dentro la cesta","isImage":true}.`,
                `Il gatto è {"answer":"nella cesta","isImage":true}.`,
              ]
            },
            {
              md: { titleOverride: "Dove è il fiore?" },
              qs: [
                `Il fiore è {"answer":"tra la lampada e il computer","isImage":true,"imageFileName":"il fiore è tra la lampada e il computer"}.`,
                `Il fiore è {"answer":"sotto la sedia","isImage":true,"imageFileName":"il fiore è sotto la sedia"}.`,
              ]
            },
            {
              md: { titleOverride: "Dove è il fiore?" },
              qs: [
                `Il fiore è {"answer":"nella scatola","isImage":true,"imageFileName":"il fiore è nella scatola"}.`,
                `Il fiore è {"answer":"fuori dalla scatola","isImage":true,"imageFileName":"il fiore è fuori dalla scatola"}.`,
                `Il fiore è {"answer":"dentro la scatola","isImage":true,"imageFileName":"il fiore è dentro la scatola"}.`,
              ]
            },
            {
              md: { titleOverride: "Dove sono?" },
              qs: [
                `Marina è seduta {"answer":"accanto a","isImage":true,"imageFileName":"Marina è seduta accanto a Stefano"} Stefano.`,
                `Marina è seduta {"answer":"di fianco a","isImage":true,"imageFileName":"Marina è seduta di fianco a Stefano"} Stefano.`,
              ]
            },
            {
              md: { titleOverride: "Dove sono?" },
              qs: [
                `La libreria è {"answer":"in fondo al corridoio","isImage":true,"imageFileName":"la libreria è in fondo al corridoio"} Stefano.`,
                `il poster è {"answer":"sopra la scrivania","isImage":true,"imageFileName":"il poster è sopra la scrivania"}.`,
              ]
            },
            {
              md: { titleOverride: "Dove sono?" },
              qs: [
                `i libri sono {"answer":"dentro lo zaino","isImage":true,"imageFileName":"i libri sono dentro lo zaino"}.`,
                `i libri sono {"answer":"nello zaino","isImage":true,"imageFileName":"i libri sono nello zaino"}.`,
                `il computer è {"answer":"sotto il tavolo","isImage":true,"imageFileName":"il computer è sotto il tavolo"}.`,
              ]
            },
        ],
        questionTitle: "Dove è il gatto?"
    },
    {
        type: "MultiOptionsQuestionPanel",
        resource: "verbip1",
        language: "italiano",
        level: "[demos] Italiano",
        program: "Diana Rosato",
        title:"Verbi [Italiano A1]",
        settings: { doShuffle: true, maxQuestions: 10, dragAndDrop: true },
        options:[
            ["Essere", "ser", "sono", "sei", "è", "siamo", "siete", "sono"],
            ["Avere", "tener", "ho", "hai", "ha", "abbiamo", "avete", "hanno"],
            ["Sognare", "soñar", "sogno", "sogni", "sogna", "sogniamo", "sognate", "sognano"],
            ["Abitare", "vivir", "abito", "abiti", "abita", "abitiamo", "abitate", "abitano"],
            ["Arrivare", "llegar", "arrivo", "arrivi", "arriva", "arriviamo", "arrivate", "arrivano"],
            ["Ascoltare", "escuchar", "ascolto", "ascolti", "ascolta", "ascoltiamo", "ascoltate", "ascoltano"],
            ["Aspettare", "esperar", "aspetto", "aspetti", "aspetta", "aspettiamo", "aspettate", "aspettano"],
            ["Ballare", "bailar", "ballo", "balli", "balla", "balliamo", "ballate", "ballano"],
            ["Cantare", "cantar", "canto", "canti", "canta", "cantiamo", "cantate", "cantano"],
            ["Chiamare", "llamar", "chiamo", "chiami", "chiama", "chiamiamo", "chiamate", "chiamano"],
            ["Comprare", "comprar", "compro", "compri", "compra", "compriamo", "comprate", "comprano"],
            ["Cucinare", "cocinar", "cucino", "cucini", "cucina", "cuciniamo", "cucinate", "cucinano"],
            ["Domandare", "preguntar", "domando", "domandi", "domanda", "domandiamo", "domandate", "domandano"],
            ["Disegnare", "dibujar", "disegno", "disegni", "disegna", "disegniamo", "disegnate", "disegnano"],
            ["Mangiare", "comer", "mangio", "mangi", "mangia", "mangiamo", "mangiate", "mangiano"],
            ["Pagare", "pagar", "pago", "paghi", "paga", "paghiamo", "pagate", "pagano"],
            ["Parlare", "hablar", "parlo", "parli", "parla", "parliamo", "parlate", "parlano"],
            ["Leggere", "leer", "leggo", "leggi", "legge", "leggiamo", "leggete", "leggono"],
            ["Chiedere", "pedir", "chiedo", "chiedi", "chiede", "chiediamo", "chiedete", "chiedono"],
            ["Credere", "creer", "credo", "credi", "crede", "crediamo", "credete", "credono"],
            ["Prendere", "agarrar", "prendo", "prendi", "prende", "prendiamo", "prendete", "prendono"],
            ["Piacere", "gustar", "piaccio", "piaci", "piace", "piacciamo", "piacete", "piacciono"],
            ["Ricevere", "recibir", "ricevo", "ricevi", "riceve", "riceviamo", "ricevete", "ricevono"],
            ["Scegliere", "elegir", "scelgo", "scegli", "sceglie", "scegliamo", "scegliete", "scelgono"],
            ["Scrivere", "escribir", "scrivo", "scrivi", "scrive", "scriviamo", "scrivete", "scrivono"],
            ["Vedere", "ver", "vedo", "vedi", "vede", "vediamo", "vedete", "vedono"],
            ["Vivere", "vivir", "vivo", "vivi", "vive", "viviamo", "vivete", "vivono"],
            ["Dormire", "dormir", "dormo", "dormi", "dorme", "dormiamo", "dormite", "dormono"],
            ["Offrire", "ofrecer", "offro", "offri", "offre", "offriamo", "offrite", "offrono"],
            ["Partire", "partir / viajar", "parto", "parti", "parte", "partiamo", "partite", "partono"],
            ["Sentire", "sentir / escuchar", "sento", "senti", "sente",  "sentiamo", "sentite", "sentono"],
            ["Vestire", "vestir", "vesto", "vesti", "veste", "vestiamo", "vestite", "vestono"],
            ["Capire", "entender", "capisco", "capisci", "capisce", "capiamo", "capite", "capiscono"],
            ["Preferire", "preferir", "preferisco", "preferisci", "preferisce", "preferiamo", "preferite", "preferiscono"],
            ["Spedire", "enviar", "spedisco", "spedisci", "spedisce", "spediamo", "spedite", "spediscono"],
            ["Unire", "unir", "unisco", "unisci", "unisce", "uniamo", "unite", "uniscono"],
            ["Pulire", "limpiar", "pulisco", "pulisci", "pulisce", "puliamo", "pulite", "puliscono"],
            ["Chiarire", "aclarar", "chiarisco", "chiarisci", "chiarisce", "chiariamo", "chiarite", "chiariscono"],
            ["Finire", "terminar", "finisco", "finisci", "finisce", "finiamo", "finite", "finiscono"]
        ],
        renderControl: {
            type: "text",
            optionIndex: 0
        },
        questionsArray: [ "Cosa significa", "io", "tu", "lui/lei/Lei", "noi", "voi", "loro" ],
        functionalQuestionPre: {
            fixedList: [ "Cosa significa", "io", "tu", "lui/lei/Lei", "noi", "voi", "loro" ]
        },
        questionTitle: "Congiugazione",
        answerIndexStart: 1,
        hintIndexStart: 1
    },
    {
        type: "SingleQuestionMultiOptionsPanel",
        resource: "cionequizp1",
        language: "italiano",
        level: "[demos] Italiano",
        program: "Passione Italiana",
        title:"Ci o Ne? Quiz [Italiano A2]",
        settings: { doShuffle: true, maxQuestions: 10, dragAndDrop: true },
        options:[
            `Che cosa metti nell'insalata? {"answer":"Ci","isImage":false} metto l'olio e l'aceto balsamico.`,
//            `Vorrei fare una bella cena domani sera? Che cosa {"answer":"ne","isImage":false} dici?`,
//            `Hai visto i tuoi amici ieri sera? Sì, {"answer":"ne","isImage":false} ho visti alcuni.`,
//            `Sono andato a Sydney da molto tempo fa, ma {"answer":"ci","isImage":false} tornerò molto presto.`,
//            `Quanti cani hai? {"answer":"Ne","isImage":false} ho tre.`,
            `Spero di passare l'essame domani,{"answer":"ci","isImage":false} tengo molto.`,
//            `Ho molta paura dell'aereo, ma {"answer":"ci","isImage":false} viaggio spesso`,
//            `Che bella Palermo! {"answer":"Ne","isImage":false} sono sempre affascinato.`,
//            `Per arrivare in Sicilia {"answer":"ci","isImage":false} vogliono circa 10 ore in maccina.`,
//            `Sai che cosa è successo a Paolo? Non {"answer":"ne","isImage":false} so niente.`,
//            `Quando vado in palestra, {"answer":"ne","isImage":false} esco sempre rigenerato.`,
//
            `Vuoi uscire con Lisa? No, non {"answer":"ci","isImage":false} voglio uscire.`,
//            `Sei felice della sorpresa? Certo, {"answer":"ne","isImage":false} sono felicissima.`,
//            `Quanto latte hai comprato? {"answer":"Ne","isImage":false} ho comprato un litro.`,
//            `Hai riflettuto sui tuoi errori? Sì, {"answer":"ci","isImage":false} ho riflettuto molto.`,
//            `Siete già stati a Sorrento? Purtroppo non {"answer":"ci","isImage":false} siano ancora stati.`,
            `Vieni al cinema con noi stasera? Mi dispiace ma non {"answer":"ne","isImage":false} ho voglia.`,
//            `Hai sentito quello che ha detto Luca? Sì, ma non {"answer":"ci","isImage":false} credo.`,
//            `Susana ha bisogno di una penna? Sì, {"answer":"ne","isImage":false} ha bisogno.`,
//            `Che cosa hai messo sulla pizza? {"answer":"Ci","isImage":false} ho messo i funghi.`,
//            `Vi siete inscritti al canale di P.I.? Sì, {"answer":"ci","isImage":false} siamo inscritti.`,
//
//            `Abiti ancora a Trieste? No, non {"answer":"ci","isImage":false} abito più da dieci anni. Adesso vivo a Bologna.`,
            `Puoi venire a trovarmi. Cosa {"answer":"ne","isImage":false} dici?
             Mi piacerebbe, ma {"answer":"ci","isImage":false} vogliono troppe ore per arrivar{"answer":"ci","isImage":false}.
              Capisco. Se non poui venire, {"answer":"ci","isImage":false} vedremo in spiaggia in agosto.`,
//            `Ci sono dei locali nuovi a Cervia?
//            - Sì, {"answer":"ne","isImage":false} hanno aperti due iere.`,
//            `Carlo si è appena licenziato!
//            - Non {"answer":"ci","isImage":false} credo! Perché?`,
            `Luca si è sposato. Non {"answer":"ne","isImage":false} sapevi nulla?
            - Ieri abbiamo incontrato Pippo e {"answer":"ci","isImage":false} ha detto tutto.`,
//            `Se Pippo apre una nuova pizzeria, {"answer":"ci","isImage":false} andrò di securo.`,
//
//            `Hai visto il mio nuovo video? Che cosa {"answer":"ne","isImage":false} pensi?`,
//            `Potremo andare al cinema questa sera. Che cosa {"answer":"ne","isImage":false} pensi?`,
//            `Se non hai voglia di preparare il pranzo, {"answer":"ci","isImage":false} penso io.`,
//            `Compri tu il pane? Sì, {"answer":"ci","isImage":false} penso io.`,
//            `Ragazzi, non ho tempo di cucinare. {"answer":"Ci","isImage":false} pensate voi?`,
//
//            `Mi manca il nonno, {"answer":"ci","isImage":false} penso sempre.`,
//            `Abito a Ferrara e {"answer":"ci","isImage":false} sto bene.`,
//            `Questa è la mia nuova maglia, cosa {"answer":"ne","isImage":false} pensi.`,
//            `Luca è partito, {"answer":"ne","isImage":false} sei sicura?`,
        ],
        questionTitle: "Completare la frase"
    }
  ],
  deutsch: [
        {
            type: "MultiOptionsQuestionPanel",
            resource: "lekt12seasons",
            language: "deutsch",
            level: "[demos] German A1",
            title: "Jahreszeiten, Lekt 12 - German 3",
            options: [
                ["pic", "зима", "der Winter", "die Winter", "Dezember", "Januar", "Februar"],
                ["pic", "весна", "der Frühling", "die Frühlinge", "März", "April", "Mai"],
                ["pic", "лето", "der Sommer", "die Sommer", "Juni", "Juli", "August"],
                ["pic", "осень", "der Herbst", "die Herbste", "September", "Oktober", "November"]
            ],
            renderControl: {
                type: "mixed",
                path: "seasons - русский язык 3",
                extension: ".png",
                functionalIndex: 0,
                optionIndex: 1
            },
            questionsArray: ["singular", "plural", "Monate 1", "Monate 2", "Monate 3"],
            questionTitle: "Jahreszeit und Monaten",
            answerIndexStart: 2,
            hintIndexStart: 2
        },
        {
            type: "MultiOptionsQuestionPanel",
            resource: "deutschverben",
            language: "deutsch",
            level: "[demos] German A1",
            title: "Deutsch Verben - German 3",
            options: [
                ["like", "mögen", "mochte", "hat gemocht", "mag", "magst", "mag" , "mögen", "mögt", "mögen"],
                ["eat", "essen", "aß", "hat gegessen", "esse", "isst", "isst", "essen", "esst", "essen"],
                ["wish/want", "möchten", "möchtete ", "hat gemöchtet", "möchte", "möchtest", "möchte", "möchten", "möchtet", "möchten"]
            ],
            renderControl: {
                type: "text",
                optionIndex: 0
            },
            settings: { doShuffle: true, maxQuestions: 10, imgHeight: 120, attempts: 12, dragAndDrop: true },
            questionsArray: ["Infinitiv", "Imperfect", "Participle", "ich", "du", "er-sie", "wir", "ihr", "Sie-sie"],
            functionalQuestionPre: {
                fixedList: ["Infinitiv", "Imperfect", "Participle", "ich", "du", "er-sie", "wir", "ihr", "Sie-sie"]
            },
            questionTitle: "Auf Deutsch?",
            answerIndexStart: 1,
            hintIndexStart: 1
        },
  ],
    adjectives2Options: [
      // -ый (-ой), -ая, -ое, -ые
      // мой, моя, мое, мои
      ["the most", "Но́вый (new)", "дом", "книга", "платье", "книги", "но́вый", "но́вая", "но́вое", "но́вые", "новый", "новая", "новое", "новые"],
      ["gray haired", "Молодой (young)", "врач", "артистка", "дерево", "люди", "молодо́й", "молода́я", "молодо́е", "молодые", "молодой", "молодая", "молодое", "молодые"],
      ["red haired", "Но́вый (new)", "дом", "улица", "здание", "магазины", "но́вый", "но́вая", "но́вое", "но́вые", "новый", "новая", "новое", "новые"],
      ["tall", "Старый (old)", "район", "площадь", "здание", "машины", "старый", "старая", "старое", "старые", "старый", "старая", "старое", "старые"],
      ["not tall", "Белый (white)", "снег", "снежинка", "окно", "деревья", "белый", "белая", "белое", "белые", "белый", "белая", "белое", "белые"],
      ["fast", "Чёрный (black)", "карандаш", "ручка", "кресло", "портфели", "чёрный", "чёрная", "чёрное", "чёрные", "чёрный", "чёрная", "чёрное", "чёрные"],
      ["slow", "Интересный (interesting)", "роман", "книга", "кино", "рассказы", "интересный", "интересная", "интересное", "интересные", "интересный", "интересная", "интересное", "интересные"],
      ["cunning", "Любимый (lovely)", "фильм", "машина", "платье", "времена года", "любимый", "любимая", "любимое", "любимые", "любимый", "любимая", "любимое", "любимые"],
      ["stubburn", "Элегантный (elegant)", "пуловер", "юбка", "платье", "туфли", "элегантный", "элегантная", "элегантное", "элегантные", "элегантный", "элегантная", "элегантное", "элегантные"],
      // -ий, -яя, -ее, -ие
      ["strong", "Си́ний (blue)", "костюм", "рубашка", "пальто", "костюмы", "си́ний", "си́няя", "си́нее", "си́ние", "синий", "синяя", "синее", "синие"],
      ["hungry", "Последний (last)", "автобус", "страница", "письмо", "слова", "последний", "последняя", "последнее", "последние", "последний", "последняя", "последнее", "последние"],
      ["coward", "Вчерашний (yesterday’s)", "разговор", "лекция", "собрание", "газеты", "вчерашний", "вчерашняя", "вчерашнее", "вчерашние", "вчерашний", "вчерашняя", "вчерашнее", "вчерашние"],
      ["brave", "Соседний (neighboring, adjacent) ", "класс", "комната", "здание", "дома", "соседний", "соседняя", "соседнее", "соседние", "соседний", "соседняя", "соседнее", "соседние"],
      ["sage", "Летний (summer(adjective))", "день", "погода", "платье", "каникулы", "летний", "летняя", "летнее", "летние", "летний", "летняя", "летнее", "летние"],
      ["angry", "Осенний (autumn(adjective))", "семестр", "погода", "солнце", "туфли", "осенний", "осенняя", "осеннее", "осенние", "осенний", "осенняя", "осеннее", "осенние"],
      ["unkind", "Весенний (spring(adjective)) ", "дождь", "погода", "настроение", "каникулы", "весенний", "весенняя", "весеннее", "весенние", "весенний", "весенняя", "весеннее", "весенние"],
      ["single", "Зимний (winter(adjective))", "спорт", "шапка", "пальто", "месяцы", "зимний", "зимняя", "зимнее", "зимние", "зимний", "зимняя", "зимнее", "зимние"],
      ["pleasant", "После́дний (last)", "трамва́й", "страница", "задание", "носки", "последний", "последняя", "последнее", "последние", "последний", "последняя", "последнее", "последние"]
    ],
    genitiveOptionsP1: [
        `У (учитель) {"answer":"учительЯ","isImage":false} отпуск (42/день) {"answer":"сорок два дня","isImage":false}.`,
        `У (ребёнок) {"answer":"ребёнка","isImage":false} каникулы (92/день) {"answer":"девяносто два днЯ","isImage":false}
        – это (3/месяц) {"answer":"три месяца","isImage":false}.`,
        `У (рабо́тник) {"answer":"работника","isImage":false} на СеверЕ отпуск (72/день) {"answer":"семьдесят два дня","isImage":false}
        – это почти (2/месяц) {"answer":"два месяца и","isImage":false} (2/неделя) {"answer":"две недели","isImage":false}`,
        `У (рабо́тница) {"answer":"работники","isImage":false} на СеверЕ отпуск (72/день) {"answer":"семьдесят два дня","isImage":false}
        – это почти (2/месяц) {"answer":"два месяца и","isImage":false} (2/неделя) {"answer":"две недели","isImage":false}`,
        `У (студент) {"answer":"студента","isImage":false} летние каникулы (2/неделя) {"answer":"двЕ недели","isImage":false}.`,
        `У (студентка) {"answer":"студентки","isImage":false} летние каникулы (2/неделя) {"answer":"двЕ недели","isImage":false}.`,
        `У (инженер) {"answer":"инженера","isImage":false} и (врач) {"answer":"врача","isImage":false} отпуск (4/неделя) {"answer":"четыре неделИ","isImage":false}.`,
        `У (бык) {"answer":"быка","isImage":false} (Бок) {"answer":"бока","isImage":false} крутые.
         У (бык) {"answer":"быка","isImage":false} (Рог) {"answer":"рога","isImage":false} Витые.
         У (бык) {"answer":"быка","isImage":false} Большие ноги.
         У (бык) {"answer":"быка","isImage":false} характер строгий.`,
        `У (бычок) {"answer":"бычка","isImage":false} Как палки Ножки.
         У (бычок) {"answer":"бычка","isImage":false} Смешные Рожки.
         Хвостик У {"answer":"бычка","isImage":false} Короткий.
         У (бычок) {"answer":"бычка","isImage":false} характер кроткий!`
    ],
}
