import {useEffect, useRef, useState} from 'react';
import Header from "@cloudscape-design/components/header";
import Container from "@cloudscape-design/components/container";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Input from "@cloudscape-design/components/input";
import Button from "@cloudscape-design/components/button";
import HelpPanel from "@cloudscape-design/components/help-panel";
import {Select, StatusIndicator, TextContent} from "@cloudscape-design/components";
import Toggle from "@cloudscape-design/components/toggle";
import ProgressBar from "@cloudscape-design/components/progress-bar";
import Modal from "@cloudscape-design/components/modal";
import Box from "@cloudscape-design/components/box";

import { useContainerQuery } from '@cloudscape-design/component-toolkit';
import { cloneOptionsV2, gpTopicMdControlV2Keyed } from '../common/common'
import { RoundSettingsV2 } from './round-settings'
import { DisplaySummaryV2 } from './display-summary'
import { dropDivV2, dragStartDivV2, dragEnterDivV2, gpSetNextTestInputStateV2, calculateTotalAttemptsV2,
  gpGetNextTestInputStateV2,
  canDisableWhenInteractiveMode, showStatusIndicator, typeOfStatusIndicator, answerOfStatusIndicator
} from './question-common';


export function MultiQuestionMultiAttributesComponentV2(props) {
  const inputReference = useRef(null);
  const dragItemDiv = useRef();
  const dragOverItemDiv = useRef();

  // displayable state attributes
  const [nonBlanksPre, setNonBlanksPre] = useState([]);
  const [nonBlanksPost, setNonBlanksPost] = useState([]);
  const [actualAnswers, setActualAnswers] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [imagedAnswers, setImagedAnswers] = useState([]);
  const [dropDownAnswers, setDropDownAnswers] = useState([]);
  const [expectedAnswers, setExpectedAnswers] = useState([]);
  const [answersIsMatch, setAnswersIsMatch] = useState([]);
  const [listAnswerDiv, setListAnswerDiv] = useState([]);
  const [listDiv, setListDiv] = useState([]);
  const [topicMd, setTopicMd] = useState({});

  const [showAnswers, setShowAnswers] = useState(false);

  const [attributeOptions, setAttributeOptions] = useState([]);
  const [selectedAttribute, setSelectedAttribute] = useState(undefined);
  const [displayableText, setDisplayableText] = useState([]);

  function wordValueMapper(value, attribute) {
    return ({
      value: value,
      expectedAttribute: attribute,
      actualAttribute: undefined
    });
  }

  const colors = [
    "red",
    "green",
    "navy",
    "navy",
    "purple",
    "olive",
    "teal",
    "maroon",
    "gray",
    "blue",
    // TODO: pick more relevant colors and actually one per new attribute
    "red",
    "green",
    "navy",
    "navy",
    "purple",
    "olive",
    "teal",
    "maroon",
    "gray",
    "blue",
    // TODO: pick more relevant colors and actually one per new attribute
    "red",
    "green",
    "navy",
    "navy",
    "purple",
    "olive",
    "teal",
    "maroon",
    "gray",
    "blue",
  ]

  function loadTestInputFromRoundState(index, currentRoundState) {
    const oldTopicState = currentRoundState[index];
    setAttributeOptions(oldTopicState.attributes.map((attribute, index) => ({
      value: attribute,
      color: colors[index]
    })));
    const newAnswersIsMatch = oldTopicState.displayableText.map((itemRow, k) =>
      itemRow.filter((itemCell, i) => itemCell.expectedAttribute)
             .map((itemCell, i) => itemCell.actualAttribute ? (itemCell.actualAttribute === itemCell.expectedAttribute ? "success" : "error") : undefined)
      );
    setAnswersIsMatch(newAnswersIsMatch);
    setDisplayableText(oldTopicState.displayableText);
    setTopicMd(oldTopicState.topicMd);
    props.setTopicMd(oldTopicState.topicMd);
  }

  function saveTestInputIntoState(index, roundState, newActualAnswers) {
    let newRoundState = [...roundState];
    let oldInputState = newRoundState[index]
    const newAnswersIsMatch = displayableText.map((itemRow, k) =>
      itemRow.filter((itemCell, i) => itemCell.expectedAttribute)
              .map((itemCell, i) => itemCell.actualAttribute ? (itemCell.actualAttribute === itemCell.expectedAttribute ? "success" : "error") : undefined)
      );
    newRoundState[index] = {
      type: oldInputState.type,
      attributes: oldInputState.attributes,
      displayableText: displayableText,
      answersIsMatch: newAnswersIsMatch,
      topicMd: topicMd,
      attempts: oldInputState.attempts + 1
    };
    return newRoundState;
  }

  useEffect(() => {
    if (props.roundState && props.roundState.length > 0 && props.testIndex < props.roundState.length) {
      loadTestInputFromRoundState(props.testIndex, props.roundState);
    }
  }, [props.roundState, props.testIndex]);

  useEffect(() => {
    if (props.commitTry) {
      setAnswersIsMatch(answersIsMatch.map(item => item.map(answer => undefined)));
      const newAnswersIsMatch = displayableText.map((itemRow, k) =>
        itemRow.filter((itemCell, i) => itemCell.expectedAttribute)
                .map((itemCell, i) => itemCell.actualAttribute ? (itemCell.actualAttribute === itemCell.expectedAttribute ? "success" : "error") : undefined)
        );
      setAnswersIsMatch(newAnswersIsMatch);
      new Promise(resolve =>
          setTimeout(() => resolve(), 250)).then(() => console.log("await 1/2 sec(s)"))
          .then(() => {
            props.setIsLoadingTest(false);
            if (props.roundState[props.testIndex].attempts + 1 <= props.settings.attempts) {
              props.setRoundState(saveTestInputIntoState(props.testIndex, props.roundState, actualAnswers));
              if (inputReference.current) inputReference.current.focus();
            }
          });
      props.setCommitTry(false);
    }
  }, [props.commitTry]);

  useEffect(() => {
    if (props.commitClear) {
      const newDisplayableText = displayableText.map((itemRow, k) =>
        itemRow.map((itemCell, i) => ({
               value: itemCell.value,
               expectedAttribute: itemCell.expectedAttribute,
               actualAttribute: undefined
          }))
        );
      setDisplayableText(newDisplayableText);
      const newAnswersIsMatch = newDisplayableText.map((itemRow, k) =>
        itemRow.filter((itemCell, i) => itemCell.expectedAttribute)
                .map((itemCell, i) => itemCell.actualAttribute ? (itemCell.actualAttribute === itemCell.expectedAttribute ? "success" : "error") : undefined)
        );
      setAnswersIsMatch(newAnswersIsMatch);
      props.setCommitClear(false);
    }
  }, [props.commitClear]);

  useEffect(() => {
    if (props.commitShow) {
      setShowAnswers(true);
      props.setTotalHintedAnswers(props.totalHintedAnswers + 1);
      setTimeout(() => setShowAnswers(false), 2000);
      props.setCommitShow(false);
    }
  }, [props.commitShow]);

  useEffect(() => {
    if (props.commitPrevious) {
      if (inputReference.current) inputReference.current.focus();
      props.setCommitPrevious(false);
    }
  }, [props.commitPrevious]);

  useEffect(() => {
    if (props.commitNext) {
      if (inputReference.current) inputReference.current.focus();
      props.setCommitNext(false);
    }
  }, [props.commitNext]);

  return (
  <SpaceBetween direction="vertical" size="l">
    <SpaceBetween direction="vertical" size="xs">
    {displayableText.map((line, row) =>
      <SpaceBetween direction="horizontal" key={`line-sb-${row}`}>
      {line.map((word, col) =>
      <SpaceBetween direction="horizontal" size={props.isReplay ? "xxs" : undefined} alignItems="center" key={`line-word-sb-${row}-${col}`}>
        <div style={{fontWeight: word.color ? 'bold' : undefined, margin: '2px', color: word.color ? 'white' : undefined, fontStyle: word.color ? 'italic' : undefined, padding: word.color ? '0rem 0.3rem 0rem 0.3rem' : undefined, backgroundColor: word.color || undefined, borderRadius: '5px'}}
             key={`line-word-div-${row}-${col}`}
             onClick={() => {
               let tmpText = [...displayableText]
               let tmpCell = displayableText[row][col];
               const oldColor = tmpCell.color;
               tmpCell.color = oldColor ?
                 (!selectedAttribute || selectedAttribute.color === oldColor ? undefined : selectedAttribute.color)
                 :
                 (selectedAttribute ? selectedAttribute.color : undefined);
               tmpCell.actualAttribute = oldColor ?
                 (!selectedAttribute || selectedAttribute.color === oldColor ? undefined : selectedAttribute.value)
                 :
                 (selectedAttribute ? selectedAttribute.value : undefined);
               setDisplayableText(tmpText);
             }}
        >
          {((props.isReplay
              ||
            (props.settings.interactiveMode && ((props.roundState[props.testIndex].attempts >= props.settings.attempts))))
            && word.expectedAttribute) ?
            <div key={`line-word-value-${row}-${col}`} style={{fontWeight: 'bold', textDecoration: 'underline', fontStyle: 'italic' }}>{word.value}</div>
          :
            <div key={`line-word-value-${row}-${col}`}>{word.value}</div>}
        </div>
        {((props.isReplay
              ||
            (props.settings.interactiveMode && (word.expectedAttribute === word.actualAttribute || (props.roundState[props.testIndex].attempts >= props.settings.attempts))))
          && word.expectedAttribute) &&
          <StatusIndicator
            key={`line-word-status-${row}-${col}`}
            type={word.expectedAttribute === word.actualAttribute ? "success" : (word.actualAttribute ? "error" : "info")}
          >{word.expectedAttribute}
          </StatusIndicator>}
      </SpaceBetween>
      )}
      </SpaceBetween>
    )}
    </SpaceBetween>
    <SpaceBetween direction="horizontal" size="xs">
      {attributeOptions.map((attribute, k) =>
        <Button
          key={`attributes-btn-${k}`}
          disabled={props.isReplay}
          variant={selectedAttribute && selectedAttribute.value === attribute.value ? 'primary' : undefined}
          onClick={() => {
            setSelectedAttribute(selectedAttribute && selectedAttribute.value === attribute.value ? undefined : attribute);
          }}
        >
          <div key={`attributes-btn-content-${k}`} style={{color:attribute.color}}>{attribute.value}</div>
        </Button>
      )}
    </SpaceBetween>
  </SpaceBetween>
  );
}