import React, {useEffect} from 'react';
import './App.css';
import {Flashbar, SpaceBetween} from "@cloudscape-design/components";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import Container from '@cloudscape-design/components/container';

function RawContent(props) {
  return (<SpaceBetween direction="vertical" size="s">
      {(props.flashBarItems && props.flashBarItems.length > 0) &&
        <Flashbar items={props.flashBarItems}/>
      }
      {props.content}
    </SpaceBetween>
  )
}

export default function PortalContent(props) {

  useEffect(() => {
    if (props.setBreadcrumbs && props.headerGroupItems) {
      props.setBreadcrumbs(() => (<BreadcrumbGroup
          items={props.headerGroupItems}
          ariaLabel="Breadcrumbs"
          expandAriaLabel="Show path"
      />));
    }
  }, [])

  return (
      <div>
        {props.rawContent ?
            <RawContent {...props}/>
            :
            <Container header={props.header}>
              <RawContent {...props}/>
            </Container>
        }
      </div>);
}
